import React, { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import { models, utils } from 'gipsy-misc'

import { TaskObjectGroups, TaskSubGroups } from 'logic/allTasks/models'
import { getScheduledGroup, sortListByTypeAndScheduledTime, isBefore } from 'logic/allTasks'
import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import { setHighlightedEventId } from 'store/calendar/actions'
import usePageActions from 'features/hooks/usePageActions2'

const pageSource = 'all-tasks-view'

export default function wrapper(Component) {
  function AllTasksContainer() {
    const { getDroppableId } = useCalendarPanelContext()
    const {
      allItems,
      completeTask,
      completeTaskFromFS,
      createInlineTask,
      createSprint,
      deleteSprint,
      endSprint,
      getFocusedTaskId,
      handleCompletedSession,
      isTaskCreationAlertShown,
      onClickDelete,
      onClickDeleteFocusSession,
      onClickFocusSession,
      onClickOutsideSprint,
      onClickSprint,
      onTaskDroppedInSprint,
      onTitleChange,
      saveTask,
      sprintDeletePopup,
      sprintTasks,
      startFsAndCreateTask,
      updateFocusSession,
    } = usePageActions()
    const session = useSelector((state) => state.session)

    const allItemsRef = useRef(allItems)
    allItemsRef.current = allItems

    const sprintTasksRef = useRef(sprintTasks)
    sprintTasksRef.current = sprintTasks

    const itemList = useMemo(() => {
      const pageGroups = {
        [TaskObjectGroups.BACKLOG]: [],
        [TaskObjectGroups.LATER]: [],
        [TaskObjectGroups.TODAY]: [],
        [TaskObjectGroups.WEEK]: [],
      }

      // we create a map to know the first instance of reccuring items
      const mapRecSprintIdFirstInstanceId = Object.keys(allItems).reduce((map, id) => {
        const item = allItems[id]
        if (!item || item?.completed === 1 || item.completionTime) return map
        if (utils.sprint.isRecurrent(item)) {
          const recSprintId = utils.sprint.getRecSprintId(item)
          const firstInstanceId = map[recSprintId]
          if (firstInstanceId) {
            const firstInstance = allItems[firstInstanceId]
            if (isBefore(item, firstInstance)) {
              map[recSprintId] = id
            }
          } else {
            map[recSprintId] = id
          }
        }
        return map
      }, {})

      const pageItems = Object.keys(allItems).reduce((items, id) => {
        const item = allItems[id]

        if (!item || item?.completed === 1 || item.completionTime) return items
        if (utils.sprint.isRecurrent(item) && !item.tasks?.length) {
          const recSprintId = utils.sprint.getRecSprintId(item)
          const firstInstanceId = mapRecSprintIdFirstInstanceId[recSprintId]
          if (firstInstanceId !== id) return items
        }

        const group = getScheduledGroup(item)
        items[group].push(item)
        return items
      }, pageGroups)

      Object.keys(pageItems).forEach((group) => {
        const groupItems = pageItems[group]

        if (groupItems.length < 2) return groupItems

        pageItems[group] = sortListByTypeAndScheduledTime(groupItems)
      })

      return pageItems
    }, [allItems])

    const onCreateInlineTask = useCallback(
      async (task, { componentSource = 'inlineAddTask', tmpId, dontShowCreationAlert } = {}) => {
        const response = await createInlineTask({
          context: { componentSource, pageSource },
          dontShowCreationAlert,
          task,
          tmpId,
        })

        return response
      },
      [createInlineTask]
    )

    const onCreateInlineTaskFromSprint = useCallback(
      async (task) => {
        if (!task.sprintInfo) return

        await createInlineTask({
          context: { componentSource: 'sprint', pageSource },
          dontShowCreationAlert: true,
          task,
        })
      },
      [createInlineTask]
    )

    const handleStartFsAndCreateTask = useCallback(
      async (taskData) => {
        await startFsAndCreateTask({ pageSource, taskData })
      },
      [startFsAndCreateTask]
    )

    const onComplete = useCallback(
      async ({ id, value }) => {
        if (value) {
          await completeTask({ id })
        }
      },
      [completeTask]
    )

    const onClickDeleteSprint = useCallback(
      (sprint) => {
        sprintDeletePopup(sprint, {
          onConfirmed: (recurrenceOption) => {
            deleteSprint(sprint.id, recurrenceOption)
          },
        })
      },
      [deleteSprint, sprintDeletePopup]
    )

    const onCreateSprint = useCallback(
      async (sprint, callback) => {
        const response = await createSprint(sprint)
        callback?.(response)
        return response
      },
      [createSprint]
    )

    const onRemoveFromSprint = useCallback(
      (task) => {
        const updatedTask = utils.task.computeTaskOnChange(task, {
          paramName: 'sprintInfo',
          value: null,
        })

        saveTask(updatedTask)
      },
      [saveTask]
    )

    const onDropTaskInSprint = useCallback(
      async ({ destinationIndex, itemId, sprintId }) => {
        if (!sprintId || !itemId) return

        await onTaskDroppedInSprint({
          destinationIndex,
          sprintId,
          taskId: itemId,
        })
      },
      [onTaskDroppedInSprint]
    )

    const onDrop = useCallback(
      (draggableItem) => {
        if (!draggableItem.source || !draggableItem.destination) return

        const { id: draggableItemId, type: draggableType } = JSON.parse(draggableItem.draggableId)
        const { extraParams, subgroup: destinationSubgroup } = getDroppableId(draggableItem.destination.droppableId)

        if (draggableType !== models.item.type.SPRINT && destinationSubgroup === TaskSubGroups.SPRINT_TASKS) {
          onDropTaskInSprint({
            destinationIndex: draggableItem.destination.index,
            itemId: draggableItemId,
            sprintId: extraParams.sprintId,
          })
        }
      },
      [getDroppableId, onDropTaskInSprint]
    )

    const toHideTaskId = getFocusedTaskId()

    return (
      <Component
        allTasks={itemList}
        handleCompletedSession={handleCompletedSession}
        isTaskCreationAlertShown={isTaskCreationAlertShown}
        onClickDelete={onClickDelete}
        onComplete={onComplete}
        onCompleteFromFS={completeTaskFromFS}
        onCreateInlineTask={onCreateInlineTask}
        onClickDeleteFocusSession={onClickDeleteFocusSession}
        onClickDeleteSprint={onClickDeleteSprint}
        onClickFocusSession={onClickFocusSession}
        onClickOutsideSprint={onClickOutsideSprint}
        onClickSprint={onClickSprint}
        onCreateInlineTaskFromSprint={onCreateInlineTaskFromSprint}
        onCreateSprint={onCreateSprint}
        onDrop={onDrop}
        onEndSprint={endSprint}
        onRemoveFromSprint={onRemoveFromSprint}
        onUpdateFocusSession={updateFocusSession}
        onSave={saveTask}
        onTitleChange={onTitleChange}
        session={session}
        setHighlightedEventId={setHighlightedEventId}
        startFsAndCreateTask={handleStartFsAndCreateTask}
        toHideTaskId={toHideTaskId}
      />
    )
  }

  return React.memo(AllTasksContainer)
}
