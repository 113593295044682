import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'
import { DropdownSelector as ProjectDropdownSelector } from 'Project'
import { DropdownSelector as TagDropdownSelector } from 'Tag'
import React from 'react'

export const fieldHeight = 30
export const CardAttribute = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${fieldHeight}px;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.focused ? props.focusedColor || styles.colors.primaryColor : styles.colors.greyBorderColor)};
  position: relative;
  justify-content: center;
  padding: 5px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  min-width: 0;

  ${({ squared }) =>
    squared &&
    css`
      height: 32px;
      width: 32px;
    `}
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ noMargin }) => (noMargin ? 0 : 6)}px;
  position: relative;
  z-index: 2;
`

export const PlaceHolder = styled.div`
  color: ${(props) => props.color};
  font-size: ${styles.fonts.fontSizeSmall};
  line-height: 26px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const getColor = ({ isSelected, hasValue, selectedColor, valueColor }) => {
  if (isSelected) {
    return selectedColor || styles.colors.primaryColor
  } else if (hasValue) {
    return valueColor || styles.colors.textMediumDarkColor
  } else {
    return styles.colors.grey
  }
}

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
`

export const AttributesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${CardAttribute} {
    margin-right: 6px;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ProjectItem = ({ selected, entity: project }) => {
  return (
    <ProjectContainer key={project.id} selected={selected}>
      <ProjectDropdownSelector value={project} />
    </ProjectContainer>
  )
}

const ProjectContainer = styled.div`
  background-color: ${(props) => (props.selected ? styles.colors.veryLightGrey : '')};
  cursor: pointer;
  padding: 10px;
`

export const TagItem = ({ selected, entity: tag }) => {
  return (
    <TagContainer key={tag.id} selected={selected}>
      <TagDropdownSelector value={tag} />
    </TagContainer>
  )
}

const TagContainer = styled.div`
  background-color: ${(props) => (props.selected ? styles.colors.veryLightGrey : '')};
  cursor: pointer;
  padding: 10px;
`
