import { utils } from 'gipsy-misc'
import { ProjectItem, TagItem } from './components/commonUIComponents'
import get from 'lodash/get'
import moment from 'moment'

export function getMakeTriggerForProjectsTags(tags, projects, selectedTags, selectedProjects) {
  function tagDataProvider(token) {
    return tags
      ? tags
          .map((tag) => {
            tag.key = tag.id
            return tag
          })
          .filter(
            (tag) =>
              tag.name.toLowerCase().includes(token.toLowerCase()) &&
              utils.array.getObjectIndexInArray(selectedTags, tag, ['id']) === -1
          )
      : []
  }

  function projectDataProvider(token) {
    return projects
      ? projects
          .map((project) => {
            project.key = project.id
            return project
          })
          .filter(
            (project) =>
              project.name.toLowerCase().includes(token.toLowerCase()) &&
              utils.array.getObjectIndexInArray(selectedProjects, project, ['id']) === -1
          )
      : []
  }

  return {
    '#': {
      dataProvider: projectDataProvider,
      component: ProjectItem,
      output: () => '',
    },
    $: {
      dataProvider: tagDataProvider,
      component: TagItem,
      output: () => '',
    },
  }
}

export function buildCreatingItem(props) {
  const pinTime = get(props, 'pin.time')
  const defaultStruct = {
    projects: props.project ? [props.project] : undefined,
    when: props.when || {
      date: pinTime ? moment(pinTime).format('YYYY-MM-DD') : '',
    },
    title: props.title || '',
    urlsInfo: props.urlInfo ? [props.urlInfo] : undefined,
    to: props.assignee || utils.user.computeUserFromSessionUser(props.session.user),
    pin: pinTime && {
      time: pinTime,
    },
    estimatedTime: props.estimatedTime,
  }
  return props.item ? { ...defaultStruct, ...props.item } : defaultStruct
}

export function convertItemPropToArray(prop) {
  if (!prop) return undefined
  else {
    return Array.isArray(prop) ? prop : [prop]
  }
}
