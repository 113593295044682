import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { pushShortcutsGroup, popShortcutsGroup } from 'store/shortcuts/actions'
import { PrimaryButton, SecondaryButton } from 'gipsy-ui'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      pushShortcutsGroup,
      popShortcutsGroup,
    },
    dispatch
  ),
})

const componentName = 'ConfirmPanel'

class ConfirmPanel extends Component {
  componentDidMount() {
    const {
      actions: { pushShortcutsGroup },
      confirmLabel,
      cancelLabel,
    } = this.props
    pushShortcutsGroup(
      [
        {
          label: cancelLabel,
          key: 'Escape',
          callback: this.onCancel,
        },
        {
          label: confirmLabel,
          key: 'Enter',
          callback: this.onConfirm,
        },
      ],
      componentName
    )
  }

  componentWillUnmount() {
    const { popShortcutsGroup } = this.props.actions
    popShortcutsGroup(componentName)
  }

  onConfirm = () => {
    const { onConfirm } = this.props
    onConfirm && onConfirm()
  }

  onCancel = () => {
    const { onCancel } = this.props
    onCancel && onCancel()
  }

  render() {
    return (
      <Container>
        <Subtitle>{this.props.subtitle}</Subtitle>
        <ButtonContainer>
          <CancelButton height={32} onClick={this.onCancel} text={this.props.cancelLabel}></CancelButton>
          <ConfirmButton
            height={32}
            onClick={this.onConfirm}
            borderRadius={8}
            text={this.props.confirmLabel}></ConfirmButton>
        </ButtonContainer>
      </Container>
    )
  }
}

ConfirmPanel.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
}

export default connect(null, mapDispatchToProps, null, {
  pure: true,
})(ConfirmPanel)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Subtitle = styled.span`
  text-align: center;
`

const CancelButton = styled(SecondaryButton)`
  margin-right: 20px;
  min-width: 116px;
`

const ConfirmButton = styled(PrimaryButton)`
  min-width: 116px;
`
