import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import throttle from 'lodash/throttle'

import { mixpanel as mixpanelApi, multicalendarsync as multicalendarsyncApi } from 'gipsy-api'
import { constants, mixpanel, styles, translations, utils } from 'gipsy-misc'
import { Icon, SimpleButton } from 'gipsy-ui'

import { MultiSyncIcon } from 'features/calendar/components/CalendarList/components/commonUIComponents'
import { expandHandleWidth } from 'features/taskPanel/components/ExpandHandle'
import { handleAPIError } from 'store/app/actions'
import { refetchEvents } from 'store/calendar/actions'
import { setUser } from 'store/session/actions'

import { getAccounts } from 'store/accounts/selectors'
import Accounts from './components/Accounts'
import MultiSync from './components/Multisync'
import { getIsExpanded, setIsExpanded } from './utils'

export default function CalendarList({ hasNavigated, onExpandToggled, shouldCollapse }) {
  const dispatch = useDispatch()
  const emailAccounts = useSelector((state) => getAccounts(state))
  const user = useSelector((state) => state.session.user)

  const areCredsInvalid = useMemo(() => {
    for (let i = 0; i < emailAccounts.length; i++) {
      if (emailAccounts[i]?.areCredentialsInvalid || emailAccounts[i]?.missingScopes) {
        return true
      }
    }
    return false
  }, [emailAccounts])

  const [expanded, setExpanded] = useState(() => getIsExpanded())
  const [isSyncing, setSyncing] = useState(false)
  const [mainAccountEmail, setMainAccountEmail] = useState(null)
  const [showAddAccountPopup, setShowAddAccountPopup] = useState(false)
  const [showMultisyncSettings, setShowMultisyncSettings] = useState(false)
  const [syncOptionSaved, setSyncOptionSaved] = useState(user.settingsPreferences.calendar?.multiCalendarSyncKind)

  useEffect(() => {
    if (!mainAccountEmail) {
      const mainAccount = emailAccounts.find((account) => account.main)
      setMainAccountEmail(mainAccount?.email)
    }
  }, [emailAccounts, mainAccountEmail])

  useEffect(() => {
    if (shouldCollapse) {
      setExpanded(false)
    }
  }, [shouldCollapse])

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const toggleExpansion = () => {
    let wasExpanded

    setExpanded((prev) => {
      const newValue = !prev
      onExpandToggled?.(newValue)
      wasExpanded = newValue
      return newValue
    })

    if (wasExpanded) {
      mixpanelApi.track({ event: mixpanel.allCalendarsPanelExpandedEvent })
    }
  }

  const handleOpenMultisyncSettings = () => {
    let googleAccounts = 0

    emailAccounts.forEach((account) => {
      if (account.provider === 'Google') {
        googleAccounts++
      }
    })

    if (googleAccounts < 2) {
      setShowAddAccountPopup(true)
      return
    }

    setShowMultisyncSettings(true)
  }

  const handleCloseMultisyncSettings = () => {
    setShowMultisyncSettings(false)
  }

  const handleCloseAddAccountPopup = () => {
    setShowAddAccountPopup(false)
  }

  const handleSyncOptionSaved = throttle(async (syncOption, rollbackCb) => {
    if (syncOption === syncOptionSaved) {
      return
    }

    setSyncing(true)
    const result = await multicalendarsyncApi.select(syncOption)
    let shouldRollback = false

    if (result?.error || result?.status === constants.runningJobStatus.failed) {
      shouldRollback = true
      dispatch(handleAPIError({ data: { code: 'unexpected', ...result } }))
    } else if (result?.status === constants.runningJobStatus.notFound) {
      shouldRollback = true
    } else if (!result?.status) {
      // the job didn't run - because the option selected is the same as the one in the back-end
      setSyncing(false)
      return
    }

    if (shouldRollback) {
      setSyncing(false)
      rollbackCb?.()
      return
    } else {
      setSyncOptionSaved(syncOption)
    }

    setSyncing(false)

    const newCalendarSettings = { ...user.settingsPreferences.calendar }
    newCalendarSettings.multiCalendarSyncKind = syncOption
    const updatedUser = { ...user }
    updatedUser.settingsPreferences.calendar = newCalendarSettings
    dispatch(setUser(updatedUser))
    dispatch(refetchEvents())
  }, 1000)

  const initialAnimationState = hasNavigated ? 'collapsed' : expanded ? 'expanded' : 'collapsed'

  return (
    <Container
      animate={expanded ? 'expanded' : 'collapsed'}
      initial={initialAnimationState}
      variants={containerVariants}>
      <ExpandHandle expanded={expanded} onClick={toggleExpansion}>
        <AnimatePresence exitBeforeEnter>
          {expanded ? (
            <motion.div animate={'visible'} exit={'hidden'} initial={'hidden'} key='arrow' variants={iconVariants}>
              <Icon fill={styles.colors.primaryColor} icon='LineLeftArrow' size={10} stroke='none' />
            </motion.div>
          ) : (
            <motion.div
              animate={'visible'}
              exit={'hidden'}
              initial={'hidden'}
              key='expand-icon'
              variants={iconVariants}>
              <ExpandIcon
                fill='none'
                hoverFill='none'
                icon='ExpandCalendarList'
                size={30}
                stroke={styles.colors.primaryColor}
                dot={areCredsInvalid}
                dotSpaceTop={8}
                dotSpaceRight={2}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </ExpandHandle>
      <Content>
        <Header>
          <Title>{translations.calendarList.title}</Title>
          {utils.user.isUserInBetaMultiCalendarSync(user) && (
            <MultiSyncButton
              backgroundColor='white'
              borderColor={styles.colors.primaryColor}
              borderRadius={8}
              height={40}
              leftIcon={
                <MultiSyncIcon fill={styles.colors.primaryColor} icon='CalendarRepeat' size={16} stroke='none' />
              }
              onClick={handleOpenMultisyncSettings}
              text={translations.calendarList.multiSync}
              textColor={styles.colors.primaryColor}
              width={127}
            />
          )}
        </Header>
        <Description>{translations.calendarList.description}</Description>
        <Accounts onCloseAddAccountPopup={handleCloseAddAccountPopup} showAddAccountPopup={showAddAccountPopup} />
      </Content>
      {mainAccountEmail && showMultisyncSettings && (
        <MultiSync
          isSyncing={isSyncing}
          mainAccountEmail={mainAccountEmail}
          onClose={handleCloseMultisyncSettings}
          onSaveSyncOption={handleSyncOptionSaved}
          syncOptionSaved={syncOptionSaved}
        />
      )}
    </Container>
  )
}

const contentWidth = 375
const handleWidth = 32

const containerVariants = {
  collapsed: {
    transition: {
      duration: styles.transitions.calendarSlide,
      ease: 'easeInOut',
    },
    overflow: 'hidden',
    width: handleWidth,
  },
  expanded: {
    transition: {
      duration: styles.transitions.calendarSlide,
      ease: 'easeInOut',
    },
    transitionEnd: {
      overflow: 'visible',
    },
    width: contentWidth,
  },
}

const Container = styled(motion.div)`
  border-radius: 8px;
  box-shadow: 0px 10px 13px rgba(33, 21, 81, 0.09);
  height: 100%;
  position: relative;
  width: ${handleWidth}px;
`

Container.displayName = 'Container'

const ExpandHandle = styled.div`
  align-items: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  transition: background-color 300ms ease;
  width: ${handleWidth}px;
  z-index: 1;

  ${({ expanded }) =>
    expanded &&
    css`
      background-color: transparent;
    `}
`

ExpandHandle.displayName = 'ExpandHandle'

const iconVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

const ExpandIcon = styled(Icon)`
  path.arrow,
  path.settings {
    fill: ${styles.colors.primaryColor};
    stroke: none;

    :hover {
      fill: ${styles.colors.primaryColor};
    }
  }
`

ExpandIcon.displayName = 'ExpandIcon'

const Content = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  min-width: ${contentWidth - handleWidth}px;
  padding: 28px ${expandHandleWidth}px 24px 24px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`

Content.displayName = 'Content'

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

Header.displayName = 'Header'

const Title = styled.p`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
`

Title.displayName = 'Title'

const MultiSyncButton = styled(SimpleButton)`
  align-items: center;
  justify-content: center;
  display: flex;

  line {
    stroke: ${styles.colors.primaryColor};
  }
`

MultiSyncButton.displayName = 'MultiSyncButton'

const Description = styled.p`
  margin: 16px 0 28px;
`

Description.displayName = 'Description'
