import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'

import { translations } from 'gipsy-misc'

import {
  CloseIcon,
  Content,
  StyledBlurLayer,
  Subtitle,
  Title,
  TitleContainer,
} from 'features/calendar/components/CalendarList/components/commonUIComponents'
import { layoutBlurLayerPortalId } from 'features/layout/index'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

import SyncOptions from './SyncOptions'

const componentName = 'MultiSync'

export default function MultiSync({ isSyncing, mainAccountEmail, onClose, onSaveSyncOption, syncOptionSaved }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  const portalNode = document.querySelector(`#${layoutBlurLayerPortalId}`)

  return portalNode
    ? createPortal(
        <StyledBlurLayer active maxWidth='868px' name='lightbox' onClose={onClose} width='calc(100% - 128px)'>
          <Content>
            <CloseIcon icon='Close' onClick={onClose} size={12} />
            <TitleContainer>
              <Title>{translations.multiCalendarSettings.title}</Title>
            </TitleContainer>
            <Subtitle>{translations.multiCalendarSettings.description}</Subtitle>
            <SyncOptions
              isSyncing={isSyncing}
              mainAccountEmail={mainAccountEmail}
              onSave={onSaveSyncOption}
              optionSaved={syncOptionSaved}
            />
          </Content>
        </StyledBlurLayer>,
        portalNode
      )
    : null
}
