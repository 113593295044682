import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import moment from 'moment'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations, utils } from 'gipsy-misc'
import { PlayIcon, StepHelperComponents } from 'gipsy-ui'

const { Container: HelperContainer, StepHelper } = StepHelperComponents

export default function CreateFocusBlockStep({ onStepDone, startFsAndCreateTask, stepNumber, user }) {
  const handleStart = () => {
    startFsAndCreateTask({
      pageSource: 'onboarding',
      taskData: models.task({
        title: translations.onboarding.createFocusBlockStep.focusedLineTitle,
        when: { date: moment().format('YYYY-MM-DD') },
        to: utils.user.computeUserFromSessionUser(user),
      }),
    })
    mixpanelApi.track({ event: mixpanel.onboardingFocusSessionStartEvent })
    onStepDone(stepNumber)
  }

  return (
    <StepContainer>
      <StepLine
        animate={{ opacity: 1, transition: { duration: 0.3 }, y: 0 }}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0, y: 10 }}>
        <StartButton onClick={handleStart}>
          <ButtonIconContainer>
            <PlayIcon fillColor={styles.colors.primaryColor} />
          </ButtonIconContainer>
          <ButtonText>{translations.general.start}</ButtonText>
        </StartButton>
        <Title>{translations.onboarding.createFocusBlockStep.lineTitle}</Title>
      </StepLine>
      <StepHelper
        animate={{ opacity: 1, transition: { delay: 0.5, duration: 0.3 }, y: 0 }}
        currentStep={stepNumber}
        exit={{ opacity: 0, transition: { duration: 0.3 }, y: 15 }}
        helperText={translations.onboarding.createFocusBlockStep.helperText}
        initial={{ opacity: 0, y: 15 }}
      />
    </StepContainer>
  )
}

const StepContainer = styled.div`
  position: relative;

  ${HelperContainer} {
    left: -90px;
    top: calc(100% + 30px);
  }
`

StepContainer.displayName = 'StepContainer'

const StepLine = styled(motion.div)`
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: ${styles.shadows.lineShadow};
  display: flex;
  height: 64px;
  width: 525px;
`

StepLine.displayName = 'StepLine'

const StartButton = styled.div`
  align-items: center;
  background: ${styles.colors.primaryColor};
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  display: flex;
  height: 64px;
  padding-left: 16px;
  width: 85px;
`

StartButton.displayName = 'StartButton'

const ButtonIconContainer = styled.div`
  background: white;
  border-radius: 10px;
  margin-right: 5px;
  height: 20px;
  width: 20px;
`

ButtonIconContainer.displayName = 'ButtonIconContainer'

const ButtonText = styled.div`
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: white;
`

ButtonText.displayName = 'ButtonText'

const Title = styled.div`
  color: ${styles.colors.textMediumDarkColor};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 18px;
  padding-left: 16px;
`

Title.displayName = 'Title'
