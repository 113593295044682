import React, { PureComponent, Fragment } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { styles, translations, utils } from 'gipsy-misc'
import { SimpleButton } from 'ReusableButton/Buttons'

import Icon from 'Icon'
import get from 'lodash/get'

const propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.any,
  onSelect: PropTypes.func.isRequired,
  dropdownSelector: PropTypes.elementType.isRequired,
  isMulti: PropTypes.bool,
  createButtonIconName: PropTypes.string,
  inlineCreateButton: PropTypes.bool,
}

class LineAttributeDropdown extends PureComponent {
  state = {
    filter: '',
  }

  static propTypes = propTypes

  static defaultProps = {
    idField: 'id',
    filterAttribute: 'label',
  }

  isSelected = (option) => {
    const { isMulti, selectedOption, idField } = this.props
    if (isMulti) {
      if (selectedOption == null) return false
      if (Array.isArray(selectedOption)) {
        return !!selectedOption.find((selectedOptionItem) => selectedOptionItem[idField] === option.value[idField])
      } else {
        throw new Error('Dropdown with multiple select can only have an array or null as selectedOption')
      }
    } else {
      if (Array.isArray(selectedOption)) {
        throw new Error('Dropdown with simple select cannot have an array as selectedOption')
      }
      return !!selectedOption && selectedOption[idField] === option.value[idField]
    }
  }

  getFilterAttribute = () => {
    const { filterAttribute } = this.props
    if (filterAttribute === 'label') return filterAttribute
    return `value.${filterAttribute}`
  }

  onChangeFilter = (e) => {
    const { value } = e.target
    this.setState({ filter: value })
  }

  renderOptions = () => {
    const { dropdownSelector: DropdownSelector, options, emptyLabel } = this.props
    const { filter } = this.state
    const filterAttribute = this.getFilterAttribute()
    let filteredOptions = options

    if (filter) {
      const regex = new RegExp(filter, 'gi')
      filteredOptions = filteredOptions.filter((option) => get(option, filterAttribute).match(regex))
    }

    if (!filteredOptions.length) {
      return <EmptyLabel> {emptyLabel}</EmptyLabel>
    }

    return filteredOptions.map((option, index) => {
      const { value, label } = option
      const showSeparator = index !== options.length - 1
      const isSelected = this.isSelected(option)
      return (
        <Fragment key={value.id}>
          <DropdownSelector
            key={value.id}
            onClick={() => this.onSelect(option)}
            value={value}
            label={label}
            selected={isSelected}
          />
          {showSeparator && <Separator key={value.id + '-separator'} />}
        </Fragment>
      )
    })
  }

  onSelect = (option) => {
    const { onSelect } = this.props
    onSelect(option.value, !this.isSelected(option))
  }

  onBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur()
    }
  }

  render() {
    const {
      options,
      onClickCreateButton,
      createLabel,
      withCreate,
      emptyLabel,
      searchPlaceholder,
      maxWidth,
      width,
      setRef = noop,
      inlineCreateButton,
      createButtonIconName,
    } = this.props

    return (
      <Container maxWidth={maxWidth} width={width} ref={setRef}>
        <FlexContainer inlineCreateButton={inlineCreateButton}>
          <SearchBar
            value={this.state.filter}
            onChange={this.onChangeFilter}
            onClick={utils.DOM.stopPropagation}
            placeholder={searchPlaceholder || translations.general.search}
          />
          {withCreate ? (
            <CreateButton
              leftIcon={
                createButtonIconName ? <CreateButtonLeftIcon size={16} icon={createButtonIconName} /> : undefined
              }
              borderRadius={8}
              backgroundColor={'white'}
              borderColor={styles.colors.darkGrey}
              textColor={styles.colors.darkGrey}
              onClick={onClickCreateButton}
              text={createLabel}
            />
          ) : (
            <Separator />
          )}
        </FlexContainer>
        {options && options.length ? (
          <ItemsContainer>{this.renderOptions()}</ItemsContainer>
        ) : (
          <EmptyLabel> {emptyLabel}</EmptyLabel>
        )}
      </Container>
    )
  }
}

const Container = styled.div.attrs(({ maxWidth }) => ({
  style: {
    maxWidth: Number.isInteger(maxWidth) ? `${maxWidth}px` : 'none',
  },
}))`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => props.width}px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: ${styles.shadows.popup};
  background-color: white;
  margin-bottom: 20px;
`

const CreateButtonLeftIcon = styled(Icon)`
  display: inline-flex;
  margin-right: 8px;
  path {
    transition: fill 0.25s ease-in-out;
  }
`

const CreateButton = styled(SimpleButton)`
  border-radius: 8px;
  margin: 0 8px 8px 8px;
  transition: all 0.25s ease-in-out;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 32px;
  :hover {
    border-color: ${styles.colors.primaryColor};
    color: ${styles.colors.primaryColor};
    ${CreateButtonLeftIcon} {
      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }
`

const SearchBar = styled.input`
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeSmall};
  padding: 16px 16px 0 16px;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  margin-bottom: 16px;
  ::placeholder {
    color: ${styles.colors.darkGrey};
    font-size: ${styles.fonts.fontSizeSmall};
  }
`

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.inlineCreateButton ? 'row' : 'column')};
  padding: ${(props) => (props.inlineCreateButton ? '16px 9px 0 7px' : '0')};

  ${CreateButton} {
    ${(props) =>
      props.inlineCreateButton &&
      css`
        height: 40px;
        padding: 0 18px;
        width: max-content;
        min-width: fit-content;
      `}
  }

  ${SearchBar} {
    padding-top: ${(props) => (props.inlineCreateButton ? 12 : 16)}px;
  }
`
const EmptyLabel = styled.span`
  color: ${styles.colors.darkGrey};
  width: 100%;
  padding: 24px 16px;
  font-size: ${styles.fonts.fontSizeSmall};
  text-align: center;
  display: block;
`

const Separator = styled.span`
  width: 100%;
  height: 1px;
  display: flex;
  border-top: 1px solid ${styles.colors.greyBorderColor};
`

const ItemsContainer = styled.div`
  max-height: 276px;
  width: 100%;
  overflow-y: scroll;
`

export default LineAttributeDropdown
