import React, { useEffect, useLayoutEffect, useState, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { BlurLayer, EditingLineComponents } from 'gipsy-ui'
import moment from 'moment'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

import { pageBlurLayerPortalId } from 'features/layout'
import HomebaseLine from 'features/list/components/line'
import variables from 'assets/styles/variables'
import ClickOutside from 'features/app/ClickOutside'
import TaskPinControls from './TaskPinControls'
import { styles, utils, variables as miscVariables } from 'gipsy-misc'

const { LineContainer: EditingLineContainer } = EditingLineComponents

function TaskLineModal(props) {
  const [creatingItem, setCreatingItem] = useState(null)
  const [editingItem, setEditingItem] = useState(null)
  const [isActive, setActive] = useState(false)
  const [showLine, setShowLine] = useState(false)
  const [taskLineModalPosition, setTaskLineModalPosition] = useState(null)
  const taskLineModalRef = useRef(null)

  const {
    getCalendarRef,
    getSelectedSlotBounds,
    onSave,
    onCancel,
    onChangeDuration,
    onChangeStartTime,
    onTogglePin,
    onCreate,
    creatingCalendarTask,
    selectedSlot,
    onClickOutside,
    portalNode,
  } = props

  const computeModalPosition = useCallback(() => {
    const calendarRef = getCalendarRef()
    const selectedSlotBounds = getSelectedSlotBounds()

    if (taskLineModalRef.current && selectedSlotBounds && calendarRef) {
      const taskLineModalNode = ReactDOM.findDOMNode(taskLineModalRef.current)
      const taskLineModalBounds = taskLineModalNode.getBoundingClientRect()
      const calendarBounds = calendarRef.getBoundingClientRect()
      const topOffset = 32
      const maxTop = calendarBounds.height
      const minLeft = calendarBounds.left
      const maxLeft = calendarBounds.width - taskLineModalBounds.width
      let left = Math.max(
        selectedSlotBounds.left - taskLineModalBounds.width / 2 + selectedSlotBounds.width / 2,
        minLeft
      )
      let top = selectedSlotBounds.height + selectedSlotBounds.top - topOffset
      if (top + taskLineModalBounds.height >= maxTop) {
        top =
          selectedSlotBounds.top -
          (taskLineModalBounds.height + topOffset * 1.5 + selectedSlotBounds.height + selectedSlotBounds.height)
      }
      if (left <= minLeft) {
        left = selectedSlotBounds.left
      }
      if (left >= maxLeft) {
        left = Math.max(selectedSlotBounds.left - taskLineModalBounds.width + selectedSlotBounds.width, minLeft)
      }
      setTaskLineModalPosition({
        top,
        left,
      })
    }
  }, [getCalendarRef, getSelectedSlotBounds])

  useLayoutEffect(() => {
    if (!props.showModal) return
    if (selectedSlot) {
      const { start, end } = selectedSlot
      const startTime = moment(start)
      const endTime = moment(end)
      const allDay = !selectedSlot.item.pin
      const diff = endTime.diff(startTime, 'minutes')
      const estimatedTime = allDay ? 0 : utils.datetime.convertMinuteToNanoseconds(diff)
      const item = cloneDeep(selectedSlot.item) || {}
      item.estimatedTime = estimatedTime
      requestAnimationFrame(computeModalPosition)

      if (props.editingCalendarTask) {
        item.sprintInfo = null
        !allDay && (item.pin.time = startTime.format())
        setEditingItem(item)
      } else if (props.localEditingCalendarTask) {
        !allDay && (item.pin.time = startTime.format())
        setEditingItem(item)
      } else {
        setCreatingItem({
          ...item,
          when: {
            date: startTime.format('YYYY-MM-DD'),
          },
          pin: {
            time: startTime.format(),
          },
          estimatedTime,
          sprintInfo: null,
        })
      }
    } else {
      if (props.localEditingCalendarTask) {
        const taskWithoutPin = utils.task.computeTaskOnChange(
          props.localEditingCalendarTask,
          { paramName: 'pin.time', value: null },
          {
            estimatedTime: null,
          }
        )
        setEditingItem(taskWithoutPin)
      }
    }
  }, [selectedSlot, props.showModal, computeModalPosition, props.editingCalendarTask, props.localEditingCalendarTask])

  useEffect(() => {
    if (!props.showModal) {
      setActive(false)
      setCreatingItem(null)
      setEditingItem(null)
    } else {
      setActive(true)
    }
  }, [props.showModal])

  useEffect(() => setShowLine(isActive), [isActive])

  useLayoutEffect(() => {
    if (isActive && props.fixedPosition) {
      requestAnimationFrame(computeModalPosition)
    } else {
      setTaskLineModalPosition(null)
    }
  }, [isActive, computeModalPosition, props.fixedPosition])

  const getLineProps = useCallback(() => {
    let lineProps = {}
    if (editingItem) {
      lineProps = {
        onSave: onSave,
        startEdition: true,
        onCancelEdit: onCancel,
        onTogglePin: onTogglePin,
        item: editingItem,
        when: get(editingItem, 'when'),
        pin: get(editingItem, 'pin'),
        estimatedTime: get(editingItem, 'estimatedTime'),
        hideBlockToCalendarOption: editingItem.pin,
      }
    } else if (creatingItem) {
      lineProps = {
        creating: true,
        onCreate: (task) => onCreate(task, { componentSource: 'calendarPanel' }),
        startCreation: true,
        item: creatingItem,
        when: get(creatingItem, 'when'),
        urlInfo: get(creatingItem, 'urlInfo'),
        project: get(creatingItem, 'project'),
        pin: get(creatingItem, 'pin'),
        estimatedTime: get(creatingItem, 'estimatedTime'),
        title: get(creatingItem, 'title'),
        useTitleProps: true,
      }
      if (creatingCalendarTask) {
        lineProps = {
          ...lineProps,
          onCreate: (task) => onCreate(task, { componentSource: 'inlineAddTask' }),
          onTogglePin: onTogglePin,
        }
      }
    }

    return lineProps
  }, [editingItem, creatingItem, creatingCalendarTask, onSave, onCancel, onTogglePin, onCreate])

  const lineProps = getLineProps()

  const unpinHandler = useCallback(() => {
    onTogglePin({ item: lineProps.item, isCreating: lineProps.creating })
  }, [lineProps, onTogglePin])

  const getTaskPinControlsProps = useCallback(() => {
    if (!selectedSlot) return

    const estimatedTimeNS = utils.calendar.getDurationNSFromCalendarSlot(selectedSlot)
    const durationFromSelectedSlot = utils.datetime.getHourAndMinuteFromNanoseconds(estimatedTimeNS)

    return {
      selectedSlot,
      date: moment(selectedSlot.start).format('ddd, MMM D'),
      durationProps: {
        onChange: onChangeDuration,
        ...durationFromSelectedSlot,
      },
      startTimeProps: {
        hour: moment(selectedSlot.start).hours(),
        minute: moment(selectedSlot.start).minutes(),
        onChange: onChangeStartTime,
      },
      unpinHandler,
    }
  }, [onChangeDuration, onChangeStartTime, selectedSlot, unpinHandler])

  let renderNode = portalNode

  if (!renderNode) {
    renderNode = document.querySelector(`#${pageBlurLayerPortalId}`)

    if (!renderNode) return null
  }

  const showPinComponent = !!lineProps.item?.pin

  return ReactDOM.createPortal(
    <StyledBlurLayer
      name={'add-task-modal'}
      active={isActive}
      onClose={props.onCancel}
      position={taskLineModalPosition}
      fixedPosition={props.fixedPosition}
      coverCalendar={!!selectedSlot}>
      {showLine && (
        <ClickOutside targetRef={taskLineModalRef} callback={onClickOutside}>
          <LineContainer ref={taskLineModalRef} fixedPosition={props.fixedPosition} showPinComponent={showPinComponent}>
            <HomebaseLine
              ignoreOutsideClicks
              hideSprint
              onChange={props.onCurrentTaskChange}
              onCancel={props.onCancel}
              onDelete={props.onDelete}
              readOnlyDate
              canBlockToCalendar
              hideBlockToCalendarOption
              marginBottom={0}
              {...lineProps}
            />
            {showPinComponent && <TaskPinControls {...getTaskPinControlsProps()} />}
          </LineContainer>
        </ClickOutside>
      )}
    </StyledBlurLayer>,
    renderNode
  )
}

export default React.memo(TaskLineModal)

const StyledBlurLayer = styled(BlurLayer)`
  display: flex;

  &.add-task-modal-layer {
    position: absolute;
    z-index: ${miscVariables.zIndex.pageBlurLayer};

    ::before {
      background: ${styles.colors.textMediumDarkColor};
    }
  }

  ${(props) => {
    if (!props.fixedPosition) {
      if (props.coverCalendar) {
        return css`
          padding-right: ${variables.calendarPanelWidth + variables.calendarLateralPadding}px;
        `
      } else {
        return css`
          padding-right: ${variables.calendarPanelWidth}px;
        `
      }
    } else {
      return css`
        &.add-task-modal-layer {
          transition-delay: 0s;
          visibility: visible;
          position: absolute;
          top: ${(props.position && props.position.top) || 0}px;
          left: ${(props.position && props.position.left) || 0}px;
          right: unset;
          bottom: unset;
          width: auto;
          max-width: 750px;
          height: auto;
          ::before {
            background: transparent;
            border-radius: 10px;
          }
        }
      `
    }
  }}

  &.add-task-modal-layer .add-task-modal-content {
    overflow: visible;
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity ${(props) => (props.fixedPosition ? 0.2 : styles.transitions.blurLayer)}s ease-in-out;
  }

  &.add-task-modal-layer.active {
    .add-task-modal-content {
      opacity: 1;
    }
    ::before {
      opacity: 0.6;
    }
  }
`
StyledBlurLayer.displayName = 'StyledBlurLayer'

const LineContainer = styled.div`
  display: flex;
  max-width: 880px;
  position: absolute;
  top: 50%;
  width: 90%;

  & > div:nth-child(1) {
    flex: 1;
    margin-bottom: 8px;
    margin-right: 8px;
  }

  ${EditingLineContainer} {
    filter: ${styles.shadows.dropShadowPopup};
  }

  ${(props) =>
    props.fixedPosition
      ? css`
          min-width: 700px;
          position: static;
        `
      : css`
          transform: translateY(-50%);
          right: ${(props) => (props.showPinComponent ? 12 : 12 + 42)}px;
        `};
`
LineContainer.displayName = 'LineContainer'
