import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { SupportChat, supportChatPortalId } from 'features/supportchat'
import { styles } from 'gipsy-misc'

import { NavBoxButton, NavBoxButtonIcon } from './commonUIComponents'

function NavBarSupport({ showLeftBorder = true, showRightBorder = true }) {
  const [showSupportChat, setShowSupportChat] = useState(false)
  const [unreadConversationsCount, setUnreadConversationsCount] = useState(false)

  const toggleShowSupportChat = () => {
    setShowSupportChat((prev) => !prev)
  }

  const onCloseSupportChat = (e) => {
    e.stopPropagation()
    setShowSupportChat(false)
  }

  const portalNode = document.querySelector(`#${supportChatPortalId}`)

  return (
    <NavBoxButton
      active={showSupportChat}
      onClick={toggleShowSupportChat}
      showLeftBorder={showLeftBorder}
      showRightBorder={showRightBorder}>
      <NavBoxButtonIcon icon='QuestionRounded' size={16} />
      <NotificationBadge count={unreadConversationsCount} />
      {portalNode &&
        createPortal(
          <SupportChatWrapper>
            <SupportChat
              isShown={showSupportChat}
              onClose={onCloseSupportChat}
              unreadConversationsCount={unreadConversationsCount}
              updateUnreadConversationsCount={setUnreadConversationsCount}
            />
          </SupportChatWrapper>,
          portalNode
        )}
    </NavBoxButton>
  )
}

const NotificationBadge = React.memo(({ count }) => {
  if (isNaN(parseInt(count, 10)) || count < 1) return null
  return <Badge>{count}</Badge>
})

const Badge = styled.span`
  font-size: 9px;
  font-weight: bold;
  line-height: 14px;
  color: white;
  background-color: ${styles.colors.pinkColor};
  border-radius: 4px;
  width: 14px;
  height: 14px;
  text-align: center;
  margin-left: 8px;
  position: absolute;
  top: 4px;
  right: 10px;
`

const SupportChatWrapper = styled.div`
  cursor: initial;
`

SupportChatWrapper.displayName = 'SupportChatWrapper'

export default NavBarSupport
