import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { styles, translations, variables } from 'gipsy-misc'
import { BlurLayer, SimpleButton } from 'gipsy-ui'

import { Content } from 'features/calendar/components/CalendarList/components/commonUIComponents'
import { fadeIn } from 'features/keyframes'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { getSrc } from 'utils/image'

const componentName = 'AddAccountPopup'

export default function AddAccountPopup({ onClose, onAddAccount }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  const handleAddAccountClicked = () => {
    onAddAccount()
    onClose()
  }

  return (
    <StyledBlurLayer active name='add-account-popup' onClose={onClose}>
      <PopupContent>
        <Icon alt='Gipsybot Icon' src={getSrc('/images/gipsybot-zen-filled-no-calendar.svg')} />
        <Title>{translations.multiCalendarSettings.addAccountPopup.title}</Title>
        <Subtitle>{translations.multiCalendarSettings.addAccountPopup.subtitle}</Subtitle>
        <StyledButton
          backgroundColor={styles.colors.primaryColor}
          borderColor={styles.colors.primaryColor}
          borderRadius={8}
          height={48}
          onClick={handleAddAccountClicked}
          text={translations.multiCalendarSettings.addAccountPopup.acceptButton}
        />
        <SimpleButton
          backgroundColor={styles.colors.veryLightGrey}
          borderColor={styles.colors.veryLightGrey}
          borderRadius={8}
          height={48}
          onClick={onClose}
          text={translations.multiCalendarSettings.addAccountPopup.dismissButton}
          textColor={styles.colors.textMediumDarkColor}
        />
      </PopupContent>
    </StyledBlurLayer>
  )
}

const StyledBlurLayer = styled(BlurLayer)`
  align-items: center;
  animation: ${fadeIn} 300ms ease-in-out forwards;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${variables.zIndex.lightbox};

  &.add-account-popup-layer,
  &.add-account-popup-layer.active {
    position: absolute;

    ::before {
      background: ${styles.colors.textDarkColor};
      border-radius: 8px;
      opacity: 0.3;
      z-index: -1;
    }

    .add-account-popup-content {
      display: flex;
      height: calc(100% - 128px);
      overflow: visible;
      padding: 0 15px;
      width: calc(100%);
    }
  }
`

StyledBlurLayer.displayName = 'StyledBlurLayer'

const PopupContent = styled(Content)`
  border-radius: 10px;
  padding: 18px;
  text-align: center;
  width: 100%;
`

Content.displayName = 'Content'

const Icon = styled.img`
  height: 77px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  width: 77px;
`

Icon.displayName = 'Icon'

const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 24px 0 22px;
`

Title.displayName = 'Title'

const Subtitle = styled.p`
  font-size: 15px;
  margin: 0 0 28px;
`

Subtitle.displayName = 'Subtitle'

const StyledButton = styled(SimpleButton)`
  margin-bottom: 8px;
`

StyledButton.displayName = 'StyledButton'
