import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Droppable } from 'react-beautiful-dnd'
import {
  innerLeftPadding as defaultInnerLeftPadding,
  innerRightPadding as defaultInnerRightPadding,
  innerNestedRightPadding,
} from 'features/layout/pages'
import HomebaseLine from 'features/list/components/line'
import { SprintLine, MemoedInsideActiveSprintLine } from 'gipsy-ui'
import { models, styles } from 'gipsy-misc'

const lighterOrange = `${styles.colors.orangeColor}10`

function InProgressSprintLineTask(props) {
  const {
    onComplete,
    onCompleteFromFS,
    onSave,
    onClickDelete,
    onRemoveFromSprint,
    onDeleteFocusSession,
    updateTaskInState,
    item,
    innerRef,
    isDraggable,
    draggableProps,
    dragHandleProps,
    draggableStyle,
    isCalendarDraggable,
    isDragging,
    startSprintCreation,
    innerLeftPadding,
    innerRightPadding,
  } = props
  if (!item) return null
  return (
    <HomebaseLine
      item={item}
      animateComplete
      keepJustCompleted
      innerRef={innerRef}
      hideWhenDate
      isDraggable={isDraggable}
      draggableProps={draggableProps}
      draggableStyle={draggableStyle}
      dragHandleProps={dragHandleProps}
      isCalendarDraggable={isCalendarDraggable}
      isDragging={isDragging}
      color={styles.colors.orangeColor}
      playIconBackground={lighterOrange}
      onSave={onSave}
      onComplete={onComplete}
      onCompleteFromFS={onCompleteFromFS}
      onDelete={onClickDelete}
      onDeleteFocusSession={onDeleteFocusSession}
      onRemoveFromSprint={onRemoveFromSprint}
      startSprintCreation={startSprintCreation}
      updateTaskInState={updateTaskInState}
      innerLeftPadding={innerLeftPadding}
      innerRightPadding={innerRightPadding}
      isSprintTask
      lineThrough={!!item.completed}
    />
  )
}

const mapStateToProps = (state) => ({
  session: state.session,
})

function InProgressSprintLine(props) {
  const {
    sprint,
    onComplete,
    onCompleteFromFS,
    onSave,
    onClickDelete,
    onRemoveFromSprint,
    updateTaskInState,
    session,
    onClickEditSprint,
    onClickDeleteSprint,
    onEndSprint,
    startSprintCreation,
    onDeleteFocusSession,
    sprintInlineTaskProps,
    draggableProps,
    draggableStyle,
    dragHandleProps,
    innerLeftPadding,
    innerRef,
    innerRightPadding,
    isCalendarDraggable,
    isDragging,
  } = props

  const taskProps = {
    onComplete,
    onCompleteFromFS,
    onSave,
    onClickDelete,
    onRemoveFromSprint,
    updateTaskInState,
    startSprintCreation,
    onDeleteFocusSession,
    innerLeftPadding: innerNestedRightPadding,
    innerRightPadding: innerNestedRightPadding,
  }

  let sprintTasks = sprint.tasks || []
  const taskInFocusSession =
    session.focusSession.task && sprintTasks.find((sprintTask) => sprintTask.id === session.focusSession.task.id)

  if (taskInFocusSession) {
    sprintTasks = sprintTasks.filter((task) => task.id !== taskInFocusSession.id)
  }

  return (
    <Container>
      <SprintLine
        innerRef={innerRef}
        isCalendarDraggable={isCalendarDraggable}
        isDragging={isDragging}
        draggableProps={draggableProps}
        dragHandleProps={dragHandleProps}
        dragIconPadding={33}
        draggableStyle={draggableStyle}
        key={sprint.id}
        sprint={sprint}
        inProgress
        onClickEdit={onClickEditSprint}
        onClickDelete={onClickDeleteSprint}
        innerLeftPadding={Number.isFinite(innerLeftPadding) ? innerLeftPadding : defaultInnerLeftPadding}
        innerRightPadding={Number.isFinite(innerRightPadding) ? innerRightPadding : defaultInnerRightPadding}
        onEnd={onEndSprint}
        taskInFocusSession={null}>
        <Droppable
          droppableId={JSON.stringify({
            sprintId: sprint.id,
          })}
          type={models.item.type.TASK}>
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              <MemoedInsideActiveSprintLine
                isDraggable
                taskList={sprintTasks}
                taskComponent={InProgressSprintLineTask}
                taskProps={taskProps}>
                <>
                  {droppableProvided.placeholder}
                  <HomebaseLine {...sprintInlineTaskProps} isInlineSprintTask sprint={sprint} />
                </>
              </MemoedInsideActiveSprintLine>
            </div>
          )}
        </Droppable>
      </SprintLine>
    </Container>
  )
}

export default connect(mapStateToProps, null, null, {
  pure: true,
})(InProgressSprintLine)

const Container = styled.div`
  margin-bottom: 5px;
`
