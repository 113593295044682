import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'

import { IntegrationAvatar } from 'features/integrations/components'
import { getSelectedDataOfTeams, getSelectedDataOfProjects } from 'logic/asana'

import { Icon, Separator, SquareCheckbox } from 'gipsy-ui'
import { styles } from 'gipsy-misc'

const workspaceLateralPadding = 15
const teamLateralPadding = 20
const projectLateralPadding = 15

const CHECKBOX_STATUS = {
  EMPTY: 'EMPTY',
  INDETERMINATE: 'INDETERMINATE',
  CHECKED: 'CHECKED',
}

const UserSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  overflow-y: auto;
  height: auto;
  flex-grow: 1;
  width: 100%;
`

const UserInfoRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

const UserNameContainer = styled.span`
  color: ${styles.colors.textDarkColor};
  font-size: ${styles.fonts.fontSize};
  font-weight: 500;
`

const UserSelect = React.memo((props) => {
  const { userData, onSelectProject, onSelectAllProjects } = props
  return (
    <UserSelectContainer>
      <UserInfoRow>
        <IntegrationAvatar src={userData.userPicture} />
        <UserNameContainer>{userData.userName}</UserNameContainer>
      </UserInfoRow>
      {userData?.workspaces?.map((workspaceData, idx) => (
        <WorkspaceSelect
          key={workspaceData.id}
          idx={idx}
          workspaceData={workspaceData}
          onSelectProject={onSelectProject}
          onSelectAllProjects={onSelectAllProjects}></WorkspaceSelect>
      ))}
      {props.children}
    </UserSelectContainer>
  )
})

export default UserSelect

const WorkspaceSelect = React.memo((props) => {
  const [isExpanded, setExpanded] = useState(true)
  const [checkboxStatus, setCheckboxStatus] = useState('')

  const { workspaceData, idx, onSelectProject, onSelectAllProjects } = props

  useEffect(() => {
    let selectedData
    if (workspaceData.isOrganization) {
      selectedData = getSelectedDataOfTeams(workspaceData.teams)
    } else {
      selectedData = getSelectedDataOfProjects(workspaceData.projects)
    }
    const { hasProjectSelected, hasProjectUnselected } = selectedData

    switch (true) {
      case hasProjectSelected && hasProjectUnselected:
        setCheckboxStatus(CHECKBOX_STATUS.INDETERMINATE)
        break
      case hasProjectSelected:
        setCheckboxStatus(CHECKBOX_STATUS.CHECKED)
        break
      case hasProjectUnselected:
        setCheckboxStatus(CHECKBOX_STATUS.EMPTY)
        break
      default:
        setCheckboxStatus(CHECKBOX_STATUS.EMPTY)
        break
    }
  }, [workspaceData])

  const onClickChevron = () => setExpanded(!isExpanded)
  const _onSelectProject = useCallback(
    ({ teamIdx, projectIdx }) => {
      onSelectProject({ workspaceIdx: idx, teamIdx, projectIdx })
    },
    [onSelectProject, idx]
  )

  const onSelectWorkspace = useCallback(() => {
    if (checkboxStatus === CHECKBOX_STATUS.CHECKED) {
      onSelectAllProjects({ workspaceIdx: idx }, false)
    } else {
      onSelectAllProjects({ workspaceIdx: idx }, true)
    }
  }, [checkboxStatus, onSelectAllProjects, idx])

  const onSelectTeam = useCallback(
    ({ teamIdx }, value) => {
      onSelectAllProjects({ workspaceIdx: idx, teamIdx: teamIdx }, value)
    },
    [onSelectAllProjects, idx]
  )

  return (
    <WorkspaceSelectContainer>
      <WorkspaceSelectFirstRow>
        <WorkspaceSelectTitle>{workspaceData.name}</WorkspaceSelectTitle>
        <RightContainer>
          <SquareCheckbox
            size={20}
            onChange={onSelectWorkspace}
            checked={checkboxStatus === CHECKBOX_STATUS.CHECKED || checkboxStatus === CHECKBOX_STATUS.INDETERMINATE}
            indeterminate={checkboxStatus === CHECKBOX_STATUS.INDETERMINATE}
          />

          <ChevronIcon
            icon={`${isExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}`}
            size={10}
            onClick={onClickChevron}
          />
        </RightContainer>
      </WorkspaceSelectFirstRow>
      <AnimateHeight duration={150} height={isExpanded ? 'auto' : 0}>
        <>
          <StyledSeparator />
          <ProjectOuterContainer>
            {workspaceData.isOrganization
              ? workspaceData.teams?.map((teamData, teamIdx) => (
                  <TeamSelect
                    key={teamData.id}
                    teamData={teamData}
                    idx={teamIdx}
                    onSelectProject={_onSelectProject}
                    onSelectTeam={onSelectTeam}></TeamSelect>
                ))
              : workspaceData.projects?.map((projectData, projectIdx) => (
                  <ProjectSelect
                    key={projectData.id}
                    projectData={projectData}
                    idx={projectIdx}
                    onSelect={_onSelectProject}></ProjectSelect>
                ))}
          </ProjectOuterContainer>
        </>
      </AnimateHeight>
    </WorkspaceSelectContainer>
  )
})

const WorkspaceSelectContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(33, 21, 81, 0.08);
  border-radius: 8px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const WorkspaceSelectFirstRow = styled.div`
  padding: 4px ${workspaceLateralPadding}px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  padding-top: 15px;
`

const WorkspaceSelectTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${styles.colors.primaryColor};
`

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-right: ${(props) => props.paddingRight}px;
`

const ChevronIcon = styled(Icon)`
  margin-left: 16px;
`

const StyledSeparator = styled(Separator)`
  margin-bottom: 10px;
`

const ProjectOuterContainer = styled.div`
  padding-left: ${workspaceLateralPadding + teamLateralPadding}px;
  padding-right: ${workspaceLateralPadding}px;
  margin-bottom: 10px;
`

const TeamSelect = React.memo((props) => {
  const [isExpanded, setExpanded] = useState(true)
  const [checkboxStatus, setCheckboxStatus] = useState('')

  const onClickChevron = () => setExpanded(!isExpanded)
  const { teamData, idx, onSelectProject, onSelectTeam } = props

  useEffect(() => {
    const { hasProjectSelected, hasProjectUnselected } = getSelectedDataOfProjects(teamData.projects)

    switch (true) {
      case hasProjectSelected && hasProjectUnselected:
        setCheckboxStatus(CHECKBOX_STATUS.INDETERMINATE)
        break
      case hasProjectSelected:
        setCheckboxStatus(CHECKBOX_STATUS.CHECKED)
        break
      case hasProjectUnselected:
        setCheckboxStatus(CHECKBOX_STATUS.EMPTY)
        break
      default:
        setCheckboxStatus(CHECKBOX_STATUS.EMPTY)
        break
    }
  }, [teamData])

  const _onSelectProject = useCallback(
    ({ projectIdx }) => {
      onSelectProject({ teamIdx: idx, projectIdx })
    },
    [onSelectProject, idx]
  )

  const _onSelectTeam = useCallback(() => {
    if (checkboxStatus === CHECKBOX_STATUS.CHECKED) {
      onSelectTeam({ teamIdx: idx }, false)
    } else {
      onSelectTeam({ teamIdx: idx }, true)
    }
  }, [onSelectTeam, idx, checkboxStatus])

  return (
    <TeamSelectContainer>
      <TeamSelectFirstRow>
        <TeamSelectTitle>{teamData.name}</TeamSelectTitle>
        {teamData.projects && (
          <RightContainer paddingRight={30}>
            <SquareCheckbox
              size={20}
              onChange={_onSelectTeam}
              checked={checkboxStatus === CHECKBOX_STATUS.CHECKED || checkboxStatus === CHECKBOX_STATUS.INDETERMINATE}
              indeterminate={checkboxStatus === CHECKBOX_STATUS.INDETERMINATE}
            />

            <ChevronIcon
              icon={`${isExpanded ? 'SingleChevronDown' : 'SingleChevronLeft'}`}
              size={10}
              onClick={onClickChevron}
            />
          </RightContainer>
        )}
      </TeamSelectFirstRow>
      {teamData.projects && (
        <AnimateHeight duration={150} height={isExpanded ? 'auto' : 0}>
          {teamData.projects?.map((projectData, projectIdx) => (
            <ProjectSelect
              key={projectData.id}
              projectData={projectData}
              idx={projectIdx}
              onSelect={_onSelectProject}></ProjectSelect>
          ))}
        </AnimateHeight>
      )}
    </TeamSelectContainer>
  )
})

const TeamSelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`

const TeamSelectFirstRow = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
`

const TeamSelectTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${styles.colors.textDarkColor};
`

const ProjectSelect = React.memo((props) => {
  const { projectData, idx, onSelect } = props

  const _onSelect = useCallback(() => {
    onSelect({ projectIdx: idx })
  }, [onSelect, idx])

  return (
    <ProjectSelectContainer>
      <ProjectSelectTitle>{projectData.name}</ProjectSelectTitle>
      <RightContainer paddingRight={50}>
        <SquareCheckbox size={20} checked={projectData.selected} onChange={_onSelect} />
      </RightContainer>
    </ProjectSelectContainer>
  )
})

const ProjectSelectContainer = styled.div`
  padding-left: ${projectLateralPadding}px;
  padding-right: ${teamLateralPadding + projectLateralPadding}px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;
  align-items: center;
`
const ProjectSelectTitle = styled.div`
  font-size: 16px;
  color: ${styles.colors.textDarkColor};
  font-weight: normal;
`
