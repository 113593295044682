import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { styles } from 'gipsy-misc'
import { Icon, media, ProjectBadge, Separator } from 'gipsy-ui'

export const innerLeftPadding = 75
export const innerRightPadding = 75
export const innerNestedLeftPadding = 32
export const innerNestedRightPadding = 32

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.sub-page-transition-enter,
  &.sub-page-transition-exit {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &.sub-page-transition-appear {
    transition: opacity 0.2s;
    opacity: 0;
  }

  &.sub-page-transition-enter {
    opacity: 0;
  }

  &.sub-page-transition-enter.sub-page-transition-enter-active {
    opacity: 1;
    transition: opacity 0.2s 0.2s ease-in;
  }

  &.sub-page-transition-exit {
    opacity: 1;
  }

  &.sub-page-transition-exit.sub-page-transition-exit-active {
    opacity: 0;
    transition: opacity 0.2s ease-in;
  }
`

PageContainer.displayName = 'PageContainer'

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  /* if we want to center the body */
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 112px;
  padding-top: 22px;

  ${media['phone-wide']`
    margin-left: 0;
    margin-right: 0;
    padding-right:20px;
    padding-left:20px;
  `}
`

PageBody.displayName = 'PageBody'

export const paddingContainerCss = css`
  padding-left: ${innerLeftPadding}px;
  padding-right: ${innerRightPadding}px;
`

// PaddingContainer sets the padding which matches the line padding
export const PaddingContainer = styled.div`
  ${paddingContainerCss};
`

export const HeaderContainer = styled(PaddingContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => props.onClick && 'pointer'};
  min-height: 26px;
`

export const MenuIconContainer = styled.div`
  display: none;

  ${media['tablet-wide']`
  cursor:pointer;
 ${() => leftIconStyle}
`}
`

export const LeftIconContainer = styled.div`
  ${() => leftIconStyle}

  ${media['phone-wide']`
  display: none;
 `}
`

const LeftIconContainerLink = styled(Link)`
  ${() => leftIconStyle}
`

export const LeftIconLink = ({ to }) => (
  <LeftIconContainerLink to={to}>
    <Icon icon="SingleChevronLeft" size={14} fill={styles.colors.darkGrey} />
  </LeftIconContainerLink>
)

const leftIconStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`

export const NameContainer = styled.span`
  color: ${(props) => (props.color ? props.color : styles.colors.textDarkColor)};
  font-size: ${styles.fonts.fontSize};
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const TitleSeparator = styled(Separator)`
  margin-top: 20px;
`

export const RightIconLinkContainer = styled(Link)`
  margin-left: auto;
  cursor: pointer;
`

export const ArchiveIcon = ({ to }) => (
  <RightIconLinkContainer to={to}>
    <Icon icon="Archive" size={28} fill={styles.colors.darkGrey} />
  </RightIconLinkContainer>
)

export const NavigationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 50%) 1fr;

  ${media['tablet-wide']`
      grid-template-columns: 1fr 1fr minmax(auto, 50%) 1fr;
  `}
  margin-bottom: 20px;
`

export const NavigationTitle = styled.span`
  color: ${styles.colors.textDarkColor};
  grid-column-start: 2;
  ${media['tablet-wide']`
      grid-column-start: 3;
  `}
  font-size: 24px;
  font-weight: 500;
  text-align: center;

  ${(props) =>
    props.textEllipsis &&
    css`
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 16px);
    `}
`

const NavigationLinkContainer = styled.div`
  min-width: ${(props) => (props.width ? props.width : 'max-content')};
  display: flex;
  align-items: center;
`

const navigationLinkPadding = 6

const NavigationLink = styled(Link)`
  &&& {
    display: flex;
    align-items: center;
    min-width: 0;
    color: ${styles.colors.textDarkColor};
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    padding: ${navigationLinkPadding}px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
    ${(props) =>
      props.$textEllipsis &&
      css`
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }
      `}

    &:hover {
      background-color: ${styles.colors.middleGrey};
    }
  }
`

export const NavigationIcon = styled(Icon)`
  flex-shrink: 0;
`

export const LeftNavigationLinkContainer = styled(NavigationLinkContainer)`
  justify-content: flex-start;

  span {
    margin-left: 10px;
    ${media['tablet-wide']`
    display:none;
  `}
  }

  ${NavigationLink} {
    margin-left: -${navigationLinkPadding}px;
  }
`

export const RightNavigationLinkContainer = styled(NavigationLinkContainer)`
  justify-content: flex-end;

  span {
    margin-right: 10px;
    ${media['tablet-wide']`
    display:none;
  `}
  }

  ${NavigationLink} {
    margin-right: -${navigationLinkPadding}px;
  }
`

export const NavigationLeftButton = ({ to, label, width, textEllipsis }) => (
  <LeftNavigationLinkContainer width={width}>
    <NavigationLink to={to} $textEllipsis={textEllipsis}>
      <NavigationIcon size={12} fill={styles.colors.primaryColor} icon={'ArrowLeft'} />
      <span>{label}</span>
    </NavigationLink>
  </LeftNavigationLinkContainer>
)

export const NavigationRightButton = ({ to, label, width, textEllipsis, onClick }) => (
  <RightNavigationLinkContainer width={width} onClick={onClick}>
    <NavigationLink to={to} $textEllipsis={textEllipsis}>
      <span>{label}</span>
      <NavigationIcon size={12} fill={styles.colors.primaryColor} icon={'ArrowRight'} />
    </NavigationLink>
  </RightNavigationLinkContainer>
)

export const NavigationProjectBadge = styled(ProjectBadge)`
  display: inline-block;
  margin-right: 8px;
`

export const FiltersContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin: 16px ${innerRightPadding}px 16px ${innerLeftPadding}px;
`
