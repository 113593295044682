import React from 'react'
import styled, { css } from 'styled-components'

import { getSrc } from 'utils/image'

import { media, Icon } from 'gipsy-ui'
import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, translations, styles, variables } from 'gipsy-misc'

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 7px;
  right: 6px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: ${styles.colors.pinkColor};
  border-radius: 4px;
  width: 23px;
  height: 23px;
`

const Backdrop = styled.div`
  background-color: rgba(240, 240, 240, 0.8);
  bottom: 0;
  display: ${(props) => (props.isShown ? 'flex' : 'none')};
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${variables.zIndex.supportchat};

  @keyframes fadeInFromNone {
    0% {
      display: none;
      background-color: rgba(240, 240, 240, 0);
    }

    1% {
      display: flex;
      background-color: rgba(240, 240, 240, 0);
    }

    100% {
      display: flex;
      background-color: rgba(240, 240, 240, 0.8);
    }
  }
  animation: fadeInFromNone 0.5s ease-out;
  ${media['phone-wide']`
    display:none;
  `}
`

const Panel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 430px;
  max-width: 100%;
  margin-left: auto;
  background-color: ${styles.colors.veryLightGrey};
  box-shadow: 4px 0 20px 0 #dedede;

  @keyframes slideIn {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
  animation: slideIn 0.5s ease;
`

const Frame = styled.div`
  &,
  #hubspot-conversations-inline-parent,
  #hubspot-conversations-inline-iframe {
    border: none;
    opacity: 0;
    height: 0;
    width: 0;
    border-radius: 8px;
  }

  & {
    background: transparent;
    position: relative;
    transition: opacity 0.25s ease-in-out 1s, box-shadow 3s ease-in-out;
    margin-left: 24px;
    opacity: 0;
    margin-right: 0;
    align-self: flex-end;
    margin-bottom: 76px;

    ${(props) =>
      !props.loading &&
      props.isShown &&
      css`
        opacity: 1;
        width: 376px;
        height: 530px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 20px;
      `}
  }

  #hubspot-conversations-inline-parent,
  #hubspot-conversations-inline-iframe {
    ${(props) =>
      !props.loading &&
      props.isShown &&
      css`
        opacity: 1;
        height: 100%;
        width: 100%;
      `}
  }
`

const Loader = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes pulsate {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }
  animation: pulsate 1s infinite;
`

const ChatIconButtonContainer = styled.span`
  background-color: ${styles.colors.primaryColor};
  width: 52px;
  height: 52px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const BackButtonContainer = styled(ChatIconButtonContainer)`
  position: absolute;
  cursor: pointer;
  bottom: 8px;
  right: 8px;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 0.5s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 20px;
  }
`

const ActionsMenuContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ActionsMenuGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 50px;
  }
`

const ActionsMenuItems = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 40px;
`

const ActionsMenuItem = styled.div`
  border-radius: 10px;
  width: calc(45% - 28px);
  height: 227px;
  background-color: white;
  box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
  transition: box-shadow 0.25s ease-in-out;
  display: flex;
  padding: 16px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    box-shadow: 0 10px 13px rgba(33, 21, 81, 0.09);
  }
`

const ActionsMenuItemText = styled.div`
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin: 24px 0;
  color: ${styles.colors.textDarkColor};
`

const ActionsTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
`

const ActionsSubTitle = styled.span`
  text-align: center;
  line-height: 16px;
  margin-top: 12px;
  font-size: 13px;
`

const Logo = styled.img`
  height: 46px;
  width: 46px;
`

class ActionsMenu extends React.Component {
  goToSlackInviteLink = () => {
    window.open(process.env.REACT_APP_SLACK_INVITE_URL)
  }
  goToYoutubeChannel = () => {
    window.open('https://www.youtube.com/channel/UCY7RLCDMpv0VtHYLlGtx7PA')
    mixpanelApi.track({ event: mixpanel.clickedWatchYoutubeVideosEvent })
  }
  render() {
    const { showChat, unreadConversationsCount } = this.props
    return (
      <ActionsMenuContainer>
        <ActionsMenuGroup>
          <ActionsTitle>{translations.supportPanel.questionTitle}</ActionsTitle>
          <ActionsSubTitle>{translations.supportPanel.questionSubtitle}</ActionsSubTitle>
          <ActionsMenuItems>
            <ActionsMenuItem onClick={showChat}>
              {unreadConversationsCount > 0 && <Badge>{unreadConversationsCount}</Badge>}
              <ChatIconButtonContainer>
                <Icon icon={'Chat'} size={30} fill={'white'} />
              </ChatIconButtonContainer>
              <ActionsMenuItemText>{translations.supportPanel.chatButton}</ActionsMenuItemText>
              <Icon icon={'DoubleChevronRight'} fill={styles.colors.primaryColor} size={12} />
            </ActionsMenuItem>
            <ActionsMenuItem onClick={this.goToSlackInviteLink}>
              <Logo src={getSrc('/images/slack_icon.svg')} />
              <ActionsMenuItemText>{translations.supportPanel.slackButton}</ActionsMenuItemText>
              <Icon icon={'DoubleChevronRight'} fill={styles.colors.primaryColor} size={12} />
            </ActionsMenuItem>
          </ActionsMenuItems>
        </ActionsMenuGroup>
        <ActionsMenuGroup>
          <ActionsTitle>{translations.supportPanel.expertTitle}</ActionsTitle>
          <ActionsMenuItems>
            <ActionsMenuItem onClick={this.goToYoutubeChannel}>
              <Logo src={getSrc('/images/youtube_icon.svg')} />
              <ActionsMenuItemText>{translations.supportPanel.youtubeButton}</ActionsMenuItemText>
              <Icon icon={'DoubleChevronRight'} fill={styles.colors.primaryColor} size={12} />
            </ActionsMenuItem>
          </ActionsMenuItems>
        </ActionsMenuGroup>
      </ActionsMenuContainer>
    )
  }
}

class DesktopSupportBox extends React.Component {
  render() {
    const { loading, isShown, unreadConversationsCount, onClose, closeChat, isChatShown, showChat } = this.props
    return (
      <Backdrop isShown={isShown} onClick={onClose}>
        <Panel isShown={isShown} onClick={(e) => e.stopPropagation()}>
          {loading && <Loader>{translations.general.loadingSupportChat}</Loader>}
          <Frame loading={loading ? 1 : 0} isShown={isChatShown} id={'hsFrame'} />
          {!isChatShown && <ActionsMenu showChat={showChat} unreadConversationsCount={unreadConversationsCount} />}
          {isChatShown && !loading && (
            <BackButtonContainer onClick={closeChat}>
              <Icon icon={'SingleChevronLeft'} size={20} fill={'white'} />
            </BackButtonContainer>
          )}
        </Panel>
      </Backdrop>
    )
  }
}

export const supportChatPortalId = 'support-chat-portal'
export class SupportChat extends React.Component {
  state = {
    loading: false,
    isChatShown: false,
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isShown && !this.props.isShown) {
      this.closeChat()
    }
  }

  initHubSpotChat = () => {
    this.props.updateUnreadConversationsCount(0)

    if (!window.HubSpotConversations) {
      window.hsConversationsSettings = {
        inlineEmbedSelector: '#hsFrame',
      }
      this.loadHsScript()
      this.hsScriptListener()
    }
  }

  loadHsScript = () => {
    this.setState({ loading: true })
    const script = document.createElement('script')
    script.src = '//js.hs-scripts.com/5293305.js'
    script.async = true
    document.body.appendChild(script)
  }

  onHsScriptReady = async () => {
    this.setState({ loading: false })

    window.HubSpotConversations.on('unreadConversationCountChanged', (payload) => {
      const isChatLoaded = window.HubSpotConversations && window.HubSpotConversations.widget.status().loaded
      const isChatHidden = isChatLoaded && !this.state.isChatShown

      if (isChatHidden) {
        this.props.updateUnreadConversationsCount(payload?.unreadCount)
      }
    })
  }

  hsScriptListener = () => {
    if (window.HubSpotConversations && !window.HubSpotConversations.widget.status().loaded) {
      this.onHsScriptReady()
    } else if (!window.HubSpotConversations) {
      window.hsConversationsOnReady = [this.onHsScriptReady]
    }
  }

  showChat = () => {
    this.initHubSpotChat()
    this.setState({ isChatShown: true })
  }

  closeChat = () => {
    this.setState({ isChatShown: false })
  }

  render() {
    const { loading, isChatShown } = this.state
    const { isShown, unreadConversationsCount, onClose } = this.props
    const commonProps = {
      loading,
      isShown,
      onClose,
      showChat: this.showChat,
      closeChat: this.closeChat,
      unreadConversationsCount,
      initHubSpotChat: this.initHubSpotChat,
      isChatShown,
    }
    return (
      <React.Fragment>
        <DesktopSupportBox {...commonProps} />
      </React.Fragment>
    )
  }
}
