import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { PrimaryButton, RadioInput, SecondaryButton } from 'gipsy-ui'

import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

export const options = {
  ALL: 'all',
  NEXT: 'next',
  SINGLE: 'single',
}

const componentName = 'RecurrentSprintPanel'

function RecurrentSprintPanel({ hideAllOption, hideSingleOption, onCancel, onConfirm }) {
  const dispatch = useDispatch()

  const hideOptions = hideAllOption && hideSingleOption

  const [optionChecked, setOptionChecked] = useState(hideOptions ? options.NEXT : options.SINGLE)

  const handleConfirm = useCallback(() => {
    onConfirm?.(optionChecked)
  }, [onConfirm, optionChecked])

  const handleCancel = useCallback(() => {
    onCancel?.()
  }, [onCancel])

  useEffect(() => {
    dispatch(
      pushShortcutsGroup(
        [
          {
            label: translations.sprint.recurrencyPanel.delete.deny,
            key: 'Escape',
            callback: handleCancel,
          },
          {
            label: translations.sprint.recurrencyPanel.delete.consent,
            key: 'Enter',
            callback: handleConfirm,
          },
        ],
        componentName
      )
    )

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch, handleCancel, handleConfirm])

  const selectSingle = useCallback(() => setOptionChecked(options.SINGLE), [])
  const selectNext = useCallback(() => setOptionChecked(options.NEXT), [])
  const selectAll = useCallback(() => setOptionChecked(options.ALL), [])

  return (
    <Container>
      <OptionsContainer>
        {hideOptions ? (
          <NoOptionsMessage>{translations.sprint.recurrencyPanel.noOptions}</NoOptionsMessage>
        ) : (
          <>
            {!hideSingleOption && (
              <OptionContainer onClick={selectSingle}>
                <StyledRadioInput
                  checked={optionChecked === options.SINGLE}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={18}
                />
                <span>{translations.sprint.recurrencyPanel.onlyThisFocusBlock}</span>
              </OptionContainer>
            )}
            <OptionContainer onClick={selectNext}>
              <StyledRadioInput
                checked={optionChecked === options.NEXT}
                checkedColor={styles.colors.primaryColor}
                disableStopPropagation
                size={18}
              />
              <span>{translations.sprint.recurrencyPanel.thisAndFollowingFocusBlocks}</span>
            </OptionContainer>
            {!hideAllOption && (
              <OptionContainer onClick={selectAll}>
                <StyledRadioInput
                  checked={optionChecked === options.ALL}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={18}
                />
                <span>{translations.sprint.recurrencyPanel.allFocusBlocks}</span>
              </OptionContainer>
            )}
          </>
        )}
      </OptionsContainer>
      <ButtonContainer>
        <CancelButton height={32} onClick={handleCancel} text={translations.sprint.recurrencyPanel.delete.deny} />
        <ConfirmButton
          height={32}
          onClick={handleConfirm}
          borderRadius={8}
          text={translations.sprint.recurrencyPanel.delete.consent}
        />
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

Container.displayName = 'Container'

const OptionsContainer = styled.div`
  margin: 16px 0;
`

OptionsContainer.displayName = 'OptionsContainer'

const OptionContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  margin-bottom: 16px;
`

OptionContainer.displayName = 'OptionContainer'

const NoOptionsMessage = styled.div`
  margin: -16px 0 8px;
  text-align: center;
`

NoOptionsMessage.displayName = 'NoOptionsMessage'

const StyledRadioInput = styled(RadioInput)`
  margin-right: 7px;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

ButtonContainer.displayName = 'ButtonContainer'

const CancelButton = styled(SecondaryButton)`
  margin-right: 20px;
  min-width: 116px;
`

CancelButton.displayName = 'CancelButton'

const ConfirmButton = styled(PrimaryButton)`
  min-width: 116px;
`

ConfirmButton.displayName = 'ConfirmButton'

export default RecurrentSprintPanel
