import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { styles } from 'gipsy-misc'
import { CustomPopup, DatePicker, Icon } from 'gipsy-ui'

function DateDropdown({ onChange, onValidRangeSelected, value: { start, end }, firstDayOfWeek }) {
  const [shown, setShown] = useState(false)
  const iconRef = useRef(null)

  const toggleDropdownShown = () => {
    setShown((prev) => !prev)
  }

  const closeDropdown = (e) => {
    // react-datepicker fires a click event on an unrendered element sometimes, this prevents the calendar from closing when clicking on it
    if (
      (e?.target &&
        e?.target?.className?.includes?.('react-datepicker') &&
        (e?.target?.getAttribute?.('type') === 'button' || e?.target?.getAttribute?.('role') === 'button')) ||
      iconRef.current?.contains?.(e.target) // the user clicked on the calendar
    ) {
      return
    }

    setShown(false)
  }

  const handleRangeSelection = (dates) => {
    const [selectedStart, selectedEnd] = dates.value
    onChange?.({
      start: selectedStart,
      end: selectedEnd,
    })

    if (!selectedStart || !selectedEnd) return

    const parsedStart = moment(selectedStart)
    const parsedEnd = moment(selectedEnd)

    if (parsedEnd.diff(parsedStart, 'months', true) > 2) {
      return onChange?.({
        start: selectedStart,
        end: null,
      })
    }

    parsedEnd.endOf('day')

    onValidRangeSelected?.({
      start: parsedStart.toISOString(),
      end: parsedEnd.toISOString(),
    })
    closeDropdown()
  }

  const filterDatesFn = (date) => {
    const parsedDate = moment(date)
    const parsedStart = moment(start)

    if (!start) return true

    return parsedDate.diff(parsedStart, 'months', true) < 2
  }

  return (
    <Container ref={iconRef}>
      <StyledIcon active={shown || start} icon='CalendarMarked' onClick={toggleDropdownShown} size={14} />
      {shown && (
        <StyledPopup onClickOutside={closeDropdown}>
          <DatePicker
            endDate={end}
            filterDates={true}
            filterDatesFn={filterDatesFn}
            inline
            maxDate={new Date()}
            monthsShown={2}
            onChange={handleRangeSelection}
            selectedDay={start}
            selectsRange
            startDate={start}
            firstDayOfWeek={firstDayOfWeek}
            showPreviousMonths={true}
          />
        </StyledPopup>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;

  &:hover {
    path {
      fill: ${styles.colors.primaryColor};
    }
  }

  ${({ active }) =>
    active &&
    css`
      path {
        fill: ${styles.colors.primaryColor};
      }
    `}
`

const StyledPopup = styled(CustomPopup)`
  background-color: white;
  border-radius: 8px;
  left: -50%;
  margin-top: 26px;
  padding: 8px;
  top: 100%;
  transform: translateX(-70%);

  & .react-datepicker__month-container {
    font-size: ${styles.fonts.fontSizeXXSmall};

    & + .react-datepicker__month-container {
      margin-left: 30px;
    }
  }

  & .react-datepicker__current-month,
  & .react-datepicker__day-name {
    color: ${styles.colors.textMediumDarkColor};
    font-weight: 400;
  }

  & .react-datepicker__day-name {
    font-weight: 600;
    text-transform: uppercase;
  }

  & .react-datepicker__day {
    color: ${styles.colors.textMediumDarkColor};
    margin: 0;
  }

  & .react-datepicker__day--keyboard-selected,
  & .react-datepicker__day--keyboard-selected:hover {
    background-color: unset;
    border-radius: unset;
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--selected.react-datepicker__day--in-selecting-range,
  & .react-datepicker__day--selected:hover,
  & .react-datepicker__day--range-start.react-datepicker__day--range-start,
  & .react-datepicker__day--range-start.react-datepicker__day--range-start:hover,
  & .react-datepicker__day--range-end.react-datepicker__day--range-end,
  & .react-datepicker__day--range-end.react-datepicker__day--range-end:hover {
    background-color: ${styles.colors.primaryColor};
    border-radius: 6px;
    color: white;
    position: relative;

    &::before {
      background-color: ${styles.colors.primaryColor}1A;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  & .react-datepicker__day--disabled {
    color: ${styles.colors.darkGrey};
  }

  & .react-datepicker__day--in-range,
  & .react-datepicker__day--in-range:hover,
  & .react-datepicker__day--in-selecting-range,
  & .react-datepicker__day--in-selecting-range:hover,
  & .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected) {
    background-color: ${styles.colors.primaryColor}1A;
    border-radius: 0;
    color: ${styles.colors.textMediumDarkColor};
  }
`

export default DateDropdown
