const headerRoutes = {
  '/': true,
  '/integrations': true,
  '/reports': true,
  '/settings': true,
  '/uncategorized': true,
}

export const shouldShowHeader = (pathname) => {
  if (!pathname) return false
  return headerRoutes[pathname] || pathname.startsWith('/project') || pathname.startsWith('/tasks')
}

const navBarRoutes = {
  '/uncategorized': true,
}

export const shouldShowNavbar = (pathname) => {
  if (!pathname) return false
  return navBarRoutes[pathname] || pathname.startsWith('/project') || pathname.startsWith('/tasks')
}

const calendarRoutes = {
  '/': true,
  '/uncategorized': true,
}

export const shouldShowCalendar = (pathname) => {
  if (!pathname) return false
  return (
    calendarRoutes[pathname] ||
    (pathname.startsWith('/project') && !pathname.startsWith('/project/reports')) ||
    pathname.startsWith('/tasks')
  )
}

export const routesToHideMobileWarn = {
  '/onboarding': true,
  '/oauthcallback': true,
  '/revalidateoauthcallback': true,
  '/integrationoauthcallback': true,
  '/email-notifications/unsubscribe': true,
  '/slack/landing-page': true,
  '/asana/landing-page': true,
}
