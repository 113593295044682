import moment from 'moment'

import { utils } from 'gipsy-misc'

import { OPTIONS } from 'features/filters/SortDropdown'

export const computeActiveProjectPath = (project) => {
  if (!project) {
    return '/'
  }
  return `/project/active/${project.id}`
}

export const computeArchiveProjectPath = (project) => {
  if (!project) {
    return '/'
  }
  return `/project/archive/${project.id}`
}
export const computeReportsProjectPath = (project) => {
  if (!project) {
    return '/'
  }
  return `/project/reports/${project.id}`
}

const getWhenDate = (item) => {
  if (item?.when?.date) return item.when.date
  if (item?.sprintInfo?.pin?.time) return moment(item.sprintInfo.pin.time).format('YYYY-MM-DD')
}

const hasPin = (item) => !!(item.pin?.time || item.sprintInfo?.pin?.time)

const isPinBefore = (a, b) => {
  const pinA = moment(a.sprintInfo ? a.sprintInfo.pin?.time : a.pin?.time)
  const pinB = moment(b.sprintInfo ? b.sprintInfo.pin?.time : b.pin?.time)

  if (pinA.isSame(pinB)) {
    return moment(a.creationTime).isBefore(moment(b.creationTime))
  }

  return pinA.isBefore(pinB)
}

const isScheduledBefore = (a, b) => {
  const whenA = getWhenDate(a)
  const whenB = getWhenDate(b)

  switch (true) {
    case whenA !== whenB: {
      return utils.datetime.isBeforeDateStr(whenA, whenB)
    }
    case hasPin(a) && hasPin(b): {
      return isPinBefore(a, b)
    }
    case hasPin(a) || hasPin(b): {
      return hasPin(a)
    }
    default: {
      return moment(a.creationTime).isBefore(moment(b.creationTime))
    }
  }
}

export const getItemProjectKey = (item, projectId) => {
  if (!projectId && item.projectsRank) {
    return Object.keys(item.projectsRank).find((projectId) => projectId.match(/UNCATEGORIZED/i))
  }

  return projectId
}

const isRankBefore = (projectId, a, b) => {
  const projectKey = getItemProjectKey(a, projectId)
  const aHasRank = !!a.projectsRank?.[projectKey]
  const bHasRank = !!b.projectsRank?.[projectKey]

  if (!aHasRank && !bHasRank) {
    return moment(a.creationTime).isBefore(moment(b.creationTime)) ? 1 : -1
  }

  if (!aHasRank || !bHasRank) {
    return aHasRank ? 1 : -1
  }

  if (a.projectsRank[projectKey] === b.projectsRank[projectKey]) return 0
  return a.projectsRank[projectKey] < b.projectsRank[projectKey] ? 1 : -1
}

const sortByScheduledDate = (list) => [...list].sort((a, b) => (isScheduledBefore(b, a) ? 1 : -1))

const sortByRank = (list, projectId) => [...list].sort((a, b) => isRankBefore(projectId, b, a))

export const sortTaskList = (sortOption, list, projectId) => {
  switch (sortOption) {
    case OPTIONS.SCHEDULED_DATE: {
      return sortByScheduledDate(list)
    }
    case OPTIONS.NONE: {
      return sortByRank(list, projectId)
    }
    default: {
      return list
    }
  }
}
