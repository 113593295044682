import { storageAvailable } from 'utils/storage'
import { asana, slack, session } from 'gipsy-api'
import qs from 'qs'

export const slackOAuthPageFromParam = 'slackOAuth-pageFrom'
export const INTEGRATION_APP = {
  SLACK: 'slack',
  ASANA: 'asana',
}
const extractStateParameters = (location, windowRef) => {
  const _window = windowRef || window
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const res = {
    error: searchParams.error,
    state: searchParams.state,
    code: searchParams.code,
    pageFrom: _window.sessionStorage.getItem(slackOAuthPageFromParam),
    localStateSlack: _window.sessionStorage.getItem('stateOAuth-slack'),
    localStateAsana: _window.sessionStorage.getItem('stateOAuth-asana'),
  }
  clearSessionStorage(_window)

  return res
}

const clearSessionStorage = (windowRef) => {
  const _window = windowRef || window
  _window.sessionStorage.removeItem('stateOAuth-slack')
  _window.sessionStorage.removeItem('stateOAuth-asana')
  _window.sessionStorage.removeItem(slackOAuthPageFromParam)
}

export const handleOAuth = async (location, windowRef) => {
  const { error, state, localStateSlack, localStateAsana, pageFrom, code } = extractStateParameters(location, windowRef)

  const querySucceeded = !error && code
  if (!querySucceeded) {
    throw new Error('Query failed.')
  }
  let integrationApp
  let responseData
  if (localStateSlack === state) {
    responseData = await slack.authenticate(state, code)
    integrationApp = INTEGRATION_APP.SLACK
  } else if (localStateAsana === state) {
    responseData = await asana.authenticate(state, code)
    integrationApp = INTEGRATION_APP.ASANA
  } else {
    throw new Error('Unknown provided state.')
  }

  return { pageFrom, integrationApp, responseData }
}

export const getSlackAuthUrlAndStoreState = async (teamId, windowRef) => {
  const _window = windowRef || window
  const { authURL, state } = await slack.getAuthURL(teamId)
  _window.sessionStorage.setItem('stateOAuth-slack', state)
  return authURL
}

export const getAsanaAuthUrlAndStoreState = async (teamId) => {
  const { authURL, state } = await asana.getAuthURL(teamId)
  window.sessionStorage.setItem('stateOAuth-asana', state)
  return authURL + '&display_ui=always'
}

export const getGoogleAuthUrlAndStoreState = async (forceConsent = false, redirectUrl = null, loginHint) => {
  clearGoogleMicrosoftOauthState()
  const { authURL, state, nonce } = await session.getAuthURL('google', forceConsent, redirectUrl, loginHint)
  window.sessionStorage.setItem('stateOAuth-google', state)
  window.sessionStorage.setItem('nonceOAuth-google', nonce)

  return authURL
}

export const getMicrosoftAuthUrlAndStoreState = async (forceConsent = false, redirectUrl = null, loginHint) => {
  clearGoogleMicrosoftOauthState()

  const { authURL, state, nonce } = await session.getAuthURL('microsoft', forceConsent, redirectUrl, loginHint)
  window.sessionStorage.setItem('stateOAuth-microsoft', state)
  window.sessionStorage.setItem('nonceOAuth-microsoft', nonce)

  return authURL
}

const clearGoogleMicrosoftOauthState = () => {
  if (storageAvailable('localStorage')) {
    window.localStorage.removeItem('stateOAuth-google')
    window.localStorage.removeItem('nonceOAuth-google')
    window.localStorage.removeItem('stateOAuth-microsoft')
    window.localStorage.removeItem('stateOAuth-microsoft')
  }
}

export function openIntegrationOAuthWindow(url, { onSuccess, onError } = {}, { width, height } = {}) {
  let integrationOAuthWindow
  const openWindowWidth = width || 600
  const openWindowHeight = height || 700
  const left = window.screenLeft + window.outerWidth / 2 - openWindowWidth / 2
  const top = window.screenTop + window.outerHeight / 2 - openWindowHeight / 2
  const strWindowFeatures = `toolbar=no, menubar=no, width=${openWindowWidth}, height=${openWindowHeight}, top=${top} left=${left}`
  integrationOAuthWindow = window.open(url, '_blank', strWindowFeatures)

  const onReceiveMessage = async (event) => {
    if (event.origin !== process.env.REACT_APP_FRONTEND_URL) {
      return onError && onError(new Error('unknown origin'))
    }
    const { data } = event
    if (data.error) {
      return onError && onError(data.error)
    }
    if ('integration-oauth' === data.source) {
      window.removeEventListener('message', onReceiveMessage)
      return onSuccess && onSuccess(data.responseData)
    }
  }

  const listener = setInterval(() => {
    try {
      if (integrationOAuthWindow.document.domain === document.domain) {
        if (integrationOAuthWindow && integrationOAuthWindow.closed) {
          clearSessionStorage()
          window.removeEventListener('message', onReceiveMessage)
          clearInterval(listener)
          integrationOAuthWindow = null
        }
      }
    } catch (e) {
      if (integrationOAuthWindow && integrationOAuthWindow.closed) {
        clearSessionStorage()
        window.removeEventListener('message', onReceiveMessage)
        clearInterval(listener)
        integrationOAuthWindow = null
      }
    }
  }, 300)

  window.addEventListener('message', onReceiveMessage, false)
}
