import React from 'react'
import styled, { css } from 'styled-components'

export function PopupTail() {
  return (
    <TailWrapper>
      <svg width="25" height="8" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.0605469 8H24.0605V6H21.6171C20.006 6 18.4626 5.35207 17.3342 4.20204L15.0322 1.85574C13.4849 0.278642 10.9519 0.254746 9.37511 1.80237L6.84859 4.2821C5.72677 5.38315 4.21766 6 2.64578 6H0.0605469V8Z"
          fill="white"
        />
      </svg>
    </TailWrapper>
  )
}

export const TailWrapper = styled.div`
  height: 8px;
  position: absolute;
  top: -11px;
  width: 25px;
`

TailWrapper.displayName = 'TailWrapper'

export const popupBorderRadius = 8

export const popupStyles = css`
  background-color: white;
  border-radius: ${popupBorderRadius}px;
  cursor: auto;
  left: 0;
  margin: 0;
  top: calc(100% + 24px);
  z-index: 3;

  ${TailWrapper} {
    left: 56px;
  }
`
