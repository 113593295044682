import React from 'react'
import styled from 'styled-components'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { models } from 'gipsy-misc'
import { InsideActiveSprintLine, SprintLine } from 'gipsy-ui'

import HomebaseLine from 'features/list/components/line'

function TaskPanelItem({
  addTaskToSprintComposer,
  draggablesTopOffset,
  hideCompleteCheckbox,
  index,
  item,
  onDelete,
  onSave,
  onComplete,
  onCreateInlineTaskFromSprint,
}) {
  const getTaskProps = (item) => ({
    disableEdit: false,
    disableLinks: true,
    dragIconLeftPosition: -18,
    estimatedTime: item.estimatedTime,
    hideCreateButton: true,
    hideCompleteCheckbox: hideCompleteCheckbox,
    hideDragIcon: false,
    hideMoreActions: true,
    hideStartButton: true,
    hideWhenDate: true,
    animateComplete: true,
    innerLeftPadding: 0,
    innerRightPadding: 0,
    isCalendarDraggable: true,
    isDraggable: true,
    item,
    onClickRightArrow: addTaskToSprintComposer,
    onDelete,
    onSave,
    onComplete,
    pin: item.pin,
  })

  const sprintTaskProps = {
    disableEdit: false,
    disableHover: true,
    hideCreateButton: true,
    hideMoreActions: true,
    hideStartButton: true,
    hideWhenDate: true,
    animateComplete: true,
    innerLeftPadding: 8,
    innerRightPadding: 8,
    onDelete,
    onSave,
    onComplete,
  }

  const sprintInlineTaskProps = {
    creating: true,
    hideBlockToCalendarOption: true,
    hideDateInput: true,
    hideSprint: true,
    isCreating: true,
    isSprintTask: true,
    onCreate: onCreateInlineTaskFromSprint,
    insideSprintHorizontalMargin: 8,
  }

  if (!item) return null

  return (
    <Draggable draggableId={item.id} index={index}>
      {(draggableProvided, snapshot) => {
        let draggableStyle = draggableProvided.draggableProps.style

        if (snapshot.isDragging) {
          draggableStyle = { ...draggableStyle, top: draggableStyle.top - (draggablesTopOffset || 0) }
        }

        if (item.type === models.item.type.SPRINT) {
          const droppableId = JSON.stringify({ id: item.id, type: models.item.type.SPRINT })

          return (
            <StyledSprintLine
              draggableProps={draggableProvided.draggableProps}
              draggableStyle={draggableStyle}
              dragHandleProps={draggableProvided.dragHandleProps}
              dragIconPadding={18}
              hideCalendarInfo
              hideMoreIcon
              innerRef={draggableProvided.innerRef}
              isCalendarDraggable
              isDragging={snapshot.isDragging}
              sprint={item}
              startButtonWidth={40}>
              <Droppable droppableId={droppableId}>
                {(droppableProvided) => (
                  <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                    {item.tasks && item.tasks.length > 0 && (
                      <InsideActiveSprintLine
                        draggablesTopOffset={draggablesTopOffset}
                        isDraggable
                        taskComponent={HomebaseLine}
                        taskList={item.tasks}
                        taskProps={sprintTaskProps}
                      />
                    )}
                    <HomebaseLine {...sprintInlineTaskProps} isInlineSprintTask sprint={item} />
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </StyledSprintLine>
          )
        }

        return (
          <HomebaseLine
            {...getTaskProps(item)}
            innerRef={draggableProvided.innerRef}
            draggableProps={draggableProvided.draggableProps}
            draggableStyle={draggableStyle}
            dragHandleProps={draggableProvided.dragHandleProps}
            isDragging={snapshot.isDragging}
          />
        )
      }}
    </Draggable>
  )
}

export default TaskPanelItem

const StyledSprintLine = styled(SprintLine)`
  width: 100%;
`
