import React from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import CloseTabsButton from 'CloseTabsButton'
import FocusModeToggle from 'FocusModeToggle'

function FocusControls({ muteModeOn, hideCloseTabsButton, onClickCloseTabs, onClickMuteMode }) {
  return (
    <Container>
      <Title>{translations.general.focus}</Title>
      {!hideCloseTabsButton && <CloseTabsButton marginRight={4} onClick={onClickCloseTabs} />}
      {<FocusModeToggle isOn={muteModeOn} onClick={onClickMuteMode} />}
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
  border-radius: 10px;
  color: ${styles.colors.textMediumDarkColor};
  display: flex;
  margin: 8px;
  min-height: 40px;
  padding: 4px 6px 4px 14px;
`

Container.displayName = 'Container'

const Title = styled.span`
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;
`

Title.displayName = 'Title'

export default FocusControls
