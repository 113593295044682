import React from 'react'
import styled, { css } from 'styled-components'
import { styles } from 'gipsy-misc'

const completionDuration = 0.3

export function Title(props) {
  const { title, whenDate, isPastItem, isStruckThrough } = props

  return (
    <TitleContainer>
      <TitleText isStruckThrough={isStruckThrough}>
        {whenDate && isPastItem ? '☠️ ' : ''}
        {title}
      </TitleText>
    </TitleContainer>
  )
}

const TitleContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-right: 10px;
  min-width: 0;
`

const TitleText = styled.span`
  display: block;
  font-size: 14px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${styles.colors.textMediumDarkColor};
  font-weight: 500;

  &:after {
    background-color: ${styles.colors.textMediumDarkColor};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(50% + 1px);
    transition: width ${completionDuration}s ease;
    width: 0;
  }

  ${({ isStruckThrough }) =>
    isStruckThrough &&
    css`
      &:after {
        width: 100%;
      }
    `}
`
