import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { produce } from 'immer'
import moment from 'moment'

import { todayview as todayviewApi } from 'gipsy-api'
import { models, utils } from 'gipsy-misc'

import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import usePageActions from 'features/hooks/usePageActions2'
import { updateItems } from 'store/items/actions'
import { sortListByScheduledTime, sortListByTodayRank } from 'logic/today'

export const sections = {
  COMPLETED: 'completed',
  OTHER: 'other',
  PINNED: 'pinned',
}

export const InProgressSprintLineDroppableId = 'InProgressSprintLineDroppable'

const pageSource = 'today-view'

export default function wrapper(Component) {
  function TodayPageContainer() {
    const {
      cancelCalendarTaskAction,
      cancelTaskAction,
      clearLocalTaskState,
      creatingCalendarTask,
      creatingTask,
      editingCalendarTask,
      editingTask,
      getDroppableId,
      ignoreOutsideClicks,
      isCreatingInlineTask,
      isDragging,
      keepCreatingTasks,
      onCreateCalendarTask,
      onDragEnd,
      onDragStart,
      onDeleteCalendarTaskCallback,
      onSaveCalendarTask,
      onTaskEditStart,
      onTogglePin,
      onTogglePinFromCalendarPanel,
      setIgnoreOutsideClicks,
      sprintComposerProps,
      startCalendarTaskAction,
      startInlineTaskCreation,
    } = useCalendarPanelContext()

    const dispatch = useDispatch()
    const {
      allItems,
      archiveTask,
      completeTask,
      completeTaskFromFS,
      createInlineTask,
      createSprint,
      deleteSprint,
      endSprint,
      findItemById,
      getFocusedTaskId,
      isTaskCreationAlertShown,
      onClickDelete,
      onClickDeleteFocusSession,
      onClickFocusSession,
      onClickOutsideSprint,
      onClickSprint,
      onTaskDroppedInSprint,
      popShortcutsGroup,
      pushShortcutsGroup,
      saveTask,
      sprintDeletePopup,
      sprints,
      startFsAndCreateTask,
      uncompleteTask,
      updateFocusSession,
    } = usePageActions()
    const session = useSelector((state) => state.session)

    const itemList = useMemo(() => {
      const today = new Date()
      const pageGroups = {
        [sections.COMPLETED]: [],
        [sections.OTHER]: [],
        [sections.PINNED]: [],
      }

      const pageItems = Object.keys(allItems).reduce((items, id) => {
        const item = allItems[id]
        let group

        if (!item || item.archived || (utils.datetime.isAfter(item.when?.date, today) && !item.completionTime))
          return items

        if (item.completionTime) {
          if (!utils.datetime.isToday(item.completionTime) || item.type !== models.item.type.TASK) return items

          group = sections.COMPLETED
        } else if (!item.completionTime) {
          group = item.pin?.time ? sections.PINNED : sections.OTHER
        }

        items[group].push(item)
        return items
      }, pageGroups)

      pageItems[sections.PINNED] = sortListByScheduledTime(pageItems[sections.PINNED])
      pageItems[sections.OTHER] = sortListByTodayRank(pageItems[sections.OTHER])
      pageItems[sections.COMPLETED] = utils.task.sortByCompletedAndCreationTimeAndCompletionTime(
        pageItems[sections.COMPLETED]
      )

      return pageItems
    }, [allItems])

    const inProgressSprints = useMemo(() => utils.sprint.getSprintsInProgress(sprints), [sprints])

    const onCreateInlineTask = useCallback(
      async (task, { componentSource = 'inlineAddTask', tmpId, dontShowCreationAlert } = {}) => {
        const response = await createInlineTask({
          context: { componentSource, pageSource },
          dontShowCreationAlert,
          task,
          tmpId,
        })

        return response
      },
      [createInlineTask]
    )

    const onCreateInlineTaskFromSprint = useCallback(
      async (task) => {
        if (!task.sprintInfo) return

        await createInlineTask({
          context: { componentSource: 'sprint', pageSource },
          dontShowCreationAlert: true,
          task,
        })
      },
      [createInlineTask]
    )

    const handleStartFsAndCreateTask = useCallback(
      async (taskData) => {
        await startFsAndCreateTask({ pageSource, taskData })
      },
      [startFsAndCreateTask]
    )

    const onComplete = useCallback(
      async ({ id, value }) => {
        if (value) {
          await completeTask({ id })
        } else {
          await uncompleteTask(id)
        }
      },
      [completeTask, uncompleteTask]
    )

    const onClickDeleteSprint = useCallback(
      (sprint) => {
        sprintDeletePopup(sprint, {
          onConfirmed: (recurrenceOption) => {
            deleteSprint(sprint.id, recurrenceOption)
          },
        })
      },
      [deleteSprint, sprintDeletePopup]
    )

    const onCreateSprint = useCallback(
      async (sprint, callback) => {
        const response = await createSprint(sprint)
        callback?.(response)
        return response
      },
      [createSprint]
    )

    const onRemoveFromSprint = useCallback(
      (task) => {
        const updatedTask = utils.task.computeTaskOnChange(task, {
          paramName: 'sprintInfo',
          value: null,
        })

        saveTask(updatedTask)
      },
      [saveTask]
    )

    const onDropTaskInSprint = useCallback(
      async ({ destinationIndex, itemId, sprintId }) => {
        if (!sprintId || !itemId) return

        await onTaskDroppedInSprint({
          destinationIndex,
          sprintId,
          taskId: itemId,
        })
      },
      [onTaskDroppedInSprint]
    )

    const onDropInOtherSection = useCallback(
      async ({ destinationIndex, destinationGroup, itemId, sourceData }) => {
        if (destinationGroup === sections.PINNED) return

        const item = findItemById(itemId)

        if (!item) return

        const { extraParams, group: sourceGroup } = sourceData

        const rank = destinationIndex + 1
        const updatedItem = produce(item, (draft) => {
          draft.when.date = moment().format('YYYY-MM-DD')
          draft.todaySectionRank = rank

          if (extraParams?.sprintId) {
            delete draft.sprintInfo
          }
        })

        let updatedItems = [updatedItem]

        if (sourceGroup === sections.OTHER) {
          updatedItems = itemList[sourceGroup].filter((sectionItem) => sectionItem.id !== updatedItem.id)
          updatedItems.splice(destinationIndex, 0, updatedItem)
          updatedItems = updatedItems.map((sectionItem, index) =>
            produce(sectionItem, (draft) => {
              draft.todaySectionRank = index + 1
            })
          )
        }

        dispatch(updateItems(updatedItems))

        await todayviewApi.dragAndDropTasksInTodaySection({
          taskId: updatedItem.id,
          toRank: rank,
          date: updatedItem.when.date,
          inTodaySection: true,
        })
      },
      [dispatch, findItemById, itemList]
    )

    const onDrop = useCallback(
      (draggableItem) => {
        if (!draggableItem.source || !draggableItem.destination) return

        const { id: draggableItemId, type: draggableType } = JSON.parse(draggableItem.draggableId)
        const sourceData = getDroppableId(draggableItem.source.droppableId)
        const { extraParams: destinationExtraParams, group: destinationGroup } = getDroppableId(
          draggableItem.destination.droppableId
        )

        if (sourceData.extraParams?.id === InProgressSprintLineDroppableId) return

        if (draggableType !== models.item.type.SPRINT && destinationExtraParams?.sprintId) {
          onDropTaskInSprint({
            destinationIndex: draggableItem.destination.index,
            itemId: draggableItemId,
            sprintId: destinationExtraParams.sprintId,
          })
        } else if (destinationGroup) {
          onDropInOtherSection({
            destinationIndex: draggableItem.destination.index,
            destinationGroup,
            itemId: draggableItemId,
            sourceData,
          })
        }
      },
      [getDroppableId, onDropInOtherSection, onDropTaskInSprint]
    )

    const onMoveToTopTodaySection = useCallback(
      async (taskId) => {
        const item = findItemById(taskId)

        if (!item) return

        const updatedItem = produce(item, (draft) => {
          draft.when.date = moment().format('YYYY-MM-DD')
          draft.todaySectionRank = 1
        })

        let updatedItems = itemList[sections.OTHER].filter((sectionItem) => sectionItem.id !== updatedItem.id)
        updatedItems.splice(0, 0, updatedItem)
        updatedItems = updatedItems.map((sectionItem, index) =>
          produce(sectionItem, (draft) => {
            draft.todaySectionRank = index + 1
          })
        )

        dispatch(updateItems(updatedItems))

        await todayviewApi.dragAndDropTasksInTodaySection({
          taskId: updatedItem.id,
          toRank: 1,
          date: updatedItem.when.date,
          inTodaySection: true,
        })
      },
      [dispatch, findItemById, itemList]
    )

    const onArchiveAllCompletedToday = useCallback(() => {
      itemList[sections.COMPLETED].forEach((task) => {
        archiveTask(task)
      })
    }, [archiveTask, itemList])

    const toHideTaskId = getFocusedTaskId()

    return (
      <Component
        allItems={itemList}
        cancelCalendarTaskAction={cancelCalendarTaskAction}
        cancelTaskAction={cancelTaskAction}
        clearLocalTaskState={clearLocalTaskState}
        creatingCalendarTask={creatingCalendarTask}
        creatingTask={creatingTask}
        editingCalendarTask={editingCalendarTask}
        editingTask={editingTask}
        ignoreOutsideClicks={ignoreOutsideClicks}
        inProgressSprints={inProgressSprints}
        isCreatingInlineTask={isCreatingInlineTask}
        isDragging={isDragging}
        isTaskCreationAlertShown={isTaskCreationAlertShown}
        keepCreatingTasks={keepCreatingTasks}
        onArchive={archiveTask}
        onArchiveAllCompletedToday={onArchiveAllCompletedToday}
        onClickDelete={onClickDelete}
        onClickDeleteFocusSession={onClickDeleteFocusSession}
        onClickDeleteSprint={onClickDeleteSprint}
        onClickFocusSession={onClickFocusSession}
        onClickOutsideSprint={onClickOutsideSprint}
        onClickSprint={onClickSprint}
        onComplete={onComplete}
        onCompleteFromFS={completeTaskFromFS}
        onCreateCalendarTask={onCreateCalendarTask}
        onCreateInlineTask={onCreateInlineTask}
        onCreateInlineTaskFromSprint={onCreateInlineTaskFromSprint}
        onCreateSprint={onCreateSprint}
        onDeleteCalendarTaskCallback={onDeleteCalendarTaskCallback}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onEndSprint={endSprint}
        onMoveToTopTodaySection={onMoveToTopTodaySection}
        onRemoveFromSprint={onRemoveFromSprint}
        onSave={saveTask}
        onSaveCalendarTask={onSaveCalendarTask}
        onTaskEditStart={onTaskEditStart}
        onTogglePin={onTogglePin}
        onTogglePinFromCalendarPanel={onTogglePinFromCalendarPanel}
        onUpdateFocusSession={updateFocusSession}
        popShortcutsGroup={popShortcutsGroup}
        pushShortcutsGroup={pushShortcutsGroup}
        session={session}
        setIgnoreOutsideClicks={setIgnoreOutsideClicks}
        sprintComposerProps={sprintComposerProps}
        startCalendarTaskAction={startCalendarTaskAction}
        startFsAndCreateTask={handleStartFsAndCreateTask}
        startInlineTaskCreation={startInlineTaskCreation}
        toHideTaskId={toHideTaskId}
      />
    )
  }

  return React.memo(withRouter(TodayPageContainer))
}
