import { styles } from 'gipsy-misc'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'Icon'

function IconButton(props) {
  return (
    <Container
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      isDisabled={props.isDisabled}
      padding={props.padding}
      borderRadius={props.borderRadius}
      labelColor={props.labelColor}
      labelHoverColor={props.labelHoverColor}
      borderColor={props.isDisabled ? props.borderHoverColor ?? styles.colors.greyBorderColor : props.borderColor}
      fillIcon={props.fillIcon}
      flexGrow={props.flexGrow}
      iconHoverColor={props.iconHoverColor}
      borderHoverColor={props.borderHoverColor}>
      <StyledIcon
        icon={props.iconName}
        fill={props.fillIcon ? props.iconColor || props.labelColor : 'transparent'}
        size={props.iconSize}
      />
      <Label textColor={props.labelColor}>{props.label}</Label>
    </Container>
  )
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  iconHoverColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  borderColor: PropTypes.string,
  borderHoverColor: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  labelColor: PropTypes.string,
  labelHoverColor: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
}

IconButton.defaultProps = {
  iconSize: 20,
  padding: 0,
  height: 40,
  borderRadius: 8,
  labelColor: styles.colors.darkGrey,
  borderColor: styles.colors.greyBorderColor,
}

export default IconButton

const Label = styled.div`
  margin-left: 8px;
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
  transition: color 0.25s ease-in-out;
  color: ${(props) => props.textColor || 'inherit'};
`

const StyledIcon = styled(Icon)`
  path {
    transition: fill 0.25s ease-in-out;
  }
  rect,
  circle {
    transition: stroke 0.25s ease-in-out;
  }
`

const Container = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? `${props.width}px` : 'auto')};
  min-width: ${(props) => (props.width ? `${props.width}px` : 0)};
  display: flex;
  flex-grow: ${(props) => (props.flexGrow ? 1 : 0)};
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: ${(props) => props.borderRadius}px;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  transition-property: border-color, color;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  padding: ${({ padding }) =>
    padding ? (Array.isArray(padding) ? padding.map((p) => `${p}px `) : `${padding}px`) : 0};

  ${StyledIcon} {
    path {
      fill: ${(props) => props.labelColor};
    }
    rect,
    circle {
      stroke: ${(props) => props.labelColor};
    }
    svg {
      fill: ${(props) => props.fillIcon && props.labelColor};
    }
  }

  :hover {
    border-color: ${(props) => props.borderHoverColor || props.borderColor};
    color: ${(props) => props.labelHoverColor || props.labelColor};
    ${StyledIcon} {
      path {
        fill: ${(props) => props.iconHoverColor || props.labelHoverColor || props.labelColor};
      }
      rect,
      circle {
        stroke: ${(props) => props.iconHoverColor || props.labelHoverColor || props.labelColor};
      }
      svg {
        fill: ${(props) => props.fillIcon && (props.iconHoverColor || props.labelHoverColor || props.labelColor)};
      }
    }
    ${Label} {
      color: ${(props) => props.labelHoverColor};
    }
  }
  :not(:first-of-type) {
    margin-left: 8px;
  }
`
