import React, { useState } from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { CustomPopup, Icon, SimpleButton } from 'gipsy-ui'

import { popupStyles, PopupTail, TailWrapper } from './commonUIComponents'
import DayPicker from './DayPicker'

function CustomSettingsPopup({ customTypeSettings, onCancel, onClickOutside, onSave, firstDayOfWeek }) {
  const [daysSelected, setDaysSelected] = useState(() => {
    if (!customTypeSettings?.days) return {}

    return customTypeSettings?.days.reduce((selected, dayNumber) => {
      selected[dayNumber] = true
      return selected
    }, {})
  })

  const handleChange = (updatedDaysSelected) => {
    setDaysSelected(updatedDaysSelected)
  }

  const handleSave = () => {
    onSave(
      Object.keys(daysSelected).reduce((days, dayKey) => {
        if (!daysSelected[dayKey]) return days

        days.push(Number.parseInt(dayKey))
        return days
      }, [])
    )
  }

  return (
    <Popup onClickOutside={onClickOutside}>
      <PopupTail />
      <BackTrail onClick={onCancel}>
        <LeftCaret fill={styles.colors.textMediumDarkColor} icon='SingleChevronLeft' size={10} />
        {translations.sprint.recurrency.customRecurrence}
      </BackTrail>
      <DayPicker daysSelected={daysSelected} firstDayOfWeek={firstDayOfWeek} onChange={handleChange} />
      <Buttons>
        <SimpleButton
          backgroundColor={styles.colors.veryLightGrey}
          height={32}
          onClick={onCancel}
          text={translations.general.cancel}
          textColor={styles.colors.textMediumDarkColor}
          width={62}
        />
        <SaveButton
          backgroundColor={styles.colors.primaryColor}
          onClick={handleSave}
          height={32}
          text={translations.general.save}
          textColor={'white'}
          width={62}
        />
      </Buttons>
    </Popup>
  )
}

const Popup = styled(CustomPopup)`
  ${popupStyles}

  display: flex;
  flex-flow: column;
  padding: 22px;
  transform: translateX(-30%);

  ${TailWrapper} {
    left: 30%;
  }
`

Popup.displayName = 'Popup'

const LeftCaret = styled(Icon)`
  margin-right: 13px;
`

LeftCaret.displayName = 'LeftCaret'

const BackTrail = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: auto;

  &:hover {
    color: ${styles.colors.primaryColor};

    ${LeftCaret} {
      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }
`

BackTrail.displayName = 'BackTrail'

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

Buttons.displayName = 'Buttons'

const SaveButton = styled(SimpleButton)`
  margin-left: 16px;
`

SaveButton.displayName = 'SaveButton'

export default CustomSettingsPopup
