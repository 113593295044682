const mixpanel = {
  closedTabsEvent: 'closedTabs',
  usedShortcut: 'usedShortcut',
  navigatedEvent: 'navigated',
  clickedAllTasksEvent: 'Clicked All Tasks',
  clickedTimeReportsEvent: 'Clicked Time Reports',
  clickedWatchYoutubeVideosEvent: 'clickedWatchYoutubeVideos',
  suggestionPopupShownEvent: 'suggestionPopupShown',
  reminderPopupShownEvent: 'reminderPopupShown',
  onboardingFocusSessionStartEvent: 'onboardingFocusSessionStart',
  onboardingCalendarSlotSelectedEvent: 'onboardingCalendarSlotSelected',
  onboardingCalendarCreateBlockEvent: 'onboardingCalendarCreateBlock',
  onboardingCalendarRecurrenceSelectedEvent: 'onboardingCalendarRecurrenceSelected',
  onboardingCalendarHelperContinueClickedEvent: 'onboardingCalendarHelperContinueClicked',
  onboardingFocusSessionCompletedEvent: 'onboardingFocusSessionCompleted',
  onboardingVideoPromptActionEvent: 'onboardingVideoPromptAction',
  onboardingVideoContinueClickedEvent: 'onboardingVideoContinueClicked',
  onboardingFirstChromePromptActionEvent: 'onboardingFirstChromePromptAction',
  onboardingFirstTimeCalendarLandedEvent: 'onboardingFirstTimeCalendarLanded',
  daysTasksClickedEvent: 'daysTasksClicked',
  allCalendarsPanelExpandedEvent: 'allCalendarsPanelExpanded',
  disconnectAccountClickedEvent: 'disconnectAccountClicked',
  addAccountClickedEvent: 'addAccountClicked',
}

export default mixpanel
