import styled, { css } from 'styled-components'

import { media } from 'gipsy-ui'
import { styles } from 'gipsy-misc'

export const IntegrationContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
  padding: 16px 20px;
  opacity: ${(props) => (props.blur ? 0.5 : 1)};
  box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
  margin-bottom: 8px;
  ${(props) =>
    props.inactive &&
    css`
      opacity: 0.5;
      box-shadow: 0px 1px 1px rgba(33, 21, 81, 0.08);
    `}

  ${media['phone-wide']`
  width:100%;
  margin-top:20px;
`};
`

export const IntegrationRow = styled.div`
  width: 100%;
  display: flex;
`

export const AppTitleContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: center;
`

export const AppTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${styles.colors.textDarkColor};
  margin-right: 7px;
`

export const AppDescription = styled.div`
  color: ${styles.colors.darkGrey};
  font-size: 13px;
`
export const IntegrationHeaderContent = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
`

const IntegrationAvatarStyles = css`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 11px;
`

const IntegrationAvatarImg = styled.img`
  ${IntegrationAvatarStyles};
`

const IntegrationAvatarSpan = styled.span`
  ${IntegrationAvatarStyles};
  color: white;
  font-size: 16px;
  font-weight: 500;
  background: ${styles.colors.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
`

export const IntegrationAvatar = (props) => {
  const { src, name = 'user' } = props
  if (src) {
    return <IntegrationAvatarImg src={src} />
  } else {
    const subNames = name.split(' ')
    const initials = subNames.length > 1 ? `${subNames[0][0]}${subNames[1][0]}` : `${subNames[0][0]}`
    return <IntegrationAvatarSpan> {initials} </IntegrationAvatarSpan>
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const IconImg = styled.div`
  height: 200px;
  width: 200px;
  background: url('${(props) => props.src}') center center no-repeat;
  background-size: contain;
`

const TextContainer = styled.div`
  font-size: 20px;
  font-weight: 500;
  ${media['phone-wide']`
    font-size: 16px;
`}
`

export const ImageSyncContainer = (props) => {
  const { src, text } = props
  return (
    <Container>
      <IconImg src={src} alt="loading-logo" />
      <TextContainer> {text}</TextContainer>
    </Container>
  )
}
