import React, { useState } from 'react'
import styled from 'styled-components'

import { user as userApi } from 'gipsy-api'
import { styles, translations } from 'gipsy-misc'
import { ReusableButton } from 'gipsy-ui'

import { LogoContainer, TitleContainer } from 'features/auth/components'
import {
  dailyTaskLoadOption,
  departmentOption,
  SurveyOption,
  toolTrackTasksOption,
} from 'pages/onboarding/components/surveyOption'
import { getSrc } from 'utils/image'

function Survey({ onStepDone, stepNumber, user }) {
  const [surveyChoices, setSurveyChoices] = useState({
    [dailyTaskLoadOption]: null,
    [departmentOption]: null,
    [toolTrackTasksOption]: null,
  })

  const onChange = (surveyOption, obj) => {
    setSurveyChoices((prev) => ({
      ...prev,
      [surveyOption]: obj?.value || null,
    }))
  }

  const hasAllMandatoryFieldsOfSurveyFilled = () => {
    return !!(
      surveyChoices[departmentOption] &&
      surveyChoices[dailyTaskLoadOption] &&
      surveyChoices[toolTrackTasksOption]
    )
  }

  const onClickSurvey = () => {
    if (hasAllMandatoryFieldsOfSurveyFilled()) {
      userApi.postOnboardingInfo(surveyChoices)
      onStepDone(stepNumber)
    }
  }

  return (
    <SurveyContainer>
      <StyledLogoContainer src={getSrc('/images/gipsybot-default.svg')} />
      <TitleContainer>
        {translations.onboarding.survey.title.replace(
          '[displayName]',
          user && user.displayName ? user.displayName : ''
        )}
      </TitleContainer>
      <SubtitleContainer>{translations.onboarding.survey.subtitle}</SubtitleContainer>
      <SurveyOptionsContainer>
        <SurveyOption name={departmentOption} onChange={onChange} />
        <SurveyOption name={dailyTaskLoadOption} onChange={onChange} />
        <SurveyOption name={toolTrackTasksOption} onChange={onChange} />
      </SurveyOptionsContainer>
      <ActionButton
        borderRadius={18}
        disabled={!hasAllMandatoryFieldsOfSurveyFilled()}
        fontSize={parseInt(styles.fonts.fontSizeSmall, 10)}
        height={56}
        onClick={onClickSurvey}
        width={260}>
        {translations.onboarding.survey.button}
      </ActionButton>
    </SurveyContainer>
  )
}

const SurveyContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: ${styles.shadows.popup};
  display: flex;
  flex-flow: column;
  max-width: 576px;
  padding: 82px 64px 32px;
  position: relative;
`

SurveyContainer.displayName = 'SurveyContainer'

const StyledLogoContainer = styled(LogoContainer)`
  margin-bottom: 0;
  position: absolute;
  transform: translateY(-50%);
  top: 0;
`

StyledLogoContainer.displayName = 'StyledLogoContainer'

const SubtitleContainer = styled.div`
  color: ${styles.colors.textDarkColor};
  font-size: 16px;
  margin-bottom: 64px;
  text-align: center;
`

SubtitleContainer.displayName = 'SubtitleContainer'

const SurveyOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;
`

SurveyOptionsContainer.displayName = 'SurveyOptionsContainer'

const ActionButton = styled(ReusableButton)`
  background: ${styles.colors.backgroundPrimaryColor};
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

ActionButton.displayName = 'ActionButton'

export default Survey
