import React, { PureComponent } from 'react'
import { Redirect } from 'react-router'
import qs from 'qs'

import { clearUTMInfoFromLocalStorage, writeUTMInfoToLocalStorage } from 'logic/login'
import { session } from 'gipsy-api'

export default function wrapper(Component) {
  class LoginPageContainer extends PureComponent {
    state = {
      redirect: null,
    }

    componentDidMount = () => {
      const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      clearUTMInfoFromLocalStorage()
      writeUTMInfoToLocalStorage(searchParams)
    }
    authenticateGoogleWithElectron = async () => {
      window.sessionStorage.setItem('stateOAuth-google', 'isgoogle')
      window.sessionStorage.setItem('nonceOAuth-google', '')
      const remote = window.require('electron').remote
      const mainProcess = remote.require('./main.js')
      try {
        const result = await mainProcess.googleSignin({
          clientId: process.env.REACT_APP_ELECTRON_GOOGLE_CLIENT_ID,
          authorizationUrl: process.env.REACT_APP_GOOGLE_AUTHORIZATION_URL,
          tokenUrl: process.env.REACT_APP_GOOGLE_TOKEN_URL,
          useBasicAuthorizationHeader: false,
          redirectUri: process.env.REACT_APP_ELECTRON_REDIRECT_URI,
        })

        const redirect = `/oauthcallback?state=isgoogle&code=${result.authorizationCode}&redirectUri=${process.env.REACT_APP_ELECTRON_REDIRECT_URI}`
        this.setState({ redirect })
      } catch (err) {
        console.error(err)
      }
    }
    goToGoogleAuth = () => {
      if (process.env.REACT_APP_ELECTRON === 'true') {
        this.authenticateGoogleWithElectron()
      } else {
        session.getAuthURL('google').then((data) => {
          window.sessionStorage.setItem('stateOAuth-google', data.state)
          window.sessionStorage.setItem('nonceOAuth-google', data.nonce)
          window.location.href = data.authURL
        })
      }
    }

    goToMicrosoftAuth = () => {
      session.getAuthURL('microsoft').then((data) => {
        window.sessionStorage.setItem('stateOAuth-microsoft', data.state)
        window.sessionStorage.setItem('nonceOAuth-microsoft', data.nonce)
        window.location.href = data.authURL
      })
    }

    render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect} />
      }
      return <Component goToGoogleAuth={this.goToGoogleAuth} goToMicrosoftAuth={this.goToMicrosoftAuth} />
    }
  }

  return LoginPageContainer
}
