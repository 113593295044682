import * as details from './details'
import * as options from './options'
import * as scheduling from './scheduling'
import * as sprints from './sprints'
import * as types from './types'

const recurrency = {
  details,
  options,
  scheduling,
  sprints,
  types,
}

export default recurrency
