import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'
import { CardAttribute, IconWrapper, PlaceHolder, getColor } from './commonUIComponents'
import { styles, translations, variables } from 'gipsy-misc'
import Icon from 'Icon'
import Tooltip from 'Tooltip'
import SprintSelect from 'Sprint/SprintSelect'

const sprintColor = styles.colors.sprintFill

export class SprintWrapper extends PureComponent {
  state = {
    dropdownLeftPosition: 0,
    sprintDropdownShown: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sprintDropdownShown && !this.state.sprintDropdownShown) {
      this.setState({
        dropdownLeftPosition: 0,
      })
    }
  }

  showSprintsDropdown = (value) => {
    this.setState(
      {
        sprintDropdownShown: value,
      },
      () => {
        if (value) {
          this.setState({
            dropdownLeftPosition: this.computeDropdownLeftPosition(),
          })
        }
      }
    )
  }

  startSprintCreationWithSettings = () => {
    this.props.startSprintCreation &&
      this.props.startSprintCreation({
        sprintActionsCallbacks: {
          onCreateSprintCallback: this.onCreateSprint,
          onCancelCreateSprintCallback: this.onCancelCreateSprint,
        },
      })
    this.showSprintsDropdown(false)
  }

  handleClickOutside = (e) => {
    const { sprintDropdownShown } = this.state
    if (sprintDropdownShown) {
      const clickedOutDropdown = this.dropdown && !this.dropdown.contains(e.target)
      const clickedOutFieldNode = this.fieldNode && !this.fieldNode.contains(e.target)
      if (clickedOutDropdown && clickedOutFieldNode) {
        this.showSprintsDropdown(false)
      }
    }
  }

  onWrapperClick = () => {
    this.showSprintsDropdown(!this.state.sprintDropdownShown)
  }

  onCreateSprint = async (newSprint) => {
    this.props.onChange(newSprint)
  }

  onCancelCreateSprint = () => {
    this.showSprintsDropdown(true)
  }

  computeDropdownLeftPosition = () => {
    const { getLineBounds } = this.props

    const lineBounds = getLineBounds?.()
    if (!this.state.sprintDropdownShown || !this.dropdown) return 0

    const { right } = this.dropdown.getBoundingClientRect()
    const { right: lineRightPosition } = lineBounds
    const prevLeft = this.state.dropdownLeftPosition

    let left = prevLeft
    if (right > lineRightPosition) {
      left = lineRightPosition - right
    } else {
      return Math.min(prevLeft, 0)
    }

    return Math.min(left, prevLeft)
  }

  renderIcon() {
    const { shrinked, value } = this.props
    const { sprintDropdownShown } = this.state
    const hasValue = value && value.id

    return (
      <IconWrapper noMargin={shrinked}>
        <Icon
          size={14}
          icon="Sprint"
          fill={getColor({
            isSelected: sprintDropdownShown,
            hasValue: hasValue,
            selectedColor: sprintColor,
            valueColor: sprintColor,
          })}
        />
        <Tooltip text={translations.line.addToSprint} vs={25} hs={5} position="top left" />
      </IconWrapper>
    )
  }

  render() {
    const { maxWidth, onChange, value, activeSprints, hideCreateButton, shrinked } = this.props
    const hasValue = value && value.id

    const { sprintDropdownShown } = this.state

    return (
      <CardAttribute
        focused={sprintDropdownShown}
        focusedColor={sprintColor}
        onClick={this.onWrapperClick}
        ref={(ref) => (this.fieldNode = ref)}
        squared={shrinked}>
        {shrinked ? (
          this.renderIcon()
        ) : hasValue ? (
          <Sprint>
            <StyledPlacedHolder
              color={getColor({
                isSelected: false,
                hasValue: true,
                valueColor: sprintColor,
              })}>
              {value.title}
            </StyledPlacedHolder>
          </Sprint>
        ) : (
          <Fragment>
            {this.renderIcon()}
            <PlaceHolder
              color={getColor({
                isSelected: sprintDropdownShown,
                selectedColor: sprintColor,
              })}>
              {translations.line.addToSprint}
            </PlaceHolder>
          </Fragment>
        )}
        {sprintDropdownShown && (
          <StyledSprintSelect
            setInnerRef={(ref) => (this.dropdown = ref)}
            inlineCreateButton={!hideCreateButton}
            onClickCreateButton={this.startSprintCreationWithSettings}
            hideCreateButton={hideCreateButton}
            value={value}
            activeSprints={activeSprints}
            onChange={onChange}
            maxWidth={maxWidth}
            style={{
              left: `${this.state.dropdownLeftPosition}px`,
            }}
          />
        )}
      </CardAttribute>
    )
  }
}

const StyledSprintSelect = styled(SprintSelect)`
  position: absolute;
  top: 32px;
  z-index: ${variables.zIndex.linePopup};
`

const StyledPlacedHolder = styled(PlaceHolder)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Sprint = styled.div`
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${styles.fonts.fontSizeXSmall};
  display: flex;
  align-items: center;
  max-width: 120px;
  justify-content: flex-end;
  position: relative;
`
