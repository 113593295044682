import React from 'react'
import styled, { css } from 'styled-components'

import { styles } from 'gipsy-misc'

import { NavLinkContainer, NavLinkContent, NavLinkIcon } from './commonUIComponents'

function NavBarLink({ active, colorFill, colorStroke, icon, text, to, onClick }) {
  return (
    <StyledNavLinkContainer to={to} onClick={onClick}>
      <StyledNavLinkContent active={active} colorFill={colorFill} colorStroke={colorStroke}>
        <NavLinkIcon icon={icon} size={14} />
        {text}
      </StyledNavLinkContent>
    </StyledNavLinkContainer>
  )
}

const StyledNavLinkContainer = styled(NavLinkContainer)`
  display: block;
  height: 100%;
`

StyledNavLinkContainer.displayName = 'StyledNavLinkContainer'

const StyledNavLinkContent = styled(NavLinkContent)`
  border-bottom: 2px solid transparent;
  height: 100%;
  padding: 0 32px;
  transition: border-bottom-color 400ms ease-in-out;

  ${({ active }) => css`
    ${active &&
    css`
      border-bottom-color: ${styles.colors.primaryColor};
    `}
  `}
`

StyledNavLinkContent.displayName = 'StyledNavLinkContent'

export default NavBarLink
