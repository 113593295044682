import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

let cancelTokens = {}

// params can be of the type {filter:filter}
// with filterTyope taking as value
// active : to only have active projects
// archived : to only have archived projects
// all : to have all of them
// if there's no filter, the default is all
export const activeParam = 'active'
export const archivedParam = 'archived'
export const get = (filter) => {
  let endpoint = `/projects`
  if (filter) {
    endpoint = utils.request.appendToUrl(endpoint, { filter: filter })
  }
  return axios.get(endpoint)
}

export const create = (body) => {
  const endpoint = `/projects`
  return axios.post(endpoint, body)
}

export const edit = (body) => {
  let endpoint = `/projects/edit`
  return axios.post(endpoint, body)
}

export const archive = (projectId) => {
  let endpoint = `/projects/archive`
  const body = { projectId }
  return axios.post(endpoint, body)
}

export const unarchive = (projectId) => {
  let endpoint = `/projects/unarchive`
  const body = { projectId }
  return axios.post(endpoint, body)
}

export const dragAndDropProject = (projectId, toRank) => {
  let endpoint = `/projects/drag-and-drop`
  const payload = { projectId, toRank }
  return axios.post(endpoint, payload)
}

export const dragAndDropTasks = (projectId, body) => {
  let endpoint = `/projects/tasks/drag-and-drop`
  const payload = { projectId: projectId, ...body }
  return axios.post(endpoint, payload)
}

export const dragAndDropTasksInUncategorized = (body) => {
  let endpoint = `/uncategorized/tasks/drag-and-drop`
  return axios.post(endpoint, body)
}

export const del = (projectId) => {
  const endpoint = `/projects/${projectId}`
  return axios.delete(endpoint)
}

export const getActiveTasksOfProject = (projectId) => {
  cancelTokens.getActiveTasksOfProject = require('axios').CancelToken.source()
  const endpoint = `/projects/tasks/active/${projectId}`
  return axios.get(endpoint, { cancelToken: cancelTokens.getActiveTasksOfProject.token })
}

export const getArchivedTasksOfProject = (projectId, lastSortKey, limit, rangeQuery = 'lessThan') => {
  cancelTokens.getArchivedTasksOfProject = require('axios').CancelToken.source()
  let endpoint = `/projects/tasks/archived/${projectId}`
  let params = {}
  params.lastSortKey = lastSortKey
  params.limit = limit
  params.rangeQuery = rangeQuery
  endpoint = utils.request.appendToUrl(endpoint, params)
  return axios.get(endpoint, { cancelToken: cancelTokens.getArchivedTasksOfProject.token })
}

export const getUncategorizedActiveTasks = () => {
  cancelTokens.getUncategorizedActiveTasks = require('axios').CancelToken.source()
  const endpoint = `/uncategorized/tasks/active`
  return axios.get(endpoint, { cancelToken: cancelTokens.getUncategorizedActiveTasks.token })
}

export const getUncategorizedArchivedTasks = (lastSortKey, limit, rangeQuery = 'lessThan') => {
  cancelTokens.getUncategorizedArchivedTasks = require('axios').CancelToken.source()
  let endpoint = `/uncategorized/tasks/archived`
  let params = {}
  params.lastSortKey = lastSortKey
  params.limit = limit
  params.rangeQuery = rangeQuery
  endpoint = utils.request.appendToUrl(endpoint, params)
  return axios.get(endpoint, { cancelToken: cancelTokens.getUncategorizedArchivedTasks.token })
}

export const cancel = (method) => {
  if (cancelTokens[method] && cancelTokens[method].cancel) {
    cancelTokens[method].cancel()
  }
}
