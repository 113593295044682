import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'

import { styles } from 'gipsy-misc'

export class Title extends PureComponent {
  render() {
    const {
      title,
      titleRef,
      onTitleChange,
      onKeyDown,
      makeTrigger,
      handleItemSelected,
      disableRightBorderRadius,
    } = this.props

    const innerRef = typeof titleRef === 'function' ? titleRef : (ref) => (titleRef.current = ref)

    return (
      <EditModeTitle
        $disableRightBorderRadius={disableRightBorderRadius}
        innerRef={innerRef}
        onItemSelected={handleItemSelected}
        loadingComponent={() => <span />}
        value={title}
        onChange={onTitleChange}
        minChar={0}
        onKeyDown={onKeyDown}
        trigger={makeTrigger}
        containerStyle={{
          width: '100%',
          position: 'relative',
        }}
        style={{
          height: '40px',
          width: '100%',
          lineHeight: '33px',
        }}
        dropdownStyle={{
          position: 'absolute',
          overflow: 'scroll',
          maxHeight: '200px',
          backgroundColor: 'white',
          borderRadius: '4px',
          border: `solid 1px ${styles.colors.middleGrey}`,
        }}
        listStyle={{
          listStyleType: 'none',
          paddingLeft: '0px',
          marginBottom: '0px',
          marginTop: '0px',
        }}
        loaderStyle={{
          position: 'absolute',
          overflow: 'scroll',
          maxHeight: '200px',
          backgroundColor: 'white',
          borderRadius: '4px',
          border: `solid 1px ${styles.colors.middleGrey}`,
        }}
      />
    )
  }
}

// todo factor border
const EditModeTitle = styled(ReactTextareaAutocomplete)`
  && {
    color: ${styles.colors.textMediumDarkColor};
    font-family: ${styles.fonts.primaryFont};
    font-size: ${styles.fonts.fontSizeSmall};
    outline: none;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid ${(props) => (props.focused ? styles.colors.primaryColor : styles.colors.greyBorderColor)};
    border-radius: 8px;
    padding-left: 10px;
    ${(props) =>
      props.$disableRightBorderRadius &&
      css`
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      `}
  }
`
