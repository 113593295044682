import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { produce } from 'immer'
import moment from 'moment'
import throttle from 'lodash/throttle'
import set from 'lodash/set'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import Footer from './components/Footer'
import ComposerContainer from './components/ComposerContainer'
import Recurrency from './components/Recurrency'
import {
  getRecurrencyDetails,
  getRecurrencyOptionsFromSprintInfo,
  recurrencyOptions,
} from './components/Recurrency/utils'
import TitleSection from './components/TitleSection'
import container from './container'
import HomebaseLine from 'features/list/components/line'

import { models, styles, translations, utils } from 'gipsy-misc'
import { CustomPopup, DatePicker, datePickerStyles, Duration, Separator, TimeInput2 } from 'gipsy-ui'

import variables from 'assets/styles/variables'
import usePageActions from 'features/hooks/usePageActions2'
import { sprintDraggableType } from 'features/taskPanel/components/DragDropContext'
const componentName = 'SprintComposer'

export const droppableId = 'sprintComposerTasks'
export const droppableType = 'sprint-composer'
export const sprintComposerPortalId = 'sprint-composer-portal'
const { taskPanelContainerWidth } = variables

function useShownChanged(isShown) {
  const isShownRef = useRef(false)
  const hasChanged = isShownRef.current !== isShown
  if (hasChanged) isShownRef.current = isShown
  return hasChanged
}

function SprintComposer(props) {
  const {
    findItemById,
    onClickDelete,
    saveTask,
    createInlineTask,
    completeTask,
    recurringSprintPopup,
  } = usePageActions()

  const dateInputRef = useRef(null)
  const pointerLeftPosition = useRef(0)
  const titleInputRef = useRef(null)

  const [title, setTitle] = useState('')
  const [isEmptyTitleErrorShown, setEmptyTitleErrorShown] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedRecurrency, setSelectedRecurrency] = useState({
    customSettings: null,
    type: recurrencyOptions.NO_REPEAT,
  })
  const [startTime, setStartTime] = useState(moment())
  const [estimatedTime, setEstimatedTime] = useState(
    utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
  )
  const [showDatePicker, setShowDatePicker] = useState(false)

  const {
    session,
    editingSprint,
    creatingSprint,
    onCreate,
    onCancel,
    onEdit,
    onDelete,
    isShown,
    addedTasks,
    customCancelButton,
    enterDelay,
    onCurrentSprintChange,
    registerShortcuts,
    unregisterShortcuts,
    updateCalendarDate,
  } = props

  const {
    addFilteredId,
    addTaskToSprint,
    addTmpItemToTaskPanel,
    clearTmpItemsFromTaskPanel,
    removeFilteredId,
    removeTaskFromSprint,
    replaceFilteredId,
    resetFilteredIds,
    setSelectModeInTaskPanel,
    setTasksFromSprint,
    updateTaskFromSprint,
  } = props.actions

  const hasShownChanged = useShownChanged(isShown)
  const isEditingMode = !!editingSprint

  useEffect(() => {
    const savePositions = (e) => {
      if (e.touches) {
        pointerLeftPosition.current = e.touches[0].pageX
      } else {
        pointerLeftPosition.current = e.pageX
      }
    }

    window.addEventListener('mousedown', savePositions)
    window.addEventListener('touchstart', savePositions)

    return () => {
      window.removeEventListener('mousedown', savePositions)
      window.removeEventListener('touchstart ', savePositions)
    }
  }, [])

  useEffect(() => {
    if (isShown && props.selectedSlot) {
      const { start, end } = props.selectedSlot
      const startTime = moment(start)
      const endTime = moment(end)
      const diff = endTime.diff(startTime, 'minutes')
      const estimatedTime = utils.datetime.convertMinuteToNanoseconds(diff)
      setStartTime(startTime)
      setEstimatedTime(estimatedTime)
      updateCalendarDate(new Date(start))
    }
  }, [props.selectedSlot, isShown, updateCalendarDate])

  const clearState = useCallback(() => {
    setTasksFromSprint([])
    resetFilteredIds()
    clearTmpItemsFromTaskPanel()
    setTitle('')
    setEmptyTitleErrorShown(false)
    setSelectedRecurrency({
      customSettings: null,
      type: recurrencyOptions.NO_REPEAT,
    })
  }, [setTasksFromSprint, resetFilteredIds, clearTmpItemsFromTaskPanel])

  const remapTasksWithSprintData = useCallback(
    (tasks) => {
      const pin = {
        time: startTime.format(),
      }
      return tasks.map((task) => {
        task.when = { date: '', rank: 0 }
        if (!task.sprintInfo) {
          task.sprintInfo = {
            estimatedTime,
            title,
            pin,
            id: editingSprint ? editingSprint.id : '',
          }
        }
        return task
      })
    },
    [title, estimatedTime, startTime, editingSprint]
  )

  const addedTasksList = useMemo(() => {
    return remapTasksWithSprintData(JSON.parse(JSON.stringify(addedTasks)))
  }, [addedTasks, remapTasksWithSprintData])

  const buildSprintFromState = useCallback(() => {
    const when = {
      date: startTime.format('YYYY-MM-DD'),
    }
    const pin = {
      time: startTime.format(),
    }

    const tasks = addedTasksList
    const sprint = {
      id: editingSprint ? editingSprint.id : '',
      title,
      estimatedTime,
      tasks,
      tasksId: tasks ? tasks.map((task) => task.id) : [],
      pin,
      when,
      type: models.item.type.SPRINT,
    }

    const recurrencyDetails = getRecurrencyDetails({
      customTypeSettings: selectedRecurrency.customSettings,
      recurrencyType: selectedRecurrency.type,
      sprintStartTime: startTime,
    })

    if (recurrencyDetails) {
      sprint.recurrencyInformation = {
        ...(editingSprint?.recurrencyInformation || {}),
        recurrencyDetails,
      }
    }

    return sprint
  }, [
    startTime,
    addedTasksList,
    editingSprint,
    title,
    estimatedTime,
    selectedRecurrency.customSettings,
    selectedRecurrency.type,
  ])

  const onClickCreate = useCallback(() => {
    if (!title) {
      setEmptyTitleErrorShown(true)
      return
    }
    const onClickCreateLogic = async () => {
      const sprint = buildSprintFromState()
      try {
        setLoading(true)
        await onCreate(sprint)
      } catch (err) {
        console.error('Focus block creation failed', err)
        onCancel()
      } finally {
        setLoading(false)
      }
    }
    onClickCreateLogic()
  }, [title, buildSprintFromState, onCreate, onCancel])

  const onClickEdit = useCallback(() => {
    if (!title) {
      setEmptyTitleErrorShown(true)
      return
    }
    const onClickEditLogic = async () => {
      const oldSprint = editingSprint
      const newSprint = buildSprintFromState()

      const edit = async (recurrenceOption) => {
        try {
          setLoading(true)
          await onEdit(newSprint, { recurrenceOption })
        } catch (err) {
          onCancel()
        } finally {
          setLoading(false)
        }
      }

      const isOldSprintRecurrent = utils.sprint.isRecurrent(oldSprint)
      const isNewSprintRecurrent = utils.sprint.isRecurrent(newSprint)
      const hasSprintInfoChanged = utils.sprint.hasSprintInfoChanged(oldSprint, newSprint)
      const hasRecurrenceChanged = !utils.recurrency.options.isRecurrenceEqual(
        oldSprint.recurrencyInformation?.recurrencyDetails,
        newSprint.recurrencyInformation?.recurrencyDetails
      )

      if (isNewSprintRecurrent && isOldSprintRecurrent && (hasRecurrenceChanged || hasSprintInfoChanged)) {
        const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date
        let hideAllOption = hasWhenDateChanged || hasRecurrenceChanged
        recurringSprintPopup(
          { hideAllOption, hideSingleOption: hasRecurrenceChanged, title: '' },
          {
            onConfirmed: (recurrenceOption) => edit(recurrenceOption),
          }
        )
      } else {
        edit()
      }
    }
    onClickEditLogic()
  }, [title, buildSprintFromState, onEdit, onCancel, editingSprint, recurringSprintPopup])

  const _registerShortcuts = useCallback(() => {
    if (registerShortcuts) {
      registerShortcuts &&
        registerShortcuts(
          [
            {
              key: 'Enter',
              label: isEditingMode ? translations.general.save : translations.general.create,
              callback: () => (isEditingMode ? onClickEdit() : onClickCreate()),
            },
            {
              key: 'Escape',
              label: translations.general.cancel,
              callback: onCancel,
            },
          ],
          componentName
        )
    }
  }, [isEditingMode, onClickCreate, onClickEdit, onCancel, registerShortcuts])

  useEffect(() => {
    if (hasShownChanged) {
      if (isShown) {
        setSelectModeInTaskPanel(true)
        titleInputRef.current && titleInputRef.current.focus()
        if (creatingSprint) {
          setTitle(creatingSprint.title || '')
          setStartTime(moment(creatingSprint.startTime))
          setEstimatedTime(
            creatingSprint.estimatedTime || utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 })
          )
          if (creatingSprint.tasks) {
            setTasksFromSprint(creatingSprint.tasks)
          }
        } else if (editingSprint) {
          setStartTime(moment(editingSprint.pin.time))
          setEstimatedTime(editingSprint.estimatedTime)
          setTitle(editingSprint.title)
          if (editingSprint.tasks) {
            setTasksFromSprint(editingSprint.tasks)
          }

          if (editingSprint.recurrencyInformation) {
            setSelectedRecurrency(getRecurrencyOptionsFromSprintInfo(editingSprint))
          }
        } else {
          setTitle('')
          setStartTime(moment())
          setEstimatedTime(utils.datetime.getNanosecondsFromHourAndMinute({ hour: 0, minute: 30 }))
        }
      } else {
        setSelectModeInTaskPanel(false)
        clearState()
      }
    }
  }, [
    isShown,
    hasShownChanged,
    clearState,
    creatingSprint,
    editingSprint,
    setTasksFromSprint,
    setSelectModeInTaskPanel,
  ])

  useEffect(() => {
    if (isShown) {
      _registerShortcuts()
      return () => unregisterShortcuts?.(componentName)
    }
  }, [isShown, _registerShortcuts, unregisterShortcuts])

  const _onCurrentSprintChange = useCallback(
    ({ param, value }) => {
      const sprint = buildSprintFromState()
      set(sprint, param, value)
      onCurrentSprintChange(sprint)
    },
    [buildSprintFromState, onCurrentSprintChange]
  )

  const onChangeTitle = useCallback(
    (e) => {
      setTitle(e.target.value)
      if (isEmptyTitleErrorShown) {
        setEmptyTitleErrorShown(false)
      }
      _onCurrentSprintChange({ param: 'title', value: e.target.value })
    },
    [isEmptyTitleErrorShown, _onCurrentSprintChange]
  )

  const onChangeStartTime = useCallback(
    (value) => {
      setStartTime((startTime) => {
        const newStartTime = moment(value).date(startTime.date()).month(startTime.month()).year(startTime.year())
        _onCurrentSprintChange({ param: 'pin.time', value: newStartTime })
        return newStartTime
      })
    },
    [_onCurrentSprintChange]
  )

  const onChangeEstimatedTime = useCallback(
    (value) => {
      const newEstimatedTime = utils.datetime.getNanosecondsFromHourAndMinute(value)
      setEstimatedTime(newEstimatedTime)
      _onCurrentSprintChange({ param: 'estimatedTime', value: newEstimatedTime })
    },
    [_onCurrentSprintChange]
  )

  const onRemoveTask = useMemo(
    () =>
      throttle((task) => {
        removeTaskFromSprint(task.id)
        if (
          editingSprint &&
          editingSprint.tasks &&
          editingSprint.tasks.find((sprintTask) => sprintTask.id === task.id)
        ) {
          addTmpItemToTaskPanel(task.id)
        } else {
          removeFilteredId(task.id)
        }
      }, 300),
    [removeTaskFromSprint, editingSprint, addTmpItemToTaskPanel, removeFilteredId]
  )

  const _onCreateTask = useCallback(
    async (task) => {
      if (createInlineTask) {
        task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
        addTaskToSprint(task)
        addFilteredId(task.id)

        const response = await createInlineTask({
          task,
          dontShowCreationAlert: true,
          context: { componentSource: 'sprintComposer' },
        })
        if (!response) {
          console.error('task creation failed')
          return
        }
        const createdTask = response.taskActions[0].task

        if (createdTask.id !== task.id) {
          updateTaskFromSprint(createdTask, task.id)
          replaceFilteredId(task.id, createdTask.id)
        }
      }
    },
    [createInlineTask, addTaskToSprint, addFilteredId, updateTaskFromSprint, replaceFilteredId, session]
  )

  const _onDelete = useCallback(() => {
    onDelete && onDelete(editingSprint)
  }, [editingSprint, onDelete])

  const handleDateClick = useCallback((e) => {
    if (e.target === dateInputRef.current) {
      setShowDatePicker((prev) => !prev)
    }
  }, [])

  const onClickOutsideDate = useCallback((e) => {
    if (!dateInputRef.current.contains(e.target)) {
      setShowDatePicker(false)
    }
  }, [])

  const onChangeDate = useCallback(
    (value) => {
      setStartTime((startTime) => {
        const newStartTime = moment(value).hours(startTime.hours()).minutes(startTime.minutes())
        _onCurrentSprintChange({ param: 'pin.time', value: newStartTime })
        return newStartTime
      })
    },
    [_onCurrentSprintChange]
  )

  const handleDateSelected = useCallback(
    ({ value }) => {
      onChangeDate(value)
      setShowDatePicker(false)
    },
    [onChangeDate]
  )

  const onSaveTask = useCallback(
    (task) => {
      const oldTask = findItemById(task.id)

      const toSaveTask = produce(task, (draft) => {
        delete draft.sprintInfo

        if (oldTask?.sprintInfo) {
          draft.sprintInfo = oldTask.sprintInfo
        }
      })

      saveTask(toSaveTask)
      updateTaskFromSprint(task, task.id)
    },
    [findItemById, saveTask, updateTaskFromSprint]
  )

  const onDeleteTask = useCallback(
    (taskId) => {
      onClickDelete(taskId, () => {
        removeTaskFromSprint(taskId)
      })
    },
    [onClickDelete, removeTaskFromSprint]
  )

  const onCompleteTask = useCallback(
    ({ id }) => {
      removeTaskFromSprint(id)

      completeTask({ id })
    },
    [completeTask, removeTaskFromSprint]
  )

  const handleRecurrencyChosen = useCallback((type, customSettings = null) => {
    setSelectedRecurrency({ customSettings, type })
  }, [])

  const createLineProps = {
    onCreate: _onCreateTask,
    innerLeftPadding: 47,
    innerRightPadding: 47,
    hideSprint: true,
    creating: true,
  }

  const estimatedTimeFromNS = utils.datetime.getHourAndMinuteFromNanoseconds(estimatedTime)

  return (
    <ComposerContainer isShown={isShown} enterDelay={enterDelay}>
      <Body>
        <InputsContainer className='fs-mask'>
          <TitleSection
            onChange={onChangeTitle}
            title={title}
            ref={titleInputRef}
            isEmptyTitleErrorShown={isEmptyTitleErrorShown}
          />
          <Separator />
          <TimeInputsContainer>
            <TimeInputContainer>
              <DateInput focused={showDatePicker} onClick={handleDateClick} ref={dateInputRef}>
                {moment(startTime).format('ddd, MMM DD')}
                {showDatePicker && (
                  <StyledPopup onClickOutside={onClickOutsideDate}>
                    <DatePicker
                      paramName={'when.date'}
                      selectedDay={startTime.toISOString()}
                      onChange={handleDateSelected}
                      firstDayOfWeek={session?.user?.settingsPreferences?.calendar?.firstDay}
                    />
                  </StyledPopup>
                )}
              </DateInput>
            </TimeInputContainer>
            <TimeInputContainer>
              <TimeInput2
                width={72}
                showPeriod={true}
                format='12h'
                hour={startTime.hours()}
                minute={startTime.minutes()}
                onChange={onChangeStartTime}
              />
            </TimeInputContainer>
            <TimeInputContainer>
              <Duration
                inputWidth={24}
                hour={estimatedTimeFromNS.hour}
                minute={estimatedTimeFromNS.minute}
                onChange={onChangeEstimatedTime}
              />
            </TimeInputContainer>
            <TimeInputContainer>
              <Recurrency
                customTypeSettings={selectedRecurrency.customSettings}
                onChange={handleRecurrencyChosen}
                recurrencyType={selectedRecurrency.type}
                sprintStartTime={startTime}
                firstDayOfWeek={session?.user?.settingsPreferences?.calendar?.firstDay}
              />
            </TimeInputContainer>
          </TimeInputsContainer>
        </InputsContainer>

        <TasksTitle>{translations.sprint.tasksSectionTitle}</TasksTitle>
        <Droppable droppableId={JSON.stringify({ id: droppableId, type: droppableType })}>
          {(droppableProvided) => (
            <TaskList ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              <DraggableTaskList
                onDeleteTask={onDeleteTask}
                onRemoveTask={onRemoveTask}
                onSaveTask={onSaveTask}
                onCompleteTask={onCompleteTask}
                pointerLeftPositionRef={pointerLeftPosition}
                tasks={addedTasksList}
              />
              {droppableProvided.placeholder}
            </TaskList>
          )}
        </Droppable>
        <HomebaseLine {...createLineProps} />
        {!addedTasksList ||
          (addedTasksList.length === 0 && (
            <EmptyTaskListPlaceHolder>{translations.sprint.addCreateTaskToBlock}</EmptyTaskListPlaceHolder>
          ))}
      </Body>
      <Footer
        customCancelButton={customCancelButton}
        isLoading={isLoading}
        createButtonLabel={isEditingMode ? translations.general.save : translations.sprint.create}
        onCreate={isEditingMode ? onClickEdit : onClickCreate}
        onCancel={onCancel}
        onDelete={isEditingMode ? _onDelete : undefined}
        isShown={isShown}
      />
    </ComposerContainer>
  )
}

const DraggableTaskList = React.memo(function DraggableTaskList({
  onDeleteTask,
  onRemoveTask,
  onSaveTask,
  onCompleteTask,
  pointerLeftPositionRef,
  tasks,
}) {
  return tasks.map((task, index) => {
    return (
      <Draggable draggableId={`${task.id}`} index={index} key={task.id} type={sprintDraggableType}>
        {(draggableProvided, snapshot) => {
          let draggableStyle = draggableProvided.draggableProps.style
          const isDragging = snapshot.isDragging

          if (isDragging) {
            const draggedWidth = taskPanelContainerWidth + 32
            draggableStyle = {
              ...draggableStyle,
              left: pointerLeftPositionRef.current - draggedWidth / 2,
              width: `${draggedWidth}px`,
            }
          }

          return (
            <HomebaseLine
              alignActionsPopupToContainer
              disableLinks
              draggableProps={draggableProvided.draggableProps}
              draggableStyle={draggableStyle}
              dragHandleProps={draggableProvided.dragHandleProps}
              hideDateInput
              hideSprint
              hideStartButton
              hideWhenDate
              innerLeftPadding={47}
              innerRef={draggableProvided.innerRef}
              innerRightPadding={47}
              isCreating
              isDraggable
              isDragging={isDragging}
              item={task}
              key={task.id}
              lineThrough={!!task.completed}
              marginAfterLine={'8px'}
              onClickLeftArrow={onRemoveTask}
              onDelete={onDeleteTask}
              onSave={onSaveTask}
              onComplete={onCompleteTask}
              animateComplete={true}
            />
          )
        }}
      </Draggable>
    )
  })
})

const InputsContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TaskList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

const EmptyTaskListPlaceHolder = styled.span`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${styles.colors.darkGrey};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`

const TasksTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
  color: ${styles.colors.textMediumDarkColor};
  width: 100%;
  text-align: center;
  margin: 42px 0;
`

const TimeInputsContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  padding: 20px;
  align-items: center;
  position: relative;
`

const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.textMediumDarkColor};
  margin-right: 10px;
`

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const DateInput = styled.div`
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  font-weight: 400;
  padding: 6px 4px;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

  &::selection {
    background: ${styles.colors.primaryColor};
    color: white;
  }

  &:hover {
    border-color: ${styles.colors.middleGrey};
    color: ${styles.colors.primaryColor};
  }

  ${({ focused }) =>
    focused &&
    css`
      background-color: ${styles.colors.veryLightGrey};
      border-color: ${styles.colors.primaryColor};

      &:hover {
        border-color: ${styles.colors.primaryColor};
      }
    `}
`

const StyledPopup = styled(CustomPopup)`
  background-color: white;
  border-radius: 8px;
  left: 0;
  margin: 0;
  padding: 8px;
  top: 100%;
  z-index: 3;

  ${datePickerStyles}
`

export default React.memo(container(SprintComposer))
