import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { styles } from 'gipsy-misc'
import { Icon } from 'gipsy-ui'

export const NavLinkContainer = styled(Link)`
  text-decoration: none;
`

NavLinkContainer.displayName = 'NavLinkContainer'

export const NavLinkIcon = styled(Icon)`
  margin-right: 10px;
`

NavLinkIcon.displayName = 'NavLinkIcon'

export const NavLinkContent = styled.div`
  align-items: center;
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  line-height: 24px;
  position: relative;
  user-select: none;

  ${({ active, colorFill, colorStroke }) => css`
    ${NavLinkIcon} {
      path {
        fill: ${colorFill ? styles.colors.textMediumDarkColor : 'transparent'};
        stroke: ${colorStroke ? styles.colors.textMediumDarkColor : 'transparent'};
      }
    }

    ${active &&
    css`
      color: ${styles.colors.primaryColor};

      ${NavLinkIcon} {
        path {
          fill: ${colorFill ? styles.colors.primaryColor : 'transparent'};
          stroke: ${colorStroke ? styles.colors.primaryColor : 'transparent'};
        }
      }
    `}

    &:hover {
      color: ${styles.colors.primaryColor};

      ${NavLinkIcon} {
        path {
          fill: ${colorFill ? styles.colors.primaryColor : 'transparent'};
          stroke: ${colorStroke ? styles.colors.primaryColor : 'transparent'};
        }
      }
    }
  `}
`

NavLinkContent.displayName = 'NavLinkContent'

export const NavBoxButtonIcon = styled(Icon)``

NavBoxButtonIcon.displayName = 'NavBoxButtonIcon'

export const NavBoxButton = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 0 24px;
  position: relative;

  ${NavBoxButtonIcon} {
    path {
      fill: ${styles.colors.darkGrey};
    }
  }

  ${({ active, showLeftBorder, showRightBorder }) => css`
    border-left: ${showLeftBorder ? `1px solid ${styles.colors.greyBorderColor}` : 'none'};
    border-right: ${showRightBorder ? `1px solid ${styles.colors.greyBorderColor}` : 'none'};

    ${active &&
    css`
      ${NavBoxButtonIcon} {
        path {
          fill: ${styles.colors.primaryColor};
        }
      }
    `}

    &:hover {
      ${NavBoxButtonIcon} {
        path {
          fill: ${styles.colors.primaryColor};
        }
      }
    }
  `}
`

NavBoxButton.displayName = 'NavBoxButton'
