import React, { Component } from 'react'
import PropTypes from 'prop-types'
import variables from 'assets/styles/variables'
import styled, { css } from 'styled-components'

import { getSrc } from 'utils/image'

import { Icon, ContextMenuContainer, ContextMenu, ContextMenuItem } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'

export const leftPaddingItemContainer = 20
export const rightPaddingItemContainer = 10
export const NavbarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => (props.isSelected ? styles.colors.primaryColor : styles.colors.textMediumDarkColor)};
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: ${leftPaddingItemContainer}px;
  padding-right: ${rightPaddingItemContainer}px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;

  ${({ sticky }) =>
    sticky &&
    css`
      background-color: white;
      bottom: 0;
      margin-bottom: 0;
      position: sticky;
    `}
`

const defaultIconSize = 18

export class DropdownNavbarItem extends Component {
  static defaultProps = {
    iconSize: defaultIconSize,
  }
  render() {
    const { iconName, label, rightIconName, onClick, iconSize, dataDraggableTitleType } = this.props

    return (
      <NavbarItemContainer onClick={onClick} data-draggable-title-type={dataDraggableTitleType}>
        <LeftContainer>
          <RegularIcon className='icon' size={iconSize} icon={iconName} />
        </LeftContainer>
        <LabelContainer>{label}</LabelContainer>
        <DropdownRightIcon size={12} icon={rightIconName} />
      </NavbarItemContainer>
    )
  }
}

DropdownNavbarItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  rightIconName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

const RightIcon = styled(Icon)`
  margin-left: auto;
`

const DropdownRightIcon = styled(RightIcon)`
  margin-top: 6px;
`

// you need to embed this class into a <ContextMenu />
export class ActiveItemMenu extends Component {
  state = {
    showOnHoverArchivePopup: false,
    showOnHoverDeletePopup: false,
  }

  render() {
    const {
      disableOnMoveDown,
      disableOnMoveUp,
      disableOnArchive,
      disableOnDelete,
      onHoverArchiveText,
      onHoverDeleteText,
      onMoveUp,
      onMoveDown,
      onEdit,
      onArchive,
      onDelete,
      callback,
    } = this.props
    return (
      <React.Fragment>
        <ContextMenuItem disabled={disableOnMoveUp} height={20} onClick={(e) => onMoveUp(e, callback)}>
          <ContextMenuItemContainer>
            <IconMenuItemContainer>
              <Icon icon='ArrowUp' size={14} />
            </IconMenuItemContainer>
            {translations.navbar.projects.moveUp}
          </ContextMenuItemContainer>
        </ContextMenuItem>
        <ContextMenuItem disabled={disableOnMoveDown} height={20} onClick={(e) => onMoveDown(e, callback)}>
          <ContextMenuItemContainer>
            <IconMenuItemContainer>
              <Icon icon='ArrowDown' size={14} />
            </IconMenuItemContainer>
            {translations.navbar.projects.moveDown}
          </ContextMenuItemContainer>
        </ContextMenuItem>
        <ContextMenuItem height={20} onClick={(e) => onEdit(e, callback)}>
          <ContextMenuItemContainer>
            <IconMenuItemContainer>
              <Icon icon='Edit' size={14} />
            </IconMenuItemContainer>
            {translations.general.edit}
          </ContextMenuItemContainer>
        </ContextMenuItem>
        {onArchive && (
          <ContextMenuItem
            disabled={disableOnArchive}
            height={20}
            onClick={(e) => onArchive(e, callback)}
            onMouseEnter={onHoverArchiveText ? () => this.setState({ showOnHoverArchivePopup: true }) : undefined}
            onMouseLeave={onHoverArchiveText ? () => this.setState({ showOnHoverArchivePopup: false }) : undefined}>
            <ContextMenuItemContainer>
              <IconMenuItemContainer>
                <Icon icon='Archive' size={20} />
              </IconMenuItemContainer>
              {translations.general.archive}
            </ContextMenuItemContainer>
          </ContextMenuItem>
        )}
        <ContextMenuItem
          disabled={disableOnDelete}
          height={20}
          onClick={(e) => onDelete(e, callback)}
          onMouseEnter={onHoverDeleteText ? () => this.setState({ showOnHoverDeletePopup: true }) : undefined}
          onMouseLeave={onHoverDeleteText ? () => this.setState({ showOnHoverDeletePopup: false }) : undefined}>
          <DeleteContextMenuItemContainer>
            <IconMenuItemContainer>
              <Icon icon='Trash' fill={styles.colors.pinkColor} size={14} />
            </IconMenuItemContainer>
            {translations.general.delete}
          </DeleteContextMenuItemContainer>
        </ContextMenuItem>
        <ActiveItemMenuMessagePopupContainer>
          {this.state.showOnHoverArchivePopup && (
            <ActiveItemMenuMessagePopup>{onHoverArchiveText}</ActiveItemMenuMessagePopup>
          )}
          {this.state.showOnHoverDeletePopup && (
            <ActiveItemMenuMessagePopup>{onHoverDeleteText}</ActiveItemMenuMessagePopup>
          )}
        </ActiveItemMenuMessagePopupContainer>
      </React.Fragment>
    )
  }
}

ActiveItemMenu.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onArchive: PropTypes.func,
  callback: PropTypes.func,
  disableOnMoveUp: PropTypes.bool,
  disableOnMoveDown: PropTypes.bool,
}

const ActiveItemMenuMessagePopupContainer = styled.div`
  position: relative;
`
const ActiveItemMenuMessagePopup = styled.span`
  position: absolute;
  background: white;
  box-shadow: 0px 17px 26px rgba(33, 21, 81, 0.1);
  border-radius: 10px;
  min-width: 200px;
  padding: 14px;
  left: -10px;
  bottom: -18px;
  transform: translateY(100%);
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${styles.colors.textMediumDarkColor};
`

export class TagNavbarItem extends Component {
  onEdit = (e, callback) => {
    e.preventDefault()
    const { tag, onEdit } = this.props
    onEdit(tag)
    callback()
  }

  onDelete = (e, callback) => {
    e.preventDefault()
    const { tag, index, onDelete } = this.props
    onDelete(tag, index)
    callback()
  }

  onMoveUp = (e, callback) => {
    e.preventDefault()
    const { tag, index, onMoveUp } = this.props
    onMoveUp(tag, index)
    callback()
  }

  onMoveDown = (e, callback) => {
    e.preventDefault()
    const { tag, index, onMoveDown } = this.props
    onMoveDown(tag, index)
    callback()
  }

  renderActiveContextMenuItems = (closeMenu) => {
    const { disableOnMoveUp, disableOnMoveDown } = this.props

    return (
      <ActiveItemMenu
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        onMoveUp={this.onMoveUp}
        onMoveDown={this.onMoveDown}
        callback={closeMenu}
        disableOnMoveUp={disableOnMoveUp}
        disableOnMoveDown={disableOnMoveDown}
      />
    )
  }

  render() {
    const { tag, onToggleMenu } = this.props

    return (
      <ContextMenuContainer onToggleMenu={onToggleMenu}>
        {(ref, onContextMenu, closeMenu, isContextMenuActive) => (
          <TagNavbarItemContainer>
            {<DragIcon icon={'DragHandle'} className={dragHandleIconClass} size={13} />}
            <IndentedLeftIconContainer />
            <ProjectAndTagLabelContainer>
              <LabelName title={tag.name}>{tag.name}</LabelName>
            </ProjectAndTagLabelContainer>
            <RightIcon className={rightIconClass} icon='More' size={15} onClick={onContextMenu} />
            <ContextMenu isActive={isContextMenuActive} setRef={ref}>
              {this.renderActiveContextMenuItems(closeMenu)}
            </ContextMenu>
          </TagNavbarItemContainer>
        )}
      </ContextMenuContainer>
    )
  }
}

const TagNavbarItemContainer = styled(NavbarItemContainer)`
  ${() => hoverIconsStyle};
  cursor: default;
  ${() => leftIndentedStyle}
`

TagNavbarItem.propTypes = {
  tag: PropTypes.object.isRequired,
  iconSize: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  disableOnMoveUp: PropTypes.bool,
  disableOnMoveDown: PropTypes.bool,
}

export class ProjectNavbarItem extends Component {
  onEdit = (e, callback) => {
    // we prevent project from being selected
    e.preventDefault()
    const { project, onEdit } = this.props
    onEdit(project)
    callback()
  }

  onMoveUp = (e, callback) => {
    e.preventDefault()
    const { project, index, onMoveUp } = this.props
    onMoveUp(project, index)
    callback()
  }

  onMoveDown = (e, callback) => {
    e.preventDefault()
    const { project, index, onMoveDown } = this.props
    onMoveDown(project, index)
    callback()
  }

  onArchive = (e, callback) => {
    e.preventDefault()
    const { project, index, onArchive } = this.props
    onArchive(project, index)
    callback()
  }

  onUnarchive = (e, callback) => {
    e.preventDefault()
    const { project, index, onUnarchive } = this.props
    onUnarchive(project, index)
    callback()
  }

  onDelete = (e, callback) => {
    e.preventDefault()
    const { project, index, onDelete } = this.props
    onDelete(project, index)
    callback()
  }

  renderArchivedContextMenuItems = (closeMenu) => (
    <React.Fragment>
      <ContextMenuItem
        disable={!!this.props.project.asanaInfo}
        height={20}
        onClick={(e) => this.onUnarchive(e, closeMenu)}>
        <ContextMenuItemContainer>
          <IconMenuItemContainer>
            <Icon icon='Redo' size={14} />
          </IconMenuItemContainer>
          {translations.navbar.projects.restore}
        </ContextMenuItemContainer>
      </ContextMenuItem>
      <ContextMenuItem
        disable={!!this.props.project.asanaInfo}
        height={20}
        onClick={(e) => this.onDelete(e, closeMenu)}>
        <DeleteContextMenuItemContainer>
          <IconMenuItemContainer>
            <Icon icon='Trash' fill={styles.colors.pinkColor} size={14} />
          </IconMenuItemContainer>
          {translations.general.delete}
        </DeleteContextMenuItemContainer>
      </ContextMenuItem>
    </React.Fragment>
  )

  renderActiveContextMenuItems = (closeMenu) => {
    const { disableOnMoveUp, disableOnMoveDown, project } = this.props
    return (
      <ActiveItemMenu
        onEdit={this.onEdit}
        onArchive={this.onArchive}
        onDelete={this.onDelete}
        onMoveUp={this.onMoveUp}
        onMoveDown={this.onMoveDown}
        callback={closeMenu}
        disableOnArchive={!!project.asanaInfo}
        onHoverArchiveText={!!project.asanaInfo && translations.navbar.projects.asanaOnlyActionWarning}
        onHoverDeleteText={!!project.asanaInfo && translations.navbar.projects.asanaOnlyActionWarning}
        disableOnDelete={!!project.asanaInfo}
        disableOnMoveUp={disableOnMoveUp}
        disableOnMoveDown={disableOnMoveDown}
        onHoverArchive={this.renderTest}
      />
    )
  }

  render() {
    const { project, isSelected, isArchived, onToggleMenu } = this.props

    const isAsanaProject = !!project.asanaInfo

    return (
      <ContextMenuContainer onToggleMenu={onToggleMenu}>
        {(ref, onContextMenu, closeMenu, isContextMenuActive) => (
          <ProjectNavbarItemContainer
            isSelected={isSelected}
            extraPaddingBottom={isAsanaProject ? asanaWorkspaceTextSize + asanaTopExtraPadding : 0}>
            {!isArchived && <DragIcon icon={'DragHandle'} className={dragHandleIconClass} size={13} />}
            <IndentedLeftIconContainer>
              <ProjectBadge color={project.color} />
            </IndentedLeftIconContainer>
            <ProjectAndTagLabelContainer>
              <LabelName title={project.name}>{project.name}</LabelName>
              {isAsanaProject && <AsanaWorkspaceName>{project.asanaInfo.workspaceName}</AsanaWorkspaceName>}
            </ProjectAndTagLabelContainer>
            {isAsanaProject && <AsanaIcon className={'asana-icon'} src={getSrc('/images/asana.png')}></AsanaIcon>}
            <RightIcon className={rightIconClass} icon='More' size={15} onClick={onContextMenu} />
            <ContextMenu isActive={isContextMenuActive} setRef={ref}>
              {isArchived
                ? this.renderArchivedContextMenuItems(closeMenu)
                : this.renderActiveContextMenuItems(closeMenu)}
            </ContextMenu>
          </ProjectNavbarItemContainer>
        )}
      </ContextMenuContainer>
    )
  }
}

const asanaWorkspaceTextSize = 8
const asanaTopExtraPadding = 2

const AsanaIcon = styled.img`
  width: 13px;
  height: 13px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  margin-left: auto;
`

const AsanaWorkspaceName = styled.div`
  position: absolute;
  text-transform: uppercase;
  font-weight: 500;
  color: ${styles.colors.darkGrey};
  font-size: ${asanaWorkspaceTextSize}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 135px;
  top: calc(100% + ${asanaTopExtraPadding}px);
`

ProjectNavbarItem.propTypes = {
  project: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  isArchived: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onArchive: PropTypes.func,
  onUnarchive: PropTypes.func,
  disableOnMoveUp: PropTypes.bool,
  disableOnMoveDown: PropTypes.bool,
}

const DragIcon = styled(Icon)`
  &&& {
    position: absolute;
    left: 4px;
    cursor: grab;
  }
`

const leftContainerSize = 20
const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${leftContainerSize}px;
  height: ${leftContainerSize}px;
  margin-right: 16px;
`

const ProjectBadge = styled.div`
  background-color: ${(props) => (props.color ? props.color : 'inherit')};
  height: 8px;
  width: 8px;
  border-radius: 3px;
`

const ContextMenuItemContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${styles.colors.textMediumDarkColor};
`

const DeleteContextMenuItemContainer = styled(ContextMenuItemContainer)`
  color: ${styles.colors.pinkColor};
`

const IconMenuItemContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`

export class AddNavbarItem extends Component {
  render() {
    const { onClick } = this.props

    return (
      <AddNavbarItemContainer onClick={onClick} sticky={true}>
        <IndentedLeftIconContainer>
          <Icon icon='SurroundedSquarePlus' size={16} fill={'false'} />
        </IndentedLeftIconContainer>
        <AddNewContainer>{translations.general.addNew}</AddNewContainer>
      </AddNavbarItemContainer>
    )
  }
}

AddNavbarItem.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const AddNavbarItemContainer = styled(NavbarItemContainer)`
  ${() => leftIndentedStyle}
`
const AddNewContainer = styled.span`
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 400;
`

const IndentedLeftIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin-right: 18px;
  width: 16px;
  height: 16px;
`

export class SelectNavbarItem extends Component {
  render() {
    const { iconName, label, labelFontWeight = 500, darkIcon, isSelected, hasDot, badge } = this.props

    const commonProps = {
      size: defaultIconSize,
      icon: iconName,
      dot: hasDot,
      dotBorderColor: isSelected ? '#ffffff' : styles.colors.navbarBackgroundColor,
    }
    return (
      <SelectNavbarItemContainer isSelected={isSelected}>
        <LeftContainer>
          {darkIcon ? (
            <DarkIcon className='dark-icon' fill='white' {...commonProps} />
          ) : (
            <RegularIcon className='icon' {...commonProps} />
          )}
        </LeftContainer>
        <LabelContainer labelFontWeight={labelFontWeight}>{label}</LabelContainer>
        {badge}
      </SelectNavbarItemContainer>
    )
  }
}

SelectNavbarItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  darkIcon: PropTypes.bool,
}

const SelectNavbarItemContainer = styled(NavbarItemContainer)`
  border-radius: 12px;
  &:hover {
    ${() => activeStyle}
  }
  ${(props) => props.isSelected && activeStyle}
  padding-bottom:${(props) => 4 + props.extraPaddingBottom ?? 0}px;
  padding-top: 4px;
`

const ProjectNavbarItemContainer = styled(SelectNavbarItemContainer)`
  ${() => hoverIconsStyle}
  ${() => leftIndentedStyle}
`

const dragHandleIconClass = 'drag-handle-icon'
const rightIconClass = 'right-icon'
const hoverIconsStyle = css`
  position: relative;
  .drag-handle-icon,
  .right-icon {
    display: none;
  }
  &:hover {
    .drag-handle-icon,
    .right-icon {
      display: block;
    }
    .asana-icon {
      display: none;
    }
  }
`
const leftIndentedStyle = css`
  margin-left: 2px;
`

const activeStyle = css`
  background-color: ${variables.bodyBackgroundColor};
  .dark-icon {
    background-color: ${styles.colors.primaryColor};
  }
  .icon {
    svg,
    path {
      fill: ${styles.colors.primaryColor};
    }
  }
`

const iconStyle = css`
  transition: background-color 0.2s ease;
`

const DarkIcon = styled(Icon)`
  ${iconStyle}
  padding: 5px;
  border-radius: 8px;
  background-color: ${(props) => (props.isSelected ? styles.colors.primaryColor : styles.colors.darkGrey)};
`

const RegularIcon = styled(Icon)`
  ${iconStyle}
`

const LabelContainer = styled.div`
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: ${({ labelFontWeight }) => (labelFontWeight ? labelFontWeight : 500)};
  flex-shrink: 10;
  margin-right: 2px;
`

const ProjectAndTagLabelContainer = styled(LabelContainer)`
  display: flex;
  flex-flow: column;
  font-weight: 400;
  min-width: 0;
  position: relative;
`

const LabelName = styled.span`
  display: block;
`
