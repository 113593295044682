import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import qs from 'qs'

import { handleAPIError } from 'store/app/actions'
import { getSrc } from 'utils/image'
import { handleOAuth, INTEGRATION_APP } from 'logic/integrationOauth'
import { ImageSyncContainer } from 'features/integrations/components'

import { translations } from 'gipsy-misc'
import { asana as asanaApi } from 'gipsy-api'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      handleAPIError,
    },
    dispatch
  ),
})

const source = 'integration-oauth'

class OauthCallback extends Component {
  componentDidMount = async () => {
    const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (searchParams.error || !searchParams.code) {
      return this.handleError(searchParams.error || 'search params error')
    }
    try {
      const { pageFrom, integrationApp, responseData } = await handleOAuth(this.props.location, window.opener)
      window.opener && window.opener.postMessage({ source, responseData, success: true })
      if (integrationApp === INTEGRATION_APP.SLACK) {
        if (window.opener) {
          const hasSameOrigin = window.opener.location.origin === window.location.origin
          if (hasSameOrigin && window.opener.location.pathname === '/slack/landing-page') {
            return window.close()
          }
        }
        this.props.history.push({
          pathname: '/slack/landing-page',
          state: { teamName: responseData.teamName, showInstalled: true },
        })
      } else if (integrationApp === INTEGRATION_APP.ASANA) {
        switch (pageFrom) {
          default:
            let userData
            if (!!responseData) {
              userData = await asanaApi.getUserData(responseData.userId)
            }
            this.props.history.push({
              pathname: '/asana/landing-page',
              state: { userData: userData },
            })
            break
        }
      }
    } catch (err) {
      this.handleError(err)
    }
  }

  handleError = (err) => {
    if (window.opener) {
      window.opener.postMessage({ source, error: 'oauth error' })
      window.close()
    } else {
      this.props.actions.handleAPIError(err)
    }
  }
  render = () => {
    return <ImageSyncContainer src={getSrc('/images/loading_kosmo.gif')} text={translations.integrations.syncingData} />
  }
}
export default withRouter(
  connect(null, mapDispatchToProps, null, {
    pure: true,
  })(OauthCallback)
)
