import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import noop from 'lodash/noop'

import { ActionsSection } from './ActionsSection'
import { useShortCuts } from './useShortCuts'
import {
  LeftPart,
  RightPart,
  CheckboxContainer,
  StyledAnimatedTitle,
  ItemInfo,
  ItemTimeInfo,
  ItemTitle,
  Content,
} from './commonUIComponents'

import { Checkbox } from 'gipsy-ui'
import { styles, utils } from 'gipsy-misc'

const completeAnimationDuration = 0.3

export function EditCalendarTaskPopupContent(props) {
  const [isJustCompleted, setIsJustCompleted] = useState(false)
  const { onClickComplete, onClickStart, onClickEdit, onClickDelete, item } = props

  const _onClickComplete = useCallback(
    ({ id: itemId = item.id }) => {
      if (!isJustCompleted) {
        setIsJustCompleted(true)
        setTimeout(() => onClickComplete?.(itemId), completeAnimationDuration * 1000)
      }
    },
    [onClickComplete, item, isJustCompleted]
  )

  useShortCuts({
    onClickComplete: _onClickComplete,
    onClickDelete,
    onClickStart,
    onClickEdit,
  })

  const focusSession = useSelector((state) => state.session.focusSession)
  const shouldShowStartButton = !(focusSession?.id || isJustCompleted)
  return (
    <Content>
      <LeftPart>
        <CheckboxContainer>
          <Checkbox
            size={16}
            disabled={isJustCompleted}
            checkmarkTop={1.2}
            checkmarkLeft={4.5}
            checkmarkWidth={3}
            checkmarkHeight={6}
            checkmarkBorderWidth={1.5}
            borderWidth={1.5}
            checkedColor={styles.colors.greenColor}
            checkMarkColor={'white'}
            hoverCheckMarkColor={styles.colors.greyBorderColor}
            hoverBackgroundColor={'white'}
            hoverBorderColor={styles.colors.darkGrey}
            borderColor={styles.colors.darkGrey}
            id={item?.id}
            paramName='completed'
            checked={!!item?.completed || isJustCompleted}
            onChange={!item?.completed ? _onClickComplete : noop}
          />
        </CheckboxContainer>
      </LeftPart>
      <RightPart>
        <ItemInfo>
          {isJustCompleted ? (
            <StyledAnimatedTitle
              title={item?.title}
              completionDuration={completeAnimationDuration}
              lineThrough={'false'}
              justCompleted>
              {item?.title}
            </StyledAnimatedTitle>
          ) : (
            <ItemTitle title={item?.title}>{item?.title}</ItemTitle>
          )}

          <ItemTimeInfo>
            {item?.pin?.time ? (
              <>
                <span>{moment(item?.pin?.time).format('ddd, MMM DD')}</span>
                <span>{utils.datetime.beautifyTime(item?.pin?.time)}</span>
                <span>{utils.datetime.beautifyDuration(item?.estimatedTime, false, true)}</span>
              </>
            ) : (
              <span>{moment(item?.when?.date).format('ddd, MMM DD')}</span>
            )}
          </ItemTimeInfo>
        </ItemInfo>
        <ActionsSection
          shouldShowStartButton={shouldShowStartButton}
          isEditDisabled={isJustCompleted}
          isDeleteDisabled={isJustCompleted}
          onClickStart={onClickStart}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      </RightPart>
    </Content>
  )
}
