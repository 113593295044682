import React from 'react'
import styled, { css } from 'styled-components'

import { models, styles, translations } from 'gipsy-misc'

export default function DayPicker({ daysSelected, firstDayOfWeek, onChange }) {
  const toggleDay = (day) => {
    const updatedSelection = { ...daysSelected }
    updatedSelection[day] = !updatedSelection[day]
    onChange(updatedSelection)
  }

  return (
    <Days>
      {firstDayOfWeek !== 1 && (
        <DayButton
          active={!!daysSelected[models.date.SUN]}
          day={models.date.SUN}
          label={translations.days.shortLabel.sunday}
          onClick={toggleDay}
        />
      )}
      <DayButton
        active={!!daysSelected[models.date.MON]}
        day={models.date.MON}
        label={translations.days.shortLabel.monday}
        onClick={toggleDay}
      />
      <DayButton
        active={!!daysSelected[models.date.TUE]}
        day={models.date.TUE}
        label={translations.days.shortLabel.tuesday}
        onClick={toggleDay}
      />
      <DayButton
        active={!!daysSelected[models.date.WED]}
        day={models.date.WED}
        label={translations.days.shortLabel.wednesday}
        onClick={toggleDay}
      />
      <DayButton
        active={!!daysSelected[models.date.THU]}
        day={models.date.THU}
        label={translations.days.shortLabel.thursday}
        onClick={toggleDay}
      />
      <DayButton
        active={!!daysSelected[models.date.FRI]}
        day={models.date.FRI}
        label={translations.days.shortLabel.friday}
        onClick={toggleDay}
      />
      <DayButton
        active={!!daysSelected[models.date.SAT]}
        day={models.date.SAT}
        label={translations.days.shortLabel.saturday}
        onClick={toggleDay}
      />
      {firstDayOfWeek === 1 && (
        <DayButton
          active={!!daysSelected[models.date.SUN]}
          day={models.date.SUN}
          label={translations.days.shortLabel.sunday}
          onClick={toggleDay}
        />
      )}
    </Days>
  )
}

function DayButton({ active, day, label, onClick }) {
  const handleClick = () => onClick(day)

  return (
    <Day active={active} onClick={handleClick}>
      {label}
    </Day>
  )
}

export const Days = styled.div`
  display: flex;
`

Days.displayName = 'Days'

export const Day = styled.button`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${styles.colors.lightVioletBorderColor};
  border-radius: 8px;
  color: ${styles.colors.lightVioletBorderColor};
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  margin-right: 8px;
  outline: none;
  padding: 8px 13px 8px;
  text-transform: capitalize;

  &:hover {
    border-color: ${styles.colors.primaryColor};
    color: ${styles.colors.primaryColor};
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${styles.colors.primaryColor};
      border-color: ${styles.colors.primaryColor};
      color: white;

      &:hover {
        color: white;
      }
    `}
`

Day.displayName = 'Day'
