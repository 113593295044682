import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import throttle from 'lodash/throttle'

import { focussession as focussessionApi, mixpanel as mixpanelApi, user as userApi } from 'gipsy-api'
import { mixpanel, models, styles } from 'gipsy-misc'
import { FocusControls } from 'gipsy-ui'

import RealTime from 'features/realTime'
import { handleAPIError } from 'store/app/actions'
import { openIntegrationOAuthWindow, getSlackAuthUrlAndStoreState } from 'logic/integrationOauth'
import { fetchIntegrationsData } from 'store/integrations/actions'
import { closeTabs, setMuteModeOn, setUser } from 'store/session/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { showSnackbar } from 'store/snackbar/actions'
import { checkIfExtensionIsInstalled, isChromeBrowser } from 'utils/chrome'
import { EXTENSION_LINK } from 'utils/chromeext'

import { ExtensionInstallPrompt, SlackIntegrationPrompt } from './Prompts'

const shortcutsGroupLabel = 'FocusControls'

function FocusControlsContainer({ session }) {
  const dispatch = useDispatch()
  const integrationsData = useSelector((state) => state.integrations)

  const [showExtPrompt, setShowExtPrompt] = useState(false)
  const [showSlackPrompt, setShowSlackPrompt] = useState(false)
  const [slackPromptChecked, setSlackPromptChecked] = useState(false)

  useEffect(() => {
    if (!integrationsData) {
      dispatch(fetchIntegrationsData())
    }
  }, [dispatch, integrationsData])

  const handleCloseTabs = useCallback(async () => {
    const hasExtension = await checkIfExtensionIsInstalled(session.user)

    if (hasExtension) {
      dispatch(closeTabs())
      mixpanelApi.track({ event: mixpanel.closedTabsEvent })
    } else {
      setShowExtPrompt(true)
    }
  }, [dispatch, session.user])

  const throttledHandleCloseTabs = throttle(handleCloseTabs, 500)

  const handleMuteMode = useCallback(
    async ({ value }) => {
      if (value && !session?.user?.settingsPreferences?.app?.hideSlackPopup && !integrationsData?.slackIntegrations) {
        setShowSlackPrompt(true)
      }

      if (value) {
        try {
          dispatch(setMuteModeOn(true))
          await focussessionApi.toggleBlockDistractions(true)
          RealTime.publishMessage('', [models.realtime.topics.focusSession])
        } catch (err) {
          dispatch(setMuteModeOn(false))
          handleAPIError(err)
        }
      } else {
        try {
          dispatch(setMuteModeOn(false))
          await focussessionApi.toggleBlockDistractions(false)
          RealTime.publishMessage('', [models.realtime.topics.focusSession])
        } catch (err) {
          dispatch(setMuteModeOn(true))
          handleAPIError(err)
        }
      }
    },
    [dispatch, integrationsData?.slackIntegrations, session?.user?.settingsPreferences?.app?.hideSlackPopup]
  )

  const throttledHandleMuteMode = throttle(handleMuteMode, 1500)

  const dismissExtensionPrompt = useCallback(() => {
    setShowExtPrompt(false)
  }, [])

  const dismissSlackPrompt = useCallback(() => {
    setShowSlackPrompt(false)
  }, [])

  const handleExtensionInstallClicked = useCallback(() => {
    window.open(EXTENSION_LINK, '_blank')
    setShowExtPrompt(false)
  }, [])

  const handleSlackInstallClicked = useCallback(async () => {
    const authURL = await getSlackAuthUrlAndStoreState()

    openIntegrationOAuthWindow(authURL, {
      onSuccess: () => dispatch(fetchIntegrationsData()),
      onError: (err) => {
        dispatch(
          showSnackbar(
            {
              message: 'Unexpected Error',
              status: 'error',
              showLogo: false,
              showClose: false,
            },
            5000
          )
        )
      },
    })
  }, [dispatch])

  const handleSlackDontAskAgainClicked = useCallback(
    async ({ value }) => {
      const user = session.user
      const newAppPreferences = { ...user.settingsPreferences.app }
      newAppPreferences.hideSlackPopup = !!value
      const newUser = { ...user }
      newUser.settingsPreferences.app = newAppPreferences
      dispatch(setUser(newUser))
      setSlackPromptChecked(!!value)

      await userApi.patch(newAppPreferences, {
        appPreferences: true,
      })
      RealTime.publishMessage('', [models.realtime.topics.user])
    },
    [dispatch, session.user]
  )

  useEffect(() => {
    if (showExtPrompt) {
      dispatch(pushShortcutsGroup([], shortcutsGroupLabel))
    } else {
      dispatch(popShortcutsGroup(shortcutsGroupLabel))
    }

    return () => {
      dispatch(popShortcutsGroup(shortcutsGroupLabel))
    }
  }, [dispatch, showExtPrompt])

  return (
    <>
      <FocusControls
        muteModeOn={session.muteModeOn}
        hideCloseTabsBUtton={!isChromeBrowser}
        onClickCloseTabs={throttledHandleCloseTabs}
        onClickMuteMode={throttledHandleMuteMode}></FocusControls>

      {showExtPrompt && (
        <ExtensionInstallPrompt onAccept={handleExtensionInstallClicked} onDismiss={dismissExtensionPrompt} />
      )}
      {showSlackPrompt && (
        <SlackIntegrationPrompt
          checked={slackPromptChecked}
          onAccept={handleSlackInstallClicked}
          onCheck={handleSlackDontAskAgainClicked}
          onDismiss={dismissSlackPrompt}
        />
      )}
    </>
  )
}

const Container = styled.div`
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
  border-radius: 10px;
  color: ${styles.colors.textMediumDarkColor};
  display: flex;
  margin: 8px;
  min-height: 40px;
  padding: 4px 6px 4px 14px;
`

Container.displayName = 'Container'

const Title = styled.span`
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-right: 16px;
`

Title.displayName = 'Title'

export default FocusControlsContainer
