import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import parser from 'react-html-parser'

import { mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations, utils } from 'gipsy-misc'
import { Calendar } from 'gipsy-ui'

import variables from 'assets/styles/variables'
import CalendarNav, { NavigationContainer } from 'features/calendar/components/CalendarPanel/components/CalendarNav'
import { fetchNextEvents, fetchPreviousEvents, updateCalendarDate, updateScrollToTime } from 'store/calendar/actions'
import { getEventsArray } from 'store/calendar/selectors'
import { computePopupTopAndLeftPosition } from 'features/calendar/utils'
import { getRecurrencyDetails } from 'features/sprintComposer/components/Recurrency/utils'
import { changeSlotDuration, changeSlotStartTime } from 'logic/calendar'
import AddSprintPopup from 'pages/onboarding/components/addSprintPopup'
import RecurrencePopup from 'pages/onboarding/components/recurrencePopup'
import { getSrc } from 'utils/image'

const { calendarLateralPadding } = variables

export default function ShowCalendarStep({ onStepDone, stepNumber, user }) {
  const dispatch = useDispatch()
  const calendarDate = useSelector((state) => state.calendar.settings.calendarDate)
  const events = useSelector((state) => getEventsArray(state))
  const scrollToTime = useSelector((state) => state.calendar.settings.scrollToTime)

  const [addItemPopupPositionProps, setAddItemPopupPositionProps] = useState({ left: null, top: 0 })
  const [hoverIndicatorStyles, setHoverIndicatorStyles] = useState(null)
  const [isAddItemPopupShown, setIsAddItemPopupShown] = useState(false)
  const [isContainerHovered, setIsContainerHovered] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isRecurrencePopupShown, setIsRecurrencePopupShown] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)

  const addItemPopupRef = useRef(null)
  const stepRef = useRef(null)
  const trackingData = useRef({
    hasTrackedSelectedSlot: false,
    hasTrackedCreateBlock: false,
  })

  const onClickLeftArrow = useCallback(() => {
    let newDate = moment(calendarDate)
    newDate.subtract(1, 'week')
    newDate = newDate.toDate()
    dispatch(fetchPreviousEvents({ date: newDate, isWeekView: true }))
    dispatch(updateCalendarDate(newDate, true))
  }, [calendarDate, dispatch])

  const onClickRightArrow = useCallback(() => {
    let newDate = moment(calendarDate)
    newDate.add(1, 'week')
    newDate = newDate.toDate()
    dispatch(fetchNextEvents({ date: newDate, isWeekView: true }))
    dispatch(updateCalendarDate(newDate, true))
  }, [calendarDate, dispatch])

  const onClickToday = useCallback(() => {
    const newDate = new Date()
    dispatch(updateCalendarDate(newDate, true))
  }, [dispatch])

  useLayoutEffect(() => {
    const startOfDay = moment().startOf('day')
    let targetScroll = moment().subtract(6, 'hours')
    targetScroll = targetScroll > startOfDay ? targetScroll : startOfDay
    dispatch(updateScrollToTime(targetScroll.toDate()))
  }, [dispatch])

  const getSelectedSlotBounds = useCallback(() => {
    const calendarNode = stepRef.current

    if (calendarNode) {
      const currentHighlighted = calendarNode.querySelector('.fc-event.highlighted')
      const currentPlaceholder = calendarNode.querySelector('.fc-event.placeholder')

      if (currentPlaceholder) {
        return currentPlaceholder.getBoundingClientRect()
      } else if (currentHighlighted) {
        return currentHighlighted.getBoundingClientRect()
      }
    }
  }, [])

  const onSelectSlot = useCallback(
    ({ bounds, start, end, allDay }) => {
      setIsRecurrencePopupShown(null)

      if (allDay) {
        return
      }

      setSelectedSlot({
        start,
        end,
        item: { title: '' },
      })

      const calendarRef = stepRef.current
      bounds = bounds ?? getSelectedSlotBounds()

      if (bounds && calendarRef) {
        const { left, shouldFlipTail, top } = computePopupTopAndLeftPosition(
          calendarRef.getBoundingClientRect(),
          bounds,
          variables.addCalendarTaskPopupHeight,
          variables.addCalendarTaskPopupWidth,
          false
        )

        setAddItemPopupPositionProps((prev) => ({
          left: !isNaN(left) ? left : prev.left,
          shouldFlipTail,
          top: !isNaN(top) ? top : prev.top,
        }))

        setIsAddItemPopupShown(true)

        if (!trackingData.current.hasTrackedSelectedSlot) {
          mixpanelApi.track({ event: mixpanel.onboardingCalendarSlotSelectedEvent })
          trackingData.current.hasTrackedSelectedSlot = true
        }
      }
    },
    [getSelectedSlotBounds]
  )

  const moveSelectedSlot = (event) => {
    const { start, end, extendedProps } = event
    const { item } = extendedProps

    if (item.isPlaceholder) {
      onSelectSlot({ start, end })
    }
  }

  const resizeSelectedSlot = (event) => {
    const { start, end, extendedProps } = event
    const { item } = extendedProps

    if (item.isPlaceholder) {
      setSelectedSlot((selectedSlot) => ({
        start,
        end,
        item: selectedSlot.item,
      }))
    }
  }

  const hideAddItemPopup = (e) => {
    if (isRecurrencePopupShown) return

    if (e.target.classList.contains('fc-event-resizer') || e.target.getAttribute('data-is-placeholder') === 'true') {
      return
    }

    setSelectedSlot(null)
    setIsAddItemPopupShown(false)
  }

  const computePlaceholderSlot = (selectedSlot) => {
    return {
      ...selectedSlot,
      color: styles.colors.orangeColor,
      item: { ...selectedSlot.item, isPlaceholder: true },
      isHighlighted: false,
      isLocalItem: true,
      isPlaceholder: true,
      isSprint: false,
    }
  }

  const onChangeStartTime = useCallback((newStartTime) => {
    setSelectedSlot((selectedSlot) => changeSlotStartTime(selectedSlot, newStartTime))
  }, [])

  const onChangeDuration = useCallback((newDurationObj) => {
    setSelectedSlot((selectedSlot) => changeSlotDuration(selectedSlot, newDurationObj))
  }, [])

  const showRecurrenceOptions = () => {
    setIsRecurrencePopupShown(new Date()) // needs to be different in order to be able to reopen popup if user closes it by mistake

    if (!trackingData.current.hasTrackedCreateBlock) {
      const start = moment(selectedSlot.start)
      const end = moment(selectedSlot.end)
      const duration = moment.duration(end.diff(start)).asMinutes()
      const when = start.startOf('day').diff(moment().startOf('day'), 'days')
      mixpanelApi.track({ event: mixpanel.onboardingCalendarCreateBlockEvent }, { duration, when })
      trackingData.current.hasTrackedCreateBlock = true
    }
  }

  const handleContinue = ({ days, typeSelected }) => {
    setIsRecurrencePopupShown(false)

    const recurrencyData = {
      customTypeSettings: { days },
      recurrencyType: typeSelected,
      sprintStartTime: moment(selectedSlot.start),
    }

    const recurrencyDetails = getRecurrencyDetails(recurrencyData)
    const timeDetails = utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(selectedSlot)
    const sprint = models.sprint({
      ...timeDetails,
      recurrencyInformation: { recurrencyDetails },
      title: translations.onboarding.calendarStep.sprintName,
    })

    mixpanelApi.track({ event: mixpanel.onboardingCalendarRecurrenceSelectedEvent })
    onStepDone(stepNumber, { recurrencyData, sprint })
  }

  useEffect(() => {
    if (selectedSlot) {
      setIsMouseDown(false)
      return
    }

    const handleMouseDown = () => {
      setIsMouseDown(true)
      setHoverIndicatorStyles(null)
    }

    const handleMouseUp = () => {
      setIsMouseDown(false)
    }

    window.addEventListener('mousedown', handleMouseDown)
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mousedown', handleMouseDown)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [selectedSlot])

  useEffect(() => {
    const handleHoverIndicator = (e) => {
      if (!isContainerHovered || isMouseDown) {
        setHoverIndicatorStyles(null)
        return
      }

      const hoveredElements = document.elementsFromPoint(e.pageX, e.pageY)
      let colEl, rowEl

      for (let el of hoveredElements) {
        if (el === addItemPopupRef.current || el.classList.contains('fc-timegrid-event-harness')) {
          setHoverIndicatorStyles(null)
          return
        }

        if (el.classList.contains('fc-timegrid-slot')) {
          rowEl = el
        } else if (el.classList.contains('fc-timegrid-col')) {
          colEl = el
        }

        if (colEl && rowEl) {
          break
        }
      }

      if (!colEl && !rowEl) {
        return
      }

      const containerBounds = stepRef.current.getBoundingClientRect()
      const colBounds = colEl.getBoundingClientRect()
      const rowBounds = rowEl.getBoundingClientRect()

      setHoverIndicatorStyles({
        height: `${rowEl.clientHeight}px`,
        left: `${colBounds.left - containerBounds.left}px`,
        top: `${rowBounds.top - containerBounds.top}px`,
        width: `${colEl.clientWidth}px`,
      })
    }

    window.addEventListener('mousemove', handleHoverIndicator)

    return () => {
      window.removeEventListener('mousemove', handleHoverIndicator)
    }
  }, [isContainerHovered, isMouseDown])

  const handleHoverEnter = () => {
    setIsContainerHovered(true)
  }

  const handleHoverExit = () => {
    setIsContainerHovered(false)
  }

  return (
    <>
      <Title>{parser(translations.onboarding.calendarStep.title)}</Title>
      <Container onMouseEnter={handleHoverEnter} onMouseLeave={handleHoverExit} ref={stepRef}>
        {hoverIndicatorStyles && <HoverIndicator style={hoverIndicatorStyles} />}
        {isAddItemPopupShown && (
          <AddSprintPopup
            left={addItemPopupPositionProps.left}
            onChangeDuration={onChangeDuration}
            onChangeStartTime={onChangeStartTime}
            onClickOutside={hideAddItemPopup}
            onClose={hideAddItemPopup}
            onCreateSprint={showRecurrenceOptions}
            ref={addItemPopupRef}
            selectedSlot={selectedSlot}
            showTooltip={false}
            top={addItemPopupPositionProps.top}
          />
        )}
        <RecurrencePopup
          firstDayOfWeek={user?.settingsPreferences?.calendar?.firstDay}
          onContinue={handleContinue}
          show={isRecurrencePopupShown}
          startTime={selectedSlot?.start}
        />
        <CalendarNav
          calendarDate={calendarDate}
          calendarTitle={moment(
            utils.date.getStartOfWeek(calendarDate, user?.settingsPreferences?.calendar?.firstDay)
          ).format('MMMM YYYY')}
          onClickLeftArrow={onClickLeftArrow}
          onClickRightArrow={onClickRightArrow}
          onClickToday={onClickToday}
        />
        <StyledCalendar
          calendarItems={selectedSlot ? events.concat([computePlaceholderSlot(selectedSlot)]) : events}
          date={calendarDate}
          disablePlanMyDay
          firstDay={user?.settingsPreferences?.calendar?.firstDay}
          hasDndNavigation={false}
          isCalendarSectionExpanded={true}
          onEventDrop={moveSelectedSlot}
          onEventResize={resizeSelectedSlot}
          onSelectSlot={onSelectSlot}
          scrollToTime={scrollToTime}
          viewMode={Calendar.viewModes.WEEK}
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  background: white;
  border-radius: 8px;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  max-height: 1200px;
  min-height: 440px;
  padding: ${`12px ${calendarLateralPadding}px 8px ${calendarLateralPadding}px`};
  position: relative;
  width: 74%;

  ${NavigationContainer} {
    opacity: 1;
  }
`

Container.displayName = 'Container'

const Title = styled.p`
  color: ${styles.colors.textDarkColor};
  font-size: 22px;
  font-weight: 500;
  margin: 35px 0;
  text-align: center;
`

Title.displayName = 'Title'

const HoverIndicator = styled.div`
  background-color: ${styles.colors.orangeColor}80;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
`

HoverIndicator.displayName = 'HoverIndicator'

const StyledCalendar = styled(Calendar)`
  background: ${styles.colors.veryLightGrey};
  border-radius: 8px;
  overflow: hidden;
  width: 100%;

  td.fc-daygrid-day.fc-day-past,
  td.fc-timegrid-col.fc-day-past {
    background-image: url(${getSrc('images/past-date-mask.svg')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  td.fc-daygrid-day.fc-day-past {
    .gipsy-more-link {
      background-color: transparent;
    }
  }
`

StyledCalendar.displayName = 'StyledCalendar'
