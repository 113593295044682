import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import EditingLine from './EditingLine'
import { buildCreatingItem, convertItemPropToArray } from './utils'
import { translations, utils } from 'gipsy-misc'

const componentName = 'EditingLine'
function EditingLineContainer(props) {
  const {
    onCreate,
    onSave,
    onCancel,
    onCancelEdit,
    session,
    onDelete,
    item,
    innerRef,
    hideSpentTimeInput,
    registerShortcuts,
    unregisterShortcuts,
    isCreating,
    ignoreOutsideClicks,
    onTogglePin,
    clearHighLightedEvent,
    activeTags,
    allProjects,
    onChange,
    onUpdateFocusSession,
    onDeleteFocusSession,
    renderCreateBottomButtons,
    renderEditBottomButtons,
    history,
    getCurrentPathName,
    readOnlyDate,
    inChromeExtension,
    hideBlockToCalendarOption,
    marginBottom,
    marginLeft,
    marginRight,
    activeSprints,
    canBlockToCalendar,
    onClickFocusSession,
    hideSprint,
    startSprintCreation,
    onStartFocus,
    onStartFsAndCreate,
    onClick,
    hideCreateButton,
    hideDateInput,
    keepTitleAfterCreation,
    when: whenProp,
    pin: pinProp,
    urlInfo: urlInfoProp,
    project: projectProp,
    title: titleProp,
    estimatedTime: estimatedTimeProp,
  } = props

  const creatingModeProps = useMemo(
    () => ({
      when: whenProp,
      urlInfo: urlInfoProp,
      project: projectProp,
      title: titleProp || '',
      session,
    }),
    [whenProp, urlInfoProp, projectProp, titleProp, session]
  )

  const [editingItem, setEditingItem] = useState(item ?? buildCreatingItem(creatingModeProps))
  const lineRef = useRef(null)

  const sessionId = session.id
  const itemId = editingItem?.id
  const itemFocusSessions = item?.focusSessions

  const [isFocusSessionsPopupShown, setIsFocusSessionsPopupShown] = useState(false)

  const createItem = useCallback(
    ({ eventName } = {}) => {
      const itemData = utils.task.buildTaskToCreate(editingItem, sessionId)
      if (getCurrentPathName) {
        onCreate(itemData, history, {
          componentSource: 'inlineAddTask',
          pageSource: getCurrentPathName(),
          eventName,
        })
      } else {
        onCreate(itemData, null, { eventName })
      }

      setEditingItem((editingItem) => {
        const newEditingItem = {
          ...buildCreatingItem(creatingModeProps),
        }
        if (keepTitleAfterCreation) {
          newEditingItem.title = editingItem.title
        }
        return newEditingItem
      })
    },
    [editingItem, sessionId, onCreate, history, getCurrentPathName, keepTitleAfterCreation, creatingModeProps]
  )

  const startFsAndCreateTask = useCallback(() => {
    const itemData = utils.task.buildTaskToCreate(editingItem, sessionId)
    return onStartFsAndCreate(itemData)
  }, [onStartFsAndCreate, editingItem, sessionId])

  const saveItem = useCallback(async () => {
    if (editingItem.tags) {
      editingItem.tagsId = editingItem.tags.map((el) => el.id)
    }

    if (editingItem.projects) {
      editingItem.projectsId = editingItem.projects.map((el) => el.id)
    }

    await onSave(editingItem)
  }, [editingItem, onSave])

  const hideFocusSessionsPopup = useCallback(() => {
    setIsFocusSessionsPopupShown(false)
    clearHighLightedEvent?.()
  }, [clearHighLightedEvent])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        projects: editingItem.projects ?? convertItemPropToArray(projectProp),
      }))
    }
  }, [projectProp, isCreating])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        urlsInfo: editingItem.urlsInfo ?? convertItemPropToArray(urlInfoProp),
      }))
    }
  }, [urlInfoProp, isCreating])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        when: editingItem.when ?? whenProp ?? { date: '' },
      }))
    }
  }, [whenProp, isCreating])

  useEffect(() => {
    if (isCreating) {
      setEditingItem((editingItem) => ({
        ...editingItem,
        title: editingItem.title ?? titleProp,
      }))
    }
  }, [titleProp, isCreating])

  useEffect(() => {
    setEditingItem((editingItem) => ({
      ...editingItem,
      when: pinProp
        ? {
            date: moment(pinProp.time).format('YYYY-MM-DD'),
          }
        : editingItem.when,
      pin: pinProp,
      estimatedTime: estimatedTimeProp,
    }))
  }, [estimatedTimeProp, pinProp])

  useEffect(() => {
    if (registerShortcuts) {
      registerShortcuts(
        [
          {
            key: 'Enter',
            label: isCreating ? translations.general.create : translations.general.save,
            callback: () => (isCreating ? createItem() : saveItem()),
          },
          {
            key: 'Escape',
            label: translations.general.cancel,
            callback: () => (isCreating ? onCancel() : onCancelEdit()),
          },
        ],
        componentName
      )
      return () => unregisterShortcuts?.(componentName)
    }
  }, [registerShortcuts, unregisterShortcuts, isCreating, createItem, saveItem, onCancel, onCancelEdit])

  useEffect(() => {
    function handleClickOutsideWhenEdit(e) {
      if (lineRef?.current?.contains(e.target) || ignoreOutsideClicks) {
        return
      }
      hideFocusSessionsPopup()
      if (isCreating) {
        if (!!editingItem?.title) {
          createItem({ eventName: utils.task.clickOutside })
        } else {
          onCancel()
        }
      } else {
        saveItem()
      }
    }
    document.addEventListener('mousedown', handleClickOutsideWhenEdit)
    return () => document.removeEventListener('mousedown', handleClickOutsideWhenEdit)
  }, [ignoreOutsideClicks, isCreating, saveItem, createItem, hideFocusSessionsPopup, onCancel, editingItem.title])

  const toggleFocusSessionsPopup = () => setIsFocusSessionsPopupShown((value) => !value)

  const _onClick = useCallback(() => onClick?.(itemId), [itemId, onClick])

  const _onChange = useCallback(
    (taskId, data, extraParams = {}) => {
      const updatedItem = utils.task.computeTaskOnChange(editingItem, data, extraParams)
      onChange?.(updatedItem)
      setEditingItem(updatedItem)
    },
    [editingItem, onChange]
  )

  const onFieldChange = useCallback(
    (value, extraParams) => {
      _onChange(itemId, value, extraParams)
    },
    [itemId, _onChange]
  )

  const onTagsChange = onFieldChange
  const onProjectsChange = onFieldChange

  const onTitleChange = (e) => {
    const value = typeof e === 'string' ? e : e.target.value
    onFieldChange({
      paramName: 'title',
      value: value,
    })
  }
  const onUrlsInfoChange = ({ value: urlsInfo }) => onFieldChange({ paramName: 'urlsInfo', value: urlsInfo })
  const onSprintChange = (sprintInfo) => onFieldChange({ paramName: 'sprintInfo', value: sprintInfo })

  const onDateChange = (value, extraParams) =>
    onFieldChange(
      {
        method: 'change',
        ...value,
      },
      extraParams
    )

  const onFocusSessionsChange = useCallback(
    (updatedFocusSessions) => onFieldChange({ paramName: 'focusSessions', value: updatedFocusSessions }),
    [onFieldChange]
  )

  const _onTogglePin = useCallback(() => onTogglePin?.({ item: editingItem, isCreating }), [
    onTogglePin,
    editingItem,
    isCreating,
  ])
  const _onDelete = useCallback(() => onDelete?.(itemId), [itemId, onDelete])

  const _onUpdateFocusSession = useCallback(
    (updatedFocusSession) => {
      onUpdateFocusSession?.(updatedFocusSession)
      // TODO: use immutableJS for this mutation
      const updatedFocusSessions = JSON.parse(JSON.stringify(itemFocusSessions))
      const idx = updatedFocusSessions.findIndex((fs) => fs.id === updatedFocusSession.id)
      if (idx >= 0) {
        updatedFocusSessions[idx] = updatedFocusSession
      }
      onFocusSessionsChange(updatedFocusSessions)
    },
    [onUpdateFocusSession, itemFocusSessions, onFocusSessionsChange]
  )

  const _onDeleteFocusSession = useCallback(
    ({ focusSession: focusSessionToDelete }) => {
      onDeleteFocusSession?.({ focusSession: focusSessionToDelete })
      const updatedFocusSessions = itemFocusSessions.filter((fs) => fs.id !== focusSessionToDelete.id)
      onFocusSessionsChange(updatedFocusSessions)
    },
    [onDeleteFocusSession, itemFocusSessions, onFocusSessionsChange]
  )

  const setLineRef = (ref) => {
    lineRef.current = ref
    if (innerRef) {
      innerRef.current = ref
    }
  }

  return (
    <EditingLine
      ref={setLineRef}
      isCreating={isCreating}
      clearHighLightedEvent={clearHighLightedEvent}
      item={editingItem}
      onTogglePin={_onTogglePin}
      onDelete={_onDelete}
      toggleFocusSessionsPopup={toggleFocusSessionsPopup}
      onTitleChange={onTitleChange}
      renderBottomButtons={isCreating ? renderCreateBottomButtons : renderEditBottomButtons}
      onStartFsAndCreate={startFsAndCreateTask}
      activeTags={activeTags}
      allProjects={allProjects}
      onProjectsChange={onProjectsChange}
      onTagsChange={onTagsChange}
      onUpdateFocusSession={_onUpdateFocusSession}
      onDeleteFocusSession={_onDeleteFocusSession}
      isFocusSessionsPopupShown={isFocusSessionsPopupShown}
      onUrlsInfoChange={onUrlsInfoChange}
      onDateChange={onDateChange}
      readOnlyDate={readOnlyDate}
      inChromeExtension={inChromeExtension}
      hideBlockToCalendarOption={hideBlockToCalendarOption}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      activeSprints={activeSprints}
      canBlockToCalendar={canBlockToCalendar}
      onClickFocusSession={onClickFocusSession}
      onClick={_onClick}
      hideSprint={hideSprint}
      startSprintCreation={startSprintCreation}
      onStartFocus={onStartFocus}
      onSprintChange={onSprintChange}
      onCreate={createItem}
      onSave={saveItem}
      hideCreateButton={hideCreateButton}
      hideDateInput={hideDateInput}
      hideSpentTimeInput={hideSpentTimeInput}
      onCancel={isCreating ? onCancel : onCancelEdit}
      firstDayOfWeek={session?.user?.settingsPreferences?.calendar?.firstDay}
    />
  )
}

EditingLineContainer.propTypes = {
  clearHighLightedEvent: PropTypes.func,
  registerShortcuts: PropTypes.func,
  unregisterShortcuts: PropTypes.func,
}

export default EditingLineContainer
