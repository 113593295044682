export const getListTotalDuration = (list) => {
  let total = 0

  for (let element of list) {
    if (!element || element.free) {
      continue
    }
    total += element.estimatedTime
  }

  return total
}

export const getFilteredTasksOfGroup = (taskGroup, filterBy = {}, toHideTaskIds = {}) => {
  if (!taskGroup) {
    return taskGroup
  }
  let filteredTasks = [...taskGroup]

  if (Object.keys(toHideTaskIds).length > 0) {
    filteredTasks = filteredTasks.filter((task) => !toHideTaskIds[task.id])
  }

  if (filterBy.projects && filterBy.projects.length > 0) {
    filteredTasks = filteredTasks.filter((task) => {
      if (task.projectsID) {
        for (let i = 0; i < task.projectsID.length; i++) {
          if (filterBy.projects.includes(task.projectsID[i])) return true
        }
      }
      return false
    })
  }
  if (filterBy.tags && filterBy.tags.length > 0) {
    filteredTasks = filteredTasks.filter((task) => {
      if (task.tagsId) {
        for (let i = 0; i < task.tagsId.length; i++) {
          if (filterBy.tags.includes(task.tagsId[i])) return true
        }
      }
      return false
    })
  }
  return filteredTasks
}
