import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import { handleAPIError } from 'store/app/actions'
import { set as setSession, remove as removeSession } from 'store/session/actions'
import { openPopup, closePopup } from 'store/popup/actions'
import { handleOAuth } from './utils'

import qs from 'qs'
import get from 'lodash/get'
import { translations } from 'gipsy-misc'
import { calendar } from 'gipsy-api'

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setSession,
      removeSession,
      openPopup,
      closePopup,
      handleAPIError,
    },
    dispatch
  ),
})

class OauthCallback extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectToLogin: false,
      redirectToInviteRequest: false,
      redirectToOnboarding: false,
      redirectToTodo: false,
      emailProvider: '',
      requestInviteEmail: '',
    }
  }

  componentDidMount = async () => {
    const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (searchParams.error) {
      this.exitToLogin()
    }
    if (!searchParams.code) {
      return
    }

    try {
      const { user, focusSession, emailToSync, code, wasCreated, emailProvider } = await handleOAuth(
        this.props.location
      )

      calendar.synchronize(emailToSync)
      await this.props.actions.setSession({ user, focusSession })

      if (wasCreated) {
        this.initFullStoryTracking(user)
        this.setState({
          redirectToOnboarding: true,
          emailProvider: emailProvider,
          emailToSync: emailToSync,
        })
      } else {
        if (code === 'timezoneChanged') {
          this.props.actions.openPopup(
            {
              message: get(translations, `message.success.timezoneChanged`),
              button: translations.general.ok.toUpperCase(),
              buttonClass: 'ok',
              action: 'onClose',
            },
            15 * 1000
          )
        }

        this.setState({
          redirectToTodo: true,
        })
      }
    } catch (err) {
      this.props.actions.handleAPIError(err)
      this.exitToLogin()
    }
  }

  initFullStoryTracking = (user) => {
    window.FS.restart()
    window.FS.identify(user.id, {
      displayName: user.displayName,
      email: user.emails?.[0],
    })
    const trackingStopDatetime = moment().add(10, 'minutes')

    const stopTrackingInterval = () => {
      setTimeout(() => {
        const now = moment()

        if (now.isAfter(trackingStopDatetime)) {
          window.FS.shutdown()
          return
        }

        stopTrackingInterval()
      }, 1000)
    }

    stopTrackingInterval()
  }

  exitToLogin = () => {
    this.props.actions.removeSession()
    this.setState({
      redirectToLogin: true,
    })
  }

  render = () => {
    if (this.state.redirectToLogin && window.location.pathname !== '/login') {
      return <Redirect to='/login' />
    }
    if (this.state.redirectToOnboarding && window.location.pathname !== '/onboarding') {
      return (
        <Redirect
          to={{
            pathname: '/onboarding',
            state: {
              emailToSync: this.state.emailToSync,
              emailProvider: this.state.emailProvider,
            },
          }}
        />
      )
    }
    if (this.state.redirectToTodo && window.location.pathname !== '/') {
      return <Redirect to='/' />
    }
    return <div />
  }
}

export default connect(null, mapDispatchToProps, null, {
  pure: true,
})(OauthCallback)
