import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { LineViewCompressed } from 'gipsy-ui'

import { startFocusSession } from 'store/session/actions'
import EditableLine from 'features/list/components/editableLine'

function AllTasksLine({
  animateComplete,
  canBlockToCalendar,
  onCancel,
  disableEdit,
  disableLinks,
  draggableProps,
  draggableStyle,
  dragHandleProps,
  hideDragIcon,
  hideMoreActions,
  innerLeftPadding,
  innerRef,
  innerRightPadding,
  isCalendarDraggable,
  isCreating,
  isDraggable,
  isDragging,
  isSprintTask,
  item,
  keepJustCompleted,
  lineThrough = true,
  onClickFocusSession,
  onComplete,
  onDelete,
  onDeleteFocusSession,
  onEditStart,
  onRemoveFromSprint,
  onTogglePin,
  onSave,
  onUpdateFocusSession,
  showCalendarEventInfo,
  startSprintCreation,
  startEdition,
}) {
  const dispatch = useDispatch()
  const session = useSelector((state) => state.session)

  const editStart = (startEditMode) => {
    const focusedTaskId = session?.focusSession?.taskId
    const isTaskOnFocusSession = focusedTaskId && focusedTaskId === item.id

    if (isTaskOnFocusSession || (!isCreating && disableEdit)) {
      return
    }

    onEditStart?.({ item })
    startEditMode?.()
  }

  const onStartFocus = ({ urlsToOpen }) => {
    dispatch(
      startFocusSession({
        taskId: item.id,
        startTime: moment(),
        task: item,
        urlsToOpen: urlsToOpen,
      })
    )
  }

  const renderRegularLine = ({ endEditMode, startEditMode }) => {
    return (
      <LineViewCompressed
        animateComplete={animateComplete}
        disableLinks={disableLinks}
        draggableProps={draggableProps}
        draggableStyle={draggableStyle}
        dragHandleProps={dragHandleProps}
        editStart={() => editStart(startEditMode)}
        endEditing={endEditMode}
        hideDragIcon={hideDragIcon}
        hideMoreActions={hideMoreActions}
        innerLeftPadding={innerLeftPadding}
        innerRef={innerRef}
        innerRightPadding={innerRightPadding}
        isCalendarDraggable={isCalendarDraggable}
        isDraggable={isDraggable}
        isDragging={isDragging}
        isSprintTask={isSprintTask}
        item={item}
        keepJustCompleted={keepJustCompleted}
        lineThrough={lineThrough}
        onComplete={onComplete}
        onDelete={onDelete}
        onRemoveFromSprint={onRemoveFromSprint}
        onStartFocus={onStartFocus}
        session={session}
        showCalendarEventInfo={showCalendarEventInfo}
      />
    )
  }

  return (
    <EditableLine
      startEdition={startEdition}
      canBlockToCalendar={canBlockToCalendar}
      item={item}
      marginLeft={innerLeftPadding}
      marginRight={innerRightPadding}
      onCancel={onCancel}
      onClickFocusSession={onClickFocusSession}
      onDelete={onDelete}
      onDeleteFocusSession={onDeleteFocusSession}
      onSave={onSave}
      onTogglePin={onTogglePin}
      onUpdateFocusSession={onUpdateFocusSession}
      renderRegularLine={renderRegularLine}
      startSprintCreation={startSprintCreation}
    />
  )
}

export default AllTasksLine
