import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Route, Redirect, Switch } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import get from 'lodash/get'

import { setHasNavigated, setInitialRoute } from 'store/layout/actions'
import { PageContainer } from 'features/layout/router'
import CalendarPage from 'pages/calendar'
import Preloader from 'pages/preloader'
import TodayPage from 'pages/today'
import ProjectPage from 'pages/project'
import UncategorizedPage from 'pages/uncategorized'
import SettingsPage from 'pages/settings'
import LoginPage from 'pages/login'
import RedirectFromWebsite from 'pages/redirectfromwebsite'
import NotFound from 'pages/notFound'
import OauthCallback from 'pages/oauthCallback'
import OnboardingPage from 'pages/onboarding'
import Integrations from 'pages/integrations'
import RevalidateOAuthCallback from 'pages/revalidateOauthCallback'
import IntegrationOauthCallback from 'pages/integrationOauthCallback'
import SlackLandingPage from 'pages/slackLandingPage'
import AsanaLandingPage from 'pages/asanaLandingPage'
import TimeReports from 'pages/timeReports'
import UnsubscribedPage from 'pages/unsubscribe'
import AllTasksPage from 'pages/allTasks'
import UpdateVersion from 'version'

import variables from 'assets/styles/variables'

import store from 'store'

const PropsRoute = ({ component: Component, restrict, ...rest }) => {
  const isConnected = get(store.getState(), 'session.id')

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (restrict === 'connected' && !isConnected && rest.path !== '/login') {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: routeProps.location },
                search: '',
              }}
            />
          )
        } else if (restrict === 'publicOnly' && isConnected && rest.path !== '/') {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: routeProps.location },
              }}
            />
          )
        }
        return <Component key={rest.path} {...routeProps} />
      }}
    />
  )
}

const mapStateToProps = (state) => ({
  hasNavigated: state.layout.navigationStats.hasNavigated,
  initialRoute: state.layout.navigationStats.initialRoute,
  session: state.session,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setHasNavigated,
      setInitialRoute,
    },
    dispatch
  ),
})

const parentRoutes = {
  '/index.html': '/',
}

class Router extends PureComponent {
  componentDidMount() {
    const { initialRoute, location } = this.props

    if (location.pathname && initialRoute !== location.pathname) {
      this.props.actions.setInitialRoute(location.pathname)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.hasNavigated) return

    const currentPath = this.props.location.pathname
    const prevPath = prevProps.location.pathname

    if (currentPath !== prevPath) {
      this.props.actions.setHasNavigated()
    }
  }

  render() {
    if (!this.props.session.loaded) {
      return <Preloader />
    }

    /* use root path to avoid transition between nested routes (example with /team & /team/archive) */
    let pathname = this.props.location.pathname
    if (parentRoutes[pathname]) {
      pathname = parentRoutes[pathname]
    }

    return (
      <Content className='content' isConnected={this.props.session.id && pathname !== '/onboarding'}>
        <UpdateVersion>
          <TransitionGroup className={'transition-group'}>
            <CSSTransition
              key={pathname}
              timeout={{ enter: 400, exit: 400 }}
              appear={true}
              mountOnEnter={true}
              unmountOnExit={true}
              classNames={'page-transition'}>
              <PageContainer isConnected={this.props.session.id} className={'page-container'}>
                <Switch key={this.props.location} location={this.props.location}>
                  <PropsRoute path='/uncategorized' restrict='connected' component={UncategorizedPage} />
                  <PropsRoute path='/project' restrict='connected' component={ProjectPage} />
                  <PropsRoute exact path='/integrations' restrict='connected' component={Integrations} />
                  <PropsRoute exact path='/settings' restrict='connected' component={SettingsPage} />
                  <PropsRoute exact path='/login' restrict='publicOnly' component={LoginPage} />
                  <PropsRoute exact path='/oauthcallback' component={OauthCallback} />
                  <PropsRoute exact path='/revalidateoauthcallback' component={RevalidateOAuthCallback} />
                  <PropsRoute exact path='/onboarding' restrict='connected' component={OnboardingPage} />
                  <PropsRoute
                    exact
                    path='/integrationoauthcallback'
                    restrict='connected'
                    component={IntegrationOauthCallback}
                  />
                  <PropsRoute exact path='/redirectfromwebsite' component={RedirectFromWebsite} />
                  <PropsRoute exact path='/email-notifications/unsubscribe' component={UnsubscribedPage} />
                  <PropsRoute path='/slack/landing-page' component={SlackLandingPage} />
                  <PropsRoute path='/asana/landing-page' component={AsanaLandingPage} />
                  <PropsRoute exact path='/reports' component={TimeReports} />
                  <PropsRoute path='/tasks/all' component={AllTasksPage} />
                  <PropsRoute path='/tasks' restrict='connected' component={TodayPage} />
                  <PropsRoute path='/' restrict='connected' component={CalendarPage} />
                  <PropsRoute component={NotFound} />
                </Switch>
              </PageContainer>
            </CSSTransition>
          </TransitionGroup>
        </UpdateVersion>
      </Content>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: true,
  })(Router)
)

const Content = styled.div`
  ${({ isConnected }) =>
    isConnected &&
    css`
      position: relative;
      height: 100%;
      margin: 0 auto;
      max-width: ${variables.pageMaxWidth};
      @media only screen and (max-width: 1450px) {
        max-width: ${variables.pageMaxWidthSmallScreen};
      }
    `}
`

Content.displayName = 'RouterContent'
