import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter, useParams } from 'react-router-dom'

import { PageContainer, PageBody, NavigationContainer, MenuIconContainer, NavigationTitle } from 'features/layout/pages'
import { openNavbar } from 'store/mobileNavbar/actions'
import ReportsList from './components/ReportsList'
import { Icon } from 'gipsy-ui'
import { translations } from 'gipsy-misc'

const mapStateToProps = (state) => ({
  session: state.session,
  areProjectsLoaded: state.projects.areProjectsLoaded,
  areTagsLoaded: state.tags.areTagsLoaded,
  projects: state.projects.items,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      openNavbar,
    },
    dispatch
  ),
})

function TimeReports({ session, areProjectsLoaded, areTagsLoaded, actions, projects }) {
  const { projectId } = useParams()
  if (!areProjectsLoaded || !areTagsLoaded) return null
  const currentProject = projects.find((p) => p.id === projectId)

  return (
    <PageContainer>
      <PageBody>
        <NavigationContainer>
          <MenuIconContainer onClick={actions.openNavbar}>
            <Icon icon='Menu' />
          </MenuIconContainer>
          <NavigationTitle>{translations.timeReports.title}</NavigationTitle>
        </NavigationContainer>
        <ReportsList user={session.user} defaultProjectIdToFilter={currentProject?.id} />
      </PageBody>
    </PageContainer>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null)(TimeReports))
