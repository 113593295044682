import { models, translations, utils } from 'gipsy-misc'
import moment from 'moment'
import groupBy from 'lodash/groupBy'

import { isTimeBefore } from 'logic/allTasks'
import { sortListByConditionFn } from 'logic/list'
import { TaskArrayGroups, TaskObjectGroups, TaskSubGroups } from 'logic/today/models'

export const computeWeekAndMonthLabel = (now) => {
  const weekLabel = isDateEndOfWeek(now) ? translations.todayView.nextWeek : translations.todayView.thisWeek

  const begOfMonthSection = getBegOfMonthSection(now)
  const monthLabel =
    begOfMonthSection.month() !== now.month() ? translations.todayView.nextMonth : translations.todayView.thisMonth

  return { weekLabel, monthLabel }
}

export const computeLastDayOfWeekSection = (now) => {
  return getBegOfMonthSection(now).subtract(1, 'day').format('YYYY-MM-DD')
}

export const computeLastDayOfMonthSection = (now) => {
  const begOfMonthSection = getBegOfMonthSection(now)
  return begOfMonthSection.endOf('month').format('YYYY-MM-DD')
}

export const getDestinationIndexAndRank = (currentIndex, group, date, filterBy, isTodaySection, toHideTaskId) => {
  //if dragging to empty list(unfiltered mode)
  if (group.length === 0) return { index: 0, rank: 1 }

  const unfilteredTaskGroups = groupBy(group, 'when.date')
  const filteredTaskList = utils.list.getFilteredTasksOfGroup(group, filterBy, { [toHideTaskId]: true })

  const filteredTasksGroups = groupBy(filteredTaskList, 'when.date')
  const filteredDateList = filteredTasksGroups[date] || []

  //if  dragging to empty(filtered mode), we send it after the last element
  if (filteredTaskList.length === 0 || filteredDateList.length === 0)
    return { index: group.length, rank: (unfilteredTaskGroups[date] || []).length + 1 }

  //if inserted at the end of list (filtered mode), we send it after the previous element (unfiltered mode) index
  if (currentIndex >= filteredDateList.length) {
    const previousFilteredTask = isTodaySection
      ? filteredTaskList[currentIndex - 1]
      : filteredDateList[currentIndex - 1]
    const previousFilteredTaskRank = isTodaySection
      ? previousFilteredTask.todaySectionRank
      : previousFilteredTask.when.rank

    return {
      index: group.findIndex((task) => task.id === previousFilteredTask.id) + 1,
      rank: previousFilteredTaskRank + 1,
    }
  } else {
    const replacedTask = isTodaySection ? filteredTaskList[currentIndex] : filteredDateList[currentIndex]
    const replacedTaskRank = isTodaySection ? replacedTask.todaySectionRank : replacedTask.when.rank
    return { index: group.findIndex((task) => task.id === replacedTask.id), rank: replacedTaskRank }
  }
}

const isDateEndOfWeek = (now) => {
  const dayOfWeek = now.day()
  return dayOfWeek === 6
}

const getBegOfMonthSection = (now) => {
  let begOfMonthSection
  isDateEndOfWeek(now)
    ? (begOfMonthSection = now.clone().add(8, 'day'))
    : (begOfMonthSection = now.clone().day('Saturday').add(1, 'day'))

  return begOfMonthSection
}

export const getScheduledTaskSubGroup = (task) => {
  if (task.type === models.item.type.SPRINT) {
    return TaskSubGroups.SPRINTS
  } else if (task.completed) {
    return undefined
  } else if (task.pin && task.pin.time) {
    return TaskSubGroups.PINNED_TASKS
  } else {
    return TaskSubGroups.TASKS
  }
}

export const getScheduledGroup = (item) => {
  switch (true) {
    case !!item.completed:
      return TaskArrayGroups.COMPLETED
    case !item.when:
      return undefined
    case item.when.date <= moment().format('YYYY-MM-DD'):
      return TaskObjectGroups.TODAY
    case item.when.date <= computeLastDayOfWeekSection(moment()):
      return TaskObjectGroups.WEEK
    case item.when.date <= computeLastDayOfMonthSection(moment()):
      return TaskObjectGroups.MONTH
    default:
      return undefined
  }
}

const isBefore = (item1, item2) => {
  switch (true) {
    case item1.when.date !== item2.when.date:
      return utils.datetime.isBeforeDateStr(item1.when.date, item2.when.date)
    case !!(item1.pin?.time && item2.pin?.time):
      return isTimeBefore(item1, item2)
    case !!(item1.pin?.time || item2.pin?.time):
      return !!item1.pin?.time
    default:
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
  }
}

export const sortListByScheduledTime = (list) => sortListByConditionFn(list, (a, b) => !isBefore(a, b))

const isRankLower = (item1, item2) => {
  const item1HasRank = Number.isInteger(item1?.todaySectionRank)
  const item2HasRank = Number.isInteger(item2?.todaySectionRank)

  switch (true) {
    case item1HasRank && item2HasRank: {
      return item1.todaySectionRank < item2.todaySectionRank
    }

    case item1HasRank && !item2HasRank: {
      return true
    }

    case !item1HasRank && item2HasRank: {
      return false
    }

    default: {
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
    }
  }
}

export const sortListByTodayRank = (list) => sortListByConditionFn(list, (a, b) => !isRankLower(a, b))
