import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { AnimatePresence, motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import throttle from 'lodash/throttle'
import moment from 'moment'

import { models, utils, styles, variables as miscVariables } from 'gipsy-misc'
import { FocusedLineCompressedConsts } from 'gipsy-ui'

import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import HomebaseLine from 'features/list/components/line'
import variables from 'assets/styles/variables'
import { updateCalendarDate } from 'store/calendar/actions'
import { navbarHeight } from 'features/header/index'
import usePageActions from 'features/hooks/usePageActions2'
import { shouldShowCalendar } from 'logic/layout'
import { addTask as addTaskToSprintComposer } from 'store/sprintComposer/actions'
import { addFilteredId, removeTmpItem as removeTmpItemFromTaskPanel, setPanelExpanded } from 'store/taskPanel/actions'

import { ItemsGroup } from './components'
import { sections } from './components/DragDropContext'
import ExpandHandle from './components/ExpandHandle'

const { containerHeight: focusedLineHeight } = FocusedLineCompressedConsts
const { taskPanelContainerWidth } = variables

function TaskPanel({
  actions: { addFilteredId, addTaskToSprintComposer, removeTmpItemFromTaskPanel, setPanelExpanded, updateCalendarDate },
  draggedItem,
  focusSessionActive,
  inOnboarding,
  inSelectMode,
  itemList,
  isShown,
  isSprintComposerDragging,
  isSprintComposerShown,
  tmpItems,
}) {
  const { onClickDelete, saveTask, createInlineTask, completeTask } = usePageActions()
  const { sprintComposerProps } = useCalendarPanelContext()
  const { hasNavigated, initialRoute } = useSelector((state) => state.layout.navigationStats)
  const isPanelExpanded = useSelector((state) => state.taskPanel.settings.isExpanded)

  const panelRef = useRef(null)

  const [keepCreatingTasks, setKeepCreatingTasks] = useState(false)

  useEffect(() => {
    if (isSprintComposerShown) {
      setPanelExpanded(true)
    }
  }, [isSprintComposerShown, setPanelExpanded])

  const throttledAddTaskToSprintComposer = useMemo(
    () =>
      throttle((task) => {
        addTaskToSprintComposer(task)

        if (tmpItems[task.id]) {
          removeTmpItemFromTaskPanel(task.id)
        } else {
          addFilteredId(task.id)
        }
      }, 300),
    [addFilteredId, addTaskToSprintComposer, removeTmpItemFromTaskPanel, tmpItems]
  )

  const onCreatePageTask = useCallback(
    (task, _, { eventName }) => {
      setKeepCreatingTasks(eventName !== utils.task.clickOutside)
      createInlineTask({ task, context: { componentSource: 'taskPanel' } })
    },
    [createInlineTask]
  )

  const onCreateInlineTaskFromSprint = useCallback(
    async (task) => {
      if (!task.sprintInfo) return

      await createInlineTask({
        context: { componentSource: 'taskPanelSprint' },
        dontShowCreationAlert: true,
        task,
      })
    },
    [createInlineTask]
  )

  const draggablesTopOffset = inOnboarding ? 0 : focusSessionActive ? focusedLineHeight + navbarHeight : navbarHeight

  const itemsWithDate = useMemo(() => {
    if (!itemList?.withDate) return null

    return Object.keys(itemList.withDate)
      .sort((a, b) => {
        const dateA = moment(a)
        const dateB = moment(b)
        return dateA.isBefore(dateB) ? -1 : 1
      })
      .map((groupDate) => {
        const otherItems = itemList.withDate[groupDate].other
        const pinnedItems = inSelectMode
          ? itemList.withDate[groupDate].pinned.filter((item) => item.type !== models.item.type.SPRINT)
          : itemList.withDate[groupDate].pinned

        if (!pinnedItems.length && !otherItems.length) return null

        return (
          <ItemsGroup
            addTaskToSprintComposer={(inOnboarding || inSelectMode) && throttledAddTaskToSprintComposer}
            date={groupDate}
            draggablesTopOffset={draggablesTopOffset}
            hideCompleteCheckbox={inOnboarding}
            key={groupDate}
            onCreateInlineTaskFromSprint={onCreateInlineTaskFromSprint}
            onDelete={onClickDelete}
            onSave={saveTask}
            onComplete={completeTask}
            onTitleClick={() => updateCalendarDate(groupDate)}
            otherItems={otherItems}
            pinnedItems={pinnedItems}
          />
        )
      })
  }, [
    itemList.withDate,
    inSelectMode,
    inOnboarding,
    throttledAddTaskToSprintComposer,
    draggablesTopOffset,
    onCreateInlineTaskFromSprint,
    onClickDelete,
    saveTask,
    completeTask,
    updateCalendarDate,
  ])

  const toggleTaskPanelExpanded = useCallback(() => {
    setPanelExpanded(!isPanelExpanded)
  }, [isPanelExpanded, setPanelExpanded])

  const backlogList = itemList?.[sections.BACKLOG]
  const initialAnimationState = hasNavigated
    ? 'hide'
    : shouldShowCalendar(initialRoute)
    ? isPanelExpanded
      ? 'showExpanded'
      : 'showCollapsed'
    : 'hide'

  const withoutExpandHandle = inOnboarding || isSprintComposerShown

  return (
    <AnimatePresence exitBeforeEnter>
      {isShown && (
        <BackgroundContainer
          animate={isPanelExpanded ? 'showExpanded' : 'showCollapsed'}
          exit={'hide'}
          initial={initialAnimationState}
          key='task-panel'
          ref={panelRef}
          variants={backgroundVariants}>
          <Background withoutExpandHandle={withoutExpandHandle}>
            {!inOnboarding && !isSprintComposerShown && (
              <AnimatePresence initial={false}>
                <ExpandHandle
                  expanded={isPanelExpanded}
                  key='task-panel-expand-handle'
                  onClick={toggleTaskPanelExpanded}
                />
              </AnimatePresence>
            )}
            <TaskPanelContainer
              hideHorizontalOverflow={isSprintComposerDragging}
              withoutExpandHandle={withoutExpandHandle}>
              {!inOnboarding && (
                <HomebaseLine
                  onCreate={onCreatePageTask}
                  startCreation={keepCreatingTasks}
                  creating={true}
                  startSprintCreation={sprintComposerProps.startSprintCreation}
                />
              )}
              <DragDropItemsContainer>
                {itemsWithDate}
                {backlogList?.length > 0 && (
                  <ItemsGroup
                    addTaskToSprintComposer={(inOnboarding || inSelectMode) && throttledAddTaskToSprintComposer}
                    date={null}
                    draggablesTopOffset={draggablesTopOffset}
                    draggedItem={draggedItem}
                    hideCompleteCheckbox={inOnboarding}
                    onDelete={onClickDelete}
                    onSave={saveTask}
                    onComplete={completeTask}
                    otherItems={backlogList}
                    pinnedItems={null}
                  />
                )}
              </DragDropItemsContainer>
            </TaskPanelContainer>
          </Background>
        </BackgroundContainer>
      )}
    </AnimatePresence>
  )
}

const mapStateToProps = (state) => ({
  focusSessionActive: !!state.session?.focusSession?.taskId,
  inSelectMode: state.taskPanel.settings.inSelectMode,
  isShown: state.taskPanel.settings.isShown,
  isSprintComposerDragging: state.sprintComposer.isDragging,
  tmpItems: state.taskPanel.tmpItems,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addFilteredId,
      addTaskToSprintComposer,
      removeTmpItemFromTaskPanel,
      setPanelExpanded,
      updateCalendarDate,
    },
    dispatch
  ),
})

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TaskPanel))

const DragDropItemsContainer = styled.div`
  transition: opacity ${styles.transitions.calendarSlide}s ease-in-out;
  opacity: 1;
`

const backgroundVariants = {
  hide: {
    transition: {
      duration: styles.transitions.calendarSlide,
      ease: 'easeInOut',
    },
    x: -(taskPanelContainerWidth + 16),
  },
  showExpanded: {
    transition: {
      duration: styles.transitions.calendarSlide,
      ease: 'easeInOut',
    },
    x: 0,
  },
  showCollapsed: {
    transition: {
      duration: styles.transitions.calendarSlide,
      ease: 'easeInOut',
    },
    x: -taskPanelContainerWidth,
  },
}

const BackgroundContainer = styled(motion.div)`
  bottom: 0;
  left: 0;
  margin: 8px 0;
  position: absolute;
  top: 0;
  width: ${taskPanelContainerWidth}px;
  will-change: transform;
  z-index: ${miscVariables.zIndex.taskPanel};
`

BackgroundContainer.displayName = 'TaskPanelBackgroundContainer'

const Background = styled.div`
  background-color: ${styles.colors.veryLightGrey};
  border-radius: 8px 0 0 8px;
  box-shadow: 2px 0px 2px rgba(33, 21, 81, 0.08);
  height: 100%;
  position: relative;

  ${({ withoutExpandHandle }) =>
    withoutExpandHandle &&
    css`
      border-radius: 8px;
    `}
`

Background.displayName = 'TaskPanelBackground'

const TaskPanelContainer = styled.div`
  display: flex;
  overflow-y: auto;
  height: calc(100% - 32px);
  align-items: stretch;
  flex-direction: column;
  border-radius: 8px;
  padding: 4px 4px 16px 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${({ hideHorizontalOverflow }) =>
    hideHorizontalOverflow &&
    css`
      overflow-x: hidden;
    `}

  ${({ withoutExpandHandle }) =>
    withoutExpandHandle &&
    css`
      padding: 4px 14px 16px 14px;
    `}
`

TaskPanelContainer.displayName = 'TaskPanelContainer'
