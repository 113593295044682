import styled, { css } from 'styled-components'

import { styles, variables } from 'gipsy-misc'
import { BlurLayer, Icon } from 'gipsy-ui'

import { fadeIn } from 'features/keyframes'

export const StyledBlurLayer = styled(BlurLayer)`
  animation: ${fadeIn} 300ms ease-in-out forwards;
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  z-index: ${variables.zIndex.lightbox};

  ${({ maxWidth, name, width }) => css`
    &.${name}-layer, &.${name}-layer.active {
      ::before {
        background: ${styles.colors.textMediumDarkColor};
        opacity: 0.6;
        z-index: -1;
      }

      .${name}-content {
        display: flex;
        height: calc(100% - 128px);
        max-width: ${maxWidth};
        overflow: visible;
        width: ${width};
      }
    }
  `}
`

StyledBlurLayer.displayName = 'StyledBlurLayer'

export const Content = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 60px 100px rgba(33, 21, 81, 0.27);
  display: flex;
  flex-flow: column;
  margin: auto;
  max-height: 100%;
  padding: 32px 48px;
  position: relative;
  width: 100%;
`

Content.displayName = 'Content'

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 18px;
  top: 18px;
`

CloseIcon.displayName = 'CloseIcon'

export const SavedContainer = styled.div`
  align-items: center;
  display: flex;
  color: ${styles.colors.greenColor};
  margin-left: auto;

  > span {
    font-size: ${styles.fonts.fontSizeSmall};
  }
`

SavedContainer.displayName = 'SavedContainer'

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 14px;

  ${SavedContainer} {
    margin-left: 12px;
    margin-top: 8px;
  }
`

TitleContainer.displayName = 'TitleContainer'

export const Title = styled.span`
  color: ${styles.colors.textDarkColor};
  display: block;
  font-size: 24px;
  font-weight: 500;
`

Title.displayName = 'Title'

export const Subtitle = styled.span`
  color: ${styles.colors.darkGrey};
  display: block;
`

Subtitle.displayName = 'Subtitle'

export const MultiSyncIcon = styled(Icon)`
  margin-right: 12px;

  line {
    transform: rotate(-15deg);
  }
`

MultiSyncIcon.displayName = 'MultiSyncIcon'
