import React from 'react'
import styled from 'styled-components'

import { styles, translations } from 'gipsy-misc'

import Icon from 'Icon'

function CloseTabsButton({ marginRight, onClick }) {
  return (
    <Container marginRight={marginRight} onClick={onClick}>
      <Icons>
        <StyledIcon fill="none" icon={'CloseTabsFolder'} size={16} />
        <StyledIcon className="cropped-icon--1" icon={'CroppedFolder'} size={11} />
        <StyledIcon className="cropped-icon--2" icon={'CroppedFolder'} size={11} />
      </Icons>
      <CloseTabsSpan>{translations.general.closeTabs}</CloseTabsSpan>
    </Container>
  )
}

const StyledIcon = styled(Icon)`
  path {
    stroke: ${styles.colors.darkGrey};
    transform-origin: right center;
    transition: transform 200ms ease-in-out;
  }

  .cropped-folder__top-line,
  .cropped-folder__bottom-line {
    transform: scaleX(100%);
  }
`

StyledIcon.displayName = 'StyledIcon'

const Container = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  margin-right: ${({ marginRight }) => (Number.isInteger(marginRight) ? marginRight : 0)}px;
  padding: 8px 8px 8px 11px;

  user-select: none;

  .cropped-icon--1 .cropped-folder,
  .cropped-icon--2 .cropped-folder {
    transform: translateX(-4px);
    transition: transform 200ms ease-in-out;
  }

  .cropped-icon--2 .cropped-folder {
    transform: translateX(-9px);
  }

  &:hover {
    color: ${styles.colors.primaryColor};

    .cropped-icon--1 .cropped-folder {
      transform: translateX(-5px);
    }

    .cropped-icon--2 .cropped-folder {
      transform: translateX(-12px);
    }

    ${StyledIcon} {
      path {
        stroke: ${styles.colors.primaryColor};
      }

      .cropped-folder__top-line,
      .cropped-folder__bottom-line {
        transform: scaleX(0);
      }
    }
  }
`

Container.displayName = 'Container'

const Icons = styled.div`
  align-items: center;
  display: flex;
  margin-right: -2px;
`

const CloseTabsSpan = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`

Icons.displayName = 'Icons'

export default CloseTabsButton
