import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { styles, variables } from 'gipsy-misc'

import CalendarPanelContext from 'features/calendar/components/CalendarPanel/context'
import Header from 'features/header'
import MobileWarn from 'features/layout/components/mobileWarn'
import Navbar from 'features/layout/components/navbar'
import OnboardingHelper from 'features/onboardingHelperOverlay'
import Popup from 'features/popup'
import Shortcuts from 'features/shortcuts'
import Snackbar from 'features/snackbar'
import { sprintComposerPortalId } from 'features/sprintComposer/index'
import { supportChatPortalId } from 'features/supportchat'

import { routesToHideMobileWarn, shouldShowCalendar, shouldShowHeader, shouldShowNavbar } from 'logic/layout'

export const fixedTooltipContainerPortalId = 'fixed-tooltip-portal'
export const layoutBlurLayerPortalId = 'layout-blur-layer-portal'
export const pageBlurLayerPortalId = 'page-blur-layer-portal'

function Layout({ children, location }) {
  const sessionId = useSelector((state) => state.session.id)
  const taskPanelShown = useSelector((state) => state.taskPanel.settings.isShown)

  const fromOnboarding = location.state?.fromOnboarding
  const isConnected = !!sessionId
  const pathname = location?.pathname
  const hideMobileWarn = isConnected && routesToHideMobileWarn[pathname]
  const showCalendar = isConnected && shouldShowCalendar(pathname)
  const showHeader = shouldShowHeader(pathname)
  const showNavbar = isConnected && shouldShowNavbar(pathname)

  return (
    <Container showLayoutOverlay={taskPanelShown}>
      {!hideMobileWarn && <MobileWarn />}
      {showHeader && <Header />}
      <PageContainer>
        <div id={layoutBlurLayerPortalId} />
        <div id={pageBlurLayerPortalId} />
        <Navbar show={showNavbar} />
        <div id={sprintComposerPortalId} />
        <CalendarPanelContext pathname={pathname} showCalendar={showCalendar}>
          <PageContent>{children}</PageContent>
        </CalendarPanelContext>
        <div id={supportChatPortalId} />
      </PageContainer>
      <Popup />
      <Snackbar />
      <Shortcuts />
      <AnimatePresence>
        {fromOnboarding && (
          <motion.div exit={{ opacity: 0 }} initial={{ opacity: 1 }}>
            <OnboardingHelper />
          </motion.div>
        )}
      </AnimatePresence>
      <div id={fixedTooltipContainerPortalId} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  z-index: ${variables.zIndex.layout};

  &::after {
    background-color: ${styles.colors.veryLightGrey};
    content: '';
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity ${styles.transitions.calendarSlide}s ease-in-out;
    user-select: none;
    width: 100%;
    z-index: 1;
  }

  ${({ showLayoutOverlay }) =>
    showLayoutOverlay &&
    css`
      &::after {
        opacity: 1;
      }
    `}
`

Container.displayName = 'LayoutContainer'

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`

PageContainer.displayName = 'LayoutPageContainer'

const PageContent = styled.div`
  flex: 1;
  margin: 8px -125px 8px 0;
  overflow: auto;
  padding-right: 100px;
`

PageContent.displayName = 'LayoutPageContent'

export default withRouter(Layout)
