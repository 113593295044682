import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'

import { styles, translations, utils } from 'gipsy-misc'

import DatePicker from 'DatePicker'
import Duration from 'Duration'
import { OkButton } from 'LineComponents/LinePopupWrapper'
import Popup from 'Popup'
import TimeInput2 from 'TimeInput2'
import { datePickerStyles } from 'WhenDatePicker'

export function PopupInputs({
  children,
  components: { Value = InputValue } = {},
  isEditing,
  item,
  onOkClicked,
  onOutsideClicked,
  parentRef,
  shrinked,
  firstDayOfWeek,
}) {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [editingStartTime, setEditingStartTime] = useState(item.startTime)
  const [editingDuration, setEditingDuration] = useState(utils.focussession.getSpentTimeObj(item))

  const editDateRef = useRef(null)

  const updateFocusSession = useCallback(() => {
    const updatedFocusSession = JSON.parse(JSON.stringify(item))
    updatedFocusSession.startTime = editingStartTime

    const editingEndTime = moment(editingStartTime)
    const { hour, minute, second } = editingDuration
    editingEndTime.add(hour, 'hour')
    editingEndTime.add(minute, 'minute')
    editingEndTime.add(second, 'second')

    updatedFocusSession.endTime = editingEndTime.toISOString()
    return updatedFocusSession
  }, [editingDuration, editingStartTime, item])

  const save = useCallback(async () => {
    const updatedFocusSession = updateFocusSession()
    onOkClicked?.(updatedFocusSession)
    setShowDatePicker(false)
    setEditingStartTime(item.startTime)
    setEditingDuration(utils.focussession.getSpentTimeObj(item))
  }, [item, onOkClicked, updateFocusSession])

  const toggleShowDatePicker = () => {
    setShowDatePicker((showDatePicker) => !showDatePicker)
  }

  const onChangeDate = ({ value: newDate }) => {
    const newDateMoment = moment(newDate)
    let newEditingStartTime = moment(editingStartTime)
      .date(newDateMoment.date())
      .month(newDateMoment.month())
      .year(newDateMoment.year())

    setEditingStartTime(newEditingStartTime.toISOString())
  }

  const onChangeDuration = (value) => {
    setEditingDuration(value)
  }

  const onChangeStartTime = (value) => {
    const startTime = moment(editingStartTime)
    const newStartTime = utils.datetime.setDateInDateTime(startTime, value)
    setEditingStartTime(newStartTime.toISOString())
  }

  useEffect(() => {
    setEditingStartTime(item.startTime)
    setEditingDuration(utils.focussession.getSpentTimeObj(item))
  }, [item])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isEditing) {
        const parentNode = parentRef.current
        const editDateNode = editDateRef.current

        if (parentNode && !parentNode.contains(e.target)) {
          onOutsideClicked?.(updateFocusSession())
        }

        if (editDateNode && !editDateNode.contains(e.target)) {
          setShowDatePicker(false)
        }
      }
    }

    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [isEditing, onOutsideClicked, parentRef, showDatePicker, updateFocusSession])

  return children({
    Date: (
      <>
        {isEditing ? (
          <EditDateContainer focused={showDatePicker} onClick={toggleShowDatePicker} ref={editDateRef}>
            {shrinked ? moment(editingStartTime).format('D MMM') : moment(editingStartTime).format('ddd, D MMM YYYY')}
            {showDatePicker && (
              <StyledPopup>
                <DatePicker
                  onChange={onChangeDate}
                  pastDatesOnly={true}
                  selectedDay={item.startTime}
                  firstDayOfWeek={firstDayOfWeek}
                />
              </StyledPopup>
            )}
          </EditDateContainer>
        ) : (
          <Value>
            {shrinked ? moment(editingStartTime).format('D MMM') : moment(item.startTime).format('ddd, D MMM YYYY')}
          </Value>
        )}
      </>
    ),
    Duration: (
      <>
        {isEditing ? (
          <StyledDuration
            width={88}
            hideControls
            inputWidth={24}
            inputFontWeight={500}
            colonPadding={0}
            transparent={true}
            hour={parseInt(editingDuration.hour)}
            minute={parseInt(editingDuration.minute)}
            second={parseInt(editingDuration.second)}
            onChange={onChangeDuration}
            withSeconds
            focusedBorderColor={styles.colors.primaryColor}
          />
        ) : (
          <DurationReadOnly>{utils.focussession.getSpentTimeString(item)}</DurationReadOnly>
        )}
      </>
    ),
    OkButton: <StyledOkButton onClick={save}>{translations.general.ok}</StyledOkButton>,
    Time: (
      <>
        {isEditing ? (
          <TimeInput2
            active
            width={75}
            inputWidth={18}
            inputFontWeight={500}
            transparent={true}
            showPeriod={true}
            format='12h'
            hour={moment(editingStartTime).hours()}
            minute={moment(editingStartTime).minutes()}
            onChange={onChangeStartTime}
          />
        ) : (
          <Value>{moment(item.startTime).format('LT')}</Value>
        )}
      </>
    ),
  })
}

const inputStyle = css`
  background-color: ${styles.colors.veryLightGrey};
  border: 1px solid ${styles.colors.middleGrey};
  border-radius: 8px;
  color: ${styles.colors.textMediumDarkColor};
  cursor: pointer;
  height: 32px;
  transition: border-color 200ms ease-in-out;

  &:hover {
    color: ${styles.colors.primaryColor};
  }

  ${({ focused }) =>
    focused &&
    css`
      border-color: ${styles.colors.primaryColor};
    `}

  &.Duration--focused {
    background-color: ${styles.colors.veryLightGrey};
    border-color: ${styles.colors.primaryColor};
  }
`

export const EditDateContainer = styled.span`
  ${inputStyle};
  align-items: center;
  display: flex;
  font-weight: 400;
  justify-content: center;
  margin-right: 5px;
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
  ${datePickerStyles};
`

export const StyledPopup = styled(Popup)`
  color: ${styles.colors.textMediumDarkColor};
  font-size: 13px;
  margin-bottom: 12px;
  margin-top: 5px;
  padding: 16px 6px;
  top: 100%;
`

export const StyledDuration = styled(Duration)`
  ${inputStyle};
`

export const InputValue = styled.span`
  color: ${styles.colors.textMediumDarkColor};
  font-weight: 400;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};
`

export const DurationReadOnly = styled(InputValue)`
  display: inline-block;
  min-width: 70px;
`

export const StyledOkButton = styled(OkButton)`
  width: 40px;
`

export default React.memo(PopupInputs)
