import React, { Component } from 'react'

import container from './container'
import { PageContainer, InnerContainer, LogoContainer, TitleContainer } from 'features/auth/components'
import { getSrc } from 'utils/image'

import { LoginButtons } from 'gipsy-ui'
import { translations } from 'gipsy-misc'

class LoginPage extends Component {
  render() {
    const { goToGoogleAuth, goToMicrosoftAuth } = this.props
    return (
      <PageContainer>
        <InnerContainer>
          <LogoContainer
            src={getSrc('/images/gipsybot-zen-filled.svg')}
            onClick={() => window.open('https://www.kosmotime.com', '_blank')}
          />
          <TitleContainer>{translations.auth.selectAccount}</TitleContainer>
          <LoginButtons onGoogleLogin={goToGoogleAuth} onMicrosoftLogin={goToMicrosoftAuth} />
        </InnerContainer>
      </PageContainer>
    )
  }
}

export default container(LoginPage)
