import React, { PureComponent } from 'react'
import styled from 'styled-components'

import container from './container'
import EmailNotifications from './components/emailnotifications'
import CustomExtensionId from './components/customextensionid'
import { PageContainer, PageBody, TitleContainer, NavigationTitle, MenuIconContainer } from 'features/layout/pages'
import ConfirmPanel from 'features/popup/components/confirmPanel'

import { Icon, SecondaryButton, Separator } from 'gipsy-ui'
import { styles, translations } from 'gipsy-misc'
import { user } from 'gipsy-api'

export class Settings extends PureComponent {
  onDelete = async () => {
    await user.del()
    this.props.onLogout()
  }

  onClickDelete = () => {
    const onConfirm = () => {
      this.onDelete()
      this.props.closePopup()
    }
    this.props.openPopup({
      title: translations.settings.section.delete.prompt,
      centeredTitle: true,
      logo: 'sad',
      component: (
        <ConfirmPanel
          confirmLabel={translations.general.yes}
          cancelLabel={translations.general.no}
          onCancel={this.props.closePopup}
          onConfirm={onConfirm}
        />
      ),
    })
  }
  render() {
    const { user, onLogout, handleAPIError, openNavbar } = this.props
    return (
      <PageContainer>
        <PageBody>
          <TitleContainer>
            <MenuIconContainer onClick={openNavbar}>
              <Icon icon="Menu" />
            </MenuIconContainer>
            <NavigationTitle>{translations.settings.title}</NavigationTitle>
          </TitleContainer>
          <ComponentsContainer>
            <CustomExtensionId user={user} />
            <EmailNotifications handleAPIError={handleAPIError} user={user} />
            <ButtonSeparator />
            <ButtonContainer>
              <SecondaryButton
                onClick={onLogout}
                height={32}
                width={110}
                text={translations.settings.section.logout.title}
              />
              <DeleteContainer onClick={this.onClickDelete}>
                <Icon icon="Trash" size={14} />
                <DeleteTitleContainer>{translations.settings.section.delete.title}</DeleteTitleContainer>
              </DeleteContainer>
            </ButtonContainer>
          </ComponentsContainer>
        </PageBody>
      </PageContainer>
    )
  }
}

const ButtonSeparator = styled(Separator)`
  margin-bottom: 60px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DeleteContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  color: ${styles.colors.darkGrey};
  cursor: pointer;
`

const DeleteTitleContainer = styled.span`
  margin-left: 10px;
  margin-bottom: 1px;
  font-weight: 500;
  font-size: 14px;
`

const ComponentsContainer = styled.div`
  margin-top: 40px;
`

export default container(Settings)
