import styled, { css } from 'styled-components'

import Icon from 'Icon'
import Popup from 'Popup'

import { styles, variables } from 'gipsy-misc'

export const LineContainer = styled.div`
  display: flex;
  width: 100%;
  height: unset;
  margin-top: ${(props) => props.marginTop || 0}px;
  position: relative;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: ${(props) => props.paddingRight}px;
  margin-bottom: 0;
  cursor: ${(props) => (props.isDraggable ? 'grab' : 'inherit')};
`

export const LineContentContainer = styled.div`
  display: flex;
  min-height: ${(props) => props.lineHeight || '30px'};
  width: 100%;
  min-width: 0;
  overflow-y: visible;
  position: relative;
  background: ${({ background }) => (background ? background : 'white')};
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
  transition: margin-bottom 0.3s ease-in-out;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
  cursor: ${(props) => (props.cursor ? props.cursor : 'inherit')};

  ${(props) =>
    !props.disableHover &&
    !props.disableHoverStyle &&
    css`
      :hover {
        box-shadow: 0 10px 13px rgba(33, 21, 81, 0.09);
        z-index: ${props.focusSessionPopupVisible ? variables.zIndex.taskHovered + 1 : variables.zIndex.taskHovered};
      }
    `};

  ${(props) =>
    props.isDragging &&
    css`
      box-shadow: ${styles.shadows.popup};
      z-index: ${variables.zIndex.taskDragged};
    `};

  ${({ theme }) => {
    switch (theme) {
      case 'orange': {
        return css`
          background-color: ${styles.colors.lightOrangeColor};
        `
      }

      default:
        return ''
    }
  }}
`

const defaultLineTopPadding = 8
const defaultLineBottomPadding = 10

export const LineContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ linePadding }) =>
    `${parseInt(linePadding) || defaultLineTopPadding}px 12px ${parseInt(linePadding) || defaultLineBottomPadding}px`};
  width: 100%;
  min-width: 0;
`

export const LineTopRow = styled.div`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
  justify-content: ${(props) => (props.justifyLeft ? 'flex-start' : 'space-between')};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'inherit')};
`

export const TitleContainer = styled.div`
  width: 100%;
  min-width: 0;
`

export const PrimaryActionButton = styled.div`
  color: ${styles.colors.grey};
  cursor: pointer;
  min-width: fit-content;
  display: flex;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
  font-weight: 500;
  font-size: ${styles.fonts.fontSizeXSmall};
  span {
    margin-left: 7px;
  }

  ${(props) =>
    !props.disableHover &&
    css`
      :hover {
        color: ${styles.colors.textMediumDarkColor};
        path {
          fill: ${styles.colors.textMediumDarkColor};
        }
      }
    `}

  ${({ theme }) => {
    switch (theme) {
      case 'orange': {
        return css`
          span {
            color: ${styles.colors.orangeColor};
          }

          path {
            fill: ${styles.colors.orangeColor};
          }

          :hover {
            path {
              fill: ${styles.colors.orangeColor};
            }
          }
        `
      }

      default:
        return ''
    }
  }}
`

export const LineBottomRow = styled.div`
  display: flex;
  width: 100%;
  font-size: ${styles.fonts.fontSizeXSmall};
  line-height: ${styles.fonts.fontSize};
  justify-content: space-between;
  color: ${styles.colors.grey};
`

export const TagsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow: hidden;
`

export const Attribute = styled.span`
  display: flex;
  align-items: center;
  max-width: ${(props) => props.maxWidth};
  :not(:last-of-type) {
    margin-right: 9.5px;
  }
  ${({ hideOverflow }) =>
    hideOverflow &&
    css`
      overflow: hidden;
    `}
`

export const AttributeIcon = styled(Icon)`
  max-height: 16px;
  margin-right: 6px;
  flex-shrink: 0;
`

export const AttributeName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ClickableAttributeName = styled(AttributeName)`
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
`

export const UrlAttribute = styled(Attribute)`
  flex-shrink: 0;
  font-size: 12px;
  position: relative;
  color: ${styles.colors.darkGrey};
`

export const Project = styled(Attribute)`
  flex-shrink: 0;
  max-width: 100%;
`

export const ProjectName = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  line-height: 16px;
  color: ${styles.colors.darkGrey};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : 'none')};
`

export const ProjectColor = styled.span`
  display: inline-block;
  background-color: ${(props) => props.color};
  min-width: 8px;
  width: 8px;
  height: 8px;
  border-radius: 3px;
  margin-left: 6px;
  margin-right: 0;
  margin-top: 1px;
`

export const ProjectDropdown = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  & ${AttributeName} {
    margin-right: 6px;
  }
`

export const ProjectDropdownPopup = styled(Popup)`
  max-height: 240px;
  overflow: auto;
  top: calc(100% + 6px);
  right: 0;
  ${(props) =>
    props.alignRight &&
    css`
      right: 0;
    `}
`

export const ProjectDropdownPopupContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 120px;
  padding: 12px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${Project} {
    justify-content: flex-end;
    margin-bottom: 8px;
    margin-right: 0px;
    max-width: none;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${ProjectName} {
    color: ${styles.colors.darkGrey};
    display: block;
  }
`

export const START_BUTTON_WIDTH = 117
export const START_BUTTON_MIN_WIDTH = 88

const getStartButtonMinWidth = (props) => {
  return props.width || START_BUTTON_MIN_WIDTH
}

export const StartButtonContainer = styled.div`
  display: flex;
  z-index: ${(props) => (props.higherZIndex ? 2 : 1)}; // to keep at root level of elevation
  flex-shrink: 0;
  width: ${(props) => (props.shrink ? getStartButtonMinWidth(props) + 1 : START_BUTTON_WIDTH)}px;
`

export const StartButtonActionsSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  cursor: pointer;
  padding: ${({ linePadding, extraPaddingTop }) =>
    `${(parseInt(linePadding) || defaultLineTopPadding) + (parseInt(extraPaddingTop) || 0) - 1}px 0 ${
      parseInt(linePadding) || defaultLineBottomPadding
    }px`};
  color: ${styles.colors.grey};
  font-weight: 500;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: ${(props) => getStartButtonMinWidth(props)}px;
  width: ${(props) => getStartButtonMinWidth(props)}px;
  height: 100%;

  ${(props) =>
    props.alignCenter &&
    css`
      justify-content: center;
    `}

  ${(props) =>
    props.isTaskOnFocusSession &&
    css`
      background: ${(props) => props.color || styles.colors.primaryColor};
      color: white;
      width: 100%;
    `}
`

export const CheckboxSection = styled.div`
  padding: ${({ linePadding, extraPaddingTop }) =>
    `${(parseInt(linePadding) || defaultLineTopPadding) + (parseInt(extraPaddingTop) || 0) + 1}px 0 ${
      parseInt(linePadding) || defaultLineBottomPadding
    }px 12px`};
  border-left: 1px solid ${styles.colors.veryLightGrey};
  left: ${(props) => getStartButtonMinWidth(props)}px;
  position: absolute;
  height: 100%;

  ${({ theme }) => {
    switch (theme) {
      case 'orange': {
        return css`
          border-left-color: ${styles.colors.orangeColor}66;
        `
      }

      default:
        return ''
    }
  }}
`

export const ReadModeTitle = styled.div`
  position: relative;
  color: ${(props) => props.color || styles.colors.textMediumDarkColor};
  font-family: ${styles.fonts.primaryFont};
  font-size: ${styles.fonts.fontSizeSmall};
  font-weight: 500;
  line-height: 18px;
  border: none;
  outline: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: ${(props) => props.linethrough && 'line-through'};
  min-width: 10px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`

export const editableTitleStyle = {
  verticalPadding: 8,
  borderSize: 1,
}

export const AttributeNameLink = styled(AttributeName).attrs({ as: 'a' })`
  color: inherit;
  text-decoration: none;
`

export const AsanaWorkspaceName = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  color: ${styles.colors.darkGrey};
  font-size: ${styles.fonts.fontSizeXXSmall};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const AsanaIcon = styled.img`
  width: 13px;
  height: 13px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  margin-left: 4px;
`
