import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { styles } from 'gipsy-misc'
import Icon from 'Icon'

import { CardAttribute, IconWrapper, getColor } from './commonUIComponents'

export class PinTimeWrapper extends PureComponent {
  render() {
    const { value, onTogglePin } = this.props
    return (
      <Container>
        <Content>
          <StyledCardAttribute value={value} onClick={onTogglePin}>
            <IconWrapper>
              <StyledIcon
                size={16}
                icon={'Pin'}
                fill={getColor({ hasValue: value })}
                hoverFill={styles.colors.primaryColor}
              />
            </IconWrapper>
          </StyledCardAttribute>
        </Content>
      </Container>
    )
  }
}

const triggerWidth = '42px'

const Container = styled.div`
  position: absolute;
  right: calc(-${styles.spacings.lineContainerPadding} - ${triggerWidth});
  top: 0;
`

Container.displayName = 'PinTimeWrapper Container'

const Content = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0 10px 10px 0;
  box-shadow: 1px 1px 1px rgba(33, 21, 81, 0.08);
  display: flex;
  justify-content: center;
  padding: ${styles.spacings.lineContainerPadding};
  padding-left: 0;
  position: relative;
  z-index: 2;

  ::after,
  ::before {
    background-color: transparent;
    border-top-left-radius: 10px;
    bottom: -50px;
    box-shadow: 0 -25px 0 0 white;
    content: '';
    height: 50px;
    left: 0;
    position: absolute;
    width: 25px;
  }

  ::after {
    box-shadow: 0 -25px 0 0 #f0f0f0;
    z-index: -1;
  }
`

Content.displayName = 'PinTimeWrapper Content'

const StyledIcon = styled(Icon)``

const StyledCardAttribute = styled(CardAttribute)`
  height: calc(${triggerWidth} - 2px);
  width: ${triggerWidth};

  &:hover {
    border-color: ${styles.colors.primaryColor};

    ${StyledIcon} {
      path {
        fill: ${styles.colors.primaryColor};
      }
    }
  }

  ${IconWrapper} {
    margin: auto;
  }
`

StyledCardAttribute.displayName = 'PinTimeWrapper StyledCardAttribute'
