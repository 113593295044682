import moment from 'moment'

import { getDailyScheduleUntil } from 'utils/recurrency/daily'
import { DailyRecurrence, RecurrencyDetails, ScheduleTypes, WeeklyRecurrence } from 'utils/recurrency/types'
import { getWeeklyScheduleUntil } from 'utils/recurrency/weekly'

const getStart = (now: string, firstOccurence: string): string => {
  if (!firstOccurence) {
    return now
  }
  if (moment(now).isBefore(moment(firstOccurence))) {
    return firstOccurence
  }

  return now
}

const getScheduledDatesUntil = (firstDate: string, details: RecurrencyDetails, until: string): string[] | null => {
  const start = getStart(firstDate, details.firstOccurrence)
  let dates: string[] = []

  switch (details.schedule) {
    case ScheduleTypes.DAILY: {
      dates = getDailyScheduleUntil(start, details as DailyRecurrence, until)
      break
    }

    case ScheduleTypes.WEEKLY: {
      dates = getWeeklyScheduleUntil(start, details as WeeklyRecurrence, until)
      break
    }

    default: {
      return null
    }
  }

  return dates
}

export const getNextDatesUntil = (
  day: string,
  details: RecurrencyDetails,
  lastScheduledDate: string | null,
  until: string
): string[] | null => {
  const scheduledDates = getScheduledDatesUntil(day, details, until)
  if (!scheduledDates) return null

  let currentMax = moment(day).add(-1, 'day')

  if (lastScheduledDate) {
    currentMax = moment(lastScheduledDate)
  }

  const result: string[] = []

  scheduledDates.forEach((date) => {
    const dateMoment = moment(date)

    if (dateMoment.isAfter(currentMax)) {
      result.push(date)
    }
  })

  return result
}
