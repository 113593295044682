import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { produce } from 'immer'
import clone from 'lodash/clone'
import differenceBy from 'lodash/differenceBy'
import get from 'lodash/get'
import moment from 'moment'

import { focussession as focussessionApi, sprint as sprintApi, task as taskApi } from 'gipsy-api'
import { constants, models, styles, translations, utils } from 'gipsy-misc'

import RealTime from 'features/realTime'
import { handleAPIError } from 'store/app/actions'
import {
  addCalendarEvent,
  removeCalendarEvent,
  setHighlightedEventId,
  updateCalendarDate,
  updateCalendarEvent,
} from 'store/calendar/actions'
import ConfirmPanel from 'features/popup/components/confirmPanel'
import RecurringSprintPanel, {
  options as sprintRecurrenceEditOptions,
} from 'features/popup/components/recurrentSprintPanel'
import Loading from 'features/popup/components/loading'
import { closePopup, openPopup } from 'store/popup/actions'
import {
  createTaskAndFs,
  endSession,
  handleCompletedSession,
  updateFocusSessionTask,
  updateFocusSessionTitle,
} from 'store/session/actions'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'
import { patchTaskRequest, toggleCompleteTaskRequest } from 'store/task/actions'
import {
  addItem,
  addItems,
  removeItem,
  removeItems,
  replaceItem,
  replaceItems,
  updateItem,
  updateItems,
} from 'store/items/actions'
import { getAllItems, getFindItemByIdFn, getSprints, getSprintsById, getSprintTasks } from 'store/items/selectors'

import { getAllInstancesOfRecSprint } from './utils/sprints'
import { sortListByScheduledTime } from 'logic/today'

export default function usePageActions() {
  const allItems = useSelector((state) => getAllItems(state.items))
  const calendarHighlightedEventId = useSelector((state) => state.calendar.highlightedEventId)
  const dispatch = useDispatch()
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const session = useSelector((state) => state.session)
  const sprints = useSelector((state) => getSprints(state.items))
  const sprintsById = useSelector((state) => getSprintsById(state.items))
  const sprintTasks = useSelector((state) => getSprintTasks(state.items))

  const [isTaskCreationAlertShown, setIsTaskCreationAlertShown] = useState(false)

  const patchers = useRef({})
  const taskCreationAlertTimeout = useRef(null)

  const addItemToCalendar = useCallback(
    (item, color, isSprint) => {
      dispatch(addCalendarEvent({ color, isSprint, item }))
    },
    [dispatch]
  )

  const removeItemFromCalendar = useCallback(
    (id) => {
      dispatch(removeCalendarEvent({ itemId: id }))
    },
    [dispatch]
  )

  const updateCalendarItem = useCallback(
    (idToUpdate, item) => {
      dispatch(
        updateCalendarEvent({
          idToUpdate,
          item,
        })
      )
    },
    [dispatch]
  )

  const sendPatchRequest = useCallback(
    ({ extraParams, method, paramName, task, value }) => {
      if (!patchers.current[task.id]) {
        patchers.current[task.id] = new utils.TaskPatcher(constants.delayBeforePatch.default)
      }

      try {
        const { params, body } = utils.task.computePatchRequest(task, { paramName, value, method }, extraParams)
        return new Promise((resolve, reject) => {
          patchers.current[task.id].put(paramName, method, () => {
            return dispatch(patchTaskRequest(task.id, params, body))
              .then((args) => {
                resolve(args)
              })
              .catch((args) => {
                reject(args)
              })
          })
        })
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch]
  )

  const archiveTask = useCallback(
    async ({ id }) => {
      const task = findItemById(id)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const paramName = 'archived'
      const value = true

      dispatch(removeItem(task))

      await sendPatchRequest({ method: 'change', paramName, task, value })
    },
    [dispatch, findItemById, sendPatchRequest]
  )

  const completeTask = useCallback(
    async ({ id }, extraParams, toAddFocusSession) => {
      let oldTask = clone(findItemById(id))
      let completionFocusSession = toAddFocusSession
      const now = moment()

      if (!oldTask) {
        console.warn('-- task not found')
        return
      }

      if (!completionFocusSession) {
        const completionTime = utils.task.getCompletionTime(oldTask)
        completionFocusSession = utils.focussession.createDefaultFocusSession({
          task: oldTask,
          endTime: completionTime,
        })
      }

      let completionTime

      if (extraParams?.inFocusSession) {
        completionTime = now
      } else {
        completionTime = utils.task.getCompletionTime(oldTask)
      }

      const completedTask = utils.task.updateTaskWhenComplete(oldTask, completionTime)

      if (completionFocusSession) {
        completedTask.focusSessions = (completedTask.focusSessions || []).concat([completionFocusSession])
        addItemToCalendar(
          { ...completionFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )
      }

      if (oldTask && oldTask.sprintInfo?.id) {
        let sprint = clone(findItemById(oldTask.sprintInfo?.id))
        if (sprint) {
          sprint.tasks = sprint.tasks?.filter((completedTask) => completedTask.id !== id)
          if (utils.sprint.shouldCompleteSprint(sprint, now)) {
            const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
            sprint.completionTime = completionTime
            sprint.estimatedTime = estimatedTime
          }
          dispatch(updateItem(sprint))
        }

        dispatch(addItem(completedTask))
      } else {
        dispatch(updateItem(completedTask))
      }

      const out = await dispatch(toggleCompleteTaskRequest(id, 1, undefined, extraParams))
      const updatedTask = clone(completedTask)

      if (out?.createdFocusSession) {
        if (completionFocusSession) {
          removeItemFromCalendar(completionFocusSession.id)
          updatedTask.focusSessions = (updatedTask.focusSessions || []).filter(
            (fs) => fs.id !== completionFocusSession.id
          )
        }

        addItemToCalendar(
          { ...out.createdFocusSession, type: models.item.type.FOCUSSESSION },
          styles.colors.focusSessionFill
        )

        updatedTask.focusSessions = (updatedTask.focusSessions || []).concat([out.createdFocusSession])
        dispatch(updateItem(updatedTask))
      }
    },
    [addItemToCalendar, dispatch, findItemById, removeItemFromCalendar]
  )

  const completeTaskFromFS = useCallback(
    async ({ id }, completionFocusSession) => {
      completeTask({ id }, { inFocusSession: true }, completionFocusSession)
      dispatch(endSession(completionFocusSession))
    },
    [completeTask, dispatch]
  )

  const showTaskCreationAlert = useCallback(() => {
    if (taskCreationAlertTimeout.current) {
      clearTimeout(taskCreationAlertTimeout.current)
      setIsTaskCreationAlertShown(false)
    }
    setIsTaskCreationAlertShown(() => {
      taskCreationAlertTimeout.current = setTimeout(() => setIsTaskCreationAlertShown(false), 1800)
      return true
    })
  }, [])

  const uncompleteTask = useCallback(
    async (id) => {
      let task = clone(findItemById(id))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const updatedTask = produce(task, (draft) => {
        draft.completionTime = ''
        draft.completed = 0
      })

      dispatch(updateItem(updatedTask))

      await dispatch(toggleCompleteTaskRequest(id, 0))
    },
    [dispatch, findItemById]
  )

  const createInlineTask = useCallback(
    async ({ context, dontShowCreationAlert, task }) => {
      let sprint

      if (task.sprintInfo) {
        sprint = clone(findItemById(task.sprintInfo?.id))

        if (!sprint) {
          console.warn('-- sprint not found')
        }
      }

      if (!task.id) {
        task = utils.ids.addIdToItem(task, models.item.type.TASK, session.id)
      }

      if (sprint) {
        sprint.tasks = (sprint.tasks || []).concat(task)
        dispatch(updateItem(sprint))
      } else {
        dispatch(addItem(task))
      }

      if (context?.componentSource === 'inlineAddTask' && !dontShowCreationAlert) {
        showTaskCreationAlert()
      }

      try {
        const { id, ...dbTask } = task
        const response = await taskApi.create(dbTask, context)

        if (!response.taskActions || !response.taskActions.length) return

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        if (response.taskActions.length > 1) {
          console.error('response of inline task has more than 1 task')
        }

        const createdTask = response.taskActions[0].task

        if (createdTask.id !== task.id) {
          if (sprint) {
            const idx = (sprint.tasks || []).findIndex((sprintTask) => sprintTask.id === task.id)
            if (idx > -1) {
              sprint = produce(sprint, (draft) => {
                draft.tasks[idx] = createdTask
              })
              dispatch(updateItem(sprint))
            }
          } else {
            dispatch(replaceItem(task, createdTask))
          }
        }

        // we need to repair the ranks
        utils.resolve.awaitOnce(`taskApi.repairActive`, taskApi.repairActive, 5000)

        return response
      } catch (err) {
        if (!err.cancel) {
          dispatch(handleAPIError(err, { task }))
        }
        return { error: get(err, 'data.code') }
      }
    },
    [dispatch, findItemById, showTaskCreationAlert, session.id]
  )

  const addRecurrenceToSingleSprint = useCallback(
    (sprint) => {
      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        sprint,
        sprint.recurrencyInformation.recurrencyDetails
      )
      const sprints = utils.recurrency.sprints.scheduleNextSprints(recurringSprint)

      if (!sprints) return { recurringSprint }

      const [firstInstance] = sprints

      if (firstInstance && sprint.tasks) {
        let firstInstanceTasks = utils.sprint.remapSprintTasksWithId(sprint.tasks, firstInstance.id)
        firstInstanceTasks = firstInstanceTasks.map((task) => {
          task.pin = undefined
          return task
        })

        firstInstance.tasks = firstInstanceTasks
      }

      dispatch(addItems(sprints))
      return { recurringSprint, sprints }
    },
    [dispatch, session.id]
  )

  const createRecurrentSprint = useCallback(
    async (toCreateSprint) => {
      const result = addRecurrenceToSingleSprint(toCreateSprint)

      if (!result) return

      const { recurringSprint, sprints } = result
      const [firstInstance] = sprints

      try {
        toCreateSprint.creationTime = recurringSprint.creationTime
        const out = await sprintApi.create(toCreateSprint)

        if (!out.instances) return out

        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== sprints.length || firstResponseInstance.id !== firstInstance.id) {
          dispatch(replaceItems(sprints, out.instances))
        }

        return out
      } catch (err) {
        dispatch(handleAPIError(err, { sprint: toCreateSprint }))
        return { error: get(err, 'data.code') }
      }
    },
    [addRecurrenceToSingleSprint, dispatch]
  )

  const createSingleInstanceSprint = useCallback(
    async (toCreateSprint) => {
      const sprint = utils.ids.addIdToItem(toCreateSprint, models.item.type.SPRINT, session.id)
      sprint.tasks = utils.sprint.remapSprintTasksWithId(sprint.tasks || [], sprint.id)

      if (sprint.tasks) {
        sprint.tasks = sprint.tasks.map((task) => {
          task.pin = undefined
          return task
        })
      }

      dispatch(addItem(sprint))

      try {
        const out = await sprintApi.create(sprint)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
        let result = sprint

        if (out && out.sprint && out.sprint.id !== sprint.id) {
          const newSprint = out.sprint
          newSprint.tasks = utils.sprint.remapSprintTasksWithId(sprint.tasks, newSprint.id)
          dispatch(replaceItem(sprint, newSprint))
          result = newSprint
        }

        return result
      } catch (err) {
        dispatch(handleAPIError(err, { sprint }))
        return { error: get(err, 'data.code') }
      }
    },
    [dispatch, session.id]
  )

  const createSprint = useCallback(
    async (toCreateSprint) => {
      let result

      if (toCreateSprint.recurrencyInformation?.recurrencyDetails) {
        result = await createRecurrentSprint(toCreateSprint)
      } else {
        result = await createSingleInstanceSprint(toCreateSprint)
      }

      return result
    },
    [createRecurrentSprint, createSingleInstanceSprint]
  )

  const deleteTask = useCallback(
    async (taskId) => {
      const task = findItemById(taskId)

      if (!task) {
        console.warn('-- task not found')
        return
      }

      dispatch(removeItem(task))

      try {
        await taskApi.del(task.id)
        RealTime.publishMessage(task.id, [models.realtime.topics.itemDelete])
      } catch (err) {
        dispatch(handleAPIError(err, { task }))
      }
    },
    [dispatch, findItemById]
  )

  const deleteFocusSession = useCallback(
    async (focusSession) => {
      const task = findItemById(focusSession.taskId)
      removeItemFromCalendar(focusSession.id)

      if (!task) {
        console.warn('-- task not found')
      } else {
        const updatedTask = clone(task)
        updatedTask.focusSessions = updatedTask.focusSessions.filter((fs) => fs.id !== focusSession.id)
        dispatch(updateItem(updatedTask))

        if (task && session.focusSession?.taskId === focusSession.taskId) {
          dispatch(updateFocusSessionTask(updatedTask))
        }
      }

      try {
        await focussessionApi.del(focusSession.id)
      } catch (err) {
        dispatch(handleAPIError(err, { focusSession }))
      }
    },
    [dispatch, findItemById, removeItemFromCalendar, session.focusSession?.taskId]
  )

  const deleteSprint = useCallback(
    async (sprintId, recurrenceOption) => {
      let sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }

      if (!recurrenceOption || recurrenceOption === sprintRecurrenceEditOptions.SINGLE) {
        dispatch(removeItem(sprint))
      } else {
        const [recurrenceId, sprintDateStr] = sprintId.split('_')
        const sprintsToRemove = []
        const sprintsToUpdate = []

        if (recurrenceOption === sprintRecurrenceEditOptions.NEXT) {
          const sprintDate = moment(sprintDateStr)

          sprintsById.forEach((currentSprintId) => {
            if (!currentSprintId.includes(recurrenceId)) return

            const [, currentSprintDateStr] = currentSprintId.split('_')
            const currentSprintDate = moment(currentSprintDateStr)

            let currentSprint = findItemById(currentSprintId)
            if (currentSprint) {
              if (currentSprintDate.isSameOrAfter(sprintDate)) {
                if (currentSprint) {
                  sprintsToRemove.push(currentSprint)
                }
              } else {
                currentSprint = produce(currentSprint, (draft) => {
                  draft.recurrencyInformation = undefined
                })
                sprintsToUpdate.push(currentSprint)
              }
            }
          })
        } else {
          sprintsById.forEach((currentSprintId) => {
            if (!currentSprintId.includes(recurrenceId)) return

            const currentSprint = findItemById(currentSprintId)

            if (currentSprint) {
              sprintsToRemove.push(currentSprint)
            }
          })
        }

        dispatch(replaceItems(sprintsToRemove, sprintsToUpdate))
      }

      try {
        await sprintApi.del(sprintId, recurrenceOption)
        RealTime.publishMessage(sprintId, [models.realtime.topics.itemDelete])
      } catch (err) {
        dispatch(handleAPIError(err, { sprintId }))
      }
    },
    [dispatch, findItemById, sprintsById]
  )

  const _closePopup = useCallback(() => {
    dispatch(closePopup())
  }, [dispatch])

  const openLoadingPopup = useCallback(() => {
    dispatch(
      openPopup({
        centeredTitle: true,
        component: <Loading text={'Stay still! We are generating your instances'} />,
        hideLogo: true,
      })
    )
  }, [dispatch])

  const recurringSprintPopup = useCallback(
    ({ hideAllOption, hideSingleOption, title } = {}, { onCancelled, onConfirmed } = {}) => {
      const onConfirm = (optionChecked) => {
        _closePopup()
        onConfirmed?.(optionChecked)
      }

      const onCancel = () => {
        onCancelled?.()
        _closePopup()
      }

      dispatch(
        openPopup({
          centeredTitle: true,
          component: (
            <RecurringSprintPanel
              hideAllOption={hideAllOption}
              hideSingleOption={hideSingleOption}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          ),
          logo: 'happy',
          title,
          onClose: onCancel,
        })
      )
    },
    [dispatch, _closePopup]
  )

  const updateLocalSprintFromEdit = useCallback(
    (newSprint, oldSprint) => {
      const newTasksInSprint = differenceBy(newSprint.tasks || [], oldSprint.tasks || [], 'id')

      if (newTasksInSprint.length) {
        // need to do this because tasks in newTasksInSprint don't have a when date
        const tasksInStateToBeRemoved = newTasksInSprint.reduce((taskList, taskToRemove) => {
          const task = findItemById(taskToRemove.id)

          if (task) {
            taskList.push(task)
          }

          return taskList
        }, [])

        dispatch(removeItems(tasksInStateToBeRemoved))
      }

      const removedTasksFromSprint = differenceBy(oldSprint.tasks || [], newSprint.tasks || [], 'id')

      if (removedTasksFromSprint.length) {
        const tasksToBeAdded = removedTasksFromSprint.map((taskToRemove) =>
          produce(taskToRemove, (draft) => {
            draft.when.date = moment(newSprint.pin.time).format('YYYY-MM-DD')
            delete draft.sprintInfo
          })
        )

        dispatch(addItems(tasksToBeAdded))
      }

      dispatch(updateItem(newSprint))
    },
    [dispatch, findItemById]
  )

  const updateSprintWithTasksAndRecurrencyInfoOfSeries = useCallback(
    (instances, newSprint) => {
      const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)

      if (!splittedInstances) return null

      const { before, instance, after } = splittedInstances

      const updatedBeforeInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })
      })

      dispatch(replaceItems(before, updatedBeforeInstances))

      const recurringSprint = utils.recurrency.sprints.computeRecurringSprint(
        session.id,
        newSprint,
        newSprint.recurrencyInformation.recurrencyDetails
      )

      const sprints = utils.recurrency.sprints.scheduleNextSprintsForDay(newSprint.when.date, recurringSprint)

      if (!sprints) return { createdRecurringSprint: recurringSprint }

      const { filledInstances: updatedInstances, toUpdateTasks } = utils.recurrency.sprints.fillNewInstancesWithTasks(
        sprints,
        after,
        newSprint.tasks
      )
      const oldRecItems = [instance, ...after]
      dispatch(replaceItems(oldRecItems, updatedInstances))
      dispatch(updateItems(toUpdateTasks))

      return { createdRecurringSprint: recurringSprint, createdInstances: updatedInstances }
    },
    [dispatch, session.id]
  )

  const updateSprintWithTasksWhenRecurrencyDetailsAreSame = useCallback(
    (instances, newSprint, oldSprint, recurrenceOption) => {
      const hasWhenDateChanged = newSprint.when.date !== oldSprint.when.date

      switch (true) {
        case recurrenceOption === sprintRecurrenceEditOptions.SINGLE: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
          return
        }
        case hasWhenDateChanged: {
          return updateSprintWithTasksAndRecurrencyInfoOfSeries(instances, newSprint)
        }

        case recurrenceOption === sprintRecurrenceEditOptions.ALL ||
          recurrenceOption === sprintRecurrenceEditOptions.NEXT: {
          if (utils.sprint.areSprintAttributesNotEqual(newSprint, oldSprint)) {
            let instancesToUpdate = instances

            if (recurrenceOption === sprintRecurrenceEditOptions.NEXT) {
              const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)
              instancesToUpdate = splittedInstances.after
            }

            const newPinMoment = moment(newSprint.pin.time)

            const updatedInstances = produce(instancesToUpdate, (draft) => {
              draft.forEach((instance) => {
                if (instance.id === newSprint.id) return

                instance.title = newSprint.title
                instance.estimatedTime = newSprint.estimatedTime
                instance.pin.time = moment(instance.pin.time).set({
                  hour: newPinMoment.hour(),
                  minute: newPinMoment.minute(),
                  second: newPinMoment.second(),
                })
              })
            })

            dispatch(updateItems(updatedInstances))
            updateLocalSprintFromEdit(newSprint, oldSprint)
          } else {
            updateLocalSprintFromEdit(newSprint, oldSprint)
          }
          break
        }

        // single option
        default: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
        }
      }
    },
    [dispatch, updateLocalSprintFromEdit, updateSprintWithTasksAndRecurrencyInfoOfSeries]
  )

  const updateSprintAndRemoveRecurrencyOfSeries = useCallback(
    (instances, newSprint, oldSprint) => {
      const splittedInstances = utils.recurrency.sprints.splitInstances(instances, newSprint.id)

      if (!splittedInstances) return null

      const { before, after } = splittedInstances

      const updatedInstances = produce(before, (draft) => {
        draft.forEach((instance) => {
          delete instance.recurrencyInformation
        })

        draft.push(newSprint)
      })

      dispatch(updateItems(updatedInstances))
      dispatch(removeItems(after))
      updateLocalSprintFromEdit(newSprint, oldSprint)
    },
    [dispatch, updateLocalSprintFromEdit]
  )

  const updateRecurringSprintInstanceWithTasks = useCallback(
    (newSprint, oldSprint, recurrenceOption) => {
      const instances = getAllInstancesOfRecSprint(
        sprintsById,
        findItemById,
        utils.sprint.getRecSprintId(oldSprint),
        true
      )

      switch (true) {
        case !newSprint.recurrencyInformation: {
          return updateSprintAndRemoveRecurrencyOfSeries(instances, newSprint, oldSprint)
        }

        case utils.recurrency.options.isRecurrenceEqual(
          oldSprint.recurrencyInformation?.recurrencyDetails,
          newSprint.recurrencyInformation?.recurrencyDetails
        ): {
          return updateSprintWithTasksWhenRecurrencyDetailsAreSame(instances, newSprint, oldSprint, recurrenceOption)
        }

        default: {
          return updateSprintWithTasksAndRecurrencyInfoOfSeries(instances, newSprint)
        }
      }
    },
    [
      findItemById,
      sprintsById,
      updateSprintAndRemoveRecurrencyOfSeries,
      updateSprintWithTasksAndRecurrencyInfoOfSeries,
      updateSprintWithTasksWhenRecurrencyDetailsAreSame,
    ]
  )

  const editSprint = useCallback(
    async (sprint, { recurrenceOption } = {}) => {
      const newSprint = clone(sprint)
      let oldSprint = findItemById(newSprint.id)

      newSprint.tasks?.forEach?.(function (task, index) {
        if (get(task, 'pin.time')) {
          task.pin = undefined
          this[index] = task
        }
      }, sprint.tasks)

      if (!oldSprint) {
        console.warn('-- sprint not found')
        return
      }

      let createdInstances
      switch (true) {
        case !!oldSprint.recurrencyInformation: {
          const result = updateRecurringSprintInstanceWithTasks(newSprint, oldSprint, recurrenceOption)
          if (!!result) {
            // to make sure the new id generated is the same as the one the front end generated
            newSprint.creationTime = result?.createdRecurringSprint.creationTime
            createdInstances = result?.createdInstances
          }
          break
        }

        case !oldSprint.recurrencyInformation && !!newSprint.recurrencyInformation: {
          dispatch(removeItem(oldSprint))
          const result = addRecurrenceToSingleSprint(newSprint)
          const { recurringSprint: createdRecurringSprint, sprints } = result
          // to make sure the new id generated is the same as the one the front end generated
          newSprint.creationTime = createdRecurringSprint.creationTime
          createdInstances = sprints
          break
        }

        default: {
          updateLocalSprintFromEdit(newSprint, oldSprint)
          break
        }
      }

      try {
        const out = await sprintApi.editWithTasks(newSprint, { recurrenceOption })
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])

        if (!out?.instances) return

        const [firstResponseInstance] = out.instances

        if (out.instances.length !== createdInstances?.length || firstResponseInstance.id !== createdInstances[0].id) {
          dispatch(replaceItems(createdInstances, out.instances))
        }
      } catch (err) {
        dispatch(handleAPIError(err, { sprint }))
      }
    },
    [
      dispatch,
      findItemById,
      updateRecurringSprintInstanceWithTasks,
      addRecurrenceToSingleSprint,
      updateLocalSprintFromEdit,
    ]
  )

  const updateFocusSession = useCallback(
    (updatedFocusSession) => {
      updateCalendarItem(updatedFocusSession.id, updatedFocusSession)

      const indexInFocusedLine =
        updatedFocusSession.taskId === session?.focusSession?.taskId
          ? session?.focusSession?.task?.focusSessions?.findIndex?.((fs) => fs.id === updatedFocusSession.id)
          : undefined

      if (indexInFocusedLine !== undefined && indexInFocusedLine !== -1) {
        const fsTask = { ...session.focusSession.task }
        const fsTaskSessions = [...fsTask.focusSessions]
        fsTaskSessions[indexInFocusedLine] = updatedFocusSession
        fsTask.focusSessions = fsTaskSessions
        dispatch(updateFocusSessionTask(fsTask))
      }

      const task = clone(findItemById(updatedFocusSession.taskId))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const idx = task.focusSessions.findIndex((fs) => fs.id === updatedFocusSession.id)

      if (idx < 0) {
        console.warn('-- focus session not found')
        return
      }

      task.focusSessions = [...task.focusSessions]
      task.focusSessions[idx] = updatedFocusSession
      dispatch(updateItem(task))

      // TODO: backend update here, decouple from FS's popup
    },
    [dispatch, findItemById, session.focusSession?.task, session.focusSession?.taskId, updateCalendarItem]
  )

  const rescheduleTasksToNextActiveInstance = useCallback(
    (recSprintId, pastSprintId, tasks) => {
      let activeInstances = getAllInstancesOfRecSprint(sprintsById, findItemById, recSprintId, true)
      activeInstances = sortListByScheduledTime(activeInstances)
      if (activeInstances.length > 0) {
        for (let instance of activeInstances) {
          if (instance.id !== pastSprintId) {
            const nextScheduledInstance = produce(instance, (draft) => {
              draft.tasks = tasks
            })
            dispatch(updateItem(nextScheduledInstance))
            break
          }
        }
      }
    },
    [dispatch, findItemById, sprintsById]
  )

  const endSprint = useCallback(
    async (sprintId) => {
      const sprint = findItemById(sprintId)

      if (!sprint) {
        console.warn('-- sprint not found')
        return
      }
      const tasks = sprint.tasks || []

      const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(sprint)
      const updatedEndedSprint = produce(sprint, (draft) => {
        draft.completionTime = completionTime
        draft.estimatedTime = estimatedTime
        draft.tasks = undefined
      })

      dispatch(updateItem(updatedEndedSprint))

      if (tasks?.length > 0) {
        if (utils.sprint.isRecurrent(sprint)) {
          rescheduleTasksToNextActiveInstance(utils.sprint.getRecSprintId(sprint), sprint.id, tasks)
        } else {
          const tasksToBeAdded = tasks.map((taskToUpdate) =>
            produce(taskToUpdate, (draft) => {
              draft.when.date = moment(sprint.pin.time).format('YYYY-MM-DD')
              delete draft.sprintInfo
            })
          )
          dispatch(addItems(tasksToBeAdded))
        }
      }

      try {
        await sprintApi.end(sprint.id)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err, { sprintId: sprint?.id }))
      }
    },
    [dispatch, findItemById, rescheduleTasksToNextActiveInstance]
  )

  const getFocusedTaskId = useCallback(() => {
    return !session.hideFocusedTask && utils.session.getFSTaskIdFromSession(session)
  }, [session])

  const saveTask = useCallback(
    async (updatedTask) => {
      const oldTask = findItemById(updatedTask.id)

      if (!oldTask) {
        console.warn('-- task not found')
        return
      }

      const updatedItems = dispatch(updateItem(updatedTask))
      const oldSprint = updatedItems.sprints[oldTask?.sprintInfo?.id]

      if (oldSprint) {
        const now = moment()

        if (utils.sprint.shouldCompleteSprint(oldSprint, now)) {
          const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(oldSprint)

          const updatedOldSprint = produce(oldSprint, (draft) => {
            draft.completionTime = completionTime
            draft.estimatedTime = estimatedTime
          })

          dispatch(updateItem(updatedOldSprint))
        }
      }

      try {
        await taskApi.putFullTask(updatedTask)
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err))
      }
    },
    [dispatch, findItemById]
  )

  const startFsAndCreateTask = useCallback(
    async ({
      pageSource,
      taskData = models.task({
        title: '',
        when: { date: moment().format('YYYY-MM-DD') },
        to: utils.user.computeUserFromSessionUser(session.user),
      }),
    }) => {
      const creatingTask = utils.ids.addIdToItem(taskData, models.item.type.TASK, session.id)

      dispatch(addItem(creatingTask))

      await dispatch(
        createTaskAndFs(creatingTask, pageSource, (createdTask) => {
          if (createdTask.id !== creatingTask.id) {
            dispatch(replaceItem(creatingTask, createdTask))
          }
        })
      )
    },
    [dispatch, session.user, session.id]
  )

  const deletionPopup = useCallback(
    ({ cancelLabel, confirmLabel, text, title }, { onCancelled, onConfirmed } = {}) => {
      const onConfirm = () => {
        onConfirmed?.()
        dispatch(closePopup())
      }

      const onCancel = () => {
        onCancelled?.()
        dispatch(closePopup())
      }

      dispatch(
        openPopup({
          title,
          centeredTitle: true,
          logo: 'sad',
          component: (
            <ConfirmPanel
              cancelLabel={cancelLabel}
              confirmLabel={confirmLabel}
              onCancel={onCancel}
              onConfirm={onConfirm}
              text={text}
            />
          ),
        })
      )
    },
    [dispatch]
  )

  const focusSessionDeletePopup = useCallback(
    (callbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.general.noKeep,
          confirmLabel: translations.general.yesPlease,
          text: translations.focussession.delete.prompt,
          title: translations.focussession.delete.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const taskDeletePopUp = useCallback(
    (callbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.taskPanel.delete.deny,
          confirmLabel: translations.taskPanel.delete.consent,
          text: translations.taskPanel.delete.prompt,
          title: translations.taskPanel.delete.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const sprintDeletePopup = useCallback(
    (sprint, callbacks) => {
      if (sprint.recurrencyInformation) {
        recurringSprintPopup({ title: translations.sprint.recurrencyPanel.delete.prompt }, callbacks)
      } else {
        deletionPopup(
          {
            cancelLabel: translations.sprint.delete.deny,
            confirmLabel: translations.sprint.delete.consent,
            text: translations.sprint.delete.prompt,
            title: translations.sprint.delete.prompt,
          },
          callbacks
        )
      }
    },
    [deletionPopup, recurringSprintPopup]
  )

  const addedLinksFSDeletePopup = useCallback(
    (callbacks) => {
      deletionPopup(
        {
          cancelLabel: translations.focussession.deleteOnEditConfirmation.cancel,
          confirmLabel: translations.focussession.deleteOnEditConfirmation.consent,
          text: translations.focussession.deleteOnEditConfirmation.prompt,
          title: translations.focussession.deleteOnEditConfirmation.prompt,
        },
        callbacks
      )
    },
    [deletionPopup]
  )

  const onClickFocusSession = useCallback(
    (fs) => {
      dispatch(setHighlightedEventId(fs.id))
      dispatch(updateCalendarDate(fs.startTime))
    },
    [dispatch]
  )

  const onClickDeleteFocusSession = useCallback(
    ({ focusSession, callback }) => {
      focusSessionDeletePopup({
        onConfirmed: () => {
          deleteFocusSession(focusSession)
          callback?.()
        },
        onCancelled: () => {
          callback?.()
        },
      })
    },
    [deleteFocusSession, focusSessionDeletePopup]
  )

  const onClickSprint = useCallback(
    (sprint) => {
      dispatch(setHighlightedEventId(sprint.id))
      dispatch(updateCalendarDate(sprint.pin.time))
    },
    [dispatch]
  )

  const onClickOutsideSprint = useCallback(
    (sprint) => {
      if (calendarHighlightedEventId === sprint.id) {
        dispatch(setHighlightedEventId(''))
      }
    },
    [calendarHighlightedEventId, dispatch]
  )

  const onTaskDroppedInSprint = useCallback(
    async ({ destinationIndex = 0, sprintId, taskId }) => {
      const targetSprint = clone(findItemById(sprintId))
      const task = clone(findItemById(taskId))

      if (!targetSprint || !task) {
        console.warn(`-- ${targetSprint ? 'task' : 'sprint'} not found`)
        return
      }

      const rank = destinationIndex + 1
      const newSprintInfo = {
        id: targetSprint.id,
        title: targetSprint.title,
        estimatedTime: targetSprint.estimatedTime,
        pin: targetSprint.pin,
        rank,
      }

      const updatedTask = utils.task.computeTaskOnChange(task, {
        paramName: 'sprintInfo',
        value: newSprintInfo,
      })

      const updatedItems = dispatch(updateItem(updatedTask))
      const oldSprint = updatedItems.sprints[task?.sprintInfo?.id]
      const sprint = updatedItems.sprints[updatedTask?.sprintInfo?.id]

      if (oldSprint) {
        const now = moment()

        if (utils.sprint.shouldCompleteSprint(oldSprint, now)) {
          const { completionTime, estimatedTime } = utils.sprint.getCompletionTimeAndEstimatedTime(oldSprint)
          oldSprint.completionTime = completionTime
          oldSprint.estimatedTime = estimatedTime
          dispatch(updateItem(oldSprint))
        }
      }

      try {
        await sprintApi.dragAndDropTasks({
          taskId: task.id,
          sprintId: sprint.id,
          toRank: rank,
        })
        RealTime.publishMessage('', [models.realtime.topics.taskSchedule])
      } catch (err) {
        dispatch(handleAPIError(err, { sprint }))
      }
    },
    [dispatch, findItemById]
  )

  const onChangeTaskParam = useCallback(
    ({ taskId, task }, { paramName, value, method = 'change' }, extraParams, forceRequest) => {
      if (!task) {
        let taskFromList = findItemById(taskId)

        if (!taskFromList) {
          console.warn('-- task not found')
          return
        }

        task = taskFromList
      } else {
        taskId = task.id
      }

      const newTask = utils.task.computeTaskOnChange(
        task,
        {
          paramName,
          value,
          method,
        },
        extraParams
      )

      dispatch(updateItem(newTask))

      if (task || forceRequest) {
        sendPatchRequest({ extraParams, method, paramName, task, value })
      }
    },
    [dispatch, findItemById, sendPatchRequest]
  )

  const onTitleChange = useCallback(
    (title, task) => {
      const focusedTaskId = getFocusedTaskId()

      if (focusedTaskId && task.id === focusedTaskId) {
        dispatch(updateFocusSessionTitle(title))
      }

      onChangeTaskParam({ task }, { paramName: 'title', value: title }, null, true)
    },
    [dispatch, getFocusedTaskId, onChangeTaskParam]
  )

  const updateTaskWithCallback = useCallback(
    (taskId, callback) => {
      const task = clone(findItemById(taskId))

      if (!task) {
        console.warn('-- task not found')
        return
      }

      const updatedTask = callback(task)
      dispatch(updateItem(updatedTask))
    },
    [dispatch, findItemById]
  )

  const _handleCompletedSession = useCallback(
    async (sessionDiffs) => {
      const completeSessionAndDiscard = (discardSession, focusSessionsUpdateFunc) => {
        dispatch(
          handleCompletedSession({ ...sessionDiffs, discardSession }, (updated) => {
            const { completedSession, completedSessionTask } = updated
            updateTaskWithCallback(completedSessionTask.id, (task) => ({
              ...task,
              focusSessions: focusSessionsUpdateFunc(task.focusSessions || [], completedSession),
              title: completedSessionTask.title,
              urlsInfo: completedSessionTask.urlsInfo,
            }))
          })
        )
      }

      const spentTimeNS = utils.focussession.getSpentTimeInNanoSeconds(sessionDiffs.updatedSession)
      const wasSessionDiscarded = !!sessionDiffs.updatedSession?.discarded

      const removeFS = (focusSessions, completedSession) => {
        return focusSessions.filter((fs) => fs.id !== completedSession.id)
      }

      const updateFS = (focusSessions, completedSession) => {
        if (focusSessions.length === 0) {
          return focusSessions.concat([completedSession])
        }

        const updatedSessions = [...focusSessions]
        const toUpdateIndex = focusSessions.findIndex((fs) => fs.id === completedSession.id)

        if (toUpdateIndex !== -1) {
          updatedSessions[toUpdateIndex] = completedSession
        }

        return updatedSessions
      }

      const noop = (fs) => fs

      if (!wasSessionDiscarded && spentTimeNS < utils.focussession.minDurationFSNS) {
        addedLinksFSDeletePopup({
          onConfirmed: () => {
            completeSessionAndDiscard(true, removeFS)
          },
        })
      } else {
        completeSessionAndDiscard(false, wasSessionDiscarded ? noop : updateFS)
      }
    },
    [addedLinksFSDeletePopup, dispatch, updateTaskWithCallback]
  )

  const _popShortcutsGroup = useCallback(
    (...args) => {
      dispatch(popShortcutsGroup(...args))
    },
    [dispatch]
  )

  const _pushShortcutsGroup = useCallback(
    (...args) => {
      dispatch(pushShortcutsGroup(...args))
    },
    [dispatch]
  )

  const onClickDelete = useCallback(
    (taskId, callback) => {
      taskDeletePopUp({
        onConfirmed: () => {
          deleteTask(taskId)
          callback?.()
        },
      })
    },
    [deleteTask, taskDeletePopUp]
  )

  return {
    allItems,
    addedLinksFSDeletePopup,
    addItemToCalendar,
    archiveTask,
    calendarHighlightedEventId,
    completeTask,
    completeTaskFromFS,
    createInlineTask,
    createSprint,
    deleteFocusSession,
    deleteSprint,
    deleteTask,
    deletionPopup,
    editSprint,
    endSprint,
    findItemById,
    focusSessionDeletePopup,
    getFocusedTaskId,
    handleCompletedSession: _handleCompletedSession,
    isTaskCreationAlertShown,
    onClickDelete,
    onClickFocusSession,
    onClickOutsideSprint,
    onClickSprint,
    onClickDeleteFocusSession,
    onTaskDroppedInSprint,
    onTitleChange,
    popShortcutsGroup: _popShortcutsGroup,
    pushShortcutsGroup: _pushShortcutsGroup,
    removeItemFromCalendar,
    saveTask,
    sendPatchRequest,
    showTaskCreationAlert,
    sprintDeletePopup,
    sprints,
    sprintTasks,
    startFsAndCreateTask,
    taskDeletePopUp,
    uncompleteTask,
    updateCalendarItem,
    updateFocusSession,
    recurringSprintPopup,
    rescheduleTasksToNextActiveInstance,
    openLoadingPopup,
    closePopup: _closePopup,
    updateRecurringSprintInstanceWithTasks,
  }
}

export const withPageActions = (Component) => {
  return (props) => {
    const actions = usePageActions()

    return <Component {...props} {...actions} />
  }
}
