import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import parser from 'react-html-parser'

import { styles } from 'gipsy-misc'

import helperIcon from 'assets/gipsybot.svg'
import Icon from 'Icon'
import ReusableButton from 'ReusableButton'

export const StepHelper = React.forwardRef(function StepHelper(
  {
    animate,
    continueButtonText,
    currentStep,
    exit,
    helperText,
    horizontal,
    iconRight,
    initial,
    key,
    onContinue,
    showContinueButton,
    style,
    totalSteps = 5,
    whileHover,
  },
  ref
) {
  const renderStepsIndicators = () =>
    Array(totalSteps)
      .fill(null)
      .map((_, index) => <StepIndicator active={index + 1 <= currentStep} key={index} />)

  return (
    <Container
      animate={animate}
      exit={exit}
      initial={initial}
      key={key}
      ref={ref}
      style={style}
      whileHover={whileHover}>
      <Content iconRight={iconRight} horizontal={horizontal}>
        <Tail fill='white' icon='Tail' />
        <HelperIcon src={helperIcon} />
        <Text>{parser(helperText)}</Text>
        <RightContainer>
          {showContinueButton && (
            <ReusableButton borderRadius={8} height={26} onClick={onContinue} width={100}>
              {continueButtonText}
            </ReusableButton>
          )}
          <StepIndicators>{renderStepsIndicators()}</StepIndicators>
        </RightContainer>
      </Content>
    </Container>
  )
})

export const Container = styled(motion.div)`
  position: absolute;
`

Container.displayName = 'Container'

const Text = styled.div`
  margin-bottom: 10px;
  margin-left: 45px;
`

Text.displayName = 'Text'

const RightContainer = styled.div`
  display: flex;
  flex-flow: column;
`

RightContainer.displayName = 'RightContainer'

const StepIndicator = styled.div`
  background: ${({ active }) => (active ? styles.colors.primaryColor : styles.colors.middleGrey)};
  border-radius: 50px;
  height: 5px;
  margin-right: 3px;
  width: 5px;
`

StepIndicator.displayName = 'StepIndicator'

const StepIndicators = styled.div`
  display: flex;
  margin: 0 auto;

  ${StepIndicator}:last-of-type {
    margin-right: 0;
  }
`

StepIndicators.displayName = 'StepIndicators'

const HelperIcon = styled.img`
  height: 55px;
  left: 4px;
  position: absolute;
  top: -16px;
  width: 55px;
`

HelperIcon.displayName = 'HelperIcon'

const Content = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: ${styles.shadows.popup};
  display: flex;
  flex-flow: column;
  padding: 12px 20px;
  position: relative;

  img {
    max-height: none;
    max-width: none;
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      flex-flow: row;

      ${Text} {
        margin-bottom: 0;
        margin-right: 16px;
      }

      ${StepIndicators} {
        margin: auto 0 0 auto;
      }
    `}

  ${({ iconRight, horizontal }) =>
    iconRight &&
    css`
      padding-right: ${horizontal ? 20 : 65}px;

      ${HelperIcon} {
        left: unset;
        right: 4px;
      }

      ${Text} {
        margin-left: 0;
      }

      ${RightContainer} {
        margin-right: ${horizontal ? 45 : 0}px;
        margin-left: ${horizontal ? 0 : 'auto'};
      }
    `}
`

Content.displayName = 'Content'

export const Tail = styled(Icon)`
  height: 16px;
  left: 133px;
  position: absolute;
  top: -10px;
  width: 24px;
`

Tail.displayName = 'Tail'
