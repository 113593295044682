import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { FocusedLineCompressedConsts } from 'gipsy-ui'

import { PageContainer } from 'features/auth/components'
import HomeBaseFocusedLine from 'features/focusSession/components/line'
import usePageActions from 'features/hooks/usePageActions2'
import Survey from 'pages/onboarding/steps/Survey'
import CreateFocusBlock from 'pages/onboarding/steps/CreateFocusBlock'
import ShowCalendar from 'pages/onboarding/steps/ShowCalendar'
import AddTasks from 'pages/onboarding/steps/AddTasks'

export const fromOnboarding = 'fromOnboarding'
export const totalSteps = 5
export const showFocusBlockStep = 4

const { containerHeight: focusedLineHeight } = FocusedLineCompressedConsts
const surveyStep = 0
const createFocusBlockStep = 1
const calendarStep = 2
const addTasksStep = 3

export default function Onboarding() {
  const history = useHistory()
  const { createInlineTask, deleteTask, findItemById, saveTask, startFsAndCreateTask } = usePageActions()
  const session = useSelector((state) => state.session)
  const user = session.user

  const [createdSprintData, setCreatedSprintData] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [showFocusSession, setShowFocusSession] = useState(false)

  const handleStepDone = (stepNumber, ...args) => {
    if (stepNumber === calendarStep) {
      setCreatedSprintData(...args)
    } else if (stepNumber === addTasksStep) {
      const onboardingState = { currentStep: stepNumber + 1, fromOnboarding: true, onboardingSprint: args[0] }
      history.push('/', onboardingState)
      return
    }

    setCurrentStep(++stepNumber)
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      case surveyStep: {
        return (
          <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} key={'survey'}>
            <Survey onStepDone={handleStepDone} stepNumber={surveyStep} user={user} />
          </motion.div>
        )
      }

      case createFocusBlockStep: {
        return (
          <motion.div exit={{ transition: { duration: 0.5 } }} key={'startFocus'}>
            <CreateFocusBlock
              onStepDone={handleStepDone}
              startFsAndCreateTask={startFsAndCreateTask}
              stepNumber={createFocusBlockStep}
              user={user}
            />
          </motion.div>
        )
      }

      case calendarStep: {
        return (
          <FullHeightStepContainer
            animate={{ opacity: 1, transition: { delay: 1 } }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            key={'calendar'}>
            <ShowCalendar onStepDone={handleStepDone} stepNumber={calendarStep} user={user} />
          </FullHeightStepContainer>
        )
      }

      case addTasksStep: {
        return (
          <FullHeightStepContainer
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0, y: 25 }}
            key={'addTask'}>
            <AddTasks
              findItemById={findItemById}
              onCreateTask={createInlineTask}
              onDeleteTask={deleteTask}
              onSaveTask={saveTask}
              onStepDone={handleStepDone}
              session={session}
              sprintData={createdSprintData}
              stepNumber={addTasksStep}
            />
          </FullHeightStepContainer>
        )
      }

      default: {
        return null
      }
    }
  }

  const focusSessionVisible = currentStep === calendarStep

  return (
    <RootContainer>
      <OnboardingPageContainer showFocusSession={focusSessionVisible}>
        {focusSessionVisible && (
          <FocusedLineContainer
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            onAnimationComplete={() => setShowFocusSession(true)}>
            {showFocusSession && <HomeBaseFocusedLine completeDisabled pauseDisabled />}
          </FocusedLineContainer>
        )}
        <AnimatePresence exitBeforeEnter initial={false}>
          {renderCurrentStep()}
        </AnimatePresence>
      </OnboardingPageContainer>
    </RootContainer>
  )
}

const RootContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`

RootContainer.displayName = 'RootContainer'

const OnboardingPageContainer = styled(PageContainer)`
  padding-top: ${({ showFocusSession }) => (showFocusSession ? focusedLineHeight : 0)}px;
`

OnboardingPageContainer.displayName = 'OnboardingPageContainer'

const FocusedLineContainer = styled(motion.div)`
  background: white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`

FocusedLineContainer.displayName = 'FocusedLineContainer'

const FullHeightStepContainer = styled(motion.div)`
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
  width: 100%;
`

FullHeightStepContainer.displayName = 'FullHeightStepContainer'
