import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Transition } from 'react-transition-group'
import styled, { css } from 'styled-components'
import { transitionDurationMs } from '../sharedVariables'
import variables from 'assets/styles/variables'
import { styles, variables as miscVariables } from 'gipsy-misc'

export default function ComposerContainer(props) {
  const isDragging = useSelector((state) => state.sprintComposer.isDragging)

  const [isShown, setShown] = useState(false)
  useEffect(() => {
    if (props.isShown) {
      if (props.enterDelay) {
        setTimeout(() => setShown(props.isShown), props.enterDelay * 1.5)
      } else {
        setShown(props.isShown)
      }
    } else {
      setShown(props.isShown)
    }
  }, [props.isShown, props.enterDelay])
  return (
    <Background isDragging={isDragging} isShown={isShown}>
      <Transition in={isShown} timeout={{ enter: transitionDurationMs, exit: transitionDurationMs }}>
        {(transitionState) => <Container transitionState={transitionState}>{props.children}</Container>}
      </Transition>
    </Background>
  )
}

const getHidingTransitionStyle = (state) => {
  switch (state) {
    case 'entering': {
      return css`
        opacity: 0;
      `
    }
    case 'entered': {
      return css`
        opacity: 1;
      `
    }
    case 'exiting': {
      return css`
        transition-duration: ${transitionDurationMs / 2}ms;
        opacity: 0;
      `
    }
    case 'exited': {
      return css`
        opacity: 0;
      `
    }
    default:
      return css``
  }
}

const Background = styled.div`
  position: absolute;
  width: calc(
    100% - ${variables.taskPanelContainerWidth + variables.calendarPanelWidth + variables.calendarLateralPadding / 2}px
  );
  left: ${(props) => (props.isShown ? `${variables.taskPanelContainerWidth}px` : `-100%`)};
  z-index: ${({ isDragging }) =>
    isDragging ? miscVariables.zIndex.taskPanel + 1 : miscVariables.zIndex.sprintComposer};
  top: 0;
  bottom: 0;
  height: calc(100%);
  min-height: calc(100%);
  display: flex;
  transition: left ${styles.transitions.calendarSlide}s ease-in;
`

const Container = styled.div`
  background-color: ${styles.colors.veryLightGrey};
  width: 100%;
  height: calc(100% - 16px);
  min-height: calc(100% - 16px);
  display: flex;
  margin: 8px;
  padding-bottom: ${variables.shortcutsBarHeight}px;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0;
  transition: opacity ${transitionDurationMs}ms ease-in-out;
  ${(props) => getHidingTransitionStyle(props.transitionState)}
`
