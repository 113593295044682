import React, { useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import styled from 'styled-components'

import { styles, variables as miscVariables } from 'gipsy-misc'

import variables from 'assets/styles/variables'
import { shouldShowNavbar } from 'logic/layout'

import NavBar from './NavBar'

function SideNavBarWrapper({ show }) {
  const controls = useAnimation()
  const { hasNavigated, initialRoute } = useSelector((state) => state.layout.navigationStats)

  const navBarRef = useRef(null)

  useLayoutEffect(() => {
    controls.start({
      transition: {
        delay: styles.transitions.calendarSlide,
        duration: 0,
      },
      width: show ? navBarRef.current.clientWidth : 0,
    })

    return () => {
      controls.stop()
    }
  }, [controls, show])

  const initialAnimationState = hasNavigated ? 'exit' : shouldShowNavbar(initialRoute) ? 'enter' : 'exit'

  return (
    <WrapperContainer animate={controls}>
      <AnimatePresence>
        {show && (
          <SideNavBarContainer
            animate='enter'
            exit='exit'
            initial={initialAnimationState}
            key='sideBar'
            ref={navBarRef}
            variants={sideBarContainerVariants}>
            <NavBar />
          </SideNavBarContainer>
        )}
      </AnimatePresence>
    </WrapperContainer>
  )
}

const WrapperContainer = styled(motion.div)`
  position: static;
`

WrapperContainer.displayName = 'WrapperContainer'

const sideBarContainerVariants = {
  enter: {
    left: 0,
  },
  exit: {
    left: `-${variables.navbarWidth}px`,
    transition: {
      duration: 0.3,
    },
  },
}

const SideNavBarContainer = styled(motion.div)`
  background-color: ${styles.colors.navbarBackgroundColor};
  border-right: 1px solid ${styles.colors.borderColor};
  bottom: 0;
  height: 100%;
  padding: 24px 10px;
  position: absolute;
  width: ${variables.navbarWidth}px;
  z-index: ${miscVariables.zIndex.navbar};
`

SideNavBarContainer.displayName = 'SideNavBarContainer'

export default SideNavBarWrapper
