import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { models } from 'gipsy-misc'

import { getAccounts } from 'store/accounts/actions'
import { refetchEvents } from 'store/calendar/actions'
import { fetchAllItems, removeItem } from 'store/items/actions'
import { getFindItemByIdFn } from 'store/items/selectors'
import { doAfterUserAction, getFocusSession, getUser } from 'store/session/actions'
import { functionId } from 'store/session/utils'

import RealTime from './index'

const componentName = 'AppRealtime'

export default function AppRealtime({ children }) {
  const dispatch = useDispatch()
  const findItemById = useSelector((state) => getFindItemByIdFn(state.items))
  const session = useSelector((state) => state.session)

  useEffect(() => {
    if (!session.id) return

    const connectToRealTime = async () => {
      try {
        await RealTime.connect()
      } catch (err) {
        console.error(err)
      }
    }

    connectToRealTime()
  }, [session])

  const handleMessage = useCallback(
    (message, topic) => {
      switch (topic) {
        case models.realtime.topics.calendarList: {
          dispatch(getAccounts())
          break
        }

        case models.realtime.topics.events: {
          dispatch(
            doAfterUserAction(functionId.REFETCH_EVENTS, () => {
              dispatch(refetchEvents())
            })
          )
          break
        }

        case models.realtime.topics.taskSchedule: {
          dispatch(
            doAfterUserAction(functionId.FETCH_ALL_ITEMS, () => {
              dispatch(fetchAllItems())
            })
          )
          break
        }

        case models.realtime.topics.itemDelete: {
          const item = findItemById(message)
          item && dispatch(removeItem(item))
          break
        }

        case models.realtime.topics.user: {
          dispatch(getUser())
          break
        }

        case models.realtime.topics.focusSession: {
          dispatch(
            doAfterUserAction(functionId.GET_FOCUSSESSION, () => {
              dispatch(getFocusSession())
            })
          )
          break
        }

        default: {
          return
        }
      }
    },
    [dispatch, findItemById]
  )

  useEffect(() => {
    RealTime.addMessageListener(componentName, models.realtime.topics.calendarList, handleMessage)
    RealTime.addMessageListener(componentName, models.realtime.topics.events, handleMessage)
    RealTime.addMessageListener(componentName, models.realtime.topics.taskSchedule, handleMessage)
    RealTime.addMessageListener(componentName, models.realtime.topics.itemDelete, handleMessage)
    RealTime.addMessageListener(componentName, models.realtime.topics.user, handleMessage)
    RealTime.addMessageListener(componentName, models.realtime.topics.focusSession, handleMessage)
  }, [handleMessage])

  return children
}
