import axios from 'utils/axios'
import { utils } from 'gipsy-misc'

// params can be of the type {filter:filter}
// with filter taking as value
// active : to only have active tags
// archived : to only have archived tags
// all : to have all of them
// if there's no filter, the default is all
export const activeParam = 'active'
export const archivedParam = 'archived'

export const get = (filter) => {
  let endpoint = `/tags`
  if (filter) {
    endpoint = utils.request.appendToUrl(endpoint, { filter: filter })
  }
  return axios.get(endpoint)
}

export const create = (body) => {
  const endpoint = `/tags`
  return axios.post(endpoint, body)
}

export const edit = (body) => {
  let endpoint = `/tags/edit`
  return axios.post(endpoint, body)
}

export const del = (id) => {
  const endpoint = `/tags/${id}`
  return axios.delete(endpoint)
}

export const dragAndDropTag = (tagId, toRank) => {
  let endpoint = `/tags/drag-and-drop`
  const payload = { tagId, toRank }
  return axios.post(endpoint, payload)
}
