import React, { useRef, useEffect, useLayoutEffect, useState, Fragment } from 'react'
import styled from 'styled-components'

import { CardAttribute, IconWrapper, PlaceHolder, getColor } from './commonUIComponents'

import { styles, translations } from 'gipsy-misc'
import Icon from 'Icon'
import Tooltip from 'Tooltip'
import LineAttributeDropdown from 'LineComponents/LineAttributeDropdown'
import { DropdownSelector } from 'Project'

const dropdownOpenedWidth = '282'

export function ProjectsWrapper({ onChange, shrinked, value, allProjects, getLineBounds }) {
  const nodeRef = useRef(null)
  const fieldNodeRef = useRef(null)
  const [projectDropdownShown, setProjectDropdownShown] = useState(false)
  const [projectDropdownLeftPosition, setProjectDropdownLeftPosition] = useState(0)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (nodeRef?.current && !nodeRef.current.contains(e.target)) {
        // we also avoid hiding project when the user click on the field input
        // this logic is already handled by onWrapperClick
        if (fieldNodeRef?.current && !fieldNodeRef.current.contains(e.target)) {
          setProjectDropdownShown(false)
        }
      }
    }

    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useLayoutEffect(() => {
    if (!projectDropdownShown) {
      setProjectDropdownLeftPosition(0)
      return
    }

    setProjectDropdownLeftPosition((prevLeft) => {
      let left = prevLeft
      const lineBounds = getLineBounds?.()

      if (!nodeRef?.current || !lineBounds) return prevLeft

      const { right } = nodeRef.current.getBoundingClientRect()

      const { right: lineRightPosition } = lineBounds
      if (right > lineRightPosition) {
        left = lineRightPosition - right
      } else {
        return Math.min(prevLeft, 0)
      }

      // prevent jumping when getBoundingClientRect is off
      return Math.min(left, prevLeft)
    })
  }, [projectDropdownShown, getLineBounds])

  const onWrapperClick = () => {
    setProjectDropdownShown(!projectDropdownShown)
  }

  const addProject = (project) => {
    const data = {
      paramName: 'projects',
      value: project,
      method: 'add',
    }
    onChange(data)
  }

  const removeProject = (project) => {
    const data = {
      paramName: 'projects',
      value: project,
      method: 'remove',
    }
    onChange(data)
  }

  const onSelect = (project, selected) => {
    if (selected) {
      addProject(project)
    } else {
      removeProject(project)
    }
  }

  const onClick = (e) => {
    // we stop the propagation to avoid triggering the logic to unfocus fields
    e.stopPropagation()
    return false
  }

  const renderIcon = () => {
    const hasValue = value?.length

    return (
      <IconWrapper noMargin={shrinked}>
        <Icon
          size={14}
          icon="Folder"
          fill={getColor({
            isSelected: projectDropdownShown,
            hasValue: hasValue,
          })}
        />
        <Tooltip text={`${translations.general.project} #`} vs={25} hs={5} position="top left" />
      </IconWrapper>
    )
  }

  const hasValue = value?.length

  const options = allProjects.map((project) => ({ value: project, label: project.name }))
  const label = hasValue ? `${value.length} ${translations.general.projects}` : translations.general.project

  return (
    <CardAttribute
      focused={projectDropdownShown}
      focusedColor={hasValue && value.color}
      onClick={onWrapperClick}
      ref={fieldNodeRef}
      squared={shrinked}>
      {shrinked ? (
        renderIcon()
      ) : hasValue && value.length === 1 ? (
        <Project>
          <StyledPlacedHolder color={getColor({ isSelected: false, hasValue: true })}>
            {value[0].name}
          </StyledPlacedHolder>
          <ProjectColor color={value[0].color} />
        </Project>
      ) : (
        <Fragment>
          {renderIcon()}
          <PlaceHolder color={getColor({ isSelected: projectDropdownShown, hasValue: hasValue })}>{label}</PlaceHolder>
        </Fragment>
      )}
      {projectDropdownShown && (
        <ProjectDropdownContainer ref={nodeRef} onClick={onClick} left={projectDropdownLeftPosition}>
          <LineAttributeDropdown
            options={options}
            selectedOption={value}
            onSelect={onSelect}
            width={dropdownOpenedWidth}
            emptyLabel={translations.projects.noProjects}
            dropdownSelector={DropdownSelector}
            isMulti
          />
        </ProjectDropdownContainer>
      )}
    </CardAttribute>
  )
}

const ProjectDropdownContainer = styled.div.attrs(({ left }) => ({
  style: {
    left: `${left}px`,
  },
}))`
  position: absolute;
  top: 32px;
  z-index: 3;
`

const StyledPlacedHolder = styled(PlaceHolder)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Project = styled.div`
  color: ${styles.colors.textMediumDarkColor};
  font-size: ${styles.fonts.fontSizeXSmall};
  display: flex;
  align-items: center;
  max-width: 120px;
  justify-content: flex-end;
  position: relative;
  min-width: 0;
`

const ProjectColor = styled.div`
  background-color: ${(props) => props.color};
  width: 8px;
  height: 8px;
  border-radius: 3px;
  margin-left: 8px;
  margin-right: 0px;
  margin-top: 1px;
  flex-shrink: 0;
`
