import React, { PureComponent } from 'react'
import styled from 'styled-components'

import UserSelect from 'features/asana/UserSelect'
import { ImageSyncContainer } from 'features/integrations/components'
import { getSrc } from 'utils/image'
import { changeSelectValueOfProject, selectAllProjects } from 'logic/asana'

import { styles, translations } from 'gipsy-misc'
import { media, PrimaryButton } from 'gipsy-ui'
import { asana as asanaApi } from 'gipsy-api'
class AsanaLandingPage extends PureComponent {
  state = {
    userData: null,
    savingData: false,
    savedData: false,
  }
  componentDidMount = () => {
    this.setState({
      userData: this.props.location.state?.userData,
    })
  }

  onSaveData = async () => {
    this.setState({ savingData: true })
    try {
      await asanaApi.selectProjects(this.state.userData)
    } catch (err) {
      console.error(err)
    } finally {
      this.setState({ savingData: false, savedData: true })
    }
  }

  onSelectProject = ({ workspaceIdx, teamIdx = -1, projectIdx }) => {
    let newUserData = JSON.parse(JSON.stringify(this.state.userData))
    newUserData = changeSelectValueOfProject(newUserData, { workspaceIdx, teamIdx, projectIdx })
    this.setState({ userData: newUserData })
  }

  onSelectAllProjects = ({ workspaceIdx, teamIdx = -1 }, value) => {
    let newUserData = JSON.parse(JSON.stringify(this.state.userData))
    newUserData = selectAllProjects(newUserData, { workspaceIdx, teamIdx }, value)

    this.setState({ userData: newUserData })
  }

  render() {
    const { userData, savingData, savedData } = this.state

    switch (true) {
      case savingData:
        return (
          <ImageSyncContainer src={getSrc('/images/loading_kosmo.gif')} text={translations.integrations.syncingData} />
        )
      case savedData:
        return (
          <ImageSyncContainer
            src={getSrc('/images/gipsybot-looking-at-you.svg')}
            text={translations.asana.appInstalled}
          />
        )
      case !!userData:
        return (
          <Container className='asana-landing-page'>
            <UserSelectTitleContainer>{translations.asana.selectSyncProjects}</UserSelectTitleContainer>
            <UserSelect
              userData={userData}
              onSelectProject={this.onSelectProject}
              onSelectAllProjects={this.onSelectAllProjects}></UserSelect>
            <PrimaryButtonContainer>
              <PrimaryButton onClick={this.onSaveData} text={translations.general.save} height={44} width={140} />
            </PrimaryButtonContainer>
          </Container>
        )
      default:
        return (
          <ImageSyncContainer
            src={getSrc('/images/gipsybot-looking-at-you.svg')}
            text={translations.asana.appInstalled}
          />
        )
    }
  }
}

export default AsanaLandingPage

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

  ${media['phone-wide']`
      margin-top:0;
      padding:10px;
`}
`

const UserSelectTitleContainer = styled.div`
  font-weight: 500;
  font-size: 17px;
  color: ${styles.colors.textMediumDarkColor};
  margin-bottom: 20px;
`

const PrimaryButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`
