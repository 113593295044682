import React, { Fragment, useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react'
import styled, { css } from 'styled-components'

import Icon from 'Icon'
import FocusSessionsPopup from 'FocusSession/Popup'
import { styles, translations, utils } from 'gipsy-misc'
import { Title } from './components/Title'
import { StartButton } from './components/StartButton'
import CalendarEventInfo, { EstimatedTimeContainer } from './components/CalendarEventInfo'

import ProjectsAttribute from 'LineComponents/ProjectsAttribute'
import TagsAttribute from 'LineComponents/TagsAttribute'
import UrlsAttribute from 'LineComponents/UrlsAttribute'
import SideIconContainer from 'LineComponents/SideIconContainer'
import Popup from 'Popup'
import Checkbox from 'Checkbox'
import { PopupItem } from 'Popup/components'
import {
  LineTopRow,
  LineContainer,
  LineContentContainer,
  LineContent,
  TitleContainer,
  PrimaryActionButton,
  AsanaIcon,
  AsanaWorkspaceName,
  editableTitleStyle,
} from './components/commonUIComponents'
import asanaLogo from 'assets/asana.png'
import { get } from 'lodash'
import { breakpoints } from 'styles/media'

const dragIconSize = 16
const moreIconRightPosition = -25
const completionDuration = 0.3

function LineView(props) {
  const {
    alignActionsPopupToContainer,
    calendarEventInfoLeft = 0,
    displayTimeOnlyForEventInfo,
    draggableProps,
    dragHandleProps,
    dragIconLeftPosition = -25,
    draggableStyle,
    hideCompleteCheckbox,
    isDragging,
    innerRef,
    innerLeftPadding,
    innerRightPadding,
    session,
    isDraggable,
    showCalendarEventInfo,
    inChromeExtension,
    item,
    noMargin,
    disableHover,
    disableEdit,
    disableLinks,
    creating,
    onPauseFocus,
    onComplete,
    isArchivable,
    onArchive,
    onScheduleToToday,
    hideWhenDate,
    lineThrough,
    editStart,
    isEditDisabled,
    onMoveToTop,
    onDelete,
    done,
    animateComplete,
    setEditing,
    keepJustCompleted,
    onStartFocus,
    openUrls,
    editing,
    hideDragIcon,
    hideMoreActions,
    showSprintInfo,
    disableStartButtonAnimation,
    color,
    playIconBackground,
    grayCheckBox,
    isCalendarDraggable,
    onDeleteFocusSession,
    onClickFocusSession,
    onUpdateFocusSession,
    ignoreOutsideClicks,
    showSnackbar,
    clearHighLightedEvent,
    onRemoveFromSprint,
    marginAfterLine,
    onClickLeftArrow,
    onClickRightArrow,
    onTitleChange,
    registerShortcuts,
    unregisterShortcuts,
    hideMiddleRow,
    shouldFocusEditableTitle,
  } = props

  const [justCompleted, setJustCompleted] = useState(false)
  const [showFocusSessionsPopup, setShowFocusSessionsPopup] = useState(false)
  const [showActionsPopup, setShowActionsPopup] = useState(false)
  const [isHovered, setHovered] = useState(false)
  const [contentWidth, setContentWidth] = useState(null)
  const contentRef = useRef(null)
  let focusSessionsPopupRef = useRef(null)
  let startButtonRef = useRef(null)
  let moreButtonRef = useRef(null)
  let actionsPopupRef = useRef(null)

  const focusedTaskId = session && session.focusSession && session.focusSession.taskId
  const isTaskOnFocusSession = focusedTaskId && focusedTaskId === item.id
  const isTitleEditable = isTaskOnFocusSession && onTitleChange

  const lineHeightSize = {
    LARGE: '72px',
    REGULAR: '56px',
    MIDDLEROW: '16px',
  }

  const hasDate = item.when && item.when.date
  const hasPinTime = item.pin && item.pin.time

  const hasBottomRow =
    (item.projects && item.projects.length) ||
    (item.tags && item.tags.length) ||
    (item.urlsInfo && item.urlsInfo.length > 0)
  const hasMiddleRow = !hideMiddleRow && (done || (hasDate && !hideWhenDate) || (showSprintInfo && item.sprintInfo))

  let lineHeight
  if (isTitleEditable) {
    lineHeight = lineHeightSize.LARGE
  } else {
    lineHeight = lineHeightSize.REGULAR
  }

  if (hasMiddleRow && hasBottomRow) {
    lineHeight = `${parseInt(lineHeight) + parseInt(lineHeightSize.MIDDLEROW)}px`
  }

  let linePadding
  let extraPaddingTop
  if (isTitleEditable) {
    linePadding = 8
    extraPaddingTop = editableTitleStyle.verticalPadding + editableTitleStyle.borderSize
  }

  const marginBottom = noMargin ? 0 : marginAfterLine || styles.spacings.marginAfterLine

  useLayoutEffect(() => {
    const setContentClientWidth = () => {
      if (!contentRef.current) return
      setContentWidth(contentRef.current.clientWidth)
    }

    setContentClientWidth()
    window.addEventListener('resize', setContentClientWidth)

    return () => {
      window.removeEventListener('resize', setContentClientWidth)
    }
  }, [])

  useEffect(() => {
    // we reset justCompleted once the item is indeed truly completed
    if (item.completed && justCompleted) {
      setJustCompleted(false)
    }
  }, [item.completed, justCompleted])

  useEffect(() => {
    if (!item.focusSessions || item.focusSessions.length < 1) {
      setShowFocusSessionsPopup(false)
    }
  }, [item.focusSessions])

  const setFocusSessionPopupRef = (ref) => {
    focusSessionsPopupRef.current = ref
  }

  const setStartButtonRef = (ref) => {
    startButtonRef.current = ref
  }

  const onClickLink = () => {
    const urlValue = item.urlsInfo && item.urlsInfo.length > 0 && item.urlsInfo[0].url
    if (urlValue) {
      openUrls([urlValue])
    }
  }

  useEffect(() => {
    if (!showFocusSessionsPopup) {
      clearHighLightedEvent()
    }
  }, [showFocusSessionsPopup, clearHighLightedEvent])

  const onClickElapsedTime = () => {
    setShowFocusSessionsPopup(!showFocusSessionsPopup)
  }

  const onClickMoreActionsButton = () => {
    setShowActionsPopup(!showActionsPopup)
  }

  const _onComplete = (val) => {
    if (animateComplete && val.value) {
      setJustCompleted(true)
      setEditing(false)
      return setTimeout(() => {
        onComplete(val)
        if (!keepJustCompleted) {
          setJustCompleted(false)
        }
      }, completionDuration * 1000)
    }

    return onComplete(val)
  }

  const onClickCompleteButton = () => {
    _onComplete({
      id: item.id,
      paramName: 'completed',
      value: 1,
    })
  }

  const onMouseEnter = useCallback(() => {
    if (!editing && !disableHover) {
      setHovered(true)
    }
  }, [editing, disableHover])

  const onMouseLeave = useCallback(() => {
    setHovered(false)
  }, [])

  const onClickMoveToTop = (e) => {
    e.stopPropagation()
    onMoveToTop && onMoveToTop(item.id)
  }

  const _onClickLeftArrow = useCallback(() => {
    onClickLeftArrow && onClickLeftArrow(item)
  }, [onClickLeftArrow, item])

  const _onClickRightArrow = useCallback(() => {
    onClickRightArrow && onClickRightArrow(item)
  }, [onClickRightArrow, item])

  const DateAndEstimatedTime = ({ startTime, estimatedTime }) => {
    const { hour, minute } = utils.datetime.getHourAndMinuteFromNanoseconds(estimatedTime)
    return (
      <Fragment>
        <span>{utils.datetime.beautifyDateWithDay(startTime)}</span>
        <TitleDateBullet>•</TitleDateBullet>
        <span>{utils.datetime.beautifyTime(startTime)}</span>
        <TitleDateBullet>•</TitleDateBullet>
        <span>
          {!!hour && `${hour} h `}
          {!!minute && `${minute} min`}
        </span>
      </Fragment>
    )
  }

  const hasMoreActions = onDelete || (isArchivable && onArchive) || onScheduleToToday || onRemoveFromSprint
  const showMoreActionsButton = !hideMoreActions && !isTaskOnFocusSession && hasMoreActions
  const hideStartButton = props.hideStartButton
  const showDragIcon = !hideDragIcon && !isTaskOnFocusSession

  let modifiedTitle = ''
  let isPastItem = false
  if (!done) {
    isPastItem = utils.task.isPast(item)

    if (isPastItem) {
      modifiedTitle += '☠️ '
    }
  }
  modifiedTitle += item.title

  useEffect(() => {
    const handleAllClickOutside = (e) => {
      if (ignoreOutsideClicks) return
      if (
        !(startButtonRef.current && startButtonRef.current.contains(e.target)) &&
        !(focusSessionsPopupRef.current && focusSessionsPopupRef.current.contains(e.target))
      ) {
        setShowFocusSessionsPopup(false)
      }
      if (
        !(moreButtonRef.current && moreButtonRef.current.contains(e.target)) &&
        !(
          actionsPopupRef.current &&
          (actionsPopupRef.current.contains(e.target) || actionsPopupRef.current === e.target)
        )
      ) {
        setShowActionsPopup(false)
      }
    }
    document.addEventListener('mousedown', handleAllClickOutside)
    return () => document.removeEventListener('mousedown', handleAllClickOutside)
  }, [ignoreOutsideClicks])

  const lineContainerClasses = `line-container fs-mask ${isCalendarDraggable ? 'calendar-draggable' : ''} ${
    isDragging ? 'dragging' : ''
  }`

  const hasFocusSessions = item.focusSessions && item.focusSessions.length > 0
  const isAsanaTask = !!item.asanaInfo
  const shouldShowMoveToTop = onMoveToTop && isHovered

  const _onTitleChange = useCallback(
    (title) => {
      onTitleChange && onTitleChange(title, item)
    },
    [onTitleChange, item]
  )

  const renderMiddleRow = () => {
    switch (true) {
      case showSprintInfo && !!item.sprintInfo:
        return (
          <SprintInfo>
            <SprintInfoIcon size={11} icon={'Sprint'} fill={styles.colors.orangeColor} />
            <SprintInfoName>{item.sprintInfo.title}</SprintInfoName>
            <TitleDateBullet>•</TitleDateBullet>
            <DateAndEstimatedTime startTime={item.sprintInfo.pin.time} estimatedTime={item.sprintInfo.estimatedTime} />
          </SprintInfo>
        )
      case !!done:
        return (
          <TitleDate>
            <TitleDateItem>
              <span>{done}</span>
            </TitleDateItem>
          </TitleDate>
        )
      case hasDate && !hideWhenDate:
        return (
          <TitleDate color={isPastItem ? styles.colors.pinkColor : undefined}>
            <TitleDateItem>
              {hasPinTime ? (
                <Fragment>
                  <TitleDateIcon
                    size={12}
                    icon={'CalendarNoDate'}
                    fill={isPastItem ? styles.colors.pinkColor : styles.colors.blueColor}
                  />
                  <DateAndEstimatedTime startTime={item.pin.time} estimatedTime={item.estimatedTime} />
                </Fragment>
              ) : (
                <span>{utils.datetime.beautifyDateWithDay(item.when.date)}</span>
              )}
            </TitleDateItem>
          </TitleDate>
        )
      default:
        return null
    }
  }

  const shouldShrinkAttributes = !contentWidth || contentWidth < breakpoints.phone

  return (
    <StyledLineContainer
      className={lineContainerClasses}
      ref={innerRef}
      data-itemid={item.id}
      {...draggableProps}
      style={draggableStyle}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      paddingLeft={innerLeftPadding}
      paddingRight={innerRightPadding}
      isDraggable={isDraggable}
      {...dragHandleProps}>
      {showDragIcon && (
        <DragIconContainer
          dragIconLeftPosition={dragIconLeftPosition}
          innerLeftPadding={innerLeftPadding}
          className={'drag-icon-container'}
          shown={isDraggable && isHovered}>
          <Icon icon='DragHandle' size={dragIconSize} />
        </DragIconContainer>
      )}
      {showCalendarEventInfo && (
        <CalendarEventInfo
          left={calendarEventInfoLeft}
          pin={item.pin}
          estimatedTime={item.estimatedTime}
          date={item.when.date}
          height={lineHeight}
          isPast={isPastItem}
          showTimeOnly={displayTimeOnlyForEventInfo}
        />
      )}
      <LineContentContainer
        lineHeight={lineHeight}
        marginBottom={marginBottom}
        isDraggable={isDraggable}
        isDragging={isDragging}
        disableHover={disableHover}
        key={item.id}
        disableEdit={disableEdit}
        ref={contentRef}
        cursor={'pointer'}
        focusSessionPopupVisible={showFocusSessionsPopup}>
        {onClickLeftArrow && (
          <ArrowActionContainer>
            <ArrowActionButton onClick={_onClickLeftArrow}>
              <Icon icon={'ArrowLeft'} fill={styles.colors.textMediumDarkColor} size={16} />
            </ArrowActionButton>
          </ArrowActionContainer>
        )}
        {!hideStartButton && (
          <StartButton
            setRef={setStartButtonRef}
            session={session}
            isDragging={isDragging}
            color={color}
            playIconBackground={playIconBackground}
            linePadding={linePadding}
            item={item}
            openUrls={openUrls}
            grayCheckBox={grayCheckBox}
            disableHoverAnimation={!!focusedTaskId || disableStartButtonAnimation}
            inChromeExtension={inChromeExtension}
            onPauseFocus={onPauseFocus}
            showFocusSessionsPopup={showFocusSessionsPopup}
            onClickElapsedTime={hasFocusSessions ? onClickElapsedTime : undefined}
            onClickLink={onClickLink}
            onStartFocus={onStartFocus}
            creating={creating}
            hasPinTime={hasPinTime}
            focusedTaskId={focusedTaskId}
            isTaskOnFocusSession={isTaskOnFocusSession}
            onComplete={_onComplete}
            onClickCompleteButton={onClickCompleteButton}
            justCompleted={justCompleted}
            extraPaddingTop={extraPaddingTop ? extraPaddingTop - 1 : 0}
          />
        )}
        {showFocusSessionsPopup && (
          <FocusSessionsPopup
            item={item}
            setRef={setFocusSessionPopupRef}
            onDeleteFocusSession={onDeleteFocusSession}
            onUpdateFocusSession={onUpdateFocusSession}
            onClickFocusSession={onClickFocusSession}
            clearHighLightedEvent={clearHighLightedEvent}
            showSnackbar={showSnackbar}
            inChromeExtension={inChromeExtension}
            firstDayOfWeek={session?.user?.settingsPreferences?.calendar?.firstDay}
          />
        )}

        <LineContent linePadding={linePadding}>
          <LineTopRow isClickable={!isEditDisabled} onClick={editStart}>
            <TitleContainer>
              <TitleRow>
                {hideStartButton && !hideCompleteCheckbox && (
                  <StyledCheckbox
                    size={16}
                    disableTransition={isDragging}
                    checkmarkTop={1.2}
                    checkmarkLeft={4.5}
                    checkmarkWidth={3}
                    checkmarkHeight={6}
                    showBoxShadow={false}
                    checkmarkBorderWidth={1.5}
                    borderWidth={1.5}
                    checkedColor={grayCheckBox ? styles.colors.middleGrey : styles.colors.greenColor}
                    checkMarkColor={grayCheckBox ? styles.colors.darkGrey : 'white'}
                    hoverCheckMarkColor={styles.colors.darkGrey}
                    hoverBackgroundColor={'white'}
                    hoverBorderColor={styles.colors.darkGrey}
                    borderColor={styles.colors.darkGrey}
                    id={item.id}
                    paramName='completed'
                    checked={!!item.completed || justCompleted}
                    onChange={_onComplete}
                    hide={isTaskOnFocusSession ? 1 : 0}
                  />
                )}
                <Title
                  shouldFocusEditableTitle={shouldFocusEditableTitle}
                  onTitleChange={_onTitleChange}
                  registerShortcuts={registerShortcuts}
                  unregisterShortcuts={unregisterShortcuts}
                  isEditable={isTitleEditable}
                  isInputDisabled={isTitleEditable && session.focusSession.isTmp}
                  title={isTaskOnFocusSession ? item.title : modifiedTitle}
                  lineThrough={lineThrough}
                  justCompleted={justCompleted}
                  completionDuration={completionDuration}
                />
                {isAsanaTask && !shouldShowMoveToTop && (
                  <AsanaContainer hideText={shouldShrinkAttributes}>
                    <AsanaWorkspaceName className='asana-workspace-name'>
                      {get(item, 'asanaInfo.workspaceName')}
                    </AsanaWorkspaceName>
                    <AsanaIcon alt={'asana logo'} src={asanaLogo} />
                  </AsanaContainer>
                )}
              </TitleRow>
              {hasMiddleRow && renderMiddleRow()}
            </TitleContainer>
            {shouldShowMoveToTop && (
              <PrimaryActionButton onClick={onClickMoveToTop}>
                <Icon icon={'MoveUp'} fill={styles.colors.grey} size={12} />
                <span>{translations.line.moveToTop}</span>
              </PrimaryActionButton>
            )}
          </LineTopRow>
          <BottomRow onClick={editStart}>
            <AttributesContainer>
              <UrlsAttribute
                urlsInfo={item.urlsInfo}
                disableLinks={disableLinks}
                maxWidth={'calc(50%)'}
                stopPropagationOnClick={true}
              />
              <TagsAttribute tags={item.tags} />
            </AttributesContainer>
            <ProjectContainer>
              <ProjectsAttribute projects={item.projects} alignRight stopPropagationOnClick={true} />
            </ProjectContainer>
          </BottomRow>
        </LineContent>
        {onClickRightArrow && (
          <ArrowActionContainer>
            <ArrowActionButton onClick={_onClickRightArrow}>
              <Icon icon={'ArrowRight'} fill={styles.colors.textMediumDarkColor} size={16} />
            </ArrowActionButton>
          </ArrowActionContainer>
        )}
      </LineContentContainer>
      {showMoreActionsButton && (
        <MoreIconContainer
          ref={moreButtonRef}
          onClick={onClickMoreActionsButton}
          shown={isHovered}
          innerRightPadding={innerRightPadding}>
          <Icon icon='More' size={16} />
        </MoreIconContainer>
      )}
      {showMoreActionsButton && showActionsPopup && (
        <ActionsPopup
          alignToContainer={alignActionsPopupToContainer}
          ref={actionsPopupRef}
          top={'26px'}
          left={`calc(100% - ${innerRightPadding + moreIconRightPosition + 3}px)`}>
          {isArchivable && onArchive && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => onArchive(item.id)}>
              <span> {translations.general.archive} </span>
            </PopupItem>
          )}

          {onScheduleToToday && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => onScheduleToToday(item.id)}>
              <span> {translations.line.scheduleToToday} </span>
            </PopupItem>
          )}
          {onRemoveFromSprint && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => onRemoveFromSprint(item)}>
              <span>{translations.general.remove}</span>
            </PopupItem>
          )}
          {onDelete && (
            <PopupItem hoverBackgroundColor={'white'} onClick={() => onDelete(item.id)}>
              <span>{translations.general.delete}</span>
            </PopupItem>
          )}
        </ActionsPopup>
      )}
    </StyledLineContainer>
  )
}

export default React.memo(LineView)

const DragIconContainer = styled(SideIconContainer)`
  top: calc(50% - 8px);
  left: ${({ dragIconLeftPosition, innerLeftPadding }) =>
    `${innerLeftPadding + (Number.isInteger(dragIconLeftPosition) ? dragIconLeftPosition : 0) || 50}px`};
  transform: translateY(-50%);
  cursor: ${({ shown }) => (shown ? 'grab' : 'auto')};
`

const StyledLineContainer = styled(LineContainer)`
  ${EstimatedTimeContainer} {
    opacity: 1;
  }

  :hover {
    ${EstimatedTimeContainer} {
      opacity: 0;
    }
  }
`

StyledLineContainer.displayName = 'StyledLineContainer'

const MoreIconContainer = styled(SideIconContainer)`
  top: 12px;
  right: ${(props) => `${props.innerRightPadding + moreIconRightPosition}px`};
  cursor: ${({ shown }) => (shown ? 'pointer' : 'auto')};
`

const TitleDate = styled.div`
  color: ${(props) => props.color || styles.colors.blueColor};
  font-size: ${styles.fonts.fontSizeXSmall};
  font-weight: 500;
  display: flex;
  align-items: center;
`

const SprintInfo = styled(TitleDate)`
  color: ${styles.colors.orangeColor};
  align-items: flex-end;
  min-width: 0;
`

const SprintInfoIcon = styled(Icon)`
  margin-right: 3px;
`

const SprintInfoName = styled.span`
  flex-shrink: 1000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const TitleDateItem = styled.span`
  display: flex;
  align-items: center;
`

const TitleDateBullet = styled.span`
  margin: 0 4px;
`

const TitleDateIcon = styled(Icon)`
  margin-right: 7px;
`

const ArrowActionContainer = styled.div`
  width: 48px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ArrowActionButton = styled.span`
  opacity: 0.2;
  width: 32px;
  cursor: pointer;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${styles.colors.darkGrey};
  border-radius: 8px;
  transition: opacity 0.2s ease-in-out;

  :hover {
    path,
    rect {
      fill: white;
    }
    border-color: ${styles.colors.primaryColor};
    background-color: ${styles.colors.primaryColor};
    opacity: 1;
  }
`

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`

const BottomRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  font-size: ${styles.fonts.fontSizeXSmall};
  line-height: ${styles.fonts.fontSize};
  height: ${styles.fonts.fontSize};
  color: ${styles.colors.grey};
  cursor: pointer;
`
const AttributesContainer = styled.div`
  max-height: 16px;
  min-width: 65%;
  display: flex;

  & > :first-child {
    margin-right: 10px;
  }
`

const ProjectContainer = styled.div`
  max-height: 16px;
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  min-width: 35%;
`

const StyledCheckbox = styled(Checkbox)`
  margin-right: 6px;
`

const AsanaContainer = styled.div`
  display: flex;
  margin-left: 4px;
  flex-direction: row;
  align-items: flex-end;
  flex-shrink: 0;
  max-width: 150px;
  margin-left: auto;

  .asana-workspace-name {
    ${(props) =>
      props.hideText &&
      css`
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s, width 0.5s;
        width: 0;
      `}
  }
  &:hover {
    .asana-workspace-name {
      visibility: visible;
      opacity: 1;
      width: auto;
    }
  }
`

const ActionsPopup = styled(Popup)`
  ${({ alignToContainer }) =>
    alignToContainer &&
    css`
      transform: translateX(-100%);
    `}
`

ActionsPopup.displayName = 'ActionsPopup'
