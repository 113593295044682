import { types } from 'store/popup/types'

const initialState = () => ({
  active: false,
  title: '',
  message: '',
  button: '',
  buttonClass: '',
  action: null,
  componentId: '',
  component: null,
  headerComponent: null,
  hideLogo: false,
})

const popupReducer = (state = initialState(), action) => {
  switch (action.type) {
    case types.OPEN_POPUP:
      document.body.style.overflow = 'hidden'
      return {
        active: true,
        ...action.payload,
      }

    case types.CLOSE_POPUP:
      document.body.style.overflow = 'auto'
      return {
        ...state,
        active: false,
      }

    case types.CLEAR_POPUP:
      document.body.style.overflow = 'auto'
      return {
        ...initialState(),
      }

    default:
      break
  }
  return state
}

export default popupReducer
