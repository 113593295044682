import React from 'react'
import styled, { css } from 'styled-components'

import { styles, translations } from 'gipsy-misc'
import { CustomPopup, Icon } from 'gipsy-ui'

import { popupStyles, PopupTail } from './commonUIComponents'
import { getRecurrencyLabel, recurrencyOptions } from '../utils'

function RecurrencyTypesPopup({ onChange, onClickOutside, onCustomClicked, sprintStartTime, typeSelected }) {
  const handleChange = (typeChosen) => {
    if (typeChosen === typeSelected) return
    onChange(typeChosen)
  }

  const selectNoRepeat = () => handleChange(recurrencyOptions.NO_REPEAT)
  const selectDaily = () => handleChange(recurrencyOptions.DAILY)
  const selectWeeklyOnCurrentDay = () => handleChange(recurrencyOptions.WEEKLY_ON_CURRENT_DAY)
  const selectEveryWeekday = () => handleChange(recurrencyOptions.EVERY_WEEKDAY)

  return (
    <Popup onClickOutside={onClickOutside}>
      <PopupTail />
      <PopupContent>
        <TypePreset
          onClick={selectNoRepeat}
          selected={typeSelected === recurrencyOptions.NO_REPEAT}
          text={translations.sprint.recurrency.noRepeat}
        />
        <TypePreset
          onClick={selectDaily}
          selected={typeSelected === recurrencyOptions.DAILY}
          text={translations.sprint.recurrency.daily}
        />
        <TypePreset
          onClick={selectWeeklyOnCurrentDay}
          selected={typeSelected === recurrencyOptions.WEEKLY_ON_CURRENT_DAY}
          text={getRecurrencyLabel({
            recurrencyType: recurrencyOptions.WEEKLY_ON_CURRENT_DAY,
            sprintStartTime,
          })}
        />
        <TypePreset
          onClick={selectEveryWeekday}
          selected={typeSelected === recurrencyOptions.EVERY_WEEKDAY}
          text={translations.sprint.recurrency.everyWeekday}
        />
        <TypePreset
          onClick={onCustomClicked}
          selected={typeSelected === recurrencyOptions.CUSTOM}
          text={translations.sprint.recurrency.custom}
        />
      </PopupContent>
    </Popup>
  )
}

function TypePreset({ onClick, selected, text }) {
  return (
    <Preset onClick={onClick} selected={selected}>
      {text}
      {selected && <CheckedIcon icon="Tick" fill={styles.colors.primaryColor} size={9} />}
    </Preset>
  )
}

const Preset = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  padding: 8px 31px 8px 21px;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${styles.colors.veryLightGrey};
    color: ${styles.colors.primaryColor};
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 500;
    `}
`

Preset.displayName = 'Preset'

const CheckedIcon = styled(Icon)`
  right: 14px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
`

CheckedIcon.displayName = 'CheckedIcon'

const Popup = styled(CustomPopup)`
  ${popupStyles}
`

Popup.displayName = 'Popup'

const PopupContent = styled.div`
  padding: 6px 0;
`

PopupContent.displayName = 'PopupContent'

export default RecurrencyTypesPopup
