import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { models, translations } from 'gipsy-misc'
import { AnimateHeight, MemoedInsideActiveSprintLine, SprintLine } from 'gipsy-ui'

import HomebaseLine from 'features/list/components/line'
import { sections } from 'pages/today/active/container'
import { SubGroupTitleSeparator } from 'pages/today/active/components'

function PinnedItems({ getTaskProps, items, sprintProps }) {
  const [expanded, setExpanded] = useState(true)

  return (
    <>
      <SubGroupTitleSeparator
        isExpanded={expanded}
        onClick={() => setExpanded(!expanded)}
        title={translations.todayView.scheduledInCalendar}
      />

      <AnimateHeight duration={300} height={expanded ? 'auto' : 0}>
        <Droppable droppableId={JSON.stringify({ group: sections.PINNED })} isDropDisabled type={models.item.type.TASK}>
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {items.map((item, index) => (
                <PinnedItem
                  index={index}
                  item={item}
                  key={item.id}
                  sprintProps={sprintProps}
                  taskProps={getTaskProps({ item })}
                />
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </AnimateHeight>
    </>
  )
}

const PinnedItem = React.memo(({ index, item, sprintProps, taskProps }) => (
  <Draggable draggableId={JSON.stringify({ id: item.id, type: item.type })} index={index} type={item.type}>
    {(draggableProvided, snapshot) => {
      if (item.type === models.item.type.SPRINT) {
        const { sprintInlineTaskProps, sprintTaskProps, ...sprintLineProps } = sprintProps

        return (
          <Droppable
            droppableId={JSON.stringify({
              sprintId: item.id,
            })}
            type={models.item.type.TASK}>
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                <SprintLine
                  draggableProps={draggableProvided.draggableProps}
                  draggableStyle={draggableProvided.draggableProps.style}
                  dragHandleProps={draggableProvided.dragHandleProps}
                  isDragging={snapshot.isDragging}
                  innerRef={draggableProvided.innerRef}
                  sprint={item}
                  {...sprintLineProps}>
                  <MemoedInsideActiveSprintLine
                    isDraggable
                    taskList={item.tasks}
                    taskComponent={HomebaseLine}
                    taskProps={sprintTaskProps}>
                    {droppableProvided.placeholder}
                    <HomebaseLine {...sprintInlineTaskProps} isInlineSprintTask sprint={item} />
                  </MemoedInsideActiveSprintLine>
                </SprintLine>
              </div>
            )}
          </Droppable>
        )
      }

      return (
        <HomebaseLine
          draggableProps={draggableProvided.draggableProps}
          draggableStyle={draggableProvided.draggableProps.style}
          dragHandleProps={draggableProvided.dragHandleProps}
          innerRef={draggableProvided.innerRef}
          isDragging={snapshot.isDragging}
          item={item}
          {...taskProps}
        />
      )
    }}
  </Draggable>
))

export default React.memo(PinnedItems)
