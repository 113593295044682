import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import cloneDeep from 'lodash/cloneDeep'
import produce from 'immer'

import { calendarlist, emailaccounts, mixpanel as mixpanelApi } from 'gipsy-api'
import { mixpanel, models, styles, translations } from 'gipsy-misc'
import { CustomPopup, GoogleButton, MicrosoftButton, ReusableButton } from 'gipsy-ui'

import RealTime from 'features/realTime'
import { extractStateParameters, clearOauthDataFromSessionStorage } from 'logic/auth'
import { getGoogleAuthUrlAndStoreState, getMicrosoftAuthUrlAndStoreState } from 'logic/integrationOauth'
import { setAccounts, getAccounts } from 'store/accounts/actions'
import { getAccounts as getAccountSelector } from 'store/accounts/selectors'
import { refetchEvents } from 'store/calendar/actions'

import AccountItem from './AccountItem'
import AddAccountPopup from './AddAccountPopup'
import SettingsLightbox from './SettingsLightbox'
import SyncingOverlay from './SyncingOverlay'

const GOOGLE = 'google'
const MICROSOFT = 'microsoft'

export default function Accounts({ onCloseAddAccountPopup, showAddAccountPopup }) {
  const dispatch = useDispatch()
  const emailAccounts = useSelector((state) => getAccountSelector(state))

  const [loginPopupShown, setLoginPopupShown] = useState(false)
  const [showSettingsLightbox, setShowSettingsLightbox] = useState(false)
  const [showSyncincOverlay, setShowSyncingOverlay] = useState(false)
  const [syncingMap, setSyncingMap] = useState({})

  const addAccountContainerRef = useRef(null)
  const previousUrl = useRef(null)
  const windowObjectRef = useRef(null)

  useEffect(() => {
    const syncing = Object.values(syncingMap).some((calendarSyncing) => !!calendarSyncing)
    setShowSyncingOverlay(syncing)
  }, [syncingMap])

  const updateCalendarState = (email, calendar) => {
    const updatedAccounts = produce(emailAccounts, (draft) => {
      const updatedAccount = draft.find((account) => account.email === email)

      if (updatedAccount) {
        const updatedCalendar = updatedAccount.calendarsList.find(
          (draftCalendar) => draftCalendar.calendarId === calendar.calendarId
        )
        if (updatedCalendar) {
          updatedCalendar.isSelected = !updatedCalendar.isSelected
        }
      }
    })

    dispatch(setAccounts(updatedAccounts))
  }

  const onToggleSelect = async (email, calendar) => {
    const mapKey = `${email}_${calendar.calendarId}`

    if (syncingMap[mapKey]) {
      return
    }

    const selectedCalendar = cloneDeep(calendar)
    updateCalendarState(email, calendar)
    setSyncingMap((prev) => {
      const updatedMap = produce(prev, (draft) => {
        draft[mapKey] = true
      })

      return updatedMap
    })

    let funcToCall = selectedCalendar.isSelected ? calendarlist.unselect : calendarlist.select

    try {
      await funcToCall(email, selectedCalendar.calendarId)
      RealTime.publishMessage('', [models.realtime.topics.calendarList, models.realtime.topics.events])
      await dispatch(refetchEvents())
    } catch (err) {
      console.error(err)
    } finally {
      setSyncingMap((prev) => {
        const updatedMap = produce(prev, (draft) => {
          draft[mapKey] = false
        })

        return updatedMap
      })
    }
  }

  const onChangeDefaultCalendar = async (email, calendar) => {
    try {
      await calendarlist.selectDefault(email, calendar.calendarId)
      const updatedAccounts = produce(emailAccounts, (draft) =>
        draft.forEach((account) => {
          account.calendarsList?.forEach?.((draftCalendar) => {
            draftCalendar.appDefaultWriteCalendar =
              account.email === email && calendar.calendarId === draftCalendar.calendarId
          })
        })
      )

      dispatch(setAccounts(updatedAccounts))
    } catch (err) {
      console.error(err)
    }
  }

  const onUnsyncAccount = (email) => {
    const updatedAccounts = produce(emailAccounts, (draft) => {
      const accountToRemoveIdx = draft.findIndex((account) => account.email === email)

      if (accountToRemoveIdx) {
        draft.splice(accountToRemoveIdx, 1)
      }
    })

    dispatch(setAccounts(updatedAccounts))
    dispatch(refetchEvents())
  }

  const onOpenSettings = () => {
    setShowSettingsLightbox(true)
  }

  const clickAddAccount = () => {
    setLoginPopupShown((popupShown) => !popupShown)
    mixpanelApi.track({ event: mixpanel.addAccountClickedEvent })
  }

  const handleClickOutside = (e) => {
    if (addAccountContainerRef?.current && !addAccountContainerRef.current.contains(e.target)) {
      setLoginPopupShown(false)
    }
  }

  const onSyncAccount = () => {
    dispatch(getAccounts())
    dispatch(refetchEvents())
  }

  const openSignInWindow = (url, name) => {
    const receiveMessage = async (event) => {
      if (event.origin !== process.env.REACT_APP_FRONTEND_URL) {
        return
      }
      const {
        data,
        data: { searchParams },
      } = event
      if (!searchParams || !searchParams.code) return
      if (searchParams.error) {
        console.error(searchParams.error)
        return
      }
      if (data.source === 'revalidate-oauth') {
        try {
          const { error, state, localStateGoogle, localStateMicrosoft, code, nonce } = extractStateParameters(
            searchParams
          )

          const querySucceeded = !error && code
          if (!querySucceeded) {
            throw new Error('Query failed.')
          }
          let provider
          if (state === localStateGoogle) {
            provider = GOOGLE
          } else if (state === localStateMicrosoft) {
            provider = MICROSOFT
          } else {
            throw new Error('Unknown OAuth method.')
          }
          const redirectURL = window.location.origin + '/revalidateoauthcallback'
          await emailaccounts.add(provider, code, nonce, redirectURL)
          onSyncAccount()
        } catch (err) {
          console.error(searchParams.error)
        } finally {
          if (windowObjectRef.current) {
            windowObjectRef.current.close()
          }
        }
      }
    }
    const width = 700
    const height = 800
    let left = window.screenLeft + window.outerWidth / 2 - width / 2
    let top = window.screenTop + window.outerHeight / 2 - height / 2
    const strWindowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top} left=${left}`

    if (windowObjectRef.current === null || windowObjectRef.current.closed) {
      windowObjectRef.current = window.open(url, name, strWindowFeatures)
    } else if (previousUrl.current !== url) {
      windowObjectRef.current = window.open(url, name, strWindowFeatures)
      windowObjectRef.current.focus()
    } else {
      windowObjectRef.current.focus()
    }
    const parentWindow = window

    let listener = setInterval(function () {
      if (windowObjectRef.current?.closed) {
        window.removeEventListener('message', receiveMessage)
        clearInterval(listener)
        clearOauthDataFromSessionStorage(parentWindow)
        windowObjectRef.current = null
      }
    }, 300)

    window.addEventListener('message', receiveMessage, false)
    previousUrl.current = url
  }

  const signInWithGoogle = async (email) => {
    const redirectURL = window.location.origin + '/revalidateoauthcallback'
    const authURL = await getGoogleAuthUrlAndStoreState(true, redirectURL, email)
    openSignInWindow(authURL, 'revalidateoauthcallback')
  }

  const signInWithMicrosoft = async (email) => {
    const redirectURL = window.location.origin + '/revalidateoauthcallback'
    // if we force the consent, then microsoft automatically choose an email
    // hence the user can't decide which account he wants to sign in with
    const forceConsent = !!email
    const authURL = await getMicrosoftAuthUrlAndStoreState(forceConsent, redirectURL, email)
    openSignInWindow(authURL, 'revalidateoauthcallback')
  }

  const onClickConnectGoogle = async () => {
    setLoginPopupShown(false)
    signInWithGoogle()
  }

  const onClickConnectMicrosoft = async () => {
    setLoginPopupShown(false)
    signInWithMicrosoft()
  }

  const reauthenticate = (provider, email) => {
    if (provider === GOOGLE) {
      signInWithGoogle(email)
    } else if (provider === MICROSOFT) {
      signInWithMicrosoft(email)
    }
  }

  const onCloseSettingsLightbox = () => {
    setShowSettingsLightbox(false)
  }

  return (
    <Container>
      {emailAccounts.map((account, index) => (
        <AccountItem
          account={account}
          key={index}
          onChangeDefaultCalendar={onChangeDefaultCalendar}
          onOpenSettings={onOpenSettings}
          onReauthenticate={reauthenticate}
          onSyncAccount={onSyncAccount}
          onToggleSelect={onToggleSelect}
          onUnsyncAccount={onUnsyncAccount}
          removeBorder={index === emailAccounts.length - 1}
        />
      ))}
      <AddAccountContainer ref={addAccountContainerRef}>
        <AddAccount borderRadius={8} onClick={clickAddAccount}>
          {translations.calendarList.addAccount}
        </AddAccount>
        {loginPopupShown && (
          <LoginPopup onClickOutside={handleClickOutside}>
            <GoogleButton label={'Google'} onClick={onClickConnectGoogle} />
            <MicrosoftButton label={'Microsoft'} onClick={onClickConnectMicrosoft} />
          </LoginPopup>
        )}
      </AddAccountContainer>
      {showSettingsLightbox && <SettingsLightbox onClose={onCloseSettingsLightbox} />}
      {showSyncincOverlay && <SyncingOverlay />}
      {showAddAccountPopup && <AddAccountPopup onAddAccount={onClickConnectGoogle} onClose={onCloseAddAccountPopup} />}
    </Container>
  )
}

const Container = styled.div``

Container.displayName = 'Container'

const AddAccountContainer = styled.div`
  position: relative;
  width: 100%;
`

AddAccountContainer.displayName = 'AddAccountContainer'

const AddAccount = styled(ReusableButton)`
  margin-top: 16px;
`

AddAccount.displayName = 'AddAccount'

const LoginPopup = styled(CustomPopup)`
  background-color: ${styles.colors.veryLightGrey};
  left: 50%;
  margin: 0;
  padding: 20px;
  top: calc(100% + 8px);
  transform: translateX(-50%);
  width: 170px;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

LoginPopup.displayName = 'LoginPopup'
