import qs from 'qs'
import React, { Component } from 'react'

import { ImageSyncContainer } from 'features/integrations/components'
import { getSrc } from 'utils/image'

import { translations } from 'gipsy-misc'

export default class RevalidateOauthCallback extends Component {
  componentDidMount = async () => {
    const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    if (window.opener) {
      window.opener.postMessage({ searchParams, source: 'revalidate-oauth' })
    }
  }
  render = () => {
    return <ImageSyncContainer src={getSrc('/images/loading_kosmo.gif')} text={translations.integrations.syncingData} />
  }
}
