import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { styles, utils } from 'gipsy-misc'

const propTypes = {
  onChange: PropTypes.func.isRequired,

  /* should be a 24h format number, it will be converted as 12h if necessary */
  hour: PropTypes.number,
  minute: PropTypes.number,

  /* by default, if format is 12, period will be shown */
  showPeriod: PropTypes.bool,

  /*
    12h : the hour value will be compute and sent to the parent as 24h format ('03' with 'PM' will be sent as 15)
    anything else (or null) : user can write any number between minHour and maxHour, it will be sent as written to the parent
  */
  format: PropTypes.string,
}

const minMinute = 0
const maxMinute = 59

class TimeInput extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...this.getStateFromProps(props),
      focused: false,
    }
  }

  getStateFromProps = (props) => {
    props = props || this.props
    let state = {
      hour: props.hour,
      minute: props.minute,
      format: props.format || null,
      period: 'am',
      maxHour: props.format === '12h' ? 12 : 23,
      minHour: props.format === '12h' ? 1 : 0,
      showPeriod: props.showPeriod || false,
    }
    state.showPeriod = !!(state.format === '12h' && state.showPeriod)

    let intHour = parseInt(state.hour || 0)
    let intMinute = parseInt(state.minute || 0)

    if (state.format === '12h') {
      const convertedHour = utils.datetime.convertHourTo12hFormat(intHour)
      intHour = convertedHour.hour
      state.period = convertedHour.period
    }

    if (intHour > state.maxHour) {
      intHour = state.maxHour
    } else if (intHour < state.minHour) {
      intHour = state.minHour
    }

    if (intMinute > maxMinute) {
      intMinute = maxMinute
    } else if (intMinute > maxMinute) {
      intMinute = maxMinute
    }

    state.hour = utils.datetime.nbToStr2digit(intHour)
    state.minute = utils.datetime.nbToStr2digit(intMinute)

    return state
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const filterFields = ['hour', 'minute', 'format', 'maxHour', 'minHour', 'maxMinute', 'minMinute']

    if (!isEqual(pick(this.props, filterFields), pick(nextProps, filterFields))) {
      const nextState = this.getStateFromProps(nextProps)
      this.setState(nextState)
    }
  }

  /* work with string|int value, (called with string when keyboard writing, int when increment (keyboard up/down & - + button)) */
  isValid = (paramName, value) => {
    const min = paramName === 'hour' ? this.state.minHour : minMinute
    const max = paramName === 'hour' ? this.state.maxHour : maxMinute

    return utils.datetime.isHourOrMinuteBetweenLimits(value, min, max)
  }

  /* make sure the state is clean : 2 digits hour|minute valid string */
  get2digitState = () => {
    const hour = utils.datetime.nbToStr2digit(
      this.isValid('hour', parseInt(this.state.hour)) ? parseInt(this.state.hour) : this.state.minHour
    )
    const minute = utils.datetime.nbToStr2digit(
      this.isValid('minute', parseInt(this.state.minute)) ? parseInt(this.state.minute) : minMinute
    )
    return { hour, minute }
  }

  /* return an object {paramName, value} to send to parent with int hour|minute, hour is converted to 24 format */
  getDataForParent = () => {
    const result = {
      hour:
        this.state.format === '12h'
          ? utils.datetime.convertHourTo24hFormat(this.state.hour, this.state.period, this.state.maxHour)
          : parseInt(this.state.hour),
      minute: parseInt(this.state.minute),
    }

    return result
  }

  onChange = ({ paramName, value }) => {
    if (!value) {
      return this.setState({ [paramName]: value })
    }

    if (!this.isValid(paramName, value)) {
      return
    }

    if (paramName === 'hour' && value && value.length >= 2 && this.secondInput) {
      this.secondInput.focus()
    }

    if (paramName === 'minute' && value && value.length >= 2 && this.periodInput) {
      this.periodInput.focus()
    }

    this.setState({ [paramName]: value }, () => {
      if (value.length >= 2) {
        this.props.onChange(this.getDataForParent())
      }
    })
  }

  onBlur = (e) => {
    const currentTarget = e.currentTarget

    setTimeout(() => {
      /* avoid calling on blur when target is a child component (input) */
      if (currentTarget.contains(document.activeElement)) {
        return
      }
      this.setState({ ...this.get2digitState(), focused: false }, () => {
        if (!this.props.onChange) {
          return
        }

        const propsData = { hour: this.props.hour, minute: this.props.minute }
        const stateData = this.getDataForParent().value
        if (isEqual(propsData, stateData)) {
          return
        }

        this.props.onChange(this.getDataForParent())
      })
    }, 0)
  }

  increment = (paramName, i) => {
    let currentValue = parseInt(this.state[paramName])
    let nextState = {
      [paramName]: utils.number.incrementToNearestMultiple(currentValue, i),
    }

    switch (paramName) {
      case 'hour':
        if (!this.isValid(paramName, nextState[paramName])) {
          nextState[paramName] = i > 0 ? this.state.minHour : this.state.maxHour
        }

        if ((i === -1 && nextState[paramName] === 11) || (i === 1 && nextState[paramName] === 12)) {
          nextState.period = this.getNewPeriod()
        }
        break

      case 'minute':
        if (!this.isValid(paramName, nextState[paramName])) {
          /* increment | decrement hour when minute is over limit (reached maximum | minimum) */
          if (i > 0) {
            const reachedMaxmimum = !this.isValid('hour', parseInt(this.state.hour) + 1)
            nextState['minute'] = reachedMaxmimum ? maxMinute : minMinute
            nextState['hour'] = reachedMaxmimum ? this.state.maxHour : parseInt(this.state.hour) + 1
          } else {
            const reachedMinimum = !this.isValid('hour', parseInt(this.state.hour) - 1)
            /* default : reachedMinimum ? 00 : 60 - 15 */
            nextState['minute'] = reachedMinimum ? minMinute : maxMinute + 1 + i
            nextState['hour'] = reachedMinimum ? this.state.minHour : parseInt(this.state.hour) - 1
          }
        }
        break

      default:
        break
    }

    if (typeof nextState['minute'] !== 'undefined') {
      nextState['minute'] = utils.datetime.nbToStr2digit(nextState['minute'])
    }

    if (typeof nextState['hour'] !== 'undefined') {
      nextState['hour'] = utils.datetime.nbToStr2digit(nextState['hour'])
    }

    this.setState(nextState, () => this.props.onChange(this.getDataForParent()))
  }

  onKeyPressHour = (e) => {
    if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 39) {
      /* enter or right arrow */
      if (this.secondInput) {
        this.secondInput.focus()
      }
      e.preventDefault()
      /* this will only update and render if a modification is made : change hour "4" to "04" for example */
    } else if (e.nativeEvent.keyCode === 38) {
      /* keyup */
      this.increment('hour', 1)
    } else if (e.nativeEvent.keyCode === 40) {
      /* keydown */
      this.increment('hour', -1)
    }
  }

  onKeyPressMinute = (e) => {
    if (e.nativeEvent.keyCode === 13 || e.nativeEvent.keyCode === 39) {
      /* enter or right arrow */
      if (this.periodInput) {
        this.periodInput.focus()
      }
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 37) {
      /* left arrow */
      if (this.firstInput) {
        this.firstInput.focus()
      }
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 38) {
      /* keyup */
      this.increment('minute', 1)
    } else if (e.nativeEvent.keyCode === 40) {
      /* keydown */
      this.increment('minute', -1)
    }
  }

  onKeyPressPeriod = (e) => {
    let nextState = {}
    if (e.nativeEvent.keyCode === 65) {
      /* A */
      nextState.period = 'am'
    } else if (e.nativeEvent.keyCode === 80) {
      /* P */
      nextState.period = 'pm'
    } else if (e.nativeEvent.keyCode === 37) {
      /* left arrow */
      if (this.secondInput) {
        this.secondInput.focus()
      }
      e.preventDefault()
    } else if (e.nativeEvent.keyCode === 38 || e.nativeEvent.keyCode === 40) {
      /* keyup / keydown */
      nextState.period = this.getNewPeriod()
    }
    this.setState(nextState, () => this.props.onChange(this.getDataForParent()))
  }

  onClickPeriod = (e) => {
    this.setState({ period: this.getNewPeriod() }, () => this.props.onChange(this.getDataForParent()))
  }

  getNewPeriod = () => {
    return this.state.period === 'am' ? 'pm' : 'am'
  }

  onFocus = (event) => {
    event.target.select()
    this.setState({ focused: true })
  }

  onContainerClick = (e) => {
    if (e.target !== this.secondInput && e.target !== this.periodInput) {
      this.firstInput.focus()
    }
  }

  render() {
    const { hour, minute, period, showPeriod, focused } = this.state
    const {
      joinChildren,
      smallFontSize,
      width,
      inputWidth,
      periodWidth,
      inputFontWeight,
      className,
      active,
    } = this.props
    return (
      <Container
        className={className}
        joinChildren={joinChildren}
        smallFontSize={smallFontSize}
        onClick={this.onContainerClick}
        onBlur={this.onBlur}
        width={width}
        inputWidth={inputWidth}
        inputFontWeight={inputFontWeight}
        periodWidth={periodWidth}
        active={active}
        focused={focused}>
        <div className={'time input-container'}>
          <input
            className="gp-input hour"
            ref={(ref) => {
              this.firstInput = ref
            }}
            type="text"
            value={hour}
            placeholder="08"
            onKeyDown={this.onKeyPressHour}
            onChange={(e) => this.onChange({ paramName: 'hour', value: e.target.value })}
            onFocus={this.onFocus}
          />
          <span className="colon">:</span>
          <input
            className="gp-input minute"
            ref={(ref) => {
              this.secondInput = ref
            }}
            type="text"
            value={minute}
            placeholder="00"
            onChange={(e) => this.onChange({ paramName: 'minute', value: e.target.value })}
            onKeyDown={this.onKeyPressMinute}
            onFocus={this.onFocus}
          />
        </div>
        {showPeriod && (
          <div className="period input-container">
            <input
              className="gp-input period"
              ref={(ref) => {
                this.periodInput = ref
              }}
              type="text"
              value={period}
              onChange={() => {}}
              onClick={this.onClickPeriod}
              onFocus={this.onFocus}
              onKeyDown={this.onKeyPressPeriod}
            />
          </div>
        )}
      </Container>
    )
  }
}

TimeInput.propTypes = propTypes

export default TimeInput

const Container = styled.div`
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 4px;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

  ${(props) =>
    props.smallFontSize &&
    css`
      input {
        font-size: 12px;
      }
      .colon {
        top: 0;
      }
    `}

  .colon {
    padding: 0;
    font-size: 14px;
    top: -1px;
    position: relative;
  }

  input {
    border: none;
    color: ${styles.colors.textMediumDarkColor};
    font-size: ${styles.fonts.fontSizeSmall};
    font-weight: 400;
    padding: 0;
  }

  .colon::selection,
  input::selection {
    background: ${styles.colors.primaryColor};
    color: white;
  }

  .input-container.time {
    padding: 0;
    display: flex;
    align-items: center;
    input {
      width: ${(props) => (!isNaN(parseInt(props.inputWidth)) ? props.inputWidth : 22)}px;
      text-align: center;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .input-container.period {
    input {
      cursor: pointer;
      width: ${(props) => (!isNaN(parseInt(props.periodWidth)) ? props.periodWidth : 24)}px;
      border: none;
      text-align: center;
      text-transform: uppercase;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &:hover {
    border-color: ${styles.colors.middleGrey};

    input {
      color: ${styles.colors.primaryColor};
    }
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${styles.colors.veryLightGrey};
      border-color: ${styles.colors.middleGrey};
    `}

  ${({ focused }) =>
    focused &&
    css`
      background-color: ${styles.colors.veryLightGrey};
      border-color: ${styles.colors.primaryColor};

      input {
        color: ${styles.colors.primaryColor};
      }

      &:hover {
        border-color: ${styles.colors.primaryColor};
      }
    `}
`
