import React, { useCallback, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Flipper } from 'react-flip-toolkit'

import { models, translations, utils } from 'gipsy-misc'
import { AnimateHeight, SprintLine } from 'gipsy-ui'

import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import CredentialsAlert from 'features/auth/components/CredentialsAlert'
import {
  innerLeftPadding,
  innerNestedLeftPadding,
  innerNestedRightPadding,
  innerRightPadding,
  PageContainer,
  PageBody,
  HeaderContainer,
  NavigationTitle,
  NavigationLeftButton,
  NavigationContainer,
  PaddingContainer,
} from 'features/layout/pages'
import { TaskObjectGroups, TaskSubGroups } from 'logic/allTasks/models'
import { filterItemsByIdInList } from 'logic/allTasks'
import HomebaseLine from 'features/list/components/line'
import { CreatingItemsSection, GroupContainer, SubGroupTitleSeparator } from 'pages/today/active/components'

import Line from './line'

import container from './container'

const computeDroppableId = ({ group, subgroup, ...extraParams }) => {
  return JSON.stringify({
    group,
    subgroup,
    ...extraParams,
  })
}

function AllTasksPage({
  allTasks,
  isTaskCreationAlertShown,
  onClickDelete,
  onClickDeleteFocusSession: _onClickDeleteFocusSession,
  onClickDeleteSprint,
  onClickFocusSession,
  onClickOutsideSprint,
  onClickSprint,
  onComplete,
  onCreateInlineTask,
  onCreateInlineTaskFromSprint,
  onDrop,
  onEndSprint,
  onUpdateFocusSession,
  onRemoveFromSprint,
  onSave,
  session,
  setHighlightedEventId,
  startFsAndCreateTask,
  toHideTaskId,
}) {
  const {
    cancelTaskAction,
    clearLocalTaskState,
    creatingCalendarTask,
    creatingTask,
    editingCalendarTask,
    editingTask,
    ignoreOutsideClicks,
    isCreatingInlineTask,
    keepCreatingTasks,
    onDragEnd,
    onDragStart,
    onTaskEditStart,
    onTogglePin,
    setIgnoreOutsideClicks,
    sprintComposerProps,
    startInlineTaskCreation,
  } = useCalendarPanelContext()

  const [todayExpanded, setTodayExpanded] = useState(true)
  const [weekExpanded, setWeekExpanded] = useState(true)
  const [laterExpanded, setLaterExpanded] = useState(true)
  const [backlogExpanded, setBacklogExpanded] = useState(true)

  const onSavePageTask = useCallback(
    (task) => {
      clearLocalTaskState()
      onSave(task)
    },
    [clearLocalTaskState, onSave]
  )

  const onCreatePageTask = (task, _, { eventName }) => {
    clearLocalTaskState({ keepCreatingTasks: eventName !== utils.task.clickOutside })
    onCreateInlineTask(task)
  }

  const onStartFsAndCreate = (taskData) => {
    startFsAndCreateTask(taskData)
    clearLocalTaskState()
  }

  const onClickDeleteFocusSession = useCallback(
    ({ focusSession }) => {
      setIgnoreOutsideClicks(true)

      const callback = () => {
        setIgnoreOutsideClicks(false)
      }

      _onClickDeleteFocusSession({ focusSession, callback })
    },
    [_onClickDeleteFocusSession, setIgnoreOutsideClicks]
  )

  const handleDragEnd = useCallback(
    (draggableItem) => {
      onDragEnd()
      onDrop(draggableItem)
    },
    [onDragEnd, onDrop]
  )

  const focusSessionPopupProps = useCallback(
    () => ({
      onClickFocusSession,
      onDeleteFocusSession: onClickDeleteFocusSession,
      onUpdateFocusSession,
      setHighlightedEventId,
    }),
    [onClickFocusSession, onClickDeleteFocusSession, onUpdateFocusSession, setHighlightedEventId]
  )

  const getSprintTaskProps = useCallback(
    (item) => ({
      animateComplete: true,
      hideWhenDate: true,
      innerLeftPadding: innerNestedLeftPadding,
      innerRightPadding: innerNestedRightPadding,
      isCalendarDraggable: true,
      isDraggable: true,
      isSprintTask: true,
      item: item,
      keepJustCompleted: true,
      key: item.id,
      onComplete,
      onDelete: onClickDelete,
      onEditStart: onTaskEditStart,
      onRemoveFromSprint,
      onSave,
      startSprintCreation: sprintComposerProps.startSprintCreation,
      ...focusSessionPopupProps(),
    }),
    [
      focusSessionPopupProps,
      onClickDelete,
      onComplete,
      onRemoveFromSprint,
      onSave,
      onTaskEditStart,
      sprintComposerProps.startSprintCreation,
    ]
  )

  const getSprintLineProps = useCallback(
    () => ({
      getSprintTaskProps: (item) => getSprintTaskProps(item),
      innerLeftPadding,
      innerRightPadding: innerLeftPadding,
      isCalendarDraggable: true,
      isDraggable: true,
      onClickCallback: onClickSprint,
      onClickDelete: onClickDeleteSprint,
      onClickEdit: sprintComposerProps.onClickEditSprint,
      onClickOutside: onClickOutsideSprint,
      onEnd: onEndSprint,
      sprintInlineTaskProps: {
        creating: true,
        hideBlockToCalendarOption: true,
        hideDateInput: true,
        hideSprint: true,
        isCreating: true,
        isSprintTask: true,
        onCreate: onCreateInlineTaskFromSprint,
      },
      theme: 'orange',
    }),
    [
      getSprintTaskProps,
      onClickDeleteSprint,
      onClickOutsideSprint,
      onClickSprint,
      onCreateInlineTaskFromSprint,
      onEndSprint,
      sprintComposerProps.onClickEditSprint,
    ]
  )

  const getLineProps = useCallback(
    (item) => {
      const isEditingTask = editingTask && item.id === editingTask.id
      const showCalendarInfo = utils.datetime.isToday(item.when?.date) ? (item.pin?.time ? true : false) : true

      return {
        animateComplete: true,
        canBlockToCalendar: true,
        hideWhenDate: true,
        ignoreOutsideClicks,
        innerLeftPadding,
        innerRightPadding,
        isCalendarDraggable: true,
        isDraggable: true,
        item: isEditingTask ? editingTask : item,
        keepJustCompleted: true,
        key: item.id,
        onCancel: cancelTaskAction,
        onComplete,
        onDelete: onClickDelete,
        onEditStart: onTaskEditStart,
        onSave: onSavePageTask,
        onTogglePin,
        showCalendarEventInfo: showCalendarInfo,
        startEdition: isEditingTask,
        startSprintCreation: sprintComposerProps.startSprintCreation,
        ...focusSessionPopupProps(),
      }
    },
    [
      cancelTaskAction,
      editingTask,
      focusSessionPopupProps,
      ignoreOutsideClicks,
      onClickDelete,
      onComplete,
      onSavePageTask,
      onTaskEditStart,
      onTogglePin,
      sprintComposerProps.startSprintCreation,
    ]
  )

  const renderTodaySection = (tasks) => {
    return (
      <>
        <SubGroupTitleSeparator
          onClick={() => setTodayExpanded(!todayExpanded)}
          title={translations.general.today}
          isExpanded={todayExpanded}
        />
        <AnimateHeight duration={300} height={todayExpanded ? 'auto' : 0}>
          <TaskLineDroppable
            group={TaskObjectGroups.TODAY}
            lineProps={getLineProps}
            sprintProps={getSprintLineProps}
            tasks={tasks}
          />
        </AnimateHeight>
      </>
    )
  }

  const renderWeekSection = (tasks) => {
    return (
      <>
        <SubGroupTitleSeparator
          onClick={() => setWeekExpanded(!weekExpanded)}
          title={translations.general.thisWeek}
          isExpanded={weekExpanded}
        />

        <AnimateHeight duration={300} height={weekExpanded ? 'auto' : 0}>
          <TaskLineDroppable
            group={TaskObjectGroups.WEEK}
            lineProps={getLineProps}
            sprintProps={getSprintLineProps}
            tasks={tasks}
          />
        </AnimateHeight>
      </>
    )
  }

  const renderLaterSection = (tasks) => {
    return (
      <>
        <SubGroupTitleSeparator
          onClick={() => setLaterExpanded(!laterExpanded)}
          title={translations.general.later}
          isExpanded={laterExpanded}
        />

        <AnimateHeight duration={300} height={laterExpanded ? 'auto' : 0}>
          <TaskLineDroppable
            group={TaskObjectGroups.LATER}
            lineProps={getLineProps}
            sprintProps={getSprintLineProps}
            tasks={tasks}
          />
        </AnimateHeight>
      </>
    )
  }

  const renderBacklogSection = (tasks) => {
    return (
      <>
        <SubGroupTitleSeparator
          onClick={() => setBacklogExpanded(!backlogExpanded)}
          title={translations.general.backlog}
          isExpanded={backlogExpanded}
        />

        <AnimateHeight duration={300} height={backlogExpanded ? 'auto' : 0}>
          <TaskLineDroppable
            group={TaskObjectGroups.BACKLOG}
            lineProps={getLineProps}
            sprintProps={getSprintLineProps}
            tasks={tasks}
          />
        </AnimateHeight>
      </>
    )
  }

  const toHideTasksIdsMap = {}
  if (toHideTaskId) {
    toHideTasksIdsMap[toHideTaskId] = true
  }
  if (editingCalendarTask) {
    toHideTasksIdsMap[editingCalendarTask.id] = true
  }

  const filteredTasks = filterItemsByIdInList(allTasks, toHideTasksIdsMap)
  const todayTasks = filteredTasks?.today || []
  const weekTasks = filteredTasks?.week || []
  const laterTasks = filteredTasks?.later || []
  const backlogTasks = filteredTasks?.backlog || []

  const renderSections = () => {
    return (
      <>
        {renderTodaySection(todayTasks)}
        {renderWeekSection(weekTasks)}
        {renderLaterSection(laterTasks)}
        {renderBacklogSection(backlogTasks)}
      </>
    )
  }

  const allDraggableItems = todayTasks.concat(weekTasks).concat(laterTasks).concat(backlogTasks)

  return (
    <PageContainer>
      <PageBody>
        <HeaderContainer>
          <NavigationContainer>
            <NavigationLeftButton to={'/tasks'} label={translations.todayView.todayFocus} />
            <NavigationTitle textEllipsis>{translations.todayView.allTasks}</NavigationTitle>
          </NavigationContainer>
        </HeaderContainer>
        <PaddingContainer>
          <CredentialsAlert />
        </PaddingContainer>
        <div>
          <DragDropContext items={allDraggableItems} onDragEnd={handleDragEnd} onDragStart={onDragStart}>
            <GroupContainer>
              <CreatingItemsSection
                creationLineProps={{
                  canBlockToCalendar: true,
                  creating: true,
                  ignoreOutsideClicks: ignoreOutsideClicks,
                  innerLeftPadding,
                  innerRightPadding,
                  item: creatingTask || undefined,
                  onCancel: cancelTaskAction,
                  onCreate: onCreatePageTask,
                  onStartFsAndCreate,
                  onTogglePin,
                  startCreation: !!creatingTask || keepCreatingTasks,
                  startSprintCreation: sprintComposerProps.startSprintCreation,
                  useTitleProps: !!creatingTask,
                }}
                isCreatingCalendarTask={!!creatingCalendarTask}
                isCreatingInlineTask={isCreatingInlineTask}
                isTaskCreationAlertShown={isTaskCreationAlertShown}
                removeStartTimerButton
                removeFocusBlockButton
                session={session}
                startInlineTaskCreation={startInlineTaskCreation}
              />
              <Flipper flipKey={allTasks.today}>{renderSections()}</Flipper>
            </GroupContainer>
          </DragDropContext>
        </div>
      </PageBody>
    </PageContainer>
  )
}

const TaskLineDroppable = React.memo(({ group, lineProps, sprintProps, tasks }) => (
  <Droppable droppableId={computeDroppableId({ group, subgroup: TaskSubGroups.TASKS })} type={models.item.type.TASK}>
    {(droppableProvided) => (
      <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
        <TaskLineDraggable group={group} lineProps={lineProps} sprintProps={sprintProps} tasks={tasks} />
        {droppableProvided.placeholder}
      </div>
    )}
  </Droppable>
))

const TaskLineDraggable = React.memo(({ group, lineProps, sprintProps, tasks }) =>
  tasks.map((item, index) => (
    <Draggable
      draggableId={JSON.stringify({ id: item.id, type: item.type })}
      index={index}
      key={item.id}
      type={item.type}>
      {(draggableProvided, snapshot) => {
        if (item.type === models.item.type.SPRINT) {
          const { getSprintTaskProps, sprintInlineTaskProps, ...sprintLineProps } = sprintProps()

          return (
            <Droppable
              droppableId={computeDroppableId({
                group,
                subgroup: TaskSubGroups.SPRINT_TASKS,
                sprintId: item.id,
              })}
              type={models.item.type.TASK}>
              {(droppableProvided) => (
                <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                  <SprintLine
                    draggableProps={draggableProvided.draggableProps}
                    draggableStyle={draggableProvided.draggableProps.style}
                    dragHandleProps={draggableProvided.dragHandleProps}
                    isDragging={snapshot.isDragging}
                    innerRef={draggableProvided.innerRef}
                    sprint={item}
                    startButtonWidth={54}
                    {...sprintLineProps}>
                    <>
                      {item.tasks?.map((task, taskIndex) => (
                        <Draggable
                          draggableId={JSON.stringify({ id: task.id, type: task.type })}
                          index={taskIndex}
                          key={task.id}
                          type={models.item.type.TASK}>
                          {(sprintDraggableProvided, sprintSnapshot) => (
                            <Line
                              draggableProps={sprintDraggableProvided.draggableProps}
                              draggableStyle={sprintDraggableProvided.draggableProps.style}
                              dragHandleProps={sprintDraggableProvided.dragHandleProps}
                              innerRef={sprintDraggableProvided.innerRef}
                              isDragging={sprintSnapshot.isDragging}
                              {...getSprintTaskProps(task)}
                            />
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                      <HomebaseLine {...sprintInlineTaskProps} isInlineSprintTask sprint={item} />
                    </>
                  </SprintLine>
                </div>
              )}
            </Droppable>
          )
        }

        return (
          <Line
            draggableProps={draggableProvided.draggableProps}
            draggableStyle={draggableProvided.draggableProps.style}
            dragHandleProps={draggableProvided.dragHandleProps}
            innerRef={draggableProvided.innerRef}
            isDragging={snapshot.isDragging}
            {...lineProps(item)}
          />
        )
      }}
    </Draggable>
  ))
)

export default container(AllTasksPage)
