import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import moment from 'moment'

import Icon from 'Icon'
import RadioInput from 'Radio'

import { styles, utils } from 'gipsy-misc'

const propTypes = {
  value: PropTypes.object.isRequired,
  selected: PropTypes.bool,
}

class DropdownSelector extends Component {
  static propTypes = propTypes
  state = {
    isHovered: false,
  }

  render() {
    const { value, selected, onClick } = this.props
    const { isHovered } = this.state

    const endTime = moment(value.pin.time).add(utils.datetime.convertNanosecondsToMinute(value.estimatedTime), 'm')
    const isPast = endTime <= moment()
    return (
      <SprintContainer
        isHovered={isHovered}
        onClick={onClick}
        onMouseEnter={() => this.setState({ isHovered: true })}
        onMouseLeave={() => this.setState({ isHovered: false })}>
        <IconContainer fill={styles.colors.orangeColor} size={12} icon="Sprint" />
        <LabelContainer>
          <span>{value.title}</span>
          <PinInfoGroup color={isPast ? styles.colors.pinkColor : undefined}>
            {`${utils.datetime.beautifyDateWithDay(value.when.date)} - ${utils.datetime.beautifyTime(value.pin.time)}`}
          </PinInfoGroup>
        </LabelContainer>
        <StyledRadioInput
          isHovered={isHovered}
          disableStopPropagation
          checkedColor={styles.colors.primaryColor}
          size={16}
          checked={selected}
        />
      </SprintContainer>
    )
  }
}

export { DropdownSelector }

const alignToTop = css`
  margin-top: 3px; // due font family extraspace
  align-self: flex-start;
`

const SprintContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px 16px 22px;
  width: 100%;
  background-color: ${(props) => (props.isHovered ? styles.colors.veryLightGrey : 'inherit')};
`
const PinInfoGroup = styled.div`
  display: flex;
  color: ${(props) => props.color || styles.colors.blueColor};
  font-size: 12px;
  font-weight: normal;
`

const IconContainer = styled(Icon)`
  margin-right: 15px;
  ${alignToTop}
`

const LabelContainer = styled.span`
  display: flex;
  flex-direction: column;
  font-size: ${styles.fonts.fontSizeSmall};
  color: ${styles.colors.textMediumDarkColor};
  font-weight: 500;
`

const StyledRadioInput = styled(RadioInput)`
  margin-left: auto;
  flex-shrink: 0;
  ${alignToTop}
`
