import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled, { css } from 'styled-components'
import throttle from 'lodash/throttle'

import { styles } from 'gipsy-misc'

export default function FixedTooltip({
  className,
  children,
  content,
  horizontalOffset,
  horizontalPosition = 'right',
  portalSelector,
  style = {},
  verticalOffset,
  verticalPosition = 'top',
}) {
  const [active, setActive] = useState(false)
  const [position, setPosition] = useState({ left: '0px', top: '0px' })

  const tooltipRef = useRef(null)
  const wrapperRef = useRef(null)

  const computePosition = () => {
    const tooltipBounds = tooltipRef.current.getBoundingClientRect()
    const wrapperBounds = wrapperRef.current.getBoundingClientRect()
    const minLeft = 0
    const maxLeft = document.documentElement.clientWidth
    const minTop = 0
    const maxTop = document.documentElement.clientHeight

    let left = wrapperBounds.left
    let top = wrapperBounds.top

    left = Math.min(
      Math.max(minLeft, horizontalPosition === 'left' ? wrapperBounds.left - tooltipBounds.width : wrapperBounds.right),
      maxLeft
    )

    top = Math.min(
      Math.max(
        minTop,
        verticalPosition === 'bottom'
          ? wrapperBounds.top + tooltipBounds.height
          : wrapperBounds.top - tooltipBounds.height
      ),
      maxTop
    )

    left += horizontalOffset || 0
    top += verticalOffset || 0

    setPosition({
      left: `${left}px`,
      top: `${top}px`,
    })
  }

  const hideTooltip = useCallback(() => {
    if (!active) return

    setActive(false)
    setPosition(null)
  }, [active])

  const showTooltip = () => {
    setActive(true)
    computePosition()
  }

  useEffect(() => {
    const throttledHideTooltip = throttle(hideTooltip, 100)
    window.addEventListener('scroll', throttledHideTooltip, true)

    return () => {
      window.removeEventListener('scroll', throttledHideTooltip, true)
    }
  }, [hideTooltip])

  let portalNode
  if (portalSelector) {
    portalNode = document.querySelector(portalSelector)

    if (!portalNode) {
      throw new Error(`Selector "${portalSelector}" didn't return a valid DOM node`)
    }
  }

  const tooltipProps = {
    active: active && position,
    children: content,
    className: `FixedTooltip ${className} ${horizontalPosition || ''} ${verticalPosition || ''}`,
    ref: tooltipRef,
    style: { ...style, ...position },
  }

  return (
    <Wrapper
      className='FixedTooltip__wrapper'
      onBlur={hideTooltip}
      onFocus={showTooltip}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      ref={wrapperRef}>
      {children}
      {portalNode ? createPortal(<Tooltip {...tooltipProps} />, portalNode) : <Tooltip {...tooltipProps} />}
    </Wrapper>
  )
}

const Wrapper = styled.div``

Wrapper.displayName = 'Wrapper'

const Tooltip = styled.div`
  background-color: ${styles.colors.textMediumDarkColor};
  border-radius: 16px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 100;

  &.bottom.right {
    border-top-left-radius: 4px;
  }

  &.bottom.left {
    border-top-right-radius: 4px;
  }

  &.top.right {
    border-bottom-left-radius: 4px;
  }

  &.top.left {
    border-bottom-right-radius: 4px;
  }

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      pointer-events: all;
    `}
`

Tooltip.displayName = 'Tooltip'
