import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { CardAttribute, IconWrapper, PlaceHolder, getColor } from './commonUIComponents'

import { styles, translations, utils } from 'gipsy-misc'
import Icon from 'Icon'
import WhenDatePicker from 'WhenDatePicker'

const color = styles.colors.blueColor
export class DateWrapper extends PureComponent {
  state = {
    calendarShown: false,
    whenDatePickerPosition: {
      left: 0,
    },
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.calendarShown && !this.state.calendarShown) {
      this.setState({
        whenDatePickerPosition: { left: 0 },
      })
    }
  }

  showCalendar = (value) => {
    this.setState(
      {
        calendarShown: value,
      },
      () => {
        if (value) {
          this.setState({
            whenDatePickerPosition: { left: this.computeDropdownLeftPosition() },
          })
        }
      }
    )
  }

  handleClickOutside = (e) => {
    if (this.whenDatePickerPopup && !this.whenDatePickerPopup.contains(e.target)) {
      // we also avoid hiding calendar when the user click on the field input
      // this logic is already handled by onWrapperClick
      if (this.fieldNode && !this.fieldNode.contains(e.target)) {
        this.showCalendar(false)
      }
    }
  }

  onWrapperClick = () => {
    if (!this.props.disabled) {
      this.showCalendar(!this.state.calendarShown)
    }
  }

  computeDropdownLeftPosition = () => {
    const { getLineBounds } = this.props
    const lineBounds = getLineBounds?.()

    if (!this.state.calendarShown || !this.whenDatePickerPopup || !lineBounds) return 0

    const { right } = this.whenDatePickerPopup.getBoundingClientRect()
    const { right: lineRightPosition } = lineBounds
    const prevLeft = this.state.whenDatePickerPosition.left

    let left = prevLeft
    if (right > lineRightPosition) {
      left = lineRightPosition - right
    } else {
      return Math.min(prevLeft, 0)
    }

    return Math.min(left, prevLeft)
  }

  renderIcon() {
    const { shrinked, value } = this.props
    const { calendarShown } = this.state
    const hasValue = !!value

    return (
      <IconWrapper noMargin={shrinked}>
        <Icon
          size={14}
          icon='Calendar'
          fill={getColor({
            isSelected: calendarShown,
            hasValue: hasValue,
            valueColor: color,
            selectedColor: color,
          })}
        />
      </IconWrapper>
    )
  }

  render() {
    const {
      value,
      title,
      pinTime,
      onChange,
      shouldBlockInCalendar,
      estimatedTime,
      disabled,
      editing,
      itemId,
      shrinked,
      firstDayOfWeek,
    } = this.props
    const { whenDatePickerPosition, calendarShown } = this.state

    return (
      <CardAttribute
        disabled={disabled}
        ref={(ref) => (this.fieldNode = ref)}
        className='date-wrapper'
        value={value}
        focusedColor={color}
        onClick={this.onWrapperClick}
        squared={shrinked}>
        {shrinked ? (
          this.renderIcon()
        ) : (
          <>
            {this.renderIcon()}
            <PlaceHolder
              color={getColor({
                isSelected: calendarShown,
                hasValue: !!value,
                valueColor: color,
                selectedColor: color,
              })}
              isSelected={calendarShown}
              hasValue={!!value}>
              {value ? utils.datetime.humanizeDateTime(value, pinTime) : translations.general.date}
            </PlaceHolder>
          </>
        )}
        {calendarShown && (
          <WhenDatePickerContainer left={whenDatePickerPosition.left} ref={(ref) => (this.whenDatePickerPopup = ref)}>
            <WhenDatePicker
              value={value}
              title={title}
              pinTime={pinTime}
              onChange={onChange}
              editing={editing}
              itemId={itemId}
              shouldBlockInCalendar={shouldBlockInCalendar}
              estimatedTime={estimatedTime}
              firstDayOfWeek={firstDayOfWeek}
            />
          </WhenDatePickerContainer>
        )}
      </CardAttribute>
    )
  }
}

const WhenDatePickerContainer = styled.div.attrs(({ left }) => ({
  style: {
    left: `${left}px`,
  },
}))`
  position: absolute;
  top: 32px;
  z-index: 3;
  padding-bottom: 20px;
`
