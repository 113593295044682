import React from 'react'
import googleLogo from './google-logo.png'
import microsoftLogo from './microsoft-logo.png'
import { styles, translations } from 'gipsy-misc'
import styled from 'styled-components'
import media from 'styles/media'

export const GoogleButton = ({ onClick, label }) => {
  return (
    <AuthButton className='google-signup' onClick={onClick}>
      <img src={googleLogo} alt='Google logo' />
      {label}
    </AuthButton>
  )
}

export const MicrosoftButton = ({ onClick, label }) => {
  return (
    <AuthButton className='microsoft-signup' onClick={onClick}>
      <img src={microsoftLogo} alt='Microsoft logo' />
      {label}
    </AuthButton>
  )
}

const LoginButtons = ({ onGoogleLogin, onMicrosoftLogin, ...p }) => {
  return (
    <LoginButtonsContainer className='login-buttons' {...p}>
      <GoogleButton onClick={onGoogleLogin} label={translations.auth.loginGoogle} />
      <MicrosoftButton onClick={onMicrosoftLogin} label={translations.auth.loginMicrosoft} />
    </LoginButtonsContainer>
  )
}

const LoginButtonsContainer = styled.div`
  display: flex;
  > :first-child {
    margin-right: 20px;
  }
  ${media['phone-wide']`
    display: block;
    width: 100%;
    .auth-button {
      width: 100%;
      margin-bottom: 8px;
    }
  `}
`

const AuthButton = styled.button`
  padding: 0 15px 0 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  background: white;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
  color: ${styles.colors.textMediumDarkColor};
  & img {
    height: 25px;
    max-height: 25px;
    margin-right: 10px;
  }
  ${media['phone-wide']`
      width: 100%;
      margin-bottom: 8px;
  `}
`

export default LoginButtons
