import moment from 'moment'

import { type as itemTypes } from 'models/item'
import { getNextDatesUntil } from 'utils/recurrency/scheduling'
import { RecurrencyDetails } from 'utils/recurrency/types'
import { generateId } from 'utils/ids'
import { Immutable, produce } from 'immer'

const numDaysAheadScheduled = 60
const { SPRINT } = itemTypes

interface RecurrencyInformation {
  recurrencyDetails: RecurrencyDetails
  recurringSprintId: string
  originalRecurrentDate: string
}
export interface Sprint {
  creationTime: Date
  estimatedTime: number
  id: string
  pin: {
    time: Date
  }
  title: string
  type: string
  when: {
    date: string
  }
  recurrencyInformation?: RecurrencyInformation
}

export interface SprintWithTasks extends Sprint {
  tasks?: any[]
}

export interface RecurringSprintBlueprint {
  estimatedTime: number
  pin: {
    time: Date
  }
  title: string
}

export interface RecurringSprint {
  id: string
  creationTime: Date
  blueprint: RecurringSprintBlueprint
  recurrencyDetails: RecurrencyDetails
}

export const computeRecurringSprint = (
  userId: string,
  sprint: Sprint,
  recurrencyDetails: RecurrencyDetails
): RecurringSprint => {
  const { id, creationTime } = generateId(SPRINT, userId)
  return {
    id,
    creationTime,
    recurrencyDetails,
    blueprint: {
      estimatedTime: sprint.estimatedTime,
      pin: sprint.pin,
      title: sprint.title,
    },
  }
}

export const scheduleNextSprintsForDay = (fromDay: string, recSprint: RecurringSprint): SprintWithTasks[] | null => {
  const dates = getNextDatesUntil(
    fromDay,
    recSprint.recurrencyDetails,
    null,
    moment().add(numDaysAheadScheduled, 'days').format('YYYY-MM-DD')
  )

  if (!dates) return null

  const newSprints: SprintWithTasks[] = dates.map((date) => {
    const dateMoment = moment(date)
    const basePin = moment(recSprint.blueprint.pin.time)
    const newPinTime = basePin
      .set({ date: dateMoment.date(), month: dateMoment.month(), year: dateMoment.year() })
      .toDate()

    return {
      creationTime: recSprint.creationTime,
      estimatedTime: recSprint.blueprint.estimatedTime,
      id: `${recSprint.id}_${date}`,
      pin: {
        time: newPinTime,
      },
      recurrencyInformation: {
        recurrencyDetails: recSprint.recurrencyDetails,
        recurringSprintId: recSprint.id,
        originalRecurrentDate: date,
      },
      title: recSprint.blueprint.title,
      type: SPRINT,
      when: {
        date: date,
      },
    }
  })

  return newSprints
}

export const scheduleNextSprints = (recSprint: RecurringSprint): SprintWithTasks[] | null => {
  const today = moment().format('YYYY-MM-DD')
  const result = scheduleNextSprintsForDay(today, recSprint)
  return result
}

export const splitInstances = (
  instances: SprintWithTasks[],
  sprintId: string
): { before: SprintWithTasks[]; instance: SprintWithTasks | null; after: SprintWithTasks[] } => {
  let before: SprintWithTasks[] = []
  let after: SprintWithTasks[] = []
  if (!instances?.length) {
    return { before, instance: null, after }
  }

  const sortedInstances = instances.sort((a, b) => {
    const [, aDateStr] = a.id.split('_')
    const [, bDateStr] = b.id.split('_')
    return aDateStr < bDateStr ? -1 : 1
  })

  const instanceIdx = sortedInstances.findIndex((instance) => instance.id === sprintId)

  if (instanceIdx === -1) {
    after = instances
    return { before, instance: null, after }
  }

  before = sortedInstances.slice(0, instanceIdx)
  const instance = sortedInstances[instanceIdx]

  if (instanceIdx + 1 < sortedInstances.length) {
    after = sortedInstances.slice(instanceIdx + 1)
  }

  return { before, instance, after }
}

export const fillNewInstancesWithTasks = (
  newSprintInstances: Immutable<SprintWithTasks>[],
  oldAfterInstances: Immutable<SprintWithTasks>[],
  firstInstanceTasks: Immutable<any>[]
): { filledInstances: Immutable<SprintWithTasks>[]; toUpdateTasks: Immutable<any>[] } => {
  let filledInstances: Immutable<SprintWithTasks>[] = []
  let toUpdateTasks: Immutable<any>[] = []
  if (!newSprintInstances.length) return { filledInstances, toUpdateTasks }

  const newSprintInstancesWithTasks = []
  const [firstInstance, ...restNewSprintInstances] = newSprintInstances

  const updatedFirstInstanceTasks = produce(firstInstanceTasks || [], (draft) => {
    draft.forEach((task) => {
      task.sprintInfo.id = firstInstance.id
      task.sprintInfo.pin = firstInstance.pin
      task.sprintInfo.title = firstInstance.title
      task.sprintInfo.estimatedTime = firstInstance.estimatedTime
    })
  })

  const updatedFirstInstance = produce(firstInstance, (draft) => {
    draft.tasks = updatedFirstInstanceTasks
  })

  newSprintInstancesWithTasks.push(updatedFirstInstance)
  const whenDate = moment()
  const scheduleMap: Map<string | undefined, Immutable<SprintWithTasks>> = new Map()
  const originalScheduledDateMap = restNewSprintInstances.reduce((scheduledMap, sprint) => {
    scheduledMap.set(sprint.recurrencyInformation?.originalRecurrentDate, sprint)
    return scheduledMap
  }, scheduleMap)

  oldAfterInstances.forEach((oldInstance) => {
    const createdInstance = originalScheduledDateMap.get(oldInstance.recurrencyInformation?.originalRecurrentDate)
    let sprintTasks = oldInstance.tasks || []

    if (createdInstance && createdInstance.id !== firstInstance.id) {
      sprintTasks = produce(sprintTasks, (draft) => {
        draft.forEach((task) => {
          task.sprintInfo.id = createdInstance.id
          task.sprintInfo.pin = createdInstance.pin
          task.sprintInfo.title = createdInstance.title
          task.sprintInfo.estimatedTime = createdInstance.estimatedTime
        })
      })

      newSprintInstancesWithTasks.push({ ...createdInstance, tasks: sprintTasks })
    } else {
      sprintTasks = produce(sprintTasks, (draft) => {
        draft.forEach((task) => {
          task.sprintInfo = null
          task.when.date = whenDate.format('YYYY-MM-DD')
          task.when.rank = 0
        })
      })
    }

    toUpdateTasks.push(...sprintTasks)
  })

  toUpdateTasks = toUpdateTasks.reduce(
    ({ filterIds, tasks }, task) => {
      if (!filterIds[task.id]) {
        tasks.push(task)
        filterIds[task.id] = true
      }

      return { filterIds, tasks }
    },
    { filterIds: {}, tasks: [] }
  ).tasks

  let newSprintInstancesWithTasksMap: Map<string, Immutable<SprintWithTasks>> = new Map()
  newSprintInstancesWithTasksMap = newSprintInstancesWithTasks.reduce((map, sprint) => {
    map.set(sprint.id, sprint)
    return map
  }, newSprintInstancesWithTasksMap)

  newSprintInstances.forEach((instance) => {
    const updatedSprintInstances = newSprintInstancesWithTasksMap.get(instance.id)
    if (updatedSprintInstances !== undefined) {
      filledInstances.push(updatedSprintInstances)
    } else {
      filledInstances.push(instance)
    }
  })

  return { filledInstances, toUpdateTasks }
}
