import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import AnimateHeight from 'react-animate-height'

import { styles } from 'gipsy-misc'
import { Icon, Checkbox, AsanaProjectsAttribute, UrlsAttribute } from 'gipsy-ui'

import { Cell, Grid, ItemDuration, Row } from './commonUIComponents'

function ReportItem({
  date,
  duration,
  isTask,
  projects,
  asanaWorkspaceName,
  taskSessions,
  time,
  title,
  urlsInfo,
  onClickTaskTitle,
  onToggleComplete,
  isCompleted,
  id,
}) {
  const [sessionsExpanded, setSessionsExpanded] = useState(false)

  const renderSessionItem = () => {
    return (
      <ItemGrid className="fs-mask" relative>
        <Row>
          <Cell>{date}</Cell>
          <Cell>{time}</Cell>
          <TaskTitle>{title}</TaskTitle>
          <ProjectCell textAlign={'end'}>
            <AsanaProjectsAttribute projects={projects} asanaWorkspaceName={asanaWorkspaceName} />
          </ProjectCell>
          <ItemDuration textAlign={'end'}>{duration}</ItemDuration>
        </Row>
      </ItemGrid>
    )
  }

  const renderTaskItem = () => {
    return (
      <ItemGrid className="fs-mask" expanded={sessionsExpanded} taskMode={true} relative>
        <Row>
          <ActionsContainer>
            <Checkbox
              id={id}
              paramName="completed"
              checked={isCompleted}
              onChange={onToggleComplete}
              size={16}
              borderRadius={10}
              checkmarkTop={1.2}
              checkmarkLeft={4.5}
              checkmarkWidth={3}
              checkmarkHeight={6}
              showBoxShadow={false}
              checkmarkBorderWidth={1.5}
              borderWidth={1.5}
              checkedColor={styles.colors.middleGrey}
              checkMarkColor={styles.colors.darkGrey}
              hoverCheckMarkColor={styles.colors.darkGrey}
              hoverBackgroundColor={'white'}
              hoverBorderColor={styles.colors.darkGrey}
              borderColor={styles.colors.darkGrey}
            />
            <SessionsExpandTrigger onClick={() => setSessionsExpanded((prev) => !prev)}>
              <SessionsBadge>{taskSessions.length}</SessionsBadge>
              <Caret expanded={sessionsExpanded} icon="CaretDown" size={8} />
            </SessionsExpandTrigger>
          </ActionsContainer>

          <TaskTitle onClick={onClickTaskTitle}>{title}</TaskTitle>
          <UrlInfoCell textAlign={'end'}>
            <UrlsAttribute urlsInfo={urlsInfo} />
          </UrlInfoCell>
          <ProjectCell textAlign={'end'}>
            <AsanaProjectsAttribute projects={projects} asanaWorkspaceName={asanaWorkspaceName} />
          </ProjectCell>

          <ItemDuration textAlign={'end'}>{duration}</ItemDuration>
        </Row>
        <StyledAnimateHeight duration={200} height={sessionsExpanded ? 'auto' : 0}>
          <SessionsGrid>{taskSessions}</SessionsGrid>
        </StyledAnimateHeight>
      </ItemGrid>
    )
  }

  return isTask ? renderTaskItem() : renderSessionItem()
}

const ActionsContainer = styled(Cell)`
  display: flex;
  align-items: center;
`

const ItemGrid = styled(Grid)`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(33, 21, 81, 0.08);
  font-size: ${styles.fonts.fontSizeSmall};
  padding-bottom: 16px;
  padding-top: 16px;
  width: 100%;

  ${(props) =>
    props.expanded &&
    css`
      box-shadow: 0px 10px 13px rgba(33, 21, 81, 0.09);
    `}

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `}
`

const ProjectCell = styled(Cell)`
  display: flex;
  justify-content: flex-end;
`

const UrlInfoCell = styled(Cell)`
  padding-right: 17.5px;
`

const SessionsExpandTrigger = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0 16px 0 13px;
`

const SessionsBadge = styled.span`
  align-items: center;
  background-color: ${styles.colors.veryLightGrey};
  border-radius: 50px;
  color: ${styles.colors.primaryColor};
  display: flex;
  font-size: 9px;
  height: 24px;
  justify-content: center;
  width: 24px;
`

const Caret = styled(Icon)`
  margin-left: 4px;
  transition: transform 200ms ease-in-out;

  ${(props) =>
    props.expanded &&
    css`
      transform: rotate(180deg);
    `}
`

const TaskTitle = styled(Cell)`
  font-weight: 500;
  cursor: pointer;
  line-height: 18px;
  min-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 17.5px;
`

const StyledAnimateHeight = styled(AnimateHeight)`
  grid-column-start: 1;
  grid-column-end: 6;
`

const SessionsGrid = styled(Grid)`
  grid-template-columns: 95px 0.75fr 1fr 15% 15%;
  border-top: 1px solid ${styles.colors.middleGrey};
  grid-column-start: 1;
  grid-column-end: 5;
  margin: 16px 0 0;

  ${Cell} {
    border-bottom: 1px solid ${styles.colors.middleGrey};
    padding-bottom: 18px;
    padding-top: 18px;
  }

  ${Cell}:first-child, ${Row}:last-child ${Cell} {
    border-bottom: none;
  }

  ${Row}:last-child ${Cell} {
    padding-bottom: 0;
  }
`

export default ReportItem
