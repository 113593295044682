import get from 'lodash/get'
import { capitalize } from 'utils/string'
import { getObjectIndexInArrayMatchingAtLeastOneField } from 'utils/array'
import injected from 'injected'

export const betaListedUsers = {
  '0afdaa38-a740-4632-9652-2aaa7f6a7d04': true, // Nicolas
  'cf246364-b8f2-4349-a2a5-96bf1ea5e9bd': true, // Alina
  '717f16d6-6976-4e9b-84af-bb820e199f80': true, // Martin
  'fb701d3f-c8d7-46a9-8801-072a7f95c050': true, // Scott Haney
  '2a93588c-2af0-4a41-bb90-5ea94aeea66f': true, // Mik Skuza
}

export const betaListedMultiSyncUsers = {
  'b55a1ceb-73c0-489a-975e-a4c17599d90c': true, // Anne Plouy
  '0afdaa38-a740-4632-9652-2aaa7f6a7d04': true, // Nicolas
  '7781716d-b82e-4beb-b796-d09bfb9014d0': true, // Elric
  '5609516c-68e6-493e-a7e9-af3e04e9d324': true, // Alex Thebert
  'cf246364-b8f2-4349-a2a5-96bf1ea5e9bd': true, // Alina
  'd7fb53e0-fccc-4a15-997a-eb12e3067f45': true, // Elyssa
  'a61b36dc-11bb-49b8-afd7-eb46b19a4156': true, // Gemma
  '717f16d6-6976-4e9b-84af-bb820e199f80': true, // Martin
  'fb701d3f-c8d7-46a9-8801-072a7f95c050': true, // Scott Haney
  'a1f32826-26ad-43b3-bbc5-82917adfc42c': true, // Sorin
  '7e2eff11-d521-4bfa-89e7-d0ed96883ff5': true, // Andrew Williams
  'b9a90ffe-89e2-448b-b514-47c99396e9af': true, // Batikan
  '4f5b79bc-2e89-438c-adb9-7ee85471bdfc': true, // Kora Graham
  '256fe3fc-471f-4986-b672-77198fae770a': true, // Ra'fat H.Bandak
  '86558f03-bb33-432d-9df4-39d4754b9af3': true, // Andrew Melnyk
  'f6acb2da-7b83-4634-8f78-113b493ef570': true, // Noe pion Pro
  '9fbaa30d-c48c-45c1-9c73-4c985b820a21': true, // Noe pion perso
}

export const isUserInBetaList = (user) => {
  return injected.env !== 'kosmotime' || betaListedUsers[user.id]
}

export const isUserInBetaMultiCalendarSync = (user) => {
  return injected.env !== 'kosmotime' || betaListedMultiSyncUsers[user.id]
}

export const getDisplayName = (user) => {
  let displayName = 'Unknown'
  if (user.displayName) {
    displayName = user.displayName.substr(0, user.displayName.indexOf(' '))
  } else if (user.email) {
    displayName = user.email.substr(0, user.email.indexOf('@'))
  }
  return capitalize(displayName)
}

export const getDisplayNames = (users) => {
  let displayNames = ''
  for (let i in users) {
    if (i > 0) {
      displayNames += ', '
    }
    displayNames += getDisplayName(users[i])
  }
  return displayNames
}

export const getDisplayNamesFromObject = (dict) => {
  const values = Object.values(dict)
  let displayNames = ''
  for (let i in values) {
    if (i > 0) {
      displayNames += ', '
    }
    displayNames += values[i]
  }
  return displayNames
}

export const getKey = (user) => {
  return get(user, 'userId') || get(user, 'email')
}

export const areSameUser = (user1, user2) => {
  return getKey(user1) === getKey(user2)
}

export const toUser = (obj) => {
  if (!obj) {
    return undefined
  }
  const user = {}
  user.userId = obj.userId
  user.email = obj.email
  user.displayName = obj.displayName
  user.picture = obj.picture
  return user
}

export const getUserIndexInList = (array, user) => {
  return getObjectIndexInArrayMatchingAtLeastOneField(array, user, ['userId', 'email'])
}

export const computeUserFromSessionUser = (obj) => {
  if (!obj) {
    return undefined
  }
  const user = {}
  user.userId = obj.id
  user.displayName = obj.displayName
  user.picture = obj.picture
  return user
}
