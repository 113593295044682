import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'

import { models, styles, translations, utils } from 'gipsy-misc'
import { Duration, IconButton, TimeInput2 } from 'gipsy-ui'

import variables from 'assets/styles/variables.js'
import useClickOutside from 'features/app/hooks/useClickOutside'
import {
  AddItemButtonContainer,
  AddItemButtonsContainer,
  InputsSection,
  TimeInputSection,
} from 'features/calendar/components/CalendarPanel/components/AddCalendarItemPopup'
import CalendarItemPopupContainer from 'features/calendar/components/CalendarPanel/components/CalendarItemPopupContainer'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

const componentName = 'AddSprintPopup'
const popupZIndex = 2

const AddSprintPopup = React.forwardRef(function AddSprintPopup(props, ref) {
  const { onChangeDuration, onChangeStartTime, onClickOutside, onClose, onCreateSprint, selectedSlot } = props

  useClickOutside(ref, onClickOutside)

  const dispatch = useDispatch()

  let startTime
  let estimatedTimeObj

  if (selectedSlot) {
    startTime = moment(selectedSlot.start)
    estimatedTimeObj = utils.datetime.getDurationObj({ start: startTime, end: selectedSlot.end })
  } else {
    startTime = moment()
    estimatedTimeObj = { hour: 0, minute: 30 }
  }

  const _onCreateSprint = useCallback(() => {
    const sprint = models.sprint({
      ...utils.sprint.getPinTimeAndEstimatedTimeFromCalendarSlot(selectedSlot),
    })
    onCreateSprint?.(sprint)
  }, [selectedSlot, onCreateSprint])

  useEffect(() => {
    dispatch(
      pushShortcutsGroup(
        [
          {
            key: 'Escape',
            label: translations.general.cancel,
            callback: onClose,
          },
          {
            key: 'Enter',
            label: translations.calendar.createSprint,
            callback: _onCreateSprint,
          },
        ],
        componentName
      )
    )
    return () => dispatch(popShortcutsGroup(componentName))
  }, [_onCreateSprint, dispatch, onClose])

  return (
    <CalendarItemPopupContainer
      ref={ref}
      zIndex={popupZIndex}
      padding={containerPadding}
      minWidth={variables.addCalendarTaskPopupWidth}
      maxWidth={variables.addCalendarTaskPopupWidth}
      top={props.top}
      shouldFlipTail={props.shouldFlipTail}
      left={props.left}>
      <StyledInputsSection>
        <TimeInputSection>
          <span>{translations.general.start}:</span>
          <TimeInput2
            inputColor={styles.colors.primaryColor}
            width={72}
            transparent={true}
            showPeriod={true}
            format='12h'
            hour={startTime.hours()}
            minute={startTime.minutes()}
            onChange={onChangeStartTime}
          />
        </TimeInputSection>
        <TimeInputSection>
          <span>{translations.general.duration}:</span>
          <Duration
            colonPadding={0}
            controlMargins={2}
            width={90}
            inputWidth={22}
            inputColor={styles.colors.primaryColor}
            transparent
            hour={estimatedTimeObj.hour}
            minute={estimatedTimeObj.minute}
            onChange={onChangeDuration}
          />
        </TimeInputSection>
      </StyledInputsSection>
      <AddItemButtonsContainer>
        <StyledAddItemButtonContainer>
          <IconButton
            onClick={_onCreateSprint}
            iconName={'Sprint'}
            width={136}
            height={32}
            labelColor={'white'}
            fillIcon={'white'}
            iconHoverColor={'white'}
            borderColor={styles.colors.orangeColor}
            borderHoverColor={`${styles.colors.orangeColor}80`}
            iconSize={16}
            label={translations.calendar.createBlock}
          />
        </StyledAddItemButtonContainer>
      </AddItemButtonsContainer>
    </CalendarItemPopupContainer>
  )
})

export default React.memo(AddSprintPopup)

const containerPadding = 8

const StyledInputsSection = styled(InputsSection)`
  width: 100%;
`

StyledInputsSection.displayName = 'StyledInputsSection'

const StyledAddItemButtonContainer = styled(AddItemButtonContainer)`
  & > div {
    background: ${styles.colors.orangeColor};
    width: 100%;
  }
`

StyledAddItemButtonContainer.displayName = 'StyledAddItemButtonContainer'
