const layout = 0
const taskHovered = 1
const navbar = 1
const calendarPanel = 2
const linePopup = 3
const sprintComposer = 3
const taskPanel = 3
const pageBlurLayer = 5
const supportchat = 10
const taskDragged = 10
const header = 20
const lightbox = 45
const layoutBlurLayer = 50
const shortcutsBar = 60
const homebaseSnackbar = 70
const mobileWarn = 100

const zIndex = {
  calendarPanel,
  header,
  homebaseSnackbar,
  layout,
  layoutBlurLayer,
  lightbox,
  linePopup,
  mobileWarn,
  navbar,
  pageBlurLayer,
  shortcutsBar,
  sprintComposer,
  supportchat,
  taskDragged,
  taskHovered,
  taskPanel,
}

const variables = { zIndex }

export default variables
