export enum ScheduleTypes {
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export type dayList = number[]

export type DailyOptions = {
  every: number
}

export type WeeklyOptions = {
  days: dayList
  every: number
}

interface RecurrencyInfo {
  firstOccurrence: string
  schedule: ScheduleTypes
}

export interface DailyRecurrence extends RecurrencyInfo {
  dailyOptions: DailyOptions
}

export interface WeeklyRecurrence extends RecurrencyInfo {
  weeklyOptions: WeeklyOptions
}

export type RecurrencyDetails = DailyRecurrence | WeeklyRecurrence
