import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DragDropContext } from 'react-beautiful-dnd'
import { produce } from 'immer'

import { todayview as todayviewApi } from 'gipsy-api'
import { models, utils } from 'gipsy-misc'

import { sortListByTypeAndScheduledTime, isBefore } from 'logic/allTasks'
import { useCalendarPanelContext } from 'features/calendar/components/CalendarPanel/context'
import usePageActions from 'features/hooks/usePageActions2'
import { updateItems } from 'store/items/actions'
import { sortListByWhenRank } from 'logic/list'
import {
  droppableId as sprintComposerDroppableId,
  droppableType as sprintComposerDroppableType,
} from 'features/sprintComposer'
import {
  addTask as addTaskToSprintComposer,
  adjustRanks as adjustSprintComposerRanks,
  removeTask as removeTaskFromSprintComposer,
  setDragging as setSprintComposerDragging,
} from 'store/sprintComposer/actions'
import TaskPanel from 'features/taskPanel'
import {
  droppableId as onboardingDroppableId,
  droppableType as onboardingDroppableType,
} from 'pages/onboarding/steps/AddTasks'
import { addFilteredId, removeFilteredId, setDragging } from 'store/taskPanel/actions'

export const sprintDraggableType = 'sprintTask'
export const taskPanelDroppablePrefix = 'taskPanel'

export const sections = {
  BACKLOG: 'backlog',
  OTHER: 'other',
  PINNED: 'pinned',
}

function TaskPanelDragDropContext({
  actions: {
    addFilteredId,
    addTaskToSprintComposer,
    adjustSprintComposerRanks,
    removeFilteredId,
    removeTaskFromSprintComposer,
    setDragging,
    setSprintComposerDragging,
    updateItems,
  },
  children,
  filteredIds,
  inOnboarding,
  isSprintComposerShown,
  tmpItems,
}) {
  const { sprintRollbackHelper } = useCalendarPanelContext()
  const { allItems, findItemById, onTaskDroppedInSprint, updateCalendarItem } = usePageActions()

  const itemList = useMemo(() => {
    const itemGroups = {
      [sections.BACKLOG]: [],
      withDate: {},
    }

    // we create a map to know the first instance of reccuring items
    const mapRecSprintIdFirstInstanceId = Object.keys(allItems).reduce((map, id) => {
      const item = allItems[id]
      if (!item || item?.completed === 1 || item.completionTime) return map
      if (utils.sprint.isRecurrent(item)) {
        const recSprintId = utils.sprint.getRecSprintId(item)
        const firstInstanceId = map[recSprintId]
        if (firstInstanceId) {
          const firstInstance = allItems[firstInstanceId]
          if (isBefore(item, firstInstance)) {
            map[recSprintId] = id
          }
        } else {
          map[recSprintId] = id
        }
      }
      return map
    }, {})

    const pageItems = Object.keys({ ...allItems, ...tmpItems }).reduce((items, id) => {
      const item = findItemById(id)

      if (!item || item?.completed === 1 || filteredIds[item.id] || item.completionTime) return items

      if (utils.sprint.isRecurrent(item) && !item.tasks?.length) {
        const recSprintId = utils.sprint.getRecSprintId(item)
        const firstInstanceId = mapRecSprintIdFirstInstanceId[recSprintId]
        if (firstInstanceId !== id) return items
      }

      const date = item.when?.date

      if (date) {
        items.withDate[date] = items.withDate[date] || { [sections.PINNED]: [], [sections.OTHER]: [] }

        if (item.pin) {
          items.withDate[date][sections.PINNED].push(item)
        } else {
          items.withDate[date][sections.OTHER].push(item)
        }
      } else {
        items[sections.BACKLOG].push(item)
      }

      return items
    }, itemGroups)

    Object.keys(pageItems.withDate).forEach((dateGroup) => {
      sortListByTypeAndScheduledTime(pageItems.withDate[dateGroup][sections.PINNED])
      sortListByWhenRank(pageItems.withDate[dateGroup][sections.OTHER])
    })

    sortListByWhenRank(pageItems[sections.BACKLOG])
    return pageItems
  }, [allItems, filteredIds, findItemById, tmpItems])

  const startDragging = useCallback(() => setDragging(true), [setDragging])
  const stopDragging = useCallback(() => setDragging(false), [setDragging])

  const handleDrag = async (data) => {
    if (!data.destination) return

    const { destination, draggableId, source } = data
    const { date: destinationDate, id: destinationId, section: destinationSection, type: destinationType } = JSON.parse(
      destination.droppableId
    )
    const { date: sourceDate, id: sourceId, section: sourceSection, type: sourceType } = JSON.parse(source.droppableId)
    const item = findItemById(draggableId)

    if (!item) return

    if (
      sourceId === destinationId &&
      (destinationId === sprintComposerDroppableId || destinationId === onboardingDroppableId)
    ) {
      adjustSprintComposerRanks(draggableId, data.destination.index)
      return
    }

    if (destinationType === models.item.type.SPRINT) {
      if (item.type === models.item.type.SPRINT) return

      return await onTaskDroppedInSprint({
        destinationIndex: data.destination.index,
        sprintId: destinationId,
        taskId: draggableId,
      })
    }

    let updatedSprintId
    const rank = data.destination.index + 1
    const updatedItem = produce(item, (draft) => {
      draft.when.date = destinationDate || ''
      draft.when.rank = rank

      if (sourceType === models.item.type.SPRINT || sourceType === sprintComposerDroppableType) {
        updatedSprintId = draft.sprintInfo?.id
        delete draft.sprintInfo
      }
    })

    if (sourceId === sprintComposerDroppableId || sourceId === onboardingDroppableId) {
      removeTaskFromSprintComposer(updatedItem.id)
      removeFilteredId(updatedItem.id)
    } else if (destinationId === sprintComposerDroppableId || destinationId === onboardingDroppableId) {
      addTaskToSprintComposer(updatedItem)
      addFilteredId(updatedItem.id)
      adjustSprintComposerRanks(draggableId, data.destination.index)
      return
    }

    let oldSectionItems = sourceDate ? itemList.withDate[sourceDate][sourceSection] : itemList[sourceSection]
    let newSectionItems = destinationDate
      ? itemList.withDate[destinationDate][destinationSection]
      : itemList[destinationSection]

    const updatedNewSectionItems = newSectionItems.filter((sectionItem) => sectionItem.id !== item.id)
    updatedNewSectionItems.splice(data.destination.index, 0, updatedItem)

    let updatedItems = updatedNewSectionItems.map((sectionItem, index) =>
      produce(sectionItem, (draft) => {
        draft.when.rank = index + 1
      })
    )

    if (oldSectionItems && oldSectionItems !== newSectionItems) {
      oldSectionItems = oldSectionItems.filter((sectionItem) => sectionItem.id !== item.id)
      updatedItems = updatedItems.concat(
        oldSectionItems.map((sectionItem, index) =>
          produce(sectionItem, (draft) => {
            draft.when.rank = index + 1
          })
        )
      )
    }

    const updatedState = updateItems(updatedItems)

    if (updatedSprintId) {
      const stateSprint = updatedState.sprints[updatedSprintId]
      stateSprint && updateCalendarItem(updatedSprintId, stateSprint)
    }

    sprintRollbackHelper.addRequest(async () => {
      await todayviewApi.dragAndDropTasksInTodaySection({
        taskId: item.id,
        toRank: rank,
        date: destinationDate || '',
        inTodaySection: false,
      })
    })
  }

  const handleTaskDragStart = (data) => {
    const { source } = data
    const { type } = JSON.parse(source.droppableId)

    if (type === sprintComposerDroppableType || type === onboardingDroppableType) {
      setSprintComposerDragging(true)
    }

    startDragging()
  }

  const handleTaskDragEnd = (data) => {
    handleDrag(data)
    setSprintComposerDragging(false)
    stopDragging()
  }

  return (
    <DragDropContext onDragEnd={handleTaskDragEnd} onDragStart={handleTaskDragStart}>
      {children}
      <TaskPanel inOnboarding={inOnboarding} isSprintComposerShown={isSprintComposerShown} itemList={itemList} />
    </DragDropContext>
  )
}

const mapStateToProps = (state) => ({
  filteredIds: state.taskPanel.filteredIds,
  tmpItems: state.taskPanel.tmpItems,
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addFilteredId,
      addTaskToSprintComposer,
      adjustSprintComposerRanks,
      removeFilteredId,
      removeTaskFromSprintComposer,
      setDragging,
      setSprintComposerDragging,
      updateItems,
    },
    dispatch
  ),
})

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TaskPanelDragDropContext))
