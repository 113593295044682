import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { styles, translations, variables } from 'gipsy-misc'
import { BlurLayer, Icon } from 'gipsy-ui'

import { calendarBlurLayerPortalId } from 'features/calendar/components/CalendarPanel'
import { fadeIn, spinAnimation } from 'features/keyframes'
import { popShortcutsGroup, pushShortcutsGroup } from 'store/shortcuts/actions'

const componentName = 'SyncingOverlay'

export default function SyncingOverlay() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pushShortcutsGroup([], componentName))

    return () => {
      dispatch(popShortcutsGroup(componentName))
    }
  }, [dispatch])

  const portalNode = document.querySelector(`#${calendarBlurLayerPortalId}`)

  return portalNode
    ? createPortal(
        <StyledBlurLayer active name='syncing-overlay'>
          <Content>
            <RefreshIcon fill={styles.colors.textDarkColor} icon='Refresh' size={16} />
            <SyncingText>{translations.calendarList.syncingCalendar}</SyncingText>
          </Content>
        </StyledBlurLayer>,
        portalNode
      )
    : null
}

const StyledBlurLayer = styled(BlurLayer)`
  align-items: center;
  animation: ${fadeIn} 300ms ease-in-out forwards;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${variables.zIndex.lightbox};

  &.syncing-overlay-layer,
  &.syncing-overlay-layer.active {
    position: absolute;

    ::before {
      background: ${styles.colors.darkGrey};
      border-radius: 8px;
      opacity: 0.5;
      z-index: -1;
    }

    .syncing-overlay-content {
      display: flex;
      height: calc(100% - 128px);
      overflow: visible;
      width: auto;
    }
  }
`

StyledBlurLayer.displayName = 'StyledBlurLayer'

const Content = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 60px 100px rgba(33, 21, 81, 0.27);
  display: flex;
  flex-flow: column;
  margin: auto;
  max-height: 100%;
  padding: 16px;
  position: relative;
  width: 100%;
`

Content.displayName = 'Content'

const RefreshIcon = styled(Icon)`
  animation: ${spinAnimation} 1s ease-in-out infinite;
  margin: 0 auto 8px;
`

RefreshIcon.displayName = 'RefreshIcon'

const SyncingText = styled.p`
  font-size: 14px;
  margin: 0 auto;
`

SyncingText.displayName = 'SyncingText'
