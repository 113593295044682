import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AnimateHeight from 'react-animate-height'
import styled from 'styled-components'
import moment from 'moment'

import { styles, translations } from 'gipsy-misc'
import { PrimaryButton, RadioInput } from 'gipsy-ui'

import { closePopup, openPopup } from 'store/popup/actions'
import DayPicker, { Day, Days } from 'features/sprintComposer/components/Recurrency/components/DayPicker'
import { getRecurrencyLabel, recurrencyOptions } from 'features/sprintComposer/components/Recurrency/utils'

export default function RecurrencePopup({ firstDayOfWeek, onContinue, show, startTime }) {
  const dispatch = useDispatch()

  const [daysSelected, setDaysSelected] = useState({})
  const [typeSelected, setTypeSelected] = useState(recurrencyOptions.NO_REPEAT)

  const selectNoRepeat = () => setTypeSelected(recurrencyOptions.NO_REPEAT)
  const selectDaily = () => setTypeSelected(recurrencyOptions.DAILY)
  const selectWeeklyOnCurrentDay = () => setTypeSelected(recurrencyOptions.WEEKLY_ON_CURRENT_DAY)
  const selectEveryWeekday = () => setTypeSelected(recurrencyOptions.EVERY_WEEKDAY)
  const selectCustom = () => setTypeSelected(recurrencyOptions.CUSTOM)

  const handleChange = (updatedDaysSelected) => {
    setDaysSelected(updatedDaysSelected)
  }

  const handleContinue = useCallback(() => {
    const days = Object.keys(daysSelected).reduce((days, dayKey) => {
      if (!daysSelected[dayKey]) return days

      days.push(Number.parseInt(dayKey))
      return days
    }, [])

    onContinue({ days, typeSelected })
  }, [daysSelected, onContinue, typeSelected])

  useEffect(() => {
    if (show) {
      dispatch(
        openPopup({
          centeredTitle: true,
          component: (
            <RecurrencePanel>
              <OptionContainer onClick={selectNoRepeat}>
                <StyledRadioInput
                  checked={typeSelected === recurrencyOptions.NO_REPEAT}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={16}
                />
                <span>{translations.sprint.recurrency.noRepeat}</span>
              </OptionContainer>
              <OptionContainer onClick={selectDaily}>
                <StyledRadioInput
                  checked={typeSelected === recurrencyOptions.DAILY}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={16}
                />
                <span>{translations.sprint.recurrency.daily}</span>
              </OptionContainer>
              <OptionContainer onClick={selectWeeklyOnCurrentDay}>
                <StyledRadioInput
                  checked={typeSelected === recurrencyOptions.WEEKLY_ON_CURRENT_DAY}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={16}
                />
                <span>
                  {getRecurrencyLabel({
                    recurrencyType: recurrencyOptions.WEEKLY_ON_CURRENT_DAY,
                    sprintStartTime: moment(startTime || new Date()),
                  })}
                </span>
              </OptionContainer>
              <OptionContainer onClick={selectEveryWeekday}>
                <StyledRadioInput
                  checked={typeSelected === recurrencyOptions.EVERY_WEEKDAY}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={16}
                />
                <span>{translations.sprint.recurrency.everyWeekday}</span>
              </OptionContainer>
              <OptionContainer onClick={selectCustom}>
                <StyledRadioInput
                  checked={typeSelected === recurrencyOptions.CUSTOM}
                  checkedColor={styles.colors.primaryColor}
                  disableStopPropagation
                  size={16}
                />
                <span>{translations.sprint.recurrency.custom}</span>
              </OptionContainer>
              <AnimateHeight duration={300} height={typeSelected === recurrencyOptions.CUSTOM ? 'auto' : 0}>
                <CustomRecurrence animate={{ height: 'auto' }} exit={{ height: 0 }} initial={{ height: 0 }}>
                  <span>{translations.general.repeatOn}:</span>
                  <DayPicker daysSelected={daysSelected} firstDayOfWeek={firstDayOfWeek} onChange={handleChange} />
                </CustomRecurrence>
              </AnimateHeight>
              <StyledPrimaryButton
                height={32}
                onClick={handleContinue}
                text={translations.general.continue}
                width={123}
              />
            </RecurrencePanel>
          ),
          logo: 'zen',
          title: translations.onboarding.calendarStep.recurrencePopupTitle,
        })
      )

      return
    }

    dispatch(closePopup())
  }, [daysSelected, dispatch, firstDayOfWeek, handleContinue, onContinue, show, startTime, typeSelected])

  return null
}

const RecurrencePanel = styled.div`
  display: flex;
  flex-flow: column;
  padding: 12px 4px 30px;
`

RecurrencePanel.displayName = 'RecurrencePanel'

const OptionContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 16px;
`

OptionContainer.displayName = 'OptionContainer'

const StyledRadioInput = styled(RadioInput)`
  margin-right: 16px;
`

StyledRadioInput.displayName = 'StyledRadioInput'

const CustomRecurrence = styled.div`
  padding: 16px 0;

  ${Days} {
    justify-content: space-evenly;
    margin-top: 8px;

    ${Day}:last-of-type {
      margin-right: 0;
    }
  }

  ${Day} {
    flex: 1;
    height: 32px;
    min-width: 36px;
    padding: 0;
  }
`

CustomRecurrence.displayName = 'CustomRecurrence'

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 16px auto 0;
`

StyledPrimaryButton.displayName = 'StyledPrimaryButton'
