import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { translations } from 'gipsy-misc'
import { DropdownSelector } from './DropdownSelector'
import LineAttributeDropdown from 'LineComponents/LineAttributeDropdown'

export default class SprintSelect extends PureComponent {
  onSelect = (sprint, selected) => {
    const { onChange } = this.props
    if (selected) {
      onChange(sprint)
    } else {
      onChange({ id: '' })
    }
  }
  render() {
    const {
      className,
      value,
      activeSprints,
      setInnerRef,
      onClickCreateButton,
      hideCreateButton,
      inlineCreateButton,
      maxWidth,
      style,
    } = this.props
    const options = activeSprints
      ? activeSprints.map((activeSprint) => ({ value: activeSprint, label: activeSprint.title }))
      : []
    return (
      <SprintDropdownContainer ref={setInnerRef} className={className} style={style}>
        <LineAttributeDropdown
          options={options}
          createButtonIconName={'Sprint'}
          inlineCreateButton={inlineCreateButton}
          onClickCreateButton={onClickCreateButton}
          createLabel={translations.sprint.createNew}
          withCreate={!hideCreateButton}
          selectedOption={value}
          onSelect={this.onSelect}
          maxWidth={maxWidth}
          width={420}
          emptyLabel={translations.sprint.noSprints}
          dropdownSelector={DropdownSelector}
        />
      </SprintDropdownContainer>
    )
  }
}

const SprintDropdownContainer = styled.div`
  border-radius: 8px;
`
