import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { TagsWrapper } from './components/TagsWrapper'
import { ProjectsWrapper } from './components/ProjectsWrapper'
import { DateWrapper } from './components/DateWrapper'
import { SpentTimeWrapper } from './components/SpentTimeWrapper'
import LinkWrapper from './components/LinkWrapper'
import { Title } from './components/Title'
import { SprintWrapper } from './components/SprintWrapper'
import { PinTimeWrapper } from './components/PinTimeWrapper'
import { getMakeTriggerForProjectsTags } from './utils'
import { ButtonsContainer, AttributesContainer, TitleContainer } from './components/commonUIComponents'

import { styles } from 'gipsy-misc'
import FocusSessionsPopup from 'FocusSession/Popup'
import asanaLogo from 'assets/asana.png'

import { breakpoints } from 'styles/media'

export default React.forwardRef(function EditingLine(props, ref) {
  const [contentWidth, setContentWidth] = useState(null)

  const contentRef = useRef(null)
  const titleRef = useRef(null)

  const {
    item,
    activeTags,
    allProjects,
    onTogglePin,
    toggleFocusSessionsPopup,
    onDelete,
    onProjectsChange,
    onTagsChange,
    isCreating,
    readOnlyDate,
    inChromeExtension,
    hideBlockToCalendarOption,
    hideCreateButton,
    marginBottom,
    marginLeft,
    marginRight,
    onTitleChange,
    canBlockToCalendar,
    clearHighLightedEvent,
    onClickFocusSession,
    onDeleteFocusSession,
    onUpdateFocusSession,
    isFocusSessionsPopupShown,
    onUrlsInfoChange,
    onDateChange,
    hideSprint,
    startSprintCreation,
    onSprintChange,
    activeSprints,
    renderBottomButtons,
    onStartFsAndCreate,
    onCancel,
    onCreate,
    onStartFocus,
    onSave,
    hideDateInput,
    hideSpentTimeInput,
    onClick,
    firstDayOfWeek,
  } = props

  useEffect(() => {
    titleRef.current?.focus()
  }, [])

  const makeTrigger = getMakeTriggerForProjectsTags(activeTags, allProjects)

  const handleItemSelected = useCallback(
    ({ currentTrigger, item }) => {
      switch (currentTrigger) {
        case '#':
          onProjectsChange({
            value: item,
            method: 'add',
            paramName: 'projects',
          })
          break
        case '$':
          onTagsChange({
            value: item,
            method: 'add',
            paramName: 'tags',
          })
          break
        default:
          break
      }
    },
    [onProjectsChange, onTagsChange]
  )

  const date = item.pin?.time ?? item.when?.date ?? ''

  const isTaskPTT = item.pin?.time
  const isAsanaTask = !!item.asanaInfo
  const disableDateInput = readOnlyDate || item.sprintTask?.id

  const hasFocusSessions = item.focusSessions?.length > 0
  const showDateWrapper = (!item.sprintInfo || !item.sprintInfo.id) && !hideDateInput
  const showSpentTimeWrapper = !isCreating && item.focusSessions && item.focusSessions.length > 0 && !hideSpentTimeInput

  useLayoutEffect(() => {
    const setContentClientWidth = () => {
      if (!contentRef.current) return
      setContentWidth(contentRef.current.clientWidth)
    }

    setContentClientWidth()
    window.addEventListener('resize', setContentClientWidth)

    return () => {
      window.removeEventListener('resize', setContentClientWidth)
    }
  }, [])

  const getLineBounds = useCallback(() => {
    if (!contentRef.current) return
    return contentRef.current.getBoundingClientRect()
  }, [])

  const shouldShrinkAttributes = !contentWidth || contentWidth < breakpoints.phone

  return (
    <LineContainer
      className='fs-mask'
      ref={ref}
      onClick={onClick}
      hasSideIcon={!hideBlockToCalendarOption}
      key={item.id}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}>
      <div ref={contentRef}>
        <TitleContainer>
          <Title
            disableRightBorderRadius={isAsanaTask}
            title={item.title}
            titleRef={titleRef}
            onTitleChange={onTitleChange}
            makeTrigger={makeTrigger}
            handleItemSelected={handleItemSelected}
            pinTime={item.pin && item.pin.time}
          />
          {isAsanaTask && (
            <AsanaIconContainer>
              <AsanaIcon alt={'asana logo'} src={asanaLogo} />
            </AsanaIconContainer>
          )}
        </TitleContainer>
        {canBlockToCalendar && !hideBlockToCalendarOption && (
          <PinTimeWrapper onTogglePin={onTogglePin} value={item.pin && item.pin.time} />
        )}
        {isFocusSessionsPopupShown && hasFocusSessions && (
          <FocusSessionsPopup
            item={item}
            onDeleteFocusSession={onDeleteFocusSession}
            onUpdateFocusSession={onUpdateFocusSession}
            clearHighLightedEvent={clearHighLightedEvent}
            onClickFocusSession={onClickFocusSession}
            inChromeExtension={inChromeExtension}
            maxWidth={contentWidth}
            firstDayOfWeek={firstDayOfWeek}
          />
        )}

        <AttributesContainer>
          {showSpentTimeWrapper && (
            <SpentTimeWrapper
              toggleFocusSessionsPopup={toggleFocusSessionsPopup}
              item={item}
              shrinked={shouldShrinkAttributes}
            />
          )}
          <LinkWrapper
            maxWidth={contentWidth}
            onChange={onUrlsInfoChange}
            shrinked={shouldShrinkAttributes}
            value={item.urlsInfo}
          />
          <TagsWrapper
            onChange={onTagsChange}
            shrinked={shouldShrinkAttributes}
            value={item.tags}
            activeTags={activeTags}
          />
          {showDateWrapper && (
            <DateWrapper
              getLineBounds={getLineBounds}
              onChange={onDateChange}
              title={item.title}
              editing={!isCreating}
              itemId={item.id}
              value={date}
              disabled={disableDateInput}
              estimatedTime={item.estimatedTime}
              pinTime={isTaskPTT && item.pin.time}
              shrinked={shouldShrinkAttributes}
              firstDayOfWeek={firstDayOfWeek}
            />
          )}

          <ProjectsWrapper
            getLineBounds={getLineBounds}
            onChange={onProjectsChange}
            shrinked={shouldShrinkAttributes}
            value={item.projects}
            allProjects={allProjects}
          />
          {!hideSprint && (
            <SprintWrapper
              maxWidth={contentWidth}
              getLineBounds={getLineBounds}
              onChange={onSprintChange}
              hideCreateButton={hideCreateButton || inChromeExtension}
              shrinked={shouldShrinkAttributes}
              startSprintCreation={startSprintCreation}
              value={item.sprintInfo}
              activeSprints={activeSprints}
            />
          )}
        </AttributesContainer>

        <ButtonsContainer>
          {renderBottomButtons?.({
            onStartFsAndCreate,
            onCancel: onCancel,
            onCancelEdit: onCancel,
            onCreate: onCreate,
            onStartFocus: onStartFocus,
            onDelete: onDelete,
            onSave: onSave,
          })}
        </ButtonsContainer>
      </div>
    </LineContainer>
  )
})

const AsanaIconContainer = styled.span`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  margin-right: 6px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 40px;
  width: 35px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${styles.colors.greyBorderColor};
  border-left: none;
`
const AsanaIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: visible;
  position: relative;
  background: white;
  border-radius: ${(props) => (props.hasSideIcon ? `10px 0 10px 10px` : `10px`)};
  padding: ${styles.spacings.lineContainerPadding};
  box-shadow: 0 1px 1px rgba(33, 21, 81, 0.08);
  margin-bottom: ${(props) => (isNaN(parseInt(props.marginBottom)) ? 5 : props.marginBottom)}px;
  margin-left: ${(props) => props.marginLeft}px;
  margin-right: ${(props) => props.marginRight}px;
`
