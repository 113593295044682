import moment from 'moment'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'

import { constants, translations } from 'gipsy-misc'

/* ************************************************************* */
/* ************************ items utils ************************ */
/* ************************************************************* */

export const findItemAndGroupById = (obj, id) => {
  if (!obj || !id) {
    return { item: undefined, group: '', index: -1 }
  }
  let index = null
  for (let group in obj) {
    if (group === 'completed') {
      if ((index = findIndex(obj[group], ['id', id])) !== -1) {
        return { item: obj[group][index], group, index }
      }
    } else if (group === 'pastSprints') {
      const pastSprints = obj['pastSprints']
      if ((index = findIndex(pastSprints, ['id', id])) !== -1) {
        return { item: pastSprints[index], group, index }
      }

      for (let sprintIdx = 0; sprintIdx < pastSprints.length; sprintIdx++) {
        const pastSprint = pastSprints[sprintIdx]
        if (pastSprint.tasks && pastSprint.tasks.length) {
          if ((index = findIndex(pastSprint.tasks, ['id', id])) !== -1) {
            return { item: pastSprint.tasks[index], group, index: sprintIdx, subIndex: index }
          }
        }
      }
    } else {
      for (let subgroup in obj[group]) {
        if ((index = findIndex(obj[group][subgroup], ['id', id])) !== -1) {
          return { item: obj[group][subgroup][index], group, subgroup, index }
        }

        if (subgroup === 'sprints') {
          const sprints = obj[group]['sprints']
          for (let sprintIdx = 0; sprintIdx < sprints.length; sprintIdx++) {
            const sprint = sprints[sprintIdx]
            if (sprint.tasks && sprint.tasks.length) {
              if ((index = findIndex(sprint.tasks, ['id', id])) !== -1) {
                return { item: sprint.tasks[index], group, subgroup, index: sprintIdx, subIndex: index }
              }
            }
          }
        }
      }
    }
  }

  return { item: undefined, group: '', index: -1 }
}

/* ************************************************************* */
/* ************************ list utils ************************* */
/* ************************************************************* */

export const findInListById = (list, id) => {
  if (!list || !id) {
    return null
  }
  let element = find(list, ['id', id])
  return element
}

export const findIndexInListById = (list, id) => {
  if (!list || !id) {
    return null
  }
  let index = findIndex(list, ['id', id])

  return index
}

export const getLastValueOfList = (list, key) => {
  if (!list || list.length === 0) {
    return ''
  }
  const lastSortKey = list[list.length - 1][key]
  return lastSortKey
}

/* sum all non-free list elements estimatedTime */
export const getListTotalDuration = (list) => {
  let total = 0

  for (let element of list) {
    if (!element || element.free) {
      continue
    }
    total += element.estimatedTime
  }

  return total
}

export const groupArchiveListByDay = (list) => {
  let result = {}
  const now = moment()

  for (let element of list) {
    const date = moment.parseZone(element.completionTime)
    let key = date.format('YYYY-MM-DD')
    if (now.diff(date, 'days') > constants.nbDaysToShowInArchivePage) {
      key = 'all'
    }
    result[key] = result[key] || []
    result[key].push(element)
  }

  return result
}

// group is either a date ar 'all' (see func groupArchiveListByDay)
export const getDoneText = (group, task) => {
  if (group === 'all') {
    return translations.general.doneAt.replace('%date', moment(task.completionTime).format('YYYY-MM-DD h:mm A'))
  }
  return translations.general.doneAt.replace('%date', moment(task.completionTime).format('h:mm A'))
}

export const moveElementInArrFromPositionToPosition = (arr, element, fromIdx, toIdx) => {
  arr.splice(fromIdx, 1)
  arr.splice(toIdx, 0, element)
  return arr
}

export const updateTaskInArr = (tasksArr, updatedTask) => {
  const idx = tasksArr.findIndex((task) => task.id === updatedTask.id)
  if (idx < 0) {
    return tasksArr
  }
  tasksArr[idx] = updatedTask
  return tasksArr
}

export const updateTaskInArrById = (tasksArr, updatedTask, taskId) => {
  const idx = tasksArr.findIndex((task) => task.id === taskId)
  if (idx < 0) {
    return tasksArr
  }
  const newTaskArr = [...tasksArr]
  newTaskArr[idx] = updatedTask
  return newTaskArr
}

export const removeTaskInArr = (tasksArr, taskToRemove) => {
  const newTaskArr = JSON.parse(JSON.stringify(tasksArr))
  const index = findIndexInListById(tasksArr, taskToRemove.id)
  if (index !== -1) {
    newTaskArr.splice(index, 1)
  }
  return newTaskArr
}

export const sortListByConditionFn = (list = [], conditionFn) => {
  for (let i = 1; i < list.length; i++) {
    for (let j = 0; j < i; j++) {
      if (conditionFn(list[j], list[i])) {
        ;[list[j], list[i]] = [list[i], list[j]]
      }
    }
  }

  return list
}

const isRankLower = (item1, item2) => {
  const item1HasRank = Number.isInteger(item1?.when?.rank)
  const item2HasRank = Number.isInteger(item2?.when?.rank)

  switch (true) {
    case item1HasRank && item2HasRank: {
      return item1.when.rank < item2.when.rank
    }

    case item1HasRank && !item2HasRank: {
      return true
    }

    case !item1HasRank && item2HasRank: {
      return false
    }

    default: {
      return moment(item1.creationTime).isBefore(moment(item2.creationTime))
    }
  }
}

export const sortListByWhenRank = (list) => sortListByConditionFn(list, (a, b) => !isRankLower(a, b))
