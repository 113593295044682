import moment from 'moment'

import { models, translations, utils } from 'gipsy-misc'

const { DAILY_SCHEDULE, WEEKLY_SCHEDULE } = models.recurrency.schedule

export const recurrencyOptions = {
  NO_REPEAT: 'NO_REPEAT',
  DAILY: 'DAILY',
  WEEKLY_ON_CURRENT_DAY: 'WEEKLY_ON_CURRENT_DAY',
  EVERY_WEEKDAY: 'EVERY_WEEKDAY',
  CUSTOM: 'CUSTOM',
}

export const getRecurrencyLabel = ({ customTypeSettings, recurrencyType, sprintStartTime }) => {
  switch (recurrencyType) {
    case recurrencyOptions.NO_REPEAT: {
      return translations.sprint.recurrency.noRepeat
    }

    case recurrencyOptions.DAILY: {
      return translations.sprint.recurrency.daily
    }

    case recurrencyOptions.WEEKLY_ON_CURRENT_DAY: {
      return translations.sprint.recurrency.weeklyOn.replace('__', sprintStartTime.format('dddd'))
    }

    case recurrencyOptions.EVERY_WEEKDAY: {
      return translations.sprint.recurrency.everyWeekday
    }

    case recurrencyOptions.CUSTOM: {
      return customTypeSettings?.days?.length
        ? utils.recurrency.details.getDaysChosenLabel(customTypeSettings.days)
        : translations.sprint.recurrency.noRepeat
    }

    default: {
      return ''
    }
  }
}

export const getRecurrencyDetails = ({ customTypeSettings, recurrencyType, sprintStartTime }) => {
  switch (recurrencyType) {
    case recurrencyOptions.NO_REPEAT: {
      return null
    }

    case recurrencyOptions.DAILY: {
      return {
        schedule: DAILY_SCHEDULE,
        dailyOptions: {
          every: 1,
        },
      }
    }

    case recurrencyOptions.WEEKLY_ON_CURRENT_DAY: {
      return {
        schedule: WEEKLY_SCHEDULE,
        weeklyOptions: {
          days: [Number.parseInt(sprintStartTime.format('d'))],
          every: 1,
        },
      }
    }

    case recurrencyOptions.EVERY_WEEKDAY: {
      return {
        schedule: WEEKLY_SCHEDULE,
        weeklyOptions: {
          days: [1, 2, 3, 4, 5],
          every: 1,
        },
      }
    }

    case recurrencyOptions.CUSTOM: {
      if (!customTypeSettings?.days?.length) return null

      return {
        schedule: WEEKLY_SCHEDULE,
        weeklyOptions: {
          days: customTypeSettings.days,
          every: 1,
        },
      }
    }

    default: {
      return null
    }
  }
}

export const getRecurrencyOptionsFromSprintInfo = (sprint) => {
  const { recurrencyInformation, when } = sprint

  if (!recurrencyInformation) {
    return {
      customSettings: null,
      type: recurrencyOptions.NO_REPEAT,
    }
  }

  const { recurrencyDetails } = recurrencyInformation
  const { schedule, weeklyOptions } = recurrencyDetails

  if (schedule === DAILY_SCHEDULE) {
    return {
      customSettings: null,
      type: recurrencyOptions.DAILY,
    }
  }

  const { days } = weeklyOptions

  if (days.length === 1 && Number.parseInt(moment(when.date).format('d')) === days[0]) {
    return {
      customSettings: null,
      type: recurrencyOptions.WEEKLY_ON_CURRENT_DAY,
    }
  }

  if (days.length === 5 && utils.integers.areElementsUniqueAndMatch(days, [1, 2, 3, 4, 5])) {
    return {
      customSettings: null,
      type: recurrencyOptions.EVERY_WEEKDAY,
    }
  }

  return {
    customSettings: {
      days,
    },
    type: recurrencyOptions.CUSTOM,
  }
}
